<div class="text-center">
  <div #iconWrapper class="mb-3">
    <i
      [@tadaEnter]
      class="fas fas fa-exclamation fa-2x bg-warning bg-gradient text-light"
    ></i>
  </div>

  <h6>Feature Unavailable</h6>
  <div class="small">We're still busy implementing this feature. Please contact <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a> for assistance.</div>
</div>
