import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() userProfile
  groupForm: FormGroup

  constructor() { }

  ngOnInit(): void {
    this.groupForm = new FormGroup({
      name: new FormControl(this.userProfile.full_name, Validators.compose([Validators.minLength(0), Validators.required])),
      profileImg: new FormControl(this.userProfile.profilePicture, Validators.compose([Validators.minLength(0), Validators.required])),
      email: new FormControl(this.userProfile.email, Validators.compose([Validators.minLength(0), Validators.required])),
    });
  }

}
