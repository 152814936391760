import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { inboxMessage } from 'src/app/interfaces/inboxMessage';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.scss']
})
export class MessageDetailsComponent implements OnInit {
  @ViewChild('messageContainer', { static: true }) messageContainer!: ElementRef;

  @Input() inboxMessage: inboxMessage
  @Input() uid: string
  messageForm: FormGroup;
  sendingMessage: boolean = false

  constructor(
    public modalRef: MdbModalRef<MessageDetailsComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.messageForm = new FormGroup({
      message: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
    });
  }

  ngAfterViewInit() {
    this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
  }

  getParticipantName(participants: any[], uid: string): string {
    const participant = participants.find(p => p.id === uid);
    return participant ? participant.name : '';
  }

  getParticipantProfilePic(participants: any[], uid: string): string {
    const participant = participants.find(p => p.id === uid);
    return participant ? participant.profilePic : '';
  }

  sendMessage() {
    if (this.messageForm.invalid) {
      return
    } else {
      this.sendingMessage = true
      const message = this.messageForm.controls.message.value
      this.messageForm.controls.message.setValue('')
      const callable = this.functions.httpsCallable('addCommunityInboxComment');
      const obs = callable({
        communityId: this.inboxMessage.communityId,
        id: this.inboxMessage.id,
        message: message
      });
      obs.subscribe({
        next: (res) => {
          this.inboxMessage.messages.push({
            body: message,
            uid: this.uid,
            created: new Date().getTime() / 1000
          })
          this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
          this.sendingMessage = false
        },
        error: (err) => {
          console.warn(err)
        },
      })
    }
  }

}
