<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Add Account</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <form [formGroup]="accountForm">

    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="accountName" id="accountName"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
          <label mdbLabel class="form-label" for="accountName">Account Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ accountForm.controls.accountName.value.length }} / 50</div>
            <div *ngIf="accountForm.controls.accountName.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="accountForm.controls.accountName.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="accountForm.controls.accountName.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="accountForm.controls.accountName.value.length < 3 || accountForm.controls.accountName.value.length === 50"
      class="noselect">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="description" id="description"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
          <label mdbLabel class="form-label" for="description">Description</label>
          <div class="form-helper">
            <div class="form-counter">{{ accountForm.controls.description.value.length }} / 50</div>
            <div *ngIf="accountForm.controls.description.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="accountForm.controls.description.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="accountForm.controls.description.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="accountForm.controls.description.value.length < 3 || accountForm.controls.description.value.length === 50"
      class="noselect">
  </form>

</div>
<div class="modal-footer">
  <button (click)="modalRef.close()" type="button" class="btn btn-grey btn-rounded"
    data-mdb-dismiss="modal">Close</button>
    <button (click)="createAccount()" [disabled]="((accountForm.invalid)||(creatingAccount))" type="button" class="btn btn-primary btn-rounded">Add account<span *ngIf="creatingAccount">&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span></button>
</div>
