import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { NgxGlideComponent } from 'ngx-glide';
import { Options } from 'ngx-qrcode-styling';
import { AppleWalletUnsupportedComponent } from 'src/app/components/modals/apple-wallet-unsupported/apple-wallet-unsupported.component';

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss']
})
export class ViewTicketComponent implements OnInit {
  initialTicketId: string
  modalRef: MdbModalRef<AppleWalletUnsupportedComponent> | null = null;
  isSafari: boolean = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || window.location.host.includes('localhost')
  isAppleDevice: boolean = /Mac|iPhone|iPod|iPad/.test(navigator.platform);
  loading: boolean = true
  loadingWalletLink: boolean = false
  tickets: any[] = []
  community = {
    name: 'Loading Ticket(s)'
  }
  event
  error
  validatorCode: string = '5555'
  public config: Options = {
    width: 200,
    height: 200,
    margin: 2,
    dotsOptions: {
      color: "#4d4d4d",
      type: "extra-rounded"
    },
    shape: 'square',
    backgroundOptions: {
      color: "#ffffff",
    },
    cornersSquareOptions: {
      color: '#000000'
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  perView: number = 1
  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustCarouselPerView()
  }

  constructor(
    private functions: AngularFireFunctions,
    private titleService: Title,
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.adjustCarouselPerView()
    this.initialTicketId = this.route.snapshot.params['id']
    this.titleService.setTitle('Loading Ticket | Event Odyssey')
    this.afAuth.authState.subscribe((user) => {
      this.fetchTicket()
    })
  }

  adjustCarouselPerView() {
    // Get the current width of the screen
    const screenWidth = window.innerWidth;
    switch (true) {
      case screenWidth < 768:
        this.perView = 1; // For smaller screens, show only 1 item
        break;
      case screenWidth < 992:
        this.perView = 2; // For medium-sized screens, show 2 items
        break;
      default:
        this.perView = 3
        // For larger screens, the perView value is already set to 3
        break;
    }
    if (this.ngxGlide) {
      this.ngxGlide.recreate();
    }
  }


  fetchTicket() {
    const callable = this.functions.httpsCallable('getTicket');
    const obs = callable({
      id: this.initialTicketId
    });
    obs.subscribe({
      next: (res) => {
        this.community = res.community
        this.event = res.event
        this.tickets = res.tickets
        if(this.tickets.length===1) {
          this.titleService.setTitle(`${this.event.name} Ticket (${this.community.name}) | Event Odyssey`)
        } else {
          this.titleService.setTitle(`${this.event.name} Tickets (${this.community.name}) | Event Odyssey`)
        }
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  addToGoogleWallet() {
    this.loadingWalletLink = true;
    const newTab = window.open('about:blank', '_blank');
    const callable = this.functions.httpsCallable('getTicketWalletLink');
    const obs = callable({
      type: 'googleWallet',
      id: this.initialTicketId
    });
    obs.subscribe({
      next: (res) => {
        this.loadingWalletLink = false;
        const token = res.token;
        if (newTab) {
          newTab.location.href = `https://pay.google.com/gp/v/save/${token}`;
        }
      },
      error: (err) => {
        this.loadingWalletLink = false
        console.warn(err);
        if (newTab) {
          newTab.close();
        }
      },
    });
  }

  addToAppleWallet() {
    this.loadingWalletLink = true;
    const callable = this.functions.httpsCallable('getTicketWalletLink');
    const obs = callable({
      type: 'appleWallet',
      id: this.initialTicketId
    });
    obs.subscribe({
      next: (res) => {
        this.loadingWalletLink = false;
        const link = document.createElement("a");
        link.href = res.pass;
        // link.download = 'pass.pkpass';
        link.click();
      },
      error: (err) => {
        this.loadingWalletLink = false
        console.warn(err);
      },
    });
  }

  addToAppleWalletUnsupported() {
    this.modalRef = this.modalService.open(AppleWalletUnsupportedComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        tickets: this.tickets
      }
    })
  }

}
