<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">Privacy Policy</h4>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <div class="row mt-3">
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <h4 class="text-uppercase fw-bold mb-4">
          Privacy Policy
        </h4>
        <p>
          This Privacy Policy explains how Event Odyssey ("we" or "us") collects, uses, and discloses personal
          information when you use our website and/or mobile application (collectively referred to as the "Service"). We
          are committed to protecting your privacy and maintaining the confidentiality and security of the personal
          information you provide to us.
        </p>

        <h5 class="fw-bold mt-4">1. Information We Collect</h5>
        <p class="mb-2">
          1.1 Personal Information:
        </p>
        <p>
          We may collect personal information, such as your name, email address, phone number, and other information you
          provide voluntarily when you register an account, make a purchase, or contact us for support.
        </p>

        <p class="mb-2">
          1.2 Usage Data:
        </p>
        <p>
          When you use our Service, we may collect usage data, such as your IP address, device information, browser
          type, pages visited, and interactions with the Service. This information helps us analyze trends, improve the
          Service, and track user activities.
        </p>

        <h5 class="fw-bold mt-4">2. Use of Information</h5>
        <p class="mb-2">
          2.1 Provide and Improve the Service:
        </p>
        <p>
          We may use your personal information to provide and maintain the Service, process your transactions, respond
          to your inquiries, and improve our products and services.
        </p>

        <p class="mb-2">
          2.2 Communication:
        </p>
        <p>
          We may use your contact information to send you important notifications, updates, promotional materials, and
          marketing communications related to our Service. You can opt out of these communications at any time.
        </p>

        <p class="mb-2">
          2.3 Analytics and Aggregated Data:
        </p>
        <p>
          We may use usage data and analytics tools to analyze trends, track user behavior, and gather demographic
          information. This data is aggregated and anonymized, and does not personally identify you.
        </p>

        <h5 class="fw-bold mt-4">3. Information Sharing</h5>
        <p class="mb-2">
          3.1 Service Providers:
        </p>
        <p>
          We may engage third-party service providers to perform certain functions on our behalf, such as payment
          processing, data analysis, marketing assistance, and customer support. These providers have access to personal
          information only as necessary to fulfill their services and are obligated to maintain its confidentiality.
        </p>

        <p class="mb-2">
          3.2 Legal Requirements:
        </p>
        <p>
          We may disclose your personal information if required by law or in good faith belief that such action is
          necessary to comply with legal obligations, protect our rights or interests, or investigate potential fraud or
          security concerns.
        </p>

        <h5 class="fw-bold mt-4">4. Data Security</h5>
        <p>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, use, or disclosure. However, no method of transmission over the internet
          or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
        <h5 class="fw-bold mt-4">5. Third-Party Links</h5>
        <p>
          Our Service may contain links to third-party websites or services that are not owned or controlled by us. This
          Privacy Policy does not apply to those third-party sites. We encourage you to review the privacy policies of
          any third-party sites or services you visit.
        </p>

        <h5 class="fw-bold mt-4">6. Children's Privacy</h5>
        <p>
          Our Service is not intended for use by individuals under the age of 13. We do not knowingly collect personal
          information from children. If you believe we have inadvertently collected personal information from a child,
          please contact us immediately.
        </p>

        <h5 class="fw-bold mt-4">7. Changes to the Privacy Policy</h5>
        <p>
          We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective
          immediately upon posting the revised policy. We encourage you to review this Privacy Policy periodically for
          any updates.
        </p>

        <h5 class="fw-bold mt-4">8. Contact Us</h5>
        <p>
          If you have any questions or concerns about this Privacy Policy, please contact us at <a
            href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a>.
        </p><br>
        <h5 class="fw-bold mt-4">Last Updated: {{lastUpdated | date}}</h5>
        <p>
          This Privacy Policy was last updated on {{lastUpdated | date:'long'}}. Any changes or modifications made to
          the Terms will be effective as of that date.
        </p>
      </div>
    </div>
  </div>
</section>
