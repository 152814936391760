import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  @Input() videoDetails
  preCheckPassed: boolean = true
  isLoadingPrecheck: boolean = true
  isSaving: boolean = false
  totalEntitledDownloads: number = 0
  remainingDownloads: number = 0
  error
  constructor(
    public modalRef: MdbModalRef<DownloadComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.fetchCreateVideoPrecheck()
  }


  fetchCreateVideoPrecheck() {
    this.isLoadingPrecheck = true
    const callable = this.functions.httpsCallable('videoDownloadPrecheck');
    const obs = callable({
      videoId: this.videoDetails.id,
    });
    obs.subscribe({
      next: (res) => {
        console.log(res)
        this.totalEntitledDownloads = res.totalDownloads
        this.remainingDownloads = res.remainingDownloads
        this.preCheckPassed = true
        this.isLoadingPrecheck = false
      },
      error: (err) => {
        this.error = err.message
        console.warn(err)
      },
    })
  }

  submit() {
    this.isSaving = true
    const callable = this.functions.httpsCallable('videoDownload');
    const obs = callable({
      videoId: this.videoDetails.id,
    });
    obs.subscribe({
      next: (res) => {
        console.log(res.url)
        this.openLink(res.url, res.ext)
        this.isSaving = false
      },
      error: (err) => {
        this.error = err.message
        this.preCheckPassed = false
        console.warn(err)
      },
    })
  }

  openLink(url: string, ext: string) {
    saveAs(url, `${this.videoDetails.name}.${ext}`);
  }

}
