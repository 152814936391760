import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbStepperComponent } from 'mdb-angular-ui-kit/stepper';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { Community } from 'src/app/interfaces/community';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-seats',
  templateUrl: './purchase-seats.component.html',
  styleUrls: ['./purchase-seats.component.scss']
})
export class PurchaseSeatsComponent implements OnInit {
  countdownMinutes: number;
  countdownSeconds: number;
  @Input() selectedSeats
  @Input() eventDetails
  @Input() communityDetails: Community


  @ViewChild('stepper') stepper!: MdbStepperComponent;
  step: number = 1
  paymentSuccessful: boolean = false;
  paymentError: string = ''
  paymentErrorType: string = 'warning'
  allStepsValid: boolean = false;
  allStepsForm: FormGroup;
  selectProductForm: FormGroup;
  selectProductValid: boolean = false
  selectPaymentForm: FormGroup;
  selectPaymentValid: boolean = false
  checkoutForm: FormGroup;
  selectProduct: any = {
    price: {},
    product: {}
  }
  checkout: any = {};
  paymentMethod: any = {};
  purchasing: boolean = false
  stripe: StripeInstance;


  constructor(
    public modalRef: MdbModalRef<PurchaseSeatsComponent>,
    private functions: AngularFireFunctions,
    private stripeFactory: StripeFactoryService,
    private analytics: AngularFireAnalytics
    ) { }

  ngOnInit(): void {
    this.setupForms();
    this.startCountdown();
  }



  setupForms() {
    this.selectProductForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.selectPaymentForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.checkoutForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.allStepsForm = new FormGroup({
      selectProductForm: this.selectProductForm,
      selectPaymentForm: this.selectPaymentForm,
      checkoutForm: this.checkoutForm,
    });
  }


  validateselectProduct(isValid) {
    isValid ? this.allStepsForm.controls.selectProductForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectProductForm.get('valid').setValue(null);
    if (!isValid) {
      this.selectProduct = {}
      this.selectProductValid = false
    } else {
      this.selectProductValid = true
    }
    this.validateAllSteps();
  }

  outputselectProduct($event) {
    this.selectProduct = $event;
  }

  validateselectPayment(isValid) {
    isValid ? this.allStepsForm.controls.selectPaymentForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectPaymentForm.get('valid').setValue(null);
    if (!isValid) {
      this.paymentMethod = {}
      this.selectPaymentValid = false
    } else {
      this.selectPaymentValid = true
    }
    this.validateAllSteps();
  }

  outputselectPayment($event) {
    this.paymentMethod = $event;
  }

  validatecheckout(isValid) {
    isValid ? this.allStepsForm.controls.checkoutForm.get('valid').setValue("valid") : this.allStepsForm.controls.checkoutForm.get('valid').setValue(null);
    if (!isValid) this.checkout = {};
    this.validateAllSteps();
  }

  outputcheckout($event) {
    this.checkout = $event;
  }

  validateAllSteps() {
    this.allStepsValid = this.allStepsForm.valid;
  }

  stepChange(event) {
    this.clearPaymentError()
    this.step = event.activeStepIndex + 1
  }

  next() {
    this.stepper.next()
  }

  previous() {
    this.stepper.previous()
  }

  purchase() {
    this.paymentError = ''
    this.purchasing = true
    const callable = this.functions.httpsCallable('createPaymentIntent');
    const obs = callable({
      seats: '',
      paymentId: this.paymentMethod.paymentMethodId
    });
    obs.subscribe({
      next: (res) => {
        this.handlePaymentIntent(res)
      },
      error: (err) => {
        this.handlePaymentError(err)
      },
    })
  }

  async handlePaymentIntent(paymentIntent) {
    this.stripe = await this.stripeFactory.create(environment.stripe, { stripeAccount: paymentIntent.stripeId })
    switch (paymentIntent.status) {
      case 'succeeded':
      case 'processing':
        this.purchasing = false
        this.paymentSuccessful = true
        break;
      case 'requires_action':
        this.paymentError = 'Your payment method requires additional information to process the payment successfully.'
        this.stripe.confirmCardPayment(paymentIntent.client_secret).subscribe(result => {
          if (result.error) { this.handlePaymentError(result.error.message, 'danger') }
          else {
            this.handlePaymentIntent(result.paymentIntent)
          }
        });

        break;
      case 'requires_payment_method':
        this.handlePaymentError(paymentIntent.error.message)
        break;
    }
  }

  handlePaymentError(message: string, type?: string) {
    if (!type) { type = 'warning' }
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    if (String(message).includes('declined')) { type = 'danger' }
    this.purchasing = false
    this.paymentErrorType = type
    this.paymentError = message
  }

  clearPaymentError() {
    this.paymentError = ''
    this.paymentErrorType = 'warning'
  }


  startCountdown(): void {
    const currentTimestamp = new Date();
    const reservationTimestamp = new Date(currentTimestamp.getTime() + 5 * 60000);
    const timeDifference = reservationTimestamp.getTime() - currentTimestamp.getTime();
    let countdownMilliseconds = Math.max(0, timeDifference);

    const updateCountdown = (): void => {
      const currentTime = new Date();
      countdownMilliseconds = Math.max(reservationTimestamp.getTime() - currentTime.getTime(), 0);
      this.countdownMinutes = Math.floor(countdownMilliseconds / 60000);
      this.countdownSeconds = Math.floor((countdownMilliseconds % 60000) / 1000);

      if (this.countdownMinutes === 0 && this.countdownSeconds === 0) {
        this.handleCountdownExpired();
      } else {
        setTimeout(updateCountdown, 1000);
      }
    };

    updateCountdown(); // Start the countdown
  }




  handleCountdownExpired(): void {
    // Perform any other necessary actions
  }

  seatsUnavailable() {
    this.modalRef.close({message: 'seatsUnavailable'})
  }

  getSelectSeatTypeStepName() {
    return this.selectedSeats.length > 1 ? 'Select Ticket Types' : 'Select Ticket Type';
  }

}
