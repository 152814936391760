<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">Get Started</h4>
      </div>
    </div>
  </div>
</div>
<section>
  <div class="container text-center text-md-start mt-5">
    <div class="row mt-3">
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12" *ngIf="givenName">
        <h4>Hey {{givenName}}, we're excited to welcome you aboard! 👋</h4>
        <p>To get started, we need to gather some information to onboard your community.</p>

        <form [formGroup]="getStartedForm">
          <div class="input-group mt-2 mb-6">
            <div class="input-container">
              <mdb-form-control>
                <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
                  class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
                <label mdbLabel class="form-label" for="name">Community Name</label>
                <div class="form-helper">
                  <div class="form-counter">{{ getStartedForm.controls.name.value.length }} / 50</div>
                  <div *ngIf="getStartedForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
                  </div>
                  <div *ngIf="getStartedForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
                  </div>
                </div>
              </mdb-form-control>
            </div>
            <span class="input-group-text border-0"><app-validation-icon [inputValid]="getStartedForm.controls.name.invalid"></app-validation-icon>
              <i class="far fa-question-circle" mdbTooltip="This is the name that will be shared for events or videos posted on behalf of your community" [html]="true" placement="right"></i></span>
          </div>
          <br class="noselect">
          <br *ngIf="getStartedForm.controls.name.value.length < 3 || getStartedForm.controls.name.value.length === 250"
            class="noselect">
          <h6>
            <span class="input-group-text border-0">Community Type&nbsp;<app-validation-icon [inputValid]="getStartedForm.controls.accountType.invalid"></app-validation-icon>
              <i class="far fa-question-circle" mdbTooltip="This will determine how we configure your experience." [html]="true" placement="right"></i></span>

          </h6>
          <div class="row mb-4">
            <div *ngFor="let account of accountTypes; let i=index" class="col-md-4 col-sm-6 mb-4">
              <div class="card text-center border border-3"
                [class.card-selected]="getStartedForm.get('accountType').value == account.type"
                [class.border-light]="getStartedForm.get('accountType').value !== account.type"
                (click)="selectAccountType(account.type)">
                <div class="card-body">
                  <i class="{{account.icon}} fa-3x mb-3"></i>
                  <h6 class="mb-2">{{account.type}}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button [disabled]="isSaving" type="submit" (click)="submit()" class="btn btn-primary btn-rounded btn-lg text-capitalize" [disabled]="!getStartedForm.valid">
              <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Creating Community</span>
              <span *ngIf="!isSaving">Create Community</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
