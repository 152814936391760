<ng-container *ngIf="!loading; else displaySkeleton">
  <div class="card">
    <div class="card-header">

      <div class="h-100 d-flex flex-row justify-content-center align-items-center">
        <div class="align-self-start flex-fill">
          To Do
        </div>
        <i class="fas fa-sync pointer" (click)="getCommunityToDos()"></i>
      </div>

    </div>
    <div class="card-body">
      <ng-container *ngIf="toDoList.length>0; else noTodos">
        <div *ngFor="let toDo of toDoList" class="h-100 d-flex flex-row justify-content-center align-items-center">
          <div class="align-self-start mt-1">
            <input mdbCheckbox class="form-check-input me-3" type="checkbox" value="" [checked]="toDo.value"
              [disabled]="true" id="flexCheckDefault" />
          </div>
          <div class="align-self-start flex-fill text-start">
            <b>{{toDo.name}}</b><br>
            <div>{{toDo.description}}</div>
          </div>
          <button [disabled]="launchingToDo" (click)="launchTodo(toDo)"
            class="btn btn-rounded btn-primary align-self-center">
            <ng-container *ngIf="launchingToDo; else notLaunching">
              <span class="spinner-border spinner-border-sm"></span>
            </ng-container>
            <ng-template #notLaunching>
              <i class="fas fa-long-arrow-alt-right"></i>
            </ng-template>
          </button>
        </div>
        <div *ngIf="removedItemCount>0" class="text-center text-muted mt-2">
          <small>{{removedItemCount}} task<span *ngIf="removedItemCount>1">s</span> hidden</small>
        </div>
      </ng-container>
      <ng-template #noTodos>
        <app-no-results message="No items found!"></app-no-results>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #displaySkeleton>
  <ng-container *ngIf="!error; else displayError">
    <div style="width: 100%; height: 200px;" class="skeleton"></div><br>
  </ng-container>
  <ng-template #displayError>
    <div class="card">
      <app-https-error [error]="error"></app-https-error>
    </div>
  </ng-template>
</ng-template>
