<ng-container *ngIf="payoutDetails; else noDetails">
  <div #pdfLoaded id="pdfLoaded">
    <div class="mt-3 ms-4 me-4 mb-4">
      <div>
        <div class="d-flex">
          <div class="flex-fill">
            <h5>Payout for {{communityDetails.name}}</h5>
            <div class="small">ID: {{payoutDetails.source.replace('po_', '')}}</div>
          </div>
          <div class="flex-fill">
            <div class="text-end">
              <img class="" src="./assets/logo.png" height="30" alt="" loading="lazy" /><br>
              support&#64;eventodyssey.com
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 ms-4 me-4 mb-5">
      <div class="">
        <div class="d-flex">
          <div class="flex-fill">
            <div class="small">Amount Paid</div>
            <h5>{{(payoutDetails.amount/100) | currency}}</h5>
          </div>
          <div class="flex-fill">
            <div class="small">Available On</div>
            <h5>{{(payoutDetails.available_on*1000) | date:'longDate'}}</h5>
          </div>
          <div class="flex-fill">
            <div class="small">Bank Account</div>
            <h5>{{bankDetails.bank_name}}</h5>
            <div class="small">Routing Number: {{bankDetails.routing_number}}</div>
            <div class="small">Account Number: ...{{bankDetails.last4}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-5 ms-4 me-4">
      <div class="">
        <div class="row border border-3">
          <div class="col-4">
            <div class="small">Charge / Refund</div>
          </div>
          <div class="col-2">
            <div class="small">Gross Amount</div>
          </div>
          <div class="col-2">
            <div class="small">Fees</div>
          </div>
          <!-- <div class="col-1">
            <div class="small">Tax</div>
          </div> -->
          <div class="col-2">
            <div class="small">Net Amount</div>
          </div>
          <div class="col-2">
            <div class="small">Total</div>
          </div>
        </div>
        <div *ngFor="let item of balanceTransactions"  class="row border-start border-end border-bottom border-3">
          <div class="col-4">
            <h5 style="margin-bottom: 0px; text-transform: capitalize;">{{item.reporting_category}}</h5>
            <div class="small">{{item.source}}</div>
          </div>
          <div class="col-2">
            {{(item.amount/100) | currency}}
          </div>
          <div class="col-2">
            {{(item.fee/100) | currency}}
          </div>
          <!-- <div class="col-1">
            {{(item.net/100) | currency}}
          </div> -->
          <div class="col-2">
            {{(item.net/100) | currency}}
          </div>
          <div class="col-2">
            {{(item.currentTotal/100) | currency}}
          </div>
        </div>
        <div class="row border-start border-end border-bottom border-3" style="height: 5px;">

        </div>
        <div class="row border border-3">
          <div class="col-8">
          </div>
          <div class="col-2">
            Grand Total
          </div>
          <div class="col-2">
            {{(payoutDetails.amount/100) | currency}}
          </div>
        </div>
      </div>
    </div>


    <div class="mt-5 ms-4 me-4">
      <div>
        <div class="d-flex">
          <div class="flex-fill">
            <app-notice [content]="'Displayed amounts reflect the gross total and the net payout amounts. It\'s important to remember that any sales tax in your product pricing is included in these figures, and you are responsible for its appropriate reporting and payment.'"
              type="warning"></app-notice><br><br>
            <h6>If you have any questions, contact us at <a
                href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a> or call at <a
                href="tel:+13174830027">+1 317-483-0027</a></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noDetails>
  <ng-container *ngIf="loading; else error">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #error>
    ERROR: Could not generate PDF
  </ng-template>
</ng-template>
