import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { AcceptTermsComponent } from '../components/modals/accept-terms/accept-terms.component';

@Injectable({
  providedIn: 'root'
})
export class JoinedCommunitiesService {
  launchedTermsModalRef: boolean = false
  termsModalRef: MdbModalRef<AcceptTermsComponent> | null = null;
  private globalAdmin = false
  private joinedCommunities = [];
  private joinedAdminCommunities = [];
  globalAdminChanged = new BehaviorSubject<any>({ 'loading': true });
  joinedCommunitiesChanged = new BehaviorSubject<any[]>([{ 'loading': true }]);
  joinedAdminCommunitiesChanged = new BehaviorSubject<any[]>([{ 'loading': true }]);

  constructor(private functions: AngularFireFunctions, private modalService: MdbModalService) { }

  async fetchJoinedCommunities() {
    const callable = this.functions.httpsCallable('getUserCommunities');
    const obs = callable({});
    obs.subscribe({
      next: (res) => {
        if (!res.termsAcceptance) { this.launchTermsModal() }
        if(res.globalAdmin) { this.globalAdmin = true }
        this.joinedCommunities = res.communities
        this.joinedAdminCommunities = res.adminCommunities
        this.globalAdminChanged.next(this.globalAdmin)
        this.joinedCommunitiesChanged.next(this.joinedCommunities)
        this.joinedAdminCommunitiesChanged.next(this.joinedAdminCommunities)
      },
      error: (err) => {
        console.warn(err)
      },
    })
  }

  launchTermsModal() {
    if (!this.launchedTermsModalRef) {
      this.termsModalRef = this.modalService.open(AcceptTermsComponent, {
        modalClass: 'modal-dialog-scrollable modal-lg',
        ignoreBackdropClick: true,
        data: {
          termsId: 'user-terms-of-service',
          enableCancelButton: false
        }
      })
      this.termsModalRef.onClose.subscribe((message: any) => {
        if (message === 'created') {
          this.fetchJoinedCommunities()
        }
      });
      this.launchedTermsModalRef = true
    }
  }



}
