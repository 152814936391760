import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { LinkCommunityComponent } from 'src/app/components/modals/link-community/link-community.component';

@Component({
  selector: 'app-list-linked-communities',
  templateUrl: './list-linked-communities.component.html',
  styleUrls: ['./list-linked-communities.component.scss']
})
export class ListLinkedCommunitiesComponent implements OnInit {
  @ViewChild('table') venuesTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  linkCommunityModalRef: MdbModalRef<LinkCommunityComponent> | null = null;
  @Input() selectLocation: boolean = false
  @Input() communityDetails: Community
  @Input() stripeAccountDetails: any
  @Output() output = new EventEmitter<any>();

  searchText: string
  loading: boolean = true
  venues: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchLinkedCommunities()
    })
  }

  fetchLinkedCommunities() {
    this.loading = true
    const callable = this.functions.httpsCallable('getLinkedCommunities');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.venues = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  selectVenueLocation(item) {

    this.output.emit((JSON.parse(JSON.stringify(item))));

  }

  linkCommunity() {
    this.linkCommunityModalRef = this.modalService.open(LinkCommunityComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.linkCommunityModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.fetchLinkedCommunities()
      }
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.venuesTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'name':
          let name = data[key]
          return name.toLowerCase().includes(searchTerm.toLowerCase());
        case 'address':
          let address = data[key]
          return address.toLowerCase().includes(searchTerm.toLowerCase());
        case 'seatmapDetails':
          let seats = data[key].seats + ' ' + data[key].seatType
          return seats.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

}
