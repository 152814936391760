import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {
  @Input() postDetails: any
  @Input() communityDetails: Community
  constructor(
    public modalRef: MdbModalRef<EditPostComponent>,
  ) { }

  ngOnInit(): void {
  }

}
