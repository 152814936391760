import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input() loggedIn
  @Input() userProfile
  @Input() globalAdmin: boolean = false
  @Input() environmentName: string
  @Input() joinedCommunitiesLoading: boolean = true
  @Input() joinedCommunities: any[] = []
  @Input() sidenav
  version: string = ''
  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.checkVersion()
  }
  checkVersion() {
    this.http.get('./assets/version.txt', {responseType: 'text'}).subscribe((data: any) => {
      this.version = data
  })}

  toggle() {
    console.log('toggle sidenav')
    this.sidenav.toggle()
    this.cdr.detectChanges()
  }


}
