import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Options } from 'ngx-qrcode-styling';

@Component({
  selector: 'app-view-label',
  templateUrl: './view-label.component.html',
  styleUrls: ['./view-label.component.scss']
})
export class ViewLabelComponent implements OnInit {
  eventName: any
  compName: any
  activationCode: string = ''
  public config: Options = {
    width: 258,
    height: 258,
    margin: 0,
    dotsOptions: {
      color: "#000000",
      type: "extra-rounded"
    },
    shape: 'square',
    backgroundOptions: {
      color: "#ffffff",
    },
    cornersSquareOptions: {
      color: '#000000'
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.fetchDetails()
  }

  fetchDetails() {
    this.route.queryParams.subscribe(params => {
      this.activationCode = params['activationCode'];
      this.eventName = params['eventName'];
      this.compName = params['compName'];
    })
  }

}
