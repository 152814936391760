<div class="modal-header">
  <h5 class="modal-title">Edit Time/Date</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="timeDateForm" autocomplete="off">

    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbDatepicker]="datepicker" type="text" class="form-control" id="date"
            (click)="datepicker.open()" formControlName="date"/>
          <label mdbLabel for="date" class="form-label">Date</label>
          <mdb-datepicker-toggle [mdbDatepicker]="datepicker"></mdb-datepicker-toggle>
          <mdb-datepicker [minDate]="minDate" [format]="'mm/dd/yyyy'" #datepicker="mdbDatepicker"></mdb-datepicker>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="timeDateForm.controls.date.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>

    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput type="text" id="doorsOpenTime" class="form-control" [mdbTimepicker]="timepicker"
            formControlName="doorsOpenTime" (click)="timepicker.open()" required />
          <label mdbLabel class="form-label" for="doorsOpenTime">Doors Open Time</label>
          <mdb-timepicker-toggle [mdbTimepickerToggle]="timepicker"></mdb-timepicker-toggle>
          <mdb-timepicker #timepicker></mdb-timepicker>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="timeDateForm.controls.doorsOpenTime.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput type="text" id="startTime" class="form-control" [mdbTimepicker]="timepickerStart"
            formControlName="startTime" (click)="timepickerStart.open()" required />
          <label mdbLabel class="form-label" for="startTime">Start Time</label>
          <mdb-timepicker-toggle [mdbTimepickerToggle]="timepickerStart"></mdb-timepicker-toggle>
          <mdb-timepicker #timepickerStart></mdb-timepicker>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0">
        <ng-container *ngIf="!(timeDateForm.hasError('doorsOpenAfterStartTime') && timeDateForm.controls.doorsOpenTime.value && timeDateForm.controls.startTime.value); else doorsOpenError">
          <app-validation-icon [inputValid]="timeDateForm.controls.startTime.invalid"></app-validation-icon>
        </ng-container>
        <ng-template #doorsOpenError>
          <app-validation-icon inputValid=false></app-validation-icon>
        </ng-template>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>

    <div *ngIf="timeDateForm.hasError('doorsOpenAfterStartTime') && timeDateForm.controls.doorsOpenTime.value && timeDateForm.controls.startTime.value" class="text-danger">
      "Doors Open Time" must occur before "Start Time"
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="submit()" [disabled]="timeDateForm.invalid" type="button" class="btn btn-primary btn-rounded">Save
  </button>
</div>
