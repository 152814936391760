import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { JoinCommunityWithCodeComponent } from '../../modals/join-community-with-code/join-community-with-code.component';
import { JoinCommunityByRequestComponent } from '../../modals/join-community-by-request/join-community-by-request.component';
import { Community } from 'src/app/interfaces/community';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-join-community',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
})
export class JoinComponent implements OnInit {
  joinWithCodeModalRef: MdbModalRef<JoinCommunityWithCodeComponent> | null = null;
  requestToJoinModalRef: MdbModalRef<JoinCommunityByRequestComponent> | null = null;
  @Input() communityDetails: Community
  @ViewChild('iconWrapper') iconWrapper: ElementRef;
  iconVisible = false;
  constructor(
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    if(this.communityDetails.requests.length>0) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.iconVisible = true;
          } else {
            this.iconVisible = false;
          }
        });
      });

      observer.observe(this.iconWrapper.nativeElement);
    }
  }

  requestAccess() {
    this.requestToJoinModalRef = this.modalService.open(JoinCommunityByRequestComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.requestToJoinModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.communityDetails.requests.push(data)
        setTimeout(() => {
          this.ngAfterViewInit()
        }, 200);
      } else {
      }
    });
  }

  joinWithCode() {
    this.joinWithCodeModalRef = this.modalService.open(JoinCommunityWithCodeComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityId: this.communityDetails.id,
        communityDetails: this.communityDetails
      }
    })
  }

}
