import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-receipt-pdf-page',
  templateUrl: './receipt-pdf-page.component.html',
  styleUrls: ['./receipt-pdf-page.component.scss']
})
export class ReceiptPdfPageComponent implements OnInit {
  chargeDetails: any
  priceDetails: any
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.fetchDetails()
  }

  fetchDetails() {
    this.route.queryParams.subscribe(params => {
      let chargeDetails = params['chargeDetails'];
      let priceDetails = params['priceDetails'];
      if(chargeDetails) { this.chargeDetails = JSON.parse(chargeDetails) }
      if(priceDetails) { this.priceDetails = JSON.parse(priceDetails) }
    })
  }

}
