import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: any): string {
    if(typeof value !== 'number') {
      value = new Date(value).getTime()
    }
    let now = Date.now();
    let seconds = Math.floor((now - value) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days ago";
    }
    if (seconds < 0) {
      interval = Math.floor(Math.abs(seconds) / 86400);
      if (interval < 3) {
        return (interval === 1 ? "tomorrow" : "in 2 days");
      }
      return "in " + interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes ago";
    }
    if (seconds < 30) {
      return "a few seconds ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

}
