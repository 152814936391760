import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { TicketScannerComponent } from '../../ticket-scanner/ticket-scanner.component';
import { NavigatorSettingsComponent } from '../navigator-settings/navigator-settings.component';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../shared/toast/toast.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-navigator-home',
  templateUrl: './navigator-home.component.html',
  styleUrls: ['./navigator-home.component.scss']
})
export class NavigatorHomeComponent implements OnInit {
  ticketScannerModalRef: MdbModalRef<TicketScannerComponent> | null = null;
  navigatorSettingsModalRef: MdbModalRef<NavigatorSettingsComponent> | null = null;
  toastOptionsSuccess = environment.toastOptions.success as MdbNotificationConfig<{}>;
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  apps = [
    { name: 'Validate', icon: 'fas fa-ticket' },
    { name: 'Sell', icon: 'fas fa-shopping-cart' },
    { name: 'Inventory', icon: 'fas fa-clipboard-check' },
    { name: 'Messages', icon: 'fas fa-comments' },
    { name: 'Stats', icon: 'fas fa-chart-line' },
    { name: 'Settings', icon: 'fas fa-cogs' }
  ];
  constructor(
    private modalService: MdbModalService,
    private toastService: MdbNotificationService,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
  }

  click(name: string) {
    switch (name) {
      case "Validate":
        this.validateTickets()
        break;
      case "Sell":
        this.launchSalesMode()
        break;
      case "Inventory":
        this.launchInventory()
        break;
      case "Settings":
        this.launchNavigatorSettings()
        break;
      default:
        break;
    }
  }

  validateTickets() {
    this.ticketScannerModalRef = this.modalService.open(TicketScannerComponent, {
      modalClass: 'modal-dialog-scrollable modal-fullscreen',
      ignoreBackdropClick: true,
      containerClass: 'right'
      // data: {
      //   communityDetails: this.communityDetails
      // }
    })
    this.ticketScannerModalRef.onClose.subscribe((data: any) => {
      if (data === 'reset') {
        this.validateTickets()
      }
    })
  }

  launchNavigatorSettings() {
    this.navigatorSettingsModalRef = this.modalService.open(NavigatorSettingsComponent, {
      modalClass: 'modal-dialog-scrollable modal-fullscreen',
      ignoreBackdropClick: true,
      containerClass: 'right'
    })
  }

  launchInventory() {

  }

  errorToast(message: string) {
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

  successToast(message: string) {
    this.toastOptionsSuccess.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsSuccess)
  }

  launchSalesMode() {
    this.setReaderInputs()
  }

  setReaderInputs() {
    console.log('set reader inputs')
    const callable = this.functions.httpsCallable('setTerminalInputs');
    const obs = callable({
      cardReaderId: 'test',
      stripeId: 'test'
    });
    obs.subscribe({
      next: (res) => {
        console.log(res)
        this.successToast(JSON.stringify(res))
      },
      error: (err) => {
        console.log(err)
        this.errorToast(JSON.stringify(err))
        // console.warn(err)
      },
    })
  }

}
