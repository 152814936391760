import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToastComponent } from '../components/shared/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notifications = [];
  private initialLoadComplete = false; // Flag to track initial load
  notificationsChanged = new BehaviorSubject<any[]>([{ 'loading': true }]);
  notificationsCountChanged = new BehaviorSubject<number>(0);
  videoNotificationsCountChanged = new BehaviorSubject<number>(0);
  notificationsSubscription: Subscription;
  toastOptionsNotification = environment.toastOptions.notification as MdbNotificationConfig<{}>;
  videosFetched: number = 0
  constructor(
    private db: AngularFirestore,
    private toastService: MdbNotificationService
    ) { }

  async fetchNotifications(userId: string) {
    this.notificationsSubscription = this.createNotificationsSnapshot(userId).subscribe(newData => {
      if (this.initialLoadComplete) {
        const newDocuments = this.findNewDocuments(newData);
        if (newDocuments.length > 0) {
          newDocuments.forEach((document: any) => {
            this.triggerToast(document.name, document.description)
            if(document.name.includes('Video')) {
              this.videosFetched += 1
              this.videoNotificationsCountChanged.next(this.videosFetched)
            }
          })
        }
      } else {
        this.initialLoadComplete = true;
      }
      this.notifications = newData;
      this.notificationsChanged.next(this.notifications);
      this.notificationsCountChanged.next(this.notifications.length);
    });
  }

  createNotificationsSnapshot(userId) {
    return this.db.collection('notifications', res => res.where('uid', '==', userId).orderBy('created', 'desc').limit(100)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data: any = a.payload.doc.data();
          return { ...data, id: a.payload.doc.id };
        });
      })
    );
  }
  private findNewDocuments(newData: any[]): any[] {
    const newDocuments = [];
    for (const newDoc of newData) {
      if (!this.notifications.find(oldDoc => oldDoc.id === newDoc.id)) {
        newDocuments.push(newDoc);
      }
    }
    return newDocuments;
  }

  deleteNotification(notificationId: string) {
    return this.db.collection('notifications').doc(notificationId).delete()
  }

  triggerToast(title: string, message: string) {
    this.toastOptionsNotification.data['content'] = message;
    this.toastOptionsNotification.data['title'] = title;
    this.toastOptionsNotification.data['iconClass'] = 'fas fa-bell';
    this.toastService.open(ToastComponent, this.toastOptionsNotification)
  }
}
