<ng-container style="width: 100%" *ngIf="elementsOptions.clientSecret as clientSecret">
    <div class="input-group mt-2">
      <ngx-stripe-payment [stripe]="stripe" [elementsOptions]="elementsOptions" [clientSecret]="clientSecret">
      </ngx-stripe-payment>
    </div>
  <br>
  <p class="card-text" *ngIf="paymentError">
    <app-notice [content]="paymentError" [type]="paymentErrorType"></app-notice><br><br>
  </p>
  <button (click)="addCard()" [disabled]="paying" type="button"
    class="btn btn-primary btn-rounded">Add Card<span *ngIf="paying">&nbsp;&nbsp;<span
        class="spinner-border spinner-border-sm me-2"></span></span></button>
</ng-container>
<div *ngIf="loading" style="width: 300px; height: 425px;" class="skeleton"></div>
