<div class="input-container" style="width: 100%">
  <mdb-form-control>
    <input mdbInput type="text" [formControl]="searchControl"
      class="form-control form-control-lg" placeholder="Search"
      maxLength="50" />
  </mdb-form-control>
</div>
<ng-container *ngIf="!loading; else showLoading">
  <ng-container *ngIf="searchResultsTotal; else noResultsOrError">
    <div class="searchItem" *ngFor="let result of searchResults; trackBy: trackById" (click)="click(result.id.raw, result)" >
      <div class="mb-2" [ngSwitch]="engine">
        <div *ngSwitchCase="'communities'" style="width: 100%">
          <h6 [innerHtml]="result.name.snippet" class="result-content"></h6>
        </div>
        <div *ngSwitchCase="'faqs'">
          <h6 [innerHtml]="result.name.snippet" class="result-content"></h6>
          <div [innerHtml]="result.content.snippet"
            class="result-content"></div>
        </div>
        <app-users-item  *ngSwitchCase="'users'" [itemDetails]="result"></app-users-item>
        <app-user-messages-list-item  *ngSwitchCase="'allmessages'" [itemDetails]="result"></app-user-messages-list-item>
        <app-video-views-item  *ngSwitchCase="'videoviews'" [itemDetails]="result"></app-video-views-item>
        <div *ngSwitchDefault>
          <app-https-error
            [error]="'Could not process search data!'"></app-https-error>
        </div>
      </div>
    </div>
    <div *ngIf="searchResults.length<searchResultsTotal" class="searchItem text-center hoverUnderline" (click)="moreResults()">
      More Results
    </div>
    <div class="text-end text-small text-muted">{{searchResults.length}} of {{searchResultsTotal}} results</div>
  </ng-container>
  <ng-template #noResultsOrError>
    <ng-container *ngIf="!error; else showError">
      <ng-container *ngIf="searchValue.length>0">
        <div class="mb-4"></div>
        <app-no-results [message]="'No results found for ' + searchValue"></app-no-results>
      </ng-container>
    </ng-container>
    <ng-template #showError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </ng-template>
</ng-container>
<ng-template #showLoading>
  <app-loading></app-loading>
</ng-template>
