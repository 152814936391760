import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-enhanced-card-detail',
  templateUrl: './enhanced-card-detail.component.html',
  styleUrls: ['./enhanced-card-detail.component.scss']
})
export class EnhancedCardDetailComponent implements OnInit {
  @Input() cardDetails
  constructor() { }

  ngOnInit(): void {
  }

}
