import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-link-lti-account',
  templateUrl: './link-lti-account.component.html',
  styleUrls: ['./link-lti-account.component.scss']
})
export class LinkLtiAccountComponent implements OnInit {
  routeSub: Subscription;
  userId: string = ''
  courseId: string = ''
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.fetchUserInfo()
  }

  fetchUserInfo() {
    this.routeSub = this.activatedRoute.queryParams.subscribe(async (params) => {
      this.userId = params['user_id'];
      this.courseId = params['context_id'];
    });
  }

}
