import { Component, Input, OnInit } from '@angular/core';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-community-manage-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class CommunityHomeComponent implements OnInit {
  @Input() communityDetails: Community

  constructor() { }

  ngOnInit(): void {
  }

}
