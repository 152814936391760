import { Component,  OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  environmentName: string
  isDarkMode: boolean = undefined
  constructor(
    private titleService: Title,
    public router: Router,
    private envService: EnvironmentService,
    private themeToggleService: ThemeToggleService
  ) { }


    ngOnInit() {
      this.titleService.setTitle('Welcome | Event Odyssey')
      this.environmentName = this.envService.getEnvironmentName();
      this.checkTheme()
    }

    checkTheme() {
      this.themeToggleService.isDarkMode.subscribe((isDarkMode: any) => {
        if(typeof isDarkMode === 'boolean') {
          this.isDarkMode = isDarkMode
        }
      });
    }

}
