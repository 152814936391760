import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { ReaderState } from 'src/app/interfaces/readerState';

@Component({
  selector: 'app-card-reader-status',
  templateUrl: './card-reader-status.component.html',
  styleUrls: ['./card-reader-status.component.scss']
})
export class CardReaderStatusComponent implements OnInit {
  @Input() cardReaderId: string
  @Input() lastReaderFunctionResponse
  @Output() output = new EventEmitter<any>();
  loading: boolean = true
  readerSubscription: Subscription
  readerState: ReaderState
  constructor(
    private db: AngularFirestore
  ) {
  }

  ngOnInit(): void {
    this.getReaderStatus()
  }

  getReaderStatus() {
    const readerRef = this.db.collection('cardReaderStates').doc(this.cardReaderId).valueChanges()
    this.readerSubscription = readerRef.subscribe(async (res: any) => {
      this.loading = false
      res.time = new Date().getTime()
      this.readerState = res
      this.handleChanges()
    })
  }

  handleChanges() {
    const showReponse = ((this.lastReaderFunctionResponse) < (this.readerState.time))
    const paymentFailed = (this.readerState.action.status === 'failed')
    if (paymentFailed && showReponse) {
      this.output.emit('declined')
    }
  }

  ngOnDestroy() {
    this.readerSubscription.unsubscribe()
  }


}
