<form [formGroup]="chapterForm">
  <div class="input-group mt-2">
    <div class="input-container">
      <mdb-form-control>
        <input mdbInput [mdbValidate] type="text" formControlName="name" id="name" class="form-control form-control-lg"
          [validateSuccess]="false" placeholder="Name" maxLength="50"/>
        <label mdbLabel class="form-label" for="name">Name</label>
        <div class="form-helper">
          <div class="form-counter">{{ chapterForm.controls.name.value.length }} / 50</div>
          <div *ngIf="chapterForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
          </div>
          <div *ngIf="chapterForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
          </div>
        </div>
      </mdb-form-control>
    </div>
    <span class="input-group-text border-0"><app-validation-icon [inputValid]="chapterForm.controls.name.invalid"></app-validation-icon>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>
  <br class="noselect">
  <br *ngIf="chapterForm.controls.name.value.length < 3 || chapterForm.controls.name.value.length === 50"
    class="noselect">
  <div class="input-group mt-2" *ngIf="groupsLoaded">
    <div class="input-container">
      <mdb-form-control>
        <ng-select  [virtualScroll]="false"  mdbInput formControlName="groups"
        (open)="displaySelectDropdown=true" (close)="displaySelectDropdown=false" [items]="groups" [multiple]="true"
        placeholder="Group(s)" bindLabel="name" bindValue="id" appearance="outline" [closeOnSelect]="true"
        [clearable]="true" [searchable]="true" notFoundText="No groups found" autocomplete="off" required>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
            <div class="">{{item.name}}</div>
            <div class="text-muted small text-wrap" [innerHTML]="item.description"></div>
          </div>
        </ng-template>
      </ng-select>
      </mdb-form-control>
    </div>
    <span class="input-group-text border-0"><i class="fas fa-plus me-2" mdbTooltip="Tooltip" [html]="true"
        placement="right"></i><span class="text-light me-2 noselect">***</span>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>
  <div *ngIf="displaySelectDropdown" style="height: 230px;"></div>

  <div class="input-group mt-2">
    <div class="input-container">
      <mdb-form-control>
        <textarea mdbInput [mdbValidate] type="text" formControlName="description"
          id="description" class="form-control form-control-lg" [validateSuccess]="false"
          placeholder="Description" maxLength="250"></textarea>
        <label mdbLabel class="form-label" for="name">Description</label>
        <div class="form-helper">
          <div class="form-counter">{{ chapterForm.controls.description.value.length }} / 250</div>
          <div *ngIf="chapterForm.controls.description.value.length === 250" class="form-counter">Maximum 250 characters allowed
          </div>
        </div>
      </mdb-form-control>
    </div>
    <span class="input-group-text border-0">
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>
  <br class="noselect">
  <br *ngIf="chapterForm.controls.description.value.length === 250"
    class="noselect">

  <div class="input-group mt-2">
    <div class="input-container">
      <div class="input-group input-group-lg">

        <mdb-form-control>
          <input (ngModelChange)="onStartTimeChange()" disabled mdbInput [mdbValidate] type="text" formControlName="startTime" id="startTime"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Start Time" />
          <label mdbLabel class="form-label" for="startTime">Start Time</label>
        </mdb-form-control>
        <ng-container>
          <span (click)="setCurrentTime('startTime')" class="input-group-text bg-grey hoverUnderline pointer">
            Set Current Time
          </span>
        </ng-container>
      </div>
    </div>
    <span class="input-group-text border-0"><app-validation-icon [inputValid]="chapterForm.controls.startTime.invalid"></app-validation-icon>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>
  <div class="input-group mt-2">
    <div class="input-container">
      <div class="input-group input-group-lg">
        <mdb-form-control>
          <input [disabled] mdbInput [mdbValidate] type="text" formControlName="endTime" id="endTime"
          class="form-control form-control-lg" [validateSuccess]="false" placeholder="End Time" />
          <label mdbLabel class="form-label" for="endTime">End Time</label>
        </mdb-form-control>
        <ng-container>
          <span (click)="setCurrentTime('endTime')" class="input-group-text bg-grey hoverUnderline pointer">
            Set Current Time
          </span>
        </ng-container>
      </div>
    </div>
    <span class="input-group-text border-0"><app-validation-icon [inputValid]="chapterForm.controls.endTime.invalid"></app-validation-icon>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>




  <br>
  <button (click)="createChapter()" [disabled]="chapterForm.invalid" type="button" class="btn btn-primary btn-rounded">Create Chapter</button>
</form>
<br>
