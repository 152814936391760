import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from "rxjs";
import firebase from "firebase/app"
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { switchMap, first, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.afAuth.authState.pipe(first()).toPromise().then(
      (authenticated: any) => {
        if (authenticated) {
          return true;
        } else {
          this.router.navigate(['/not-authenticated'], {
            queryParams: {
              redirectUrl: state.url
            }
          });
        }
      }
    )
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.afAuth.authState.pipe(first()).toPromise().then(
      (authenticated: any) => {
        if (authenticated) {
          return true;
        } else {
          this.router.navigate(['/not-authenticated'], {
            queryParams: {
              redirectUrl: state.url
            }
          });
        }
      }
    )
  }
}
