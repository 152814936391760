import { Component, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-ticket-scanner',
  templateUrl: './ticket-scanner.component.html',
  styleUrls: ['./ticket-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketScannerComponent implements OnDestroy {
  @ViewChild('scannerElem') scanner!: ZXingScannerComponent;
  scanContent: string
  private scanSuccessSubscription: any;
  private navigationSubscription: any;
  public hasPermission: boolean = false;
  public isScannerStopped: boolean = false;
  public loadingTicketStatus: boolean = false;
  public availableDevices: MediaDeviceInfo[];
  private currentDeviceIndex: number = -1;

  constructor(private router: Router, public modalRef: MdbModalRef<TicketScannerComponent>, private cdr: ChangeDetectorRef) {
    this.navigationSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.ngOnDestroy();
    });
  }

  ngOnDestroy(): void {
    this.scanSuccessSubscription?.unsubscribe();
    this.scanner?.reset();
    this.navigationSubscription?.unsubscribe();
  }

  async scanSuccessHandler($event) {
    this.scanContent = $event
    this.scanner?.scanStop();
    this.isScannerStopped = true;
    this.loadingTicketStatus = true
    this.cdr.detectChanges();
    await this.delay(3000);
    this.loadingTicketStatus = false
    this.cdr.detectChanges();
    await this.delay(5000);
    this.isScannerStopped = false;
    this.cdr.detectChanges();
    this.scanner?.scanStart();
  }

  async scanErrorHandler($event) {
    console.log($event);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  camerasFoundHandler(devices: MediaDeviceInfo[]): void {
    this.hasPermission = true;
    this.availableDevices = devices;
    if (devices && devices.length > 0) {
      this.currentDeviceIndex = 0;
      this.scanner.device = this.availableDevices[this.currentDeviceIndex];
    }
  }

  permissionResponseHandler(hasPermission: boolean): void {
    this.hasPermission = hasPermission;
  }

  toggleCamera(): void {
    if (this.availableDevices && this.availableDevices.length > 0) {
      this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.availableDevices.length;
      this.scanner.device = this.availableDevices[this.currentDeviceIndex];
    }
  }

  resetModal() {
    const closeMessage = 'reset';
    this.modalRef.close(closeMessage)
  }

}
