<div class="row">
  <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">
    <div class="modal-body">
      <h3>Congratulations on creating your community! 🎉</h3>
      <p>We're delighted to welcome you and are eager to guide you through the process of onboarding your members. To begin, we need to collect some essential information.</p>
      <p>After you've completed the onboarding process, you'll be well-prepared to unleash the full potential of Event Odyssey! One crucial step on this journey involves configuring your payment system using Stripe, which will streamline transactions and provide a seamless experience for your members.</p>
      <!-- <br> -->
      <p>If you have any questions or require assistance along the way, our support team is always available to assist you. Don't hesitate to reach out to us <a routerLink="/support" class="pointer">here</a>.</p>
    </div>
  </div>
  <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">
    <app-community-todos [communityDetails]="communityDetails"></app-community-todos>
  </div>
</div>
