import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { InviteMemberComponent } from '../invite-member/invite-member.component';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { MdbTabChange } from 'mdb-angular-ui-kit/tabs/tabs.component';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-community-manage-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class CommunityMessagesComponent implements OnInit {
  @ViewChild(MdbTabsComponent) tabs: MdbTabsComponent
  @Input() communityDetails: Community
  @Input() isVertical: boolean = true
  firstTabChange: boolean = true


  constructor(
    private location: Location,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.setActiveSection()
  }


  async setActiveSection() {
    const section = this.route.snapshot.params['settingsSection']
    if (this.tabs) {
      switch (section) {
        case 'inbox':
          this.tabs.setActiveTab(0)
          break;
        case 'support':
          this.tabs.setActiveTab(1)
          break;
      }
    } else {
      // Keep retrying until tabs available
      setTimeout(() => {
        this.setActiveSection()
      }, 10);
    }

  }
  onTabChange(event: MdbTabChange): void {
    if (this.firstTabChange) { this.firstTabChange = false } else {
      const tabName = event.tab.title.toLowerCase()
      this.location.replaceState('/communities/' + this.communityDetails.id + '/manage/messages/' + tabName.replace(/[\s\/]+/g, "-"))
    }
  }

}
