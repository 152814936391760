import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-edit-community-header',
  templateUrl: './edit-community-header.component.html',
  styleUrls: ['./edit-community-header.component.scss']
})
export class EditCommunityHeaderComponent implements OnInit {
  @Input() communityDetails: Community
  public files: NgxFileDropEntry[] = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    public modalRef: MdbModalRef<EditCommunityHeaderComponent>
  ) { }

  ngOnInit(): void {
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {

  }
  cropperReady() {

  }

}
