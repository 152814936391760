import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbNotificationConfig, MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';
import { Community } from 'src/app/interfaces/community';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-community-manage-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class ManageCommunityBillingComponent implements OnInit {
  @Input() communityDetails: Community
  @Output() outputStripeDetails = new EventEmitter<any>();
  stripeAccountDetails: any
  error: string;
  loading: boolean = true
  stripeAccountExists: boolean = false
  loadingStripeLink: boolean = false
  notificationRef: MdbNotificationRef<ToastComponent>;
  toastOptionsSuccess = environment.toastOptions.success as MdbNotificationConfig<{}>;
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private toastService: MdbNotificationService,
  ) { }

  ngOnInit(): void {
    this.getStripeAccountDetails()
  }

  getStripeAccountDetails() {
    this.afAuth.authState.subscribe((user) => {
      const callable = this.functions.httpsCallable('getStripeAccountDetails');
      const obs = callable({
        communityId: this.communityDetails.id,
      });
      obs.subscribe({
        next: (res) => {
          this.stripeAccountDetails = res
          this.outputStripeDetails.emit(this.stripeAccountDetails)
          this.stripeAccountExists = true
          this.loading = false
        },
        error: (err) => {
          this.handleStripeAccountErrors(err.message)
        },
      })
    })
  }

  setupStripe() {
    if (!this.loadingStripeLink) {
      this.loadingStripeLink = true
      this.afAuth.authState.subscribe((user) => {
        const callable = this.functions.httpsCallable('getStripeAccountLink');
        const obs = callable({
          communityId: this.communityDetails.id,
          returnUrl: window.location.origin + '/communities/' + this.communityDetails.id + '/manage',
          refreshUrl: window.location.origin + '/communities/' + this.communityDetails.id + '/manage',
        });
        obs.subscribe({
          next: (res) => {
            this.successToast('Redirecting you to Stripe to complete your billing profile...')
            this.loadingStripeLink = false
            window.location = res.url
          },
          error: (err) => {
            this.errorToast(err.message)
            this.loadingStripeLink = false
          },
        })
      })
    } else {
      this.errorToast('Generating stripe account link - please wait for this to complete.')
    }
  }

  errorToast(message: string) {
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

  successToast(message: string) {
    this.toastOptionsSuccess.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsSuccess)
  }

  handleStripeAccountErrors(message) {
    if (message.includes('Firebase')) {
      message = message.replace('Firebase: ', '')
      message = message.replace(/Firebase/g, '')
    }
    switch (message) {
      case 'This community still needs to start the stripe setup process!':
        this.stripeAccountExists = false
        this.loading = false
        break;
      default:
        this.error = message
        break;
    }
  }

}
