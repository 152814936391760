<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Group</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="groupForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50" />
          <label mdbLabel class="form-label" for="name">Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ groupForm.controls.name.value.length }} / 50</div>
            <div *ngIf="groupForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="groupForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="groupForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="groupForm.controls.name.value.length < 3 || groupForm.controls.name.value.length === 50"
      class="noselect">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <textarea mdbInput [mdbValidate] type="text" formControlName="description" id="description"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Description" maxLength="250"></textarea>
          <label mdbLabel class="form-label" for="description">Description</label>
          <div class="form-helper">
            <div class="form-counter">{{ groupForm.controls.description.value.length }} / 250</div>
            <div *ngIf="groupForm.controls.description.value.length === 250" class="form-counter">Maximum 250 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><span class="me-2">&nbsp;</span>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="groupForm.controls.description.value.length === 250"
      class="noselect">
  </form>


</div>
<div class="modal-footer">
  <button (click)="submit()" [disabled]="groupForm.invalid||isSaving" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Updating</span>
    <span *ngIf="!isSaving">Update</span>
  </button>
</div>
