<div class="text-center">
  <div #iconWrapper class="mb-3">
    <i
      [@tadaEnter]
      class="fas fas fa-exclamation fa-2x bg-warning bg-gradient text-light"
    ></i>
  </div>

  <h6>{{message}}</h6>
</div>
