<ng-container *ngIf="!loading; else displayLoading">

  <div class="datatable">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="input-group">
          <input *ngIf="members.length>0" type="text" class="form-control form-control-lg" placeholder="Filter members"
            (keyup)="search($event)" #searchInput size=25 />
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-end align-items-center">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <!-- <li (click)="createMember()"><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Send
                  Invite</a></li> -->
              <li (click)="fetchMembers()"><a class="dropdown-item pointer"><i
                    class="fas fa-sync-alt me-2"></i>Refresh</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="members.length===0">
      <app-no-results message="No pending members found"></app-no-results>
    </div>
    <div *ngIf="members.length>0">
      <table responsive class="table align-middle mb-0 bg-white"
        class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
        mdbTableSort #table="mdbTable" #sort="mdbTableSort" [sort]="sort" [style]="" [responsive]="true" [dataSource]="members"
        [pagination]="pagination" [filterFn]="filterTable">

        <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
        <thead class="bg-light">
          <tr>
            <th [mdbTableSortHeader]="'displayName'">Member</th>
            <th [mdbTableSortHeader]="'groups'">Group(s)</th>
            <th [mdbTableSortHeader]="'lastSignIn'">Last Sign-in</th>
            <th></th>
          </tr>
        </thead>
      </ng-container>
      <ng-template #noResultsFound>
        <div class="mb-5"></div>
        <app-no-results [message]="'No pending members found matching: ' + searchText"></app-no-results>
        <div class="mb-5"></div>
      </ng-template>
        <tbody>


          <tr *ngFor="let item of table.data; let i = index">
            <td>
              <div class="d-flex align-items-center">
                <img referrerPolicy="no-referrer" [src]="item.profilePic" (error)="item.profilePic = './assets/user-profile.png'" alt="" style="width: 45px; height: 45px;"
                  class="rounded-circle" />
                <div class="ms-3">
                  <p class="fw-bold mb-1">{{item.displayName}}</p>
                  <p class="text-muted mb-0">{{item.email}}</p>
                </div>
              </div>
            </td>
            <td style="width: 225px" >
              <p class="fw-normal mb-1">
                {{item.requestText}}
              </p>
            </td>
            <td style="width: 100px">
              {{(item.lastUpdated*1000) | timeAgo}}<br>
              {{(item.lastUpdated*1000) | date:'medium'}}
            </td>
            <td style="width: 50px" class="text-end">
              <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
                  class="dropdown-toggle hidden-arrow tableDropdown text-center " role="button" mdbDropdownToggle aria-expanded="false">
                  <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                    class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                </span>
                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <a (click)="handleDecision('approve', item)" class="dropdown-item pointer">Approve</a>
                    <a (click)="handleDecision('deny', item)" class="dropdown-item pointer">Deny</a>
                    <a (click)="handleDecision('block', item)" class="dropdown-item pointer">Block</a>
                  </li>
                </ul>
              </span>
            </td>
          </tr>


        </tbody>
      </table>
      <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
    </div>
  </div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
