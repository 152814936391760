import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-chapter',
  templateUrl: './add-chapter.component.html',
  styleUrls: ['./add-chapter.component.scss']
})
export class AddChapterComponent implements OnInit {
  @Output() output = new EventEmitter<any>();
  @Input() playerTime
  groupsLoaded: boolean = false
  displaySelectDropdown: boolean = false
  @Input() chapters
  chapterForm: FormGroup;
  chapterLabel: string = 'Ensemble'
  groups: any[]  = [
    { id: '1', name: 'NHS Singers', description: 'test' },
    { id: '2', name: 'Momentum', description: 'test' },
    { id: '3', name: 'MaleTonez', description: 'test' },
    { id: '4', name: 'Sensation', description: 'test' },
    { id: '5', name: 'MadJazz', description: 'test' },
    { id: '6', name: 'Synergy', description: 'test' },
    { id: '7', name: 'New Dimension', description: 'test' },
  ];
  @Input() minTimeInSeconds
  @Input() maxTimeInSeconds
  constructor(

  ) { }

  ngOnInit(): void {
    this.chapterForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      description: new FormControl(''),
      groups: new FormControl([]),
      startTime: new FormControl('00:00:00', [Validators.pattern('^([0-9]{2}:[0-9]{2}:[0-9]{2})$'), this.timecodeValidator.bind(this)]),
      endTime: new FormControl('00:00:00', [Validators.pattern('^([0-9]{2}:[0-9]{2}:[0-9]{2})$'), this.timecodeValidator.bind(this)]),
    });
    this.chapterForm.get('endTime').setValidators([Validators.pattern('^([0-9]{2}:[0-9]{2}:[0-9]{2})$'), this.timecodeValidator.bind(this), this.endTimeValidator.bind(this)]);
    this.chapterForm.get('endTime').updateValueAndValidity();
    this.groupsLoaded = true
  }


  setCurrentTime(type: string) {
    const timestamp = this.convertSecondsToTimecode(this.playerTime)
    switch(type) {
      case 'startTime':
        this.chapterForm.get('startTime').setValue(timestamp)
        this.chapterForm.get('startTime').updateValueAndValidity();
        this.chapterForm.get('endTime').updateValueAndValidity();
        break;
        case 'endTime':
        this.chapterForm.get('endTime').setValue(timestamp)
        this.chapterForm.get('startTime').updateValueAndValidity();
        this.chapterForm.get('endTime').updateValueAndValidity();
        break;
    }
  }

  convertSecondsToTimecode(inputSeconds: any) {
    if(!inputSeconds) { inputSeconds = 0 }
    const hours = ('0' + Math.floor((inputSeconds / 3600))).slice(-2);
    const minutes = ('0' + Math.floor((inputSeconds % 3600) / 60)).slice(-2);
    const seconds = ('0' + Math.floor(inputSeconds % 60)).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  }

  convertTimecodeToSeconds(inputTimecode: any) {
      const parts = inputTimecode.split(':');
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(parts[2], 10);
      return (hours * 3600) + (minutes * 60) + seconds;
  }

  onStartTimeChange() {
    this.chapterForm.get('endTime').updateValueAndValidity();
  }

  timecodeValidator(control: FormControl) {
    const value = control.value;
    const hours = parseInt(value.split(':')[0], 10);
    const minutes = parseInt(value.split(':')[1], 10);
    const seconds = parseInt(value.split(':')[2], 10);
    const timecodeInSeconds = (hours * 3600) + (minutes * 60) + seconds;
    if (timecodeInSeconds > this.maxTimeInSeconds) {return { maxTime: true }}
    if (timecodeInSeconds < this.minTimeInSeconds) {return { minTime: true }}
    return null;
  }

  endTimeValidator(control: FormControl) {
    let startTime = this.chapterForm.controls.startTime.value;
    const endTime = control.value;
    const startTimeInSeconds = (parseInt(startTime.split(':')[0], 10) * 3600) + (parseInt(startTime.split(':')[1], 10) * 60) + parseInt(startTime.split(':')[2], 10);
    const endTimeInSeconds = (parseInt(endTime.split(':')[0], 10) * 3600) + (parseInt(endTime.split(':')[1], 10) * 60) + parseInt(endTime.split(':')[2], 10);
    if (endTimeInSeconds <= startTimeInSeconds) {return { endTimeInvalid: true }}
    return null;
  }

  createChapter() {
    const chapterData = {
      start: this.convertTimecodeToSeconds(this.chapterForm.controls.startTime.value),
      end: this.convertTimecodeToSeconds(this.chapterForm.controls.endTime.value),
      name: this.chapterForm.controls.name.value,
      groups: this.chapterForm.controls.groups.value,
      description: this.chapterForm.controls.description.value
    }
    this.output.emit(chapterData)
    this.chapterForm.controls.startTime.setValue(this.chapterForm.controls.endTime.value)
    this.chapterForm.controls.endTime.setValue('00:00:00')
    this.chapterForm.controls.name.setValue('')
    this.chapterForm.controls.description.setValue('')
    this.chapterForm.controls.groups.setValue([])
    this.chapterForm.get('endTime').updateValueAndValidity()
  }



}


