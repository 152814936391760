<div class="card border border-light shadow-0">
  <div class="card-body">
    <h4 style="margin-bottom: 0px;" class="card-title">{{videoDetails.name}}

      <span *ngIf="streamStatus" [ngSwitch]="streamStatus.status">
        <span *ngSwitchCase="'connected'" class="badge badge-warning">
          <i class="fas fa-broadcast-tower me-2"></i> Starting Soon
        </span>
        <!-- <span *ngSwitchCase="'active'" class="badge badge-danger">
          <i class="fas fa-broadcast-tower me-2"></i> LIVE
        </span> -->
        <span *ngSwitchCase="'disconnected'" class="badge badge-warning">
          <i class="fas fa-broadcast-tower me-2"></i> Stream Ended
        </span>
        <!-- <span *ngSwitchCase="'idle'" class="badge badge-info">
          <i class="fas fa-broadcast-tower me-2"></i> Stream Idle
        </span> -->
      </span>

    </h4>
    <p style="margin-top: 0px;" class="card-text">{{videoDetails.description}}</p>
    <p class="card-text">{{videoDetails.communityDetails.name}}<br>
      <span><i class="far fa-calendar"></i> {{videoDetails.created | date}}</span>&nbsp;&nbsp;
      <span><i class="far fa-eye"></i> {{videoDetails.views}}</span>
    </p>
  </div>
</div>

<div class="row">
  <div *ngIf="currentChapter" class="col-md-6 col-lg-6 col-xl-6 mb-2">
    <div class="card card border border-none shadow-0">
      <div class="card-body">
        <h5 class="card-title">{{currentChapter.name}}</h5>
        <h6><span class="badge badge-primary">Current Song</span></h6>

        <p class="card-text" *ngIf="currentChapter.description">
          Description: {{currentChapter.description}}
        </p>
        <p class="card-text" *ngIf="currentChapter.groups">
          <i class="fas fa-users"></i> &nbsp;
          <span *ngFor="let group of currentChapter.groups; index as i">
            <span>{{group.name}}</span><span *ngIf="((currentChapter.groups.length!==1)&&((i+1)!==currentChapter.groups.length))">, </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="nextChapter" class="col-md-6 col-lg-6 col-xl-6">
    <div  class="card card border border-none shadow-0">
      <div class="card-body">
        <h5 class="card-title">{{nextChapter.name}}</h5>
        <h6><span class="badge badge-dark">Up Next</span>&nbsp;&nbsp;<a class="text-reset hoverUnderline pointer"
          (click)="triggerSeekTo(nextChapter.start)">Play Now</a></h6>

        <p class="card-text" *ngIf="nextChapter.description">
          Description: {{nextChapter.description}}
        </p>
        <p class="card-text" *ngIf="nextChapter.groups">
          <i class="fas fa-users"></i>&nbsp;
          <span *ngFor="let group of nextChapter.groups; index as i">
            <span>{{group.name}}</span><span *ngIf="((nextChapter.groups.length!==1)&&((i+1)!==nextChapter.groups.length))">, </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
