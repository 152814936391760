import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-customer-billing-info',
  templateUrl: './customer-billing-info.component.html',
  styleUrls: ['./customer-billing-info.component.scss']
})
export class CustomerBillingInfoComponent implements OnInit {

  constructor(
    public modalRef: MdbModalRef<CustomerBillingInfoComponent>,
  ) { }

  ngOnInit(): void {
  }

}
