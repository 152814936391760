<header>
  <div class="p-5 text-center bg-gradient-animation bg-image" style="height: 350px;"
  [ngStyle]="{'background-image': previewImage ? 'url(' + previewImage + ')' : ''}">
    <div class="mask" [ngStyle]="{'background-color': previewImage ? 'rgba(0, 0, 0, 0.6)' : ''}">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-white">
          <h4 class="display-4 mb-3">{{communityDetails.name}}</h4>
        </div>
      </div>
    </div>
  </div>
</header>
