import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-create-new-message',
  templateUrl: './create-new-message.component.html',
  styleUrls: ['./create-new-message.component.scss']
})
export class CreateNewMessageComponent implements OnInit {

  constructor(
    public modalRef: MdbModalRef<CreateNewMessageComponent>,
  ) { }

  ngOnInit(): void {
  }

}
