import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-join-community-by-request',
  templateUrl: './join-community-by-request.component.html',
  styleUrls: ['./join-community-by-request.component.scss']
})
export class JoinCommunityByRequestComponent implements OnInit {
  requestForm: FormGroup
  givenName: string
  creatingRequest: boolean = false
  @Input() communityDetails: Community
  characterCount = 0;
  maxLength = 250;
  constructor(
    private userService: UserService,
    public modalRef: MdbModalRef<JoinCommunityByRequestComponent>,
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.userService.userChanged.subscribe((user: any) => {
      this.givenName = user.given_name
    });
    this.requestForm = new FormGroup({
      requestText: new FormControl('', Validators.compose([Validators.minLength(5), Validators.required]))
    });
    this.requestForm.valueChanges.subscribe(val => {
      this.characterCount = val.requestText.length
    });
  }

  createRequest() {
    this.creatingRequest = true
    const callable = this.functions.httpsCallable('createCommunityRequest');
    const obs = callable({
      id: this.communityDetails.id,
      requestText: this.requestForm.get('requestText').value,
    });
    obs.subscribe({
      next: (res) => {
        this.creatingRequest = false
        this.modalRef.close(res)
      },
      error: (err) => {
        console.warn(err)

      },
    })
  }



}
