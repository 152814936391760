<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Customer Info</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">



</div>
<div class="modal-footer">
  <button (click)="modalRef.close()" type="button" class="btn btn-grey btn-rounded"
    data-mdb-dismiss="modal">Close</button>
</div>
