<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Add Campaign</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">


  <form [formGroup]="campaignForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50" />
          <label mdbLabel class="form-label" for="name">Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ campaignForm.controls.name.value.length }} / 50</div>
            <div *ngIf="campaignForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="campaignForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters
              allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="campaignForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="campaignForm.controls.name.value.length < 3 || campaignForm.controls.name.value.length === 50"
      class="noselect">
    <br *ngIf="campaignForm.controls.description.value.length === 250" class="noselect">
    <ng-container *ngIf="loadingGroups; else groupsInput">
      <div style="width: 100%; height: 50px;" class="skeleton mt-1"></div>
    </ng-container>
    <ng-template #groupsInput>
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <ng-select [validateSuccess]="false" [mdbValidate] mdbInput (open)="displaySelectDropdown=true"
              (close)="displaySelectDropdown=false" formControlName="groups" [items]="groups" [multiple]="true"
              [virtualScroll]="true" placeholder="Group(s)" bindLabel="name" bindValue="id" appearance="outline"
              [closeOnSelect]="true" [clearable]="true" [searchable]="true" [virtualScroll]="true"
              notFoundText="No groups found" autocomplete="off" required>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                  <div class="">{{item.name}}</div>
                  <div class="text-muted small text-wrap" [innerHTML]="item.description"></div>
                </div>
              </ng-template>
            </ng-select>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0 align-self-center" style="margin-top: -20px">
          <app-validation-icon [inputValid]="campaignForm.controls.groups.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <div *ngIf="displaySelectDropdown" style="height: 230px;"></div>
    </ng-template>
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <textarea mdbInput [mdbValidate] type="text" formControlName="description" id="description"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Description"
            maxLength="250"></textarea>
          <label mdbLabel class="form-label" for="description">Description</label>
          <div class="form-helper">
            <div class="form-counter">{{ campaignForm.controls.description.value.length }} / 250</div>
            <div *ngIf="campaignForm.controls.description.value.length === 250" class="form-counter">Maximum 250
              characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="true" displayEmpty="true"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
  </form>


</div>
<div class="modal-footer">
  <button (click)="modalRef.close()" type="button" class="btn btn-grey btn-rounded"
    data-mdb-dismiss="modal">Close</button>
</div>
