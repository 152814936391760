import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContactComponent } from '../../support/contact/contact.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-user-purchase-list',
  templateUrl: './user-purchase-list.component.html',
  styleUrls: ['./user-purchase-list.component.scss']
})
export class UserPurchaseListComponent implements OnInit {
  @Input() lookupUid: string
  modalRef: MdbModalRef<ContactComponent> | null = null;
  searchText: string
  loading: boolean = true
  videoItems: any[] = []
  error
  @ViewChild('table') purchasesTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;

  constructor(
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
  ) { }

  ngOnInit(): void {
    this.fetchPurchases()
  }

  fetchPurchases() {
    const callable = this.functions.httpsCallable('getMyPurchases');
    const obs = callable({
      overrideUid: this.lookupUid
    });
    obs.subscribe({
      next: (res) => {
        this.videoItems = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.purchasesTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'video':
          let videoName = data[key].name
          return videoName.toLowerCase().includes(searchTerm.toLowerCase());
        case 'payment_method_details':
          let paymentMethod
          if (data[key].card_present) {
            paymentMethod = data[key].card_present.brand + ' ' + data[key].card_present.last4 + + ' ' + data[key].card_present.network
          } else {
            paymentMethod = data[key].card.brand + ' ' + data[key].card.last4 + + ' ' + data[key].card.network
          }
          return paymentMethod.toLowerCase().includes(searchTerm.toLowerCase());

        case 'calculated_statement_descriptor':
          let paymentDescription = data[key]
          if (paymentDescription.card_present) {

          } else {
            return paymentDescription.toLowerCase().includes(searchTerm.toLowerCase());
          }
      }
    });
  }

  contactSupport(itemDetails) {
    this.modalRef = this.modalService.open(ContactComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      data: {
        communityName: itemDetails.calculated_statement_descriptor,
        chargeId: itemDetails.id,
      },
      ignoreBackdropClick: true,
    })
  }

}

