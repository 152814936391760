import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { Community } from 'src/app/interfaces/community';
import { CreateVideoComponent } from './create-video/create-video.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { EditVideoComponent } from 'src/app/components/modals/edit-video/edit-video.component';
import { CreateStreamComponent } from '../streams/create-stream/create-stream.component';

@Component({
  selector: 'app-community-manage-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class ManageCommunityVideosComponent implements OnInit {

  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  searchText: string
  modalRef: MdbModalRef<CreateVideoComponent> | null = null;
  streamModalRef: MdbModalRef<CreateStreamComponent> | null = null;
  editVideoModalRef: MdbModalRef<EditVideoComponent> | null = null;
  @Input() communityDetails: Community
  loading: boolean = true
  videoItems: any[] = []
  error
  user
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private notificationsService: NotificationsService,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user: any) => {
      this.user = user._delegate
      this.notificationsService.videoNotificationsCountChanged.subscribe((changeCount: number) => {
        this.fetchCommunityVideos()
      });
    })
  }

  fetchCommunityVideos() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityVideos');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.videoItems = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }


  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'name':
          let name = data[key]
          return name.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  addVideo() {
    this.modalRef = this.modalService.open(CreateVideoComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        user: this.user
      }
    })
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.fetchCommunityVideos()
      }
    })
  }

  addStream() {
    this.streamModalRef = this.modalService.open(CreateStreamComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        user: this.user
      }
    })
    this.streamModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.fetchCommunityVideos()
      }
    })
  }

  edit(itemDetails, section: string) {
    this.editVideoModalRef = this.modalService.open(EditVideoComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        section: section,
        itemDetails: itemDetails,
        communityDetails: this.communityDetails
      }
    })
  }

}
