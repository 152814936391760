<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Validate Tickets</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
  <div class="modal-body">
    <div *ngIf="hasPermission === false">
      <p>Please enable camera permission.</p>
      <button (click)="resetModal()()" class="btn btn-dark btn-rounded">Retry</button>
    </div>
    <div *ngIf="isScannerStopped">
      <app-loading *ngIf="loadingTicketStatus"></app-loading>
      <p *ngIf="!loadingTicketStatus">
        <app-success-scan [content]="scanContent"></app-success-scan>
        <app-error-scan [content]="scanContent"></app-error-scan>
      </p>
    </div>
    <zxing-scanner #scannerElem [ngClass]="{'scanner-stopped': isScannerStopped, 'scanner-running': !isScannerStopped}" (scanSuccess)="scanSuccessHandler($event)" (scanError)="scanErrorHandler($event)" (camerasFound)="camerasFoundHandler($event)" (permissionResponse)="permissionResponseHandler($event)"></zxing-scanner>
    <!-- <button *ngIf="!isScannerStopped && availableDevices?.length > 1" (click)="toggleCamera()" class="btn btn-dark btn-rounded">Toggle Camera</button> -->
    <br>
  </div>
