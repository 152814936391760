import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ContactComponent } from 'src/app/components/support/contact/contact.component';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  modalRef: MdbModalRef<ContactComponent> | null = null;
  environmentName: string = ''
  constructor(
    private titleService: Title,
    private modalService: MdbModalService,
    private envService: EnvironmentService
  ) { }

  ngOnInit(): void {
    this.environmentName = this.envService.getEnvironmentName();
    this.titleService.setTitle('Pricing | Event Odyssey')
  }

  contactSales() {
    // TODO: Replace contact support with contact sales component (and uncomment button in frontend)
    this.modalRef = this.modalService.open(ContactComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
    })
  }

}
