import { Component, ElementRef, Input, OnInit, ViewChild, NgZone } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { EditEventTimeDateComponent } from './edit-event-time-date/edit-event-time-date.component';
import { EventLocationComponent } from './edit/event-location/event-location.component';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
  eventForm: FormGroup
  @Input() communityDetails
  @ViewChild('search') public searchElementRef: ElementRef;

  savingEvent: boolean = false
  editorStyle = {
    height: '300px'
  };
  timeDetails
  locationDetails: any = {
    id: '',
    name: '',
    address: '',
    seatmapDetails: {
      seats: 0,
      seatType: 'unassigned',
      status: 'not-setup'
    },
    notes: ''
  }
  eventTimesModal: MdbModalRef<EditEventTimeDateComponent> | null = null;
  eventLocationModal: MdbModalRef<EventLocationComponent> | null = null;

  mapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]
  config = {
    toolbar: [
      ['bold', 'italic', 'underline',],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ]
  };
  lat = 40.7589;
  lng = -73.9851;
  options = {
    bounds: {
      north: this.lat + 1,
      south: this.lat - 1,
      east: this.lng + 1,
      west: this.lng - 1,
    },
    types: ['establishment'],
    fields: ["address_components", "geometry", "icon", "name"],
    componentRestrictions: {
      country: ['US']
    }
  };
  geoCoder
  constructor(
    public modalRef: MdbModalRef<CreateEventComponent>,
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.eventForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      description: new FormControl('', Validators.compose([Validators.minLength(5), Validators.required])),
      seatmapId: new FormControl('', Validators.compose([Validators.minLength(5), Validators.required])),
      venueId: new FormControl('', Validators.compose([Validators.minLength(5), Validators.required])),
      date: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
      startTime: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
      doorsOpenTime: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
    });
  }


  submit() {
    this.savingEvent = true
    const callable = this.functions.httpsCallable('createCommunityEvent');
    const obs = callable({
      communityId: this.communityDetails.id,
      name: this.eventForm.controls.name.value,
      description: this.eventForm.controls.description.value,
      seatmapId: this.eventForm.controls.seatmapId.value,
      venueId: this.eventForm.controls.venueId.value,
      date: this.eventForm.controls.date.value,
      startTime: this.eventForm.controls.startTime.value,
      doorsOpenTime: this.eventForm.controls.doorsOpenTime.value,
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created')
        this.savingEvent = false
      },
      error: (err) => {
        console.warn(err)
      },
    })
  }

  editEventTimes() {
    this.eventTimesModal = this.modalService.open(EditEventTimeDateComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        timeDetails: this.timeDetails
      }
    })
    this.eventTimesModal.onClose.subscribe((data: any) => {
      if(data) {
        this.timeDetails = data
        this.eventForm.controls.date.setValue(data.date)
        this.eventForm.controls.startTime.setValue(data.startTime)
        this.eventForm.controls.doorsOpenTime.setValue(data.doorsOpenTime)
      }
    })
  }

  editEventLocation() {
    this.eventLocationModal = this.modalService.open(EventLocationComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.eventLocationModal.onClose.subscribe((data: any) => {
      if(data) {
        this.locationDetails = data
        this.eventForm.controls.seatmapId.setValue(data.seatmapId)
        this.eventForm.controls.venueId.setValue(data.id)
        this.lat = data.lat
        this.lng = data.lng
      }
    })
  }

}
