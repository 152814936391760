
<ng-container *ngIf="loading; else showArticle">
  <app-loading></app-loading>
</ng-container>
<ng-template #showArticle>
  <ng-container *ngIf="view==='showArticle'; else editArticle">
  <div class="text-end">
    <i (click)="view='editArticle'" class="fas fa-edit pointer" title="Edit Article"></i> 
  </div>
  <div>
    <h3>{{articleData.name}}</h3>
    <p class="fw-normal mb-1">
      <span style="text-transform: Capitalize" *ngFor="let tag of articleData.tags; index as i">
        {{tag}}<span *ngIf="((articleData.tags.length!==1)&&((i+1)!==articleData.tags.length))">,</span>
      </span>
    </p>
    <p [innerHtml]="articleData.content"></p>
  </div>
  </ng-container>
  <ng-template #editArticle>
    <div class="text-end">
      <i (click)="view='showArticle'" class="fas fa-arrow-left pointer me-2" title="Back to Article"></i> 
      <i (click)="view='showArticle'" class="fas fa-save pointer" title="Save Article"></i> 
    </div>
    <form [formGroup]="postForm">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
              class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" />
            <label mdbLabel class="form-label" for="name">Name</label>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon [inputValid]="postForm.controls.name.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect">
      <div class="row mb-5">
        <quill-editor formControlName="message" [style]="editorStyle" [modules]="config"></quill-editor>
      </div>
    </form>
  </ng-template>


</ng-template>
