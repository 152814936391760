<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Link Community</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <app-work-in-progress></app-work-in-progress>
  <!-- <h5>Hey {{givenName}}, let's get you connected with {{communityDetails.name}}!</h5>
  <p>Before we continue, can you share some information about yourself with {{communityDetails.name}}?<br>They'll review these details as part of their membership request process.</p>
  <p>We'll send you an email once the request is accepted or denied.</p>
   <form [formGroup]="requestForm">
    <div class="input-group mt-2 mb-5">
      <div class="input-container">
        <mdb-form-control>
          <textarea mdbInput [mdbValidate] type="text" formControlName="requestText" id="requestText"  type="text" class="form-control" placeholder="" aria-label=""
          aria-describedby="button-addon2" aria-describedby="characterCounter" maxlength="150"></textarea>
          <label mdbLabel class="form-label" for="requestText">Request Message</label>
          <div class="form-helper">
            <div class="form-counter">{{ requestForm.controls.requestText.value.length }} / 150</div>
            <div *ngIf="requestForm.controls.requestText.value.length < 5" class="form-counter">Minimum 5 characters required</div>
            <div *ngIf="requestForm.controls.requestText.value.length === 150" class="form-counter">Maximum 150 characters allowed</div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="requestForm.controls.requestText.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
  </form> -->
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-rounded">Send Request<span *ngIf="creatingRequest">&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span></button>
</div> -->
