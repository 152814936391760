import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {
  @Input() content = "This is an infocard";
  @Input() type = "generic";
  textClass: string;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(): void {
    switch (this.type) {
      case 'generic':
        this.textClass = 'text';
        break;
      case 'info':
        this.textClass = 'text-primary';
        break;
      case 'success':
        this.textClass = 'text-success';
        break;
      case 'warning':
        this.textClass = 'text-warning';
        break;
      case 'danger':
        this.textClass = 'text-danger';
        break;
    }
  }

}
