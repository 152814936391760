<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Create Price</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="billingForm">

    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50" />
          <label mdbLabel class="form-label" for="name">Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ billingForm.controls.name.value.length }} / 50</div>
            <div *ngIf="billingForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="billingForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters
              allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon
          [inputValid]="billingForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle"
          mdbTooltip="The name that will be shown to customers when viewing the price(s) to access a video.<br><br>EX: Holiday Spectacular"
          [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="billingForm.controls.name.value.length < 3 || billingForm.controls.name.value.length === 50"
      class="noselect">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <textarea mdbInput [mdbValidate] type="text" formControlName="description" id="description"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Description"
            maxLength="250"></textarea>

          <label mdbLabel class="form-label" for="name">Description</label>
          <div class="form-helper">
            <div class="form-counter">{{ billingForm.controls.description.value.length }} / 250</div>
            <div *ngIf="billingForm.controls.description.value.length === 250" class="form-counter">Maximum 250
              characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="true"
          [displayEmpty]="true"></app-validation-icon>
        <i class="far fa-question-circle"
          mdbTooltip="A brief sentence to describe your product.<br><br>EX: Includes access to the 2022 Holiday Concert."
          [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="billingForm.controls.description.value.length === 250" class="noselect">
    <div class="input-group mt-2">
      <div class="input-container">
        <ng-container *ngIf="loadingGroups; else groupsInput">
          <div style="width: 100%; height: 60px;" class="skeleton mt-1"></div>
          <br class="noselect">
        </ng-container>
        <ng-template #groupsInput>
          <mdb-form-control>
            <ng-select [validateSuccess]="false" [mdbValidate] mdbInput (open)="displaySelectGroupDropdown=true"
              (close)="displaySelectGroupDropdown=false" formControlName="groups" [items]="groups" [multiple]="true"
              [virtualScroll]="true" placeholder="Group(s)" bindLabel="name" bindValue="id" appearance="outline"
              [closeOnSelect]="true" [clearable]="true" [searchable]="true" [virtualScroll]="true" groupBy="communityName"
              [selectableGroup]="true" [selectableGroupAsModel]="false" notFoundText="No groups found"
              autocomplete="off" required>
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                {{item.communityName}}
            </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                  <div class="">{{item.name}}</div>
                  <div class="text-muted small text-wrap" [innerHTML]="item.description"></div>
                </div>
              </ng-template>
            </ng-select>
          </mdb-form-control>

        </ng-template>
      </div>
      <span class="input-group-text border-0 align-self-center" style="margin-top: -20px">
        <app-validation-icon [inputValid]="billingForm.controls.groups.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <div *ngIf="displaySelectGroupDropdown" [style.height]="calculateHeight('groups')"></div>
    <!-- <div class="input-group">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput style="background-color: #ffffff !important" [mdbDatepicker]="datepicker" type="text"
            class="form-control form-control-lg" id="enableDate" (click)="datepicker.open()" [readonly]="true"
            formControlName="enableDate" />
          <label mdbLabel for="enableDate" class="form-label">Date Available</label>
          <mdb-datepicker-toggle [mdbDatepicker]="datepicker"></mdb-datepicker-toggle>
          <mdb-datepicker [inline]="true" #datepicker [format]="'mm/dd/yyyy'" [startDay]="1" [minDate]="minDate">
          </mdb-datepicker>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon
          [inputValid]="billingForm.controls.enableDate.invalid"></app-validation-icon>
        <i class="far fa-question-circle"
          mdbTooltip="The date this product will be available for purchase.<br><br>Defaults to today unless otherwise specified. "
          [html]="true" placement="right"></i></span>
    </div> -->
    <div class="input-group mt-2">
      <div class="input-container">
        <ng-container *ngIf="loadingTaxProfiles; else taxProfileInput">
          <div style="width: 100%; height: 60px;" class="skeleton mt-1"></div>
          <br class="noselect">
        </ng-container>
        <ng-template #taxProfileInput>
          <mdb-form-control>
            <ng-select [validateSuccess]="false" [mdbValidate] mdbInput (open)="displaySelectTaxProfileDropdown=true"
              (close)="displaySelectTaxProfileDropdown=false" formControlName="taxProfiles" [items]="taxProfiles" [multiple]="true"
              [virtualScroll]="true" placeholder="Tax Profile(s)" bindLabel="name" bindValue="id" appearance="outline"
              [closeOnSelect]="true" [clearable]="true" [searchable]="true" [virtualScroll]="true"
              notFoundText="No tax profiles found"
              autocomplete="off">
              <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                {{item.communityName}}
            </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [ngOptionHighlight]="search">
                  <div class="">{{item.name}}</div>
                </div>
              </ng-template>
            </ng-select>
          </mdb-form-control>

        </ng-template>
      </div>
      <span class="input-group-text border-0 align-self-center" style="margin-top: -20px">
        <app-validation-icon [inputValid]="true" [displayEmpty]="true"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <div *ngIf="displaySelectTaxProfileDropdown" [style.height]="calculateHeight('taxProfiles')"></div>
    <div class="input-group mt-4">
      <div class="input-container">
        <div class="input-group input-group-lg">
          <ng-container>
            <span class="input-group-text bg-grey">
              USD $
            </span>
          </ng-container>
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="number" formControlName="price" id="price"
              class="form-control form-control-lg" [validateSuccess]="false" placeholder="Price" />
          </mdb-form-control>
          <div class="form-helper mt-1">
            <div *ngIf="billingForm.controls.price.value<0.5" class="form-counter">Minimum price must be at least $0.50
            </div>
          </div>
        </div>
      </div>
      <span class="input-group-text border-0"><app-validation-icon
          [inputValid]="billingForm.controls.price.invalid"></app-validation-icon>
        <i class="far fa-question-circle"
          mdbTooltip="The product price that will be charged to your customers. It must be more than $0.50 to be considered valid. This value does not include transaction fees or service fees. "
          [html]="true" placement="right"></i></span>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="createProduct()" [disabled]="((billingForm.invalid)||(creatingProduct))" type="button"
    class="btn btn-primary btn-rounded">
    <span *ngIf="creatingProduct"><span class="spinner-border spinner-border-sm me-2" role="status"
        aria-hidden="true"></span> Creating Price</span>
    <span *ngIf="!creatingProduct">Create Price</span></button>
</div>
