import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { JoinedCommunitiesService } from '../services/joined-communities.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalAdminGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private joinedCommunitiesService: JoinedCommunitiesService
  ) { }

  private checkGlobalAdmin(state: RouterStateSnapshot): Observable<boolean> {
    return this.joinedCommunitiesService.globalAdminChanged.pipe(
      filter(globalAdmin => typeof globalAdmin === 'boolean'), // Wait until the value is a boolean
      take(1), // Take only the first boolean value
      map(globalAdmin => {
        if (globalAdmin) {
          return true;
        } else {
          this.router.navigate(['/access-denied'], {
            queryParams: {
              redirectUrl: state.url
            }
          });
          return false;
        }
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkGlobalAdmin(state).toPromise();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkGlobalAdmin(state).toPromise();
  }
}
