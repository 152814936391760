<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Add Location</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="locationForm">

    <div class="input-group mt-2 mb-3" style="z-index: 5000">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
              class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
            <label mdbLabel class="form-label" for="name">Name</label>
            <div class="form-helper">
              <div class="form-counter">{{ locationForm.controls.name.value.length }} / 50</div>
              <div *ngIf="locationForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required</div>
              <div *ngIf="locationForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed</div>
            </div>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon [inputValid]="locationForm.controls.name.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect">
      <br class="noselect">
      <br class="noselect">
      <br *ngIf="locationForm.controls.name.value.length < 3 || locationForm.controls.name.value.length === 50" class="noselect">
      <div class="input-container">
        <mdb-form-control>
          <input style="z-index: 9999" mdbInput [mdbValidate] type="text" formControlName="address" #search
            id="pac-input" type="text" placeholder="Enter a location" class="form-control form-control-lg"
            [validateSuccess]="false" placeholder="Address" />
          <label mdbLabel class="form-label" for="address">Address</label>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="locationForm.controls.formattedAddress.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <ng-container *ngIf="searched">
      <!-- <agm-map *ngIf="searched" [disableDefaultUI]="true" [latitude]="lat" [longitude]="lng" [styles]="mapStyles"
        [keyboardShortcuts]="false" [zoom]="15">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      </agm-map> -->
      <br class="noselect" />
      <div class="input-group">
        <div class="input-container">
          <mdb-form-control>
            <textarea mdbInput [mdbValidate] type="text" formControlName="notes" id="notes"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="150"></textarea>

            <label mdbLabel class="form-label" for="notes">Notes</label>
            <div class="form-helper">
              <div class="form-counter">{{ locationForm.controls.notes.value.length }} / 150</div>
              <div *ngIf="locationForm.controls.notes.value.length === 150" class="form-counter mb-2">Maximum 150 characters allowed
              </div>
            </div>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0">
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect" />
    </ng-container>

  </form>
</div>
<div class="modal-footer">
  <button (click)="createLocation()" [disabled]="locationForm.invalid||isSaving" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Creating</span>
    <span *ngIf="!isSaving">Create</span>
  </button>
</div>
