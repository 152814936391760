<ng-container *ngIf="loading; else displayCardSelector">
  <app-loading></app-loading>
</ng-container>
<ng-template #displayCardSelector>
  <form [formGroup]="cardsForm">
    <div *ngIf="cards.length!==0" >
      <div class="input-group mt-1">
        <div class="input-container">
          <mdb-form-control>
            <ng-select [validateSuccess]="false" [mdbValidate] mdbInput (open)="displaySelectDropdown=true"
              (close)="displaySelectDropdown=false" formControlName="paymentMethod" [items]="cards" [multiple]="false"
              [virtualScroll]="true" placeholder="Payment Method" appearance="outline" [closeOnSelect]="true"
              [clearable]="false" [searchable]="false" [virtualScroll]="true" required>
              <ng-template ng-label-tmp let-item="item">
                <app-card-detail [cardDetails]="item"></app-card-detail>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                  <app-card-detail [cardDetails]="item"></app-card-detail>
                  <app-enhanced-card-detail [cardDetails]="item"></app-enhanced-card-detail>
                </div>
              </ng-template>
            </ng-select>
          </mdb-form-control>
        </div>
      </div>
      <div class="ms-1">
        <div>
          <span type="button" (click)="basicCollapse.toggle()" [attr.aria-expanded]="!basicCollapse.collapsed"
          aria-controls="collapseExample" class="mb-0 me-3 pointer hoverUnderline">Add Payment Method</span>
          <span (click)="deleteCard()" class="text-danger mb-0 pointer hoverUnderline">Delete Card</span>
        </div>
      </div>
      <div *ngIf="displaySelectDropdown" [style.height]="calculateHeight()"></div>
    </div>
    <div [collapsed]="cards.length!==0" class="mt-3" id="collapseExample" mdbCollapse #basicCollapse="mdbCollapse">
      <app-add-card (outputAddedCard)="outputAddedCard($event)"></app-add-card>
    </div>
  </form>
</ng-template>
