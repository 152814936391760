import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { LoginComponent } from 'src/app/components/shared/login/login.component';

@Component({
  selector: 'app-not-authenticated',
  templateUrl: './not-authenticated.component.html',
  styleUrls: ['./not-authenticated.component.scss']
})
export class NotAuthenticatedComponent implements OnInit {
  modalRef: MdbModalRef<LoginComponent> | null = null;

  constructor(
    private modalService: MdbModalService,
  ) { }

  ngOnInit(): void {
  }

  login() {
    this.modalRef = this.modalService.open(LoginComponent, {
      ignoreBackdropClick: true,
    })
  }

}
