import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrl: './view-map.component.scss'
})
export class ViewMapComponent {
  apiLoaded: boolean = false;
  @Input() lat: number;
  @Input() lng: number;
  @Input() mapStyles: any;

  constructor() { }

  ngOnInit(): void {
    this.loadGoogleMapsApi();
  }

  private loadGoogleMapsApi() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.apiLoaded = true;
    };
    document.head.appendChild(script);
  }
}