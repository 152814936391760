import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Title } from '@angular/platform-browser';
import { Options } from 'ngx-qrcode-styling';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit {
  loading: boolean = true
  tickets: any[] = []
  error
  validatorCode: string = '5555'
  public config: Options = {
    width: 200,
    height: 200,
    margin: 2,
    dotsOptions: {
      color: "#4d4d4d",
      type: "extra-rounded"
    },
    shape: 'square',
    backgroundOptions: {
      color: "#ffffff",
    },
    cornersSquareOptions: {
      color: '#000000'
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  constructor(
    private functions: AngularFireFunctions,
    private titleService: Title,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('My Tickets | Event Odyssey')
    this.afAuth.authState.subscribe((user) => {
      this.fetchMyVideos()
    })
  }

  fetchMyVideos() {
    const callable = this.functions.httpsCallable('getMyTickets');
    const obs = callable({});
    obs.subscribe({
      next: (res) => {
        this.tickets = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

}
