import { Component, Input, OnInit } from '@angular/core';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit {
  @Input() message: string
  constructor() { }

  ngOnInit(): void {
  }

}
