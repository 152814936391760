import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { EditVideoComponent } from '../../modals/edit-video/edit-video.component';
import { PurchaseVideoWithCardReaderComponent } from '../../modals/purchase-video-with-card-reader/purchase-video-with-card-reader.component';
import { PurchaseVideoComponent } from '../../modals/purchase-video/purchase-video.component';

@Component({
  selector: 'app-community-manage-list-item',
  templateUrl: './community-manage-list-item.component.html',
  styleUrls: ['./community-manage-list-item.component.scss']
})
export class CommunityManageListItemComponent implements OnInit {
  @Input() communityDetails
  posModalRef: MdbModalRef<PurchaseVideoWithCardReaderComponent> | null = null;
  modalRef: MdbModalRef<EditVideoComponent> | null = null;
  purchaseModalRef: MdbModalRef<PurchaseVideoComponent> | null = null;
  @Input() itemDetails = {
    name: '',
    communityDetails: {
      name: ''
    },
    length: '',
    id: '',
    gif: 'https://image.mux.com/S3klOSZ00YuWV02002lIPd9fU6C7EDlD1bQ6oB00c3yjCsA/animated.gif?start=30&end=40',
    thumbnail: 'https://image.mux.com/S3klOSZ00YuWV02002lIPd9fU6C7EDlD1bQ6oB00c3yjCsA/thumbnail.png?time=30'
  }
  constructor(
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
  }

  edit(section: string) {
    this.modalRef = this.modalService.open(EditVideoComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        section: section,
        itemDetails: this.itemDetails
      }
    })
  }

  sellWithCardReader() {
    this.posModalRef = this.modalService.open(PurchaseVideoWithCardReaderComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        itemDetails: this.itemDetails,
        communityDetails: this.communityDetails
      }
    })
  }

  purchase() {
    this.purchaseModalRef = this.modalService.open(PurchaseVideoComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        itemDetails: this.itemDetails
      }
    })
  }



}
