<form [formGroup]="groupForm">
  <div class="input-group">
    <div class="input-container">
      <mdb-form-control>
        <input mdbInput [mdbValidate] type="text" [readonly]="true" formControlName="name" id="name"
          class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" />
        <label mdbLabel class="form-label" for="name">Full Name</label>
      </mdb-form-control>
    </div>
    <span class="input-group-text border-0"><app-validation-icon [inputValid]="groupForm.controls.name.invalid"></app-validation-icon>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>
  <div class="input-group mt-2">
    <div class="input-container">
      <mdb-form-control>
        <input mdbInput [mdbValidate] type="text" [readonly]="true" formControlName="email" id="email"
          class="form-control form-control-lg" [validateSuccess]="false" placeholder="Email" />
        <label mdbLabel class="form-label" for="email">Email</label>
      </mdb-form-control>

    </div>
    <span class="input-group-text border-0"><app-validation-icon [inputValid]="groupForm.controls.email.invalid"></app-validation-icon>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>
</form>
<small *ngIf="userProfile.provider!=='phone'">
  <span *ngIf="userProfile.provider==='google.com'"> Your account is managed by Google. You can edit your profile <a href="https://myaccount.google.com/personal-info" target="_blank">here</a>.</span>
  <span *ngIf="userProfile.provider==='facebook.com'"> Your account is managed by Facebook. You can edit your profile <a href="https://www.facebook.com/profile.php?sk=about_contact_and_basic_info" target="_blank">here</a>.</span>
  <span *ngIf="userProfile.provider==='twitter.com'"> Your account is managed by Twitter. You can edit your profile <a href="https://twitter.com/settings/profile" target="_blank">here</a>.</span>
  <span *ngIf="userProfile.provider==='apple.com'"> Your account is managed by Apple. You can edit your profile <a href="https://appleid.apple.com/account/manage" target="_blank">here</a>.</span>
</small>
