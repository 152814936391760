<div class="card">
  <div class="card-body">
    <h5 class="card-title">{{postDetails.title}}</h5>
    <p class="card-text" [innerHTML]="postDetails.body"></p>
    <div class="card-text">
      <div class="d-flex align-items-center">
        <img referrerPolicy="no-referrer" [src]="postDetails.editors[0].profilePic" alt=""
          style="width: 40px; height: 40px;" class="rounded-circle" />
        <div class="ms-3">
          <p class="lh-1 fw-bold mb-1">{{postDetails.editors[0].name}}</p>
          <p class="lh-1 text-muted mb-0">
            {{(postDetails.created*1000) | date:'mediumDate'}}&nbsp;({{(postDetails.created*1000) | timeAgo}})
          </p>
        </div>
      </div>
    </div>

  </div>
</div>
