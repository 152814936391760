<mdb-tabs [vertical]="isVertical" [pills]="true" (activeTabChange)="onTabChange($event)" #tabs *ngIf="communityDetails.stripe">
  <mdb-tab title="Overview">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-chart-line me-2"></i> Overview</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-community-manage-billing [communityDetails]="communityDetails" (outputStripeDetails)="outputStripeDetails($event)"></app-community-manage-billing>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Payouts">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-dollar-sign me-2"></i> Payouts</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-community-payouts-list [communityDetails]="communityDetails" [stripeAccountDetails]="stripeAccountDetails"></app-community-payouts-list>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Transactions">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-list me-2"></i> Transactions</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-community-transactions-list [communityDetails]="communityDetails"></app-community-transactions-list>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Accounts" *ngIf="environmentName!=='production'">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-university me-2"></i> Accounts</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-accounts [communityDetails]="communityDetails"></app-list-accounts>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Sales Tax">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-percentage me-2"></i> Sales Tax</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-community-sales-tax [communityDetails]="communityDetails"></app-community-sales-tax>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Card Readers" *ngIf="environmentName!=='production'">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-credit-card me-2"></i> Card Readers</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-card-readers [communityDetails]="communityDetails"></app-list-card-readers>
    </ng-template>
  </mdb-tab>
</mdb-tabs>
<div *ngIf="!communityDetails.stripe">
  <app-community-manage-billing [communityDetails]="communityDetails"></app-community-manage-billing>
</div>
