import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationConfig, MdbNotificationRef, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../shared/toast/toast.component';

@Component({
  selector: 'app-apple-wallet-unsupported',
  templateUrl: './apple-wallet-unsupported.component.html',
  styleUrls: ['./apple-wallet-unsupported.component.scss']
})
export class AppleWalletUnsupportedComponent implements OnInit {
  toastOptions = environment.toastOptions.generic as MdbNotificationConfig<{}>;

  constructor(
    public modalRef: MdbModalRef<AppleWalletUnsupportedComponent>,
    private toastService: MdbNotificationService,
    private clipboard: Clipboard
    ) { }

  ngOnInit(): void {
  }

  copyURL() {
    const currentUrl = window.location.href;
    this.clipboard.copy(currentUrl);
    // this.toastOptions.data['content'] = 'URL Copied to clipboard!';
    this.toastOptions.data['title'] = 'URL Copied to clipboard!';
    this.toastService.open(ToastComponent, this.toastOptions)
    this.modalRef.close()
  }

}
