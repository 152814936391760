import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Title } from '@angular/platform-browser';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';

@Component({
  selector: 'app-my-videos-view',
  templateUrl: './my-videos-view.component.html',
  styleUrls: ['./my-videos-view.component.scss']
})
export class MyVideosViewComponent implements OnInit {
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  @Input() lookupUid 
  loading: boolean = true
  searchText: string
  videoItems: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private titleService: Title,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('My Videos | Event Odyssey')
    this.afAuth.authState.subscribe((user) => {
      this.fetchMyVideos()
    })
  }

  fetchMyVideos() {
    this.loading = true
    const callable = this.functions.httpsCallable('getMyVideos');
    let obs = callable({});
    if(this.lookupUid) { obs = callable({ overrideUid: this.lookupUid })  }
    obs.subscribe({
      next: (res) => {
        this.videoItems = res
        // this.membersTable.search('')
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'name':
          let name = data[key]
          return name.toLowerCase().includes(searchTerm.toLowerCase());
        case 'communityDetails':
          let communityName = data[key].name
          return communityName.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

}
