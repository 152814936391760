import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {
  eventForm: FormGroup
  @Input() communityDetails
  @Input() eventDetails
  savingEvent: boolean = false
  editorStyle = {
    height: '100px'
  };
  config = {
    toolbar: [
      ['bold', 'italic', 'underline',],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ]
  };
  constructor(
    public modalRef: MdbModalRef<EditEventComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.eventForm = new FormGroup({
      name: new FormControl(this.eventDetails.title, Validators.compose([Validators.minLength(3), Validators.required])),
      ticketColor: new FormControl(this.eventDetails.ticketColor, Validators.compose([Validators.minLength(6), Validators.required])),
      message: new FormControl(this.eventDetails.body, Validators.compose([Validators.minLength(3), Validators.required])),
    });
  }

  submit() {
    const name = this.eventForm.controls.name.value
    const message = this.eventForm.controls.message.value
    const ticketColor = this.eventForm.controls.ticketColor.value
    this.savingEvent = true
    const callable = this.functions.httpsCallable('updateCommunityEvent');
    const obs = callable({
      communityId: this.communityDetails.id,
      id: this.eventDetails.id,
      title: name,
      ticketColor: ticketColor,
      body: message
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created')
        this.savingEvent = false
      },
      error: (err) => {
        console.warn(err)
      },
    })
  }

}
