<div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-8">
    <!-- Content -->
    <ng-container *ngIf="!loading; else displaySkeleton">
      <div class="datatable">
        <div class="d-flex justify-content-between" style="margin-bottom: 20px">
          <div>
            <div class="input-group">
              <input *ngIf="videoItems.length>0" type="text" class="form-control form-control-lg" placeholder="Filter videos"
                (keyup)="search($event)" #searchInput size=25 />
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-end align-items-center">
              <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
                <button [disabled]="loading" class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button"
                  id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
                  <span><i class="fas fa-cog me-2"></i>Actions</span>
                </button>
                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                  <li (click)="fetchMyVideos()"><a class="dropdown-item pointer"><i
                        class="fas fa-sync-alt me-2"></i>Refresh</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row" mdbTable mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true"
        [dataSource]="videoItems" [pagination]="pagination" [filterFn]="filterTable">

        <div class="col-md-4 col-lg-3 col-xl-3" *ngFor="let item of table.data; let i = index">
          <div>
            <app-list-item [itemDetails]="item"></app-list-item><br>
          </div>
        </div>
        <ng-container *ngIf="videoItems.length>0; else noVideos">
          <app-no-results *ngIf="pagination._total===0&&searchText" [message]="'No videos found matching: ' + searchText"></app-no-results>
        </ng-container>
        <ng-template #noVideos>
          <app-no-results message="No purchased videos found"></app-no-results>
        </ng-template>
        <mdb-table-pagination class="mt-3" #pagination [entries]="12" rowsPerPageText="Results"></mdb-table-pagination>


      </div>
      </div>

    </ng-container>
    <ng-template #displaySkeleton>
      <ng-container *ngIf="!error; else displayError">
        <div class="row">
          <div *ngFor="let item of [].constructor(6); let i = index" class="col-md-4 col-lg-3 col-xl-3">
            <app-list-item-loading></app-list-item-loading><br>
          </div>
        </div>
      </ng-container>
      <ng-template #displayError>
        <app-https-error [error]="error"></app-https-error>
      </ng-template>
    </ng-template>
  </div>