<ng-container *ngIf="!loading; else displayLoading">

  <div class="datatable">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="input-group">
          <input *ngIf="transactions.length>0" type="text" class="form-control form-control-lg" placeholder="Filter transactions"
            (keyup)="search($event)" #searchInput size=25 />
        </div>
      </div>
      <div>
        <div style="text-align:right;" class="text-right">
          <input class="button-input-no-outline" class="datepicker" type="text" ngxDaterangepickerMd [ranges]="ranges"
            [alwaysShowCalendars]="true" (datesUpdated)="updateDates($event)" />
          <button class="ngx-daterangepicker-action btn btn-rounded btn-white pointer"
            style="position: relative;z-index: 200;" (click)="openDatepicker();">
            <span class="pointer">{{startDate}} <i class="fas fa-long-arrow-alt-right mx-2"
                style="padding-top:0.15rem"></i>
              {{endDate}}</span>

          </button>&nbsp;
          <button (click)="export()" class="btn btn-rounded btn-white pointer">
            <span class="pointer"><i class="fas fa-download me-2"></i>Export
              <span *ngIf="savingExport">&nbsp;&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="transactions.length===0">
      <app-no-results message="No transactions found for the specified date range."></app-no-results>
    </div>
    <div *ngIf="transactions.length>0">
        <table responsive class="table align-middle mb-0 bg-white"
        class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
        mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true" [dataSource]="transactions"
        [pagination]="pagination" [sort]="sort" [filterFn]="filterTable">

        <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">

        <thead class="bg-light">
          <tr>
            <th [mdbTableSortHeader]="'id'">Purchase</th>
            <th [mdbTableSortHeader]="'created'">Date</th>
            <th [mdbTableSortHeader]="'amount'">Gross</th>
            <th [mdbTableSortHeader]="'fees'">Fees</th>
            <th [mdbTableSortHeader]="'net'">Net</th>
            <th [mdbTableSortHeader]="'refunded'">Status</th>
            <th></th>
          </tr>
        </thead>
      </ng-container>
      <ng-template #noResultsFound>
        <div class="mb-5"></div>
        <app-no-results [message]="'No transactions found matching: ' + searchText"></app-no-results>
        <div class="mb-5"></div>
      </ng-template>
      <tbody>
          <tr *ngFor="let item of table.data; let i = index">
            <td>
              <div class="d-flex align-items-center">
                <div class="">
                  <p class="fw-bold mb-1">{{item.customer.name}}</p>
                  <!-- <p class="text-muted mb-0">{{item.video.name}}</p> -->
                  <p class="text-muted mb-0"> <app-card-detail [cardDetails]="item.payment_method_details"></app-card-detail> </p>
                </div>
              </div>
            </td>
            <td>
              <p class="fw-normal mb-1">{{(item.created*1000) | timeAgo}}</p>
              <p class="fw-normal mb-1"><span class="small">{{(item.created*1000) | date:'medium'}}</span></p>
            </td>
            <td>
              <p *ngIf="(item.amount-item.amount_refunded)===0" class="fw-normal mb-1"><strike>{{((item.amount)/100) | currency}}</strike></p>
              <p class="fw-normal mb-1">{{((item.amount-item.amount_refunded)/100) | currency}}</p>
            </td>
            <td>
              <p *ngIf="(item.amount-item.amount_refunded)===0" class="fw-normal mb-1"><strike>({{(item.fees/100) | currency}})</strike></p>
              <p *ngIf="(item.amount-item.amount_refunded)===0" class="fw-normal mb-1">$0.00</p>
              <p *ngIf="(item.amount-item.amount_refunded)!==0" class="fw-normal mb-1">({{(item.fees/100) | currency}})</p>
            </td>
            <td>
              <p *ngIf="(item.amount-item.amount_refunded)!==0" class="fw-normal mb-1">{{(item.net/100) | currency}}</p>
              <p *ngIf="(item.amount-item.amount_refunded)===0" class="fw-normal mb-1"><strike>{{(item.net/100) | currency}}</strike></p>
              <p *ngIf="(item.amount-item.amount_refunded)===0" class="fw-normal mb-1">{{((item.amount-item.amount_refunded)/100) | currency}}</p>
            </td>
            <td style="width: 100px;">
              <span *ngIf="item.status==='succeeded'&&item.refunded===false"
                class="badge badge-success rounded-pill d-inline">Complete</span>
              <span *ngIf="item.status==='pending'" class="badge badge-warning rounded-pill d-inline">Pending</span>
              <span *ngIf="item.status==='refunded'" class="badge badge-danger rounded-pill d-inline">Refunded</span>
              <span *ngIf="item.status==='succeeded'&&item.refunded===true" class="badge badge-danger rounded-pill d-inline">Refunded</span>
            </td>
            <td style="width:50px">
              <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;" class="dropdown-toggle hidden-arrow tableDropdown text-center "
                  role="button" mdbDropdownToggle aria-expanded="false">
                  <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                    class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                </span>
                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <a (click)="details(item)" class="dropdown-item pointer">Details</a>
                  </li>
                  <li *ngIf="!item.refunded" (click)="refund(item)">
                    <a class="dropdown-item pointer">Refund</a>
                  </li>
                  <li *ngIf="item.userId!=='guest'">
                    <a (click)="viewCustomer(item)" class="dropdown-item pointer">View Customer</a>
                  </li>
                </ul>
              </span>
            </td>
          </tr>


        </tbody>
      </table>

      <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
    </div>
  </div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
