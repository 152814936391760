import { Component, Input, OnInit } from '@angular/core';
import { Community } from 'src/app/interfaces/community';
import { EditCommunityHeaderComponent } from '../edit-community-header/edit-community-header.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-general-details',
  templateUrl: './edit-general-details.component.html',
  styleUrls: ['./edit-general-details.component.scss']
})
export class EditGeneralDetailsComponent implements OnInit {
  modalRef: MdbModalRef<EditCommunityHeaderComponent> | null = null;
  private communitiesSubscription: Subscription;
  private adminCommunitiesSubscription: Subscription;
  joinedCommunitiesLoading: boolean = false
  hasDeletePermission: boolean = false
  hasTransferPermission: boolean = false
  loadingCommunityActionsCard: boolean = false
  joinedCommunities: any[] = []
  joinedAdminCommunities: any[] = []
  confirmActionModalRef: MdbModalRef<ConfirmActionComponent> | null = null;

  @Input() communityDetails: Community




  constructor(
    private joinedCommunitiesService: JoinedCommunitiesService,
    private modalService: MdbModalService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    console.log(this.communityDetails)
    this.communitiesSubscription = this.joinedCommunitiesService.joinedCommunitiesChanged.subscribe((joinedCommunities: any[]) => {
      this.joinedCommunitiesLoading = joinedCommunities[0]?.loading || false;
      this.joinedCommunities = joinedCommunities;
    });
    this.adminCommunitiesSubscription = this.joinedCommunitiesService.joinedAdminCommunitiesChanged.subscribe((joinedAdminCommunities: any[]) => {
      if (!(joinedAdminCommunities[0]?.loading)) {
        this.joinedAdminCommunities = joinedAdminCommunities
        if (this.communityDetails.parentCommunity) {
          const parentCommunityId = (this.communityDetails.parentCommunity[(this.communityDetails.parentCommunity.length - 1)].id)
          this.hasTransferPermission = this.joinedAdminCommunities.some(community => community.id === parentCommunityId && community.permissions.includes('transfer_community'));
          this.hasDeletePermission = this.joinedAdminCommunities.some(community => community.id === parentCommunityId && community.permissions.includes('delete_community'));
        } else {
          this.hasTransferPermission = this.joinedAdminCommunities.some(community => community.id === this.communityDetails.id && community.permissions.includes('transfer_community'));
          this.hasDeletePermission = this.joinedAdminCommunities.some(community => community.id === this.communityDetails.id && community.permissions.includes('delete_community'));
        }
        this.loadingCommunityActionsCard = true
      }
    });
  }


  editHeader() {
    this.modalRef = this.modalService.open(EditCommunityHeaderComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
  }


  deleteCommunity() {
    let content = `<div class="text-center">Are you sure you want to delete this account?</div>`;
    this.confirmActionModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Delete Community',
        content: content,
        confirmButton: 'Delete Community',
        confirmButtonPlural: 'Deleting Community',
        type: 'deleteCommunity',
        miscData: {
          communityId: this.communityDetails.id,
        },
        confirmText: this.communityDetails.name,
        confirmTextLabel: 'Account Name'
      }
    })
    this.confirmActionModalRef.onClose.subscribe((message: any) => {
      if (message === 'removed') {
        this.joinedCommunitiesService.fetchJoinedCommunities()
        this.router.navigateByUrl('/')
      }
    });
  }

}
