<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Sell to Guest</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
  <div class="modal-body">
    <form [formGroup]="inviteForm">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="text" formControlName="name" id="name" class="form-control form-control-lg"
              [validateSuccess]="false" placeholder="Name" maxLength="50"/>
            <label mdbLabel class="form-label" for="name">Name</label>
            <div class="form-helper">
              <div class="form-counter">{{ inviteForm.controls.name.value.length }} / 50</div>
              <div *ngIf="inviteForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
              </div>
              <div *ngIf="inviteForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
              </div>
            </div>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon [inputValid]="inviteForm.controls.name.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect">
      <br *ngIf="inviteForm.controls.name.value.length < 3 || inviteForm.controls.name.value.length === 50"
        class="noselect">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="text" formControlName="phoneNumber" id="phoneNumber" class="form-control form-control-lg"
              [validateSuccess]="false" placeholder="Phone Number" mask="(000) 000-0000" />
            <label mdbLabel class="form-label" for="phoneNumber">Phone Number</label>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon [inputValid]="inviteForm.controls.phoneNumber.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br>
      <button (click)="submit()" [disabled]="inviteForm.invalid" type="button" class="btn btn-primary btn-rounded">Submit </button>
    </form>
  </div>
