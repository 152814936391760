<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Payout Details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!loading; else displayLoading">
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="card mb-6">
          <div class="card-body text-center">
            <h5 class="card-title">{{(enhancedPayoutDetails.net/100) | currency}}</h5>
            <p class="card-text">
              Amount
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="card mb-6">
          <div class="card-body text-center">
            <h5 class="card-title">{{(enhancedPayoutDetails.created*1000) | date}}</h5>
            <p class="card-text">
              Created
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="card mb-6">
          <div class="card-body text-center">
            <h5 class="card-title">{{(enhancedPayoutDetails.available_on*1000) | date}}</h5>
            <p class="card-text">
              Available
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="datatable">
      <div class="d-flex justify-content-between" style="margin-bottom: 20px">
        <div>
          <div class="input-group">
            <input *ngIf="payoutTransactions.length>0" type="text" class="form-control form-control-lg"
              placeholder="Filter transactions" (keyup)="search($event)" #searchInput size=25 />
          </div>
        </div>
        <div>
          <div style="text-align:right;" class="text-right">
            <button (click)="export()" class="btn btn-rounded btn-white pointer">
              <span class="pointer"><i class="fas fa-download me-2"></i>Export
                <span *ngIf="savingExport">&nbsp;&nbsp;&nbsp;<span
                    class="spinner-border spinner-border-sm me-2"></span></span>
              </span>
            </button>
          </div>
        </div>

      </div>
      <div *ngIf="payoutTransactions.length===0">
        <app-no-results message="No transactions found"></app-no-results>
      </div>
      <div *ngIf="payoutTransactions.length>0">
        <table responsive class="table align-middle mb-0 bg-white"
          class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
          mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true"
          [dataSource]="payoutTransactions" [sort]="sort" [pagination]="pagination" [filterFn]="filterTable">

          <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
          <thead class="bg-light">
            <tr>
              <th [mdbTableSortHeader]="'description'">Payout</th>
              <th [mdbTableSortHeader]="'type'">Status</th>
              <th [mdbTableSortHeader]="'amount'">Gross</th>
              <th [mdbTableSortHeader]="'fee'">Fees</th>
              <th [mdbTableSortHeader]="'net'">Net</th>
              <th [mdbTableSortHeader]="'currentTotal'">Total</th>
              <th></th>
            </tr>
          </thead>
        </ng-container>
        <ng-template #noResultsFound>
          <div class="mb-5"></div>
          <app-no-results [message]="'No transactions found matching: ' + searchText"></app-no-results>
          <div class="mb-5"></div>
        </ng-template>
          <tbody>


            <tr *ngFor="let item of table.data; let i = index">
              <td>
                <p class="fw-normal mb-1"><b>{{item.description || 'CHARGE'}}</b></p>
                <p class="fw-normal mb-1">ID: {{item.source.substring(3)}}</p>
              </td>
              <td>
                <span *ngIf="item.type==='charge'" class="badge badge-success rounded-pill d-inline">Payment</span>
                <span *ngIf="item.type==='refund'" class="badge badge-danger rounded-pill d-inline">Refunded</span>
              </td>
              <td>
                <p class="fw-normal mb-1">{{(item.amount/100) | currency}}</p>
              </td>
              <td>
                <p class="fw-normal mb-1">({{(item.fee/100) | currency}})</p>
              </td>
              <td>
                <p class="fw-normal mb-1">{{(item.net/100) | currency}}</p>
              </td>
              <td>
                <p class="fw-normal mb-1">{{(item.currentTotal/100) | currency}}</p>
              </td>
              <td style="width: 50px" class="text-end">
                <span *ngIf="item.type==='charge'" style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                  <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
                    class="dropdown-toggle hidden-arrow tableDropdown text-center " role="button" mdbDropdownToggle aria-expanded="false">
                    <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                      class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                  </span>
                  <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                    <li (click)="details(item)">
                      <a class="dropdown-item pointer">Details</a>
                    </li>
                  </ul>
                </span>

              </td>
            </tr>


          </tbody>
        </table>
        <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
      </div>
    </div>



  </ng-container>
  <ng-template #displayLoading>
    <ng-container *ngIf="!error; else displayError">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </ng-template>

</div>
