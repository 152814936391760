<div class="container text-center">
  <div #iconWrapper class="mb-3">
    <i
      [@tadaEnter]
      class="fas fas fa-exclamation fa-2x bg-danger bg-gradient text-light"
    ></i>
  </div>
  <h1>We're Sorry!</h1>
  <h5>We ran into a problem while processing your request.</h5>
  <h6 *ngIf="error && error.toLowerCase() !== 'internal'">{{ error }}</h6>
</div>
