<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Login</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="loginView==='menu'; else otherView">
    <div class="container">
      <div class="row">
        <div class="d-flex border mb-3 btn" (click)="socialLogin('google')" title="Continue with Google"  role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating" (click)="socialLogin('google')" style="background-color: #dd4b39" role="button"><i class="fab fa-google"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Google</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('apple')" title="Continue with Apple"  role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating" (click)="socialLogin('apple')" style="background-color: #323232" role="button"><i class="fab fa-apple"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Apple</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('microsoft')" title="Continue with Microsoft" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating" style="background-color: #0078d4" role="button"><i class="fab fa-windows"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Microsoft</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('facebook')" title="Continue with Facebook" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating" style="background-color: #3b5998" role="button"><i class="fab fa-facebook-f"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Facebook</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('yahoo')" title="Continue with Yahoo" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating" style="background-color: #400090" role="button"><i class="fab fa-yahoo"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Yahoo</div>
        </div>
        <div class="d-flex border mb-2 btn" (click)="socialLogin('twitter')" title="Continue with Twitter" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating" style="background-color: #55acee" role="button"><i class="fab fa-twitter"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Twitter</div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #otherView>
    <div class="container">
      <div class="row">
        <span (click)="loginView = 'menu'" class="pointer hoverUnderline"><i class="fas fa-arrow-left"></i> Other login methods</span>
        <div id="sign-in-button"></div>
      </div><br>
    </div>
    <div *ngIf="loginView==='phone'">
      <div class="container">
        <div class="row">
          <form [formGroup]="phoneForm">
            <div class="input-group mt-2" style="margin-bottom: 20px">
              <div class="input-container">
                <mdb-form-control>
                  <input mdbInput [mdbValidate] type="text" formControlName="phoneNumber" id="phoneNumber" class="form-control form-control-lg"
                    [validateSuccess]="false" placeholder="Phone Number" mask="(000) 000-0000" (keyup.enter)="loginWithPhone()" />
                  <label mdbLabel class="form-label" for="phoneNumber">Phone Number</label>
                </mdb-form-control>
              </div>
            </div>
            <div id="reCaptcha"></div>
            <div *ngIf="loginWithPhoneError">
              <div><app-notice [content]="loginWithPhoneError" [type]="loginWithPhoneErrorType"></app-notice>&nbsp;&nbsp;
                <span *ngIf="loginWithPhoneErrorType==='warning'" class="hoverUnderline pointer" (click)="login('phone')">Retry Verification</span></div><br>
            </div>
            <button (click)="loginWithPhone()" [disabled]="((phoneForm.invalid)||(sendingPhoneVerificationCode))" type="button" class="btn btn-primary btn-rounded">Send Verification Code <span *ngIf="sendingPhoneVerificationCode">&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span></button>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="loginView==='verifyPhone'">
      <div class="container">
        <div class="row">
          <form [formGroup]="phoneVerificationForm">
            <div class="input-group mt-2" style="margin-bottom: 20px">
              <div class="input-container">
                <mdb-form-control>
                  <input mdbInput [mdbValidate] type="text" formControlName="verificationCode" id="verificationCode" class="form-control form-control-lg"
                    [validateSuccess]="false" placeholder="Verification Code" mask="000000" (keyup.enter)="verifyPhoneNumber()"/>
                  <label mdbLabel class="form-label" for="verificationCode">Verification Code</label>
                </mdb-form-control>
              </div>
            </div>
            <div *ngIf="phoneVerificationError">
              <app-notice [content]="phoneVerificationError" type="danger"></app-notice><br><br>
            </div>
            <button (click)="verifyPhoneNumber()" [disabled]="((phoneVerificationForm.invalid)||(validatingCode))" type="button" class="btn btn-primary btn-rounded">Verify Number <span *ngIf="validatingCode">&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span></button>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="loginView==='email'">
      <div class="container">
        <div class="row">
          Email login form
        </div>
      </div>
    </div>
  </ng-template>
</div>
