import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit {
  @Input() itemDetails
  @Input() communityDetails
  billingForm: FormGroup;
  creatingProduct: boolean = false
  displaySelectGroupDropdown: boolean = false
  displaySelectTaxProfileDropdown: boolean = false
  minDate;
  loadingGroups: boolean = true
  loadingTaxProfiles: boolean = true
  groups: any[] = []
  taxProfiles: any[] = []
  error
  constructor(
    public modalRef: MdbModalRef<CreateProductComponent>,
    private functions: AngularFireFunctions,
  ) { }

  ngOnInit(): void {
    console.log(this.itemDetails)
    console.log(this.communityDetails)
    this.fetchGroups()
    this.fetchTaxProfiles()
    const yesterdayDate = (new Date().getMonth()+1)+'/'+(new Date().getDate()-1)+'/'+new Date().getFullYear()
    this.minDate = new Date(yesterdayDate);
    let enableDate = new Date();
    this.billingForm = new FormGroup({
        name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
        description: new FormControl(''),
        groups: new FormControl([], Validators.compose([Validators.minLength(1), Validators.required])),
        taxProfiles: new FormControl([], this.alwaysValid),
        enableDate: new FormControl(enableDate, [Validators.required]),
        price: new FormControl('25.00', Validators.compose([Validators.min(0.50), Validators.required])),
    });
  }

  fetchGroups() {
    this.loadingGroups = true
    const callable = this.functions.httpsCallable('getGroups');
    const obs = callable({
      communityId: this.itemDetails.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        res.unshift({
          communityName: 'Test123',
          communityId: this.itemDetails.communityDetails.id,
          community: {id: 'test', name: 'test 123'},
          description: `All registered members of ${this.itemDetails.communityDetails.name}.`,
          name: 'All community members',
          id: `${this.itemDetails.communityDetails.id}-allcommunitymembers`
        })
        this.groups = res
        this.billingForm.controls.groups.setValue([`${this.itemDetails.communityDetails.id}-allcommunitymembers`])
        this.loadingGroups = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  fetchTaxProfiles() {
    this.loadingTaxProfiles = true
    const callable = this.functions.httpsCallable('getCommunityTaxProfiles');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.taxProfiles = res
        this.loadingTaxProfiles = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  createProduct() {
    this.creatingProduct = true
    const callable = this.functions.httpsCallable('createProduct');
    const obs = callable({
      videoId: this.itemDetails.id,
      communityId: this.communityDetails.id,
      productName: this.billingForm.controls.name.value,
      productDescription: this.billingForm.controls.description.value,
      taxProfiles: this.billingForm.controls.taxProfiles.value,
      dateAvailable: this.billingForm.controls.enableDate.value,
      price: this.billingForm.controls.price.value,
      groups: this.billingForm.controls.groups.value
    });
    obs.subscribe({
      next: (res) => {
        this.creatingProduct = false
        this.modalRef.close('created')
      },
      error: (err) => {
        console.warn(err)

      },
    })
  }

  calculateHeight(arrayId: string): string {
    let baseHeight = 230;
    let minimumHeight = 100;
    if(arrayId==='taxProfiles') {
      minimumHeight = 50
      baseHeight = 115
     }
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    return Math.max(minimumHeight, heights[this[arrayId].length] || baseHeight) + 'px';
  }

  alwaysValid(control: FormControl) {
    return null; // Always consider the control as valid
  }

}
