<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{userDetails.displayName}}'s Groups</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!loading; else displayLoading">
    <form [formGroup]="groupForm">

      <div class="input-group mt-2">
        <div #group class="input-container">
          <mdb-form-control>
            <ng-select  [virtualScroll]="false"  mdbInput formControlName="groups"
              (open)="displaySelectDropdown=true" (close)="displaySelectDropdown=false" [items]="groups" [multiple]="true"
              placeholder="Group(s)" bindLabel="name" bindValue="id" appearance="outline" [closeOnSelect]="true"
              [clearable]="true" [searchable]="true" notFoundText="No groups found" autocomplete="off" required>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                  <div class="">{{item.name}}</div>
                  <div class="text-muted small text-wrap" [innerHTML]="item.description"></div>
                </div>
              </ng-template>
            </ng-select>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0 align-self-center" style="margin-top: -20px"><i (click)="addGroup()"
            class="fas fa-plus me-2 pointer" placement="right"></i>
          <span class="text-light me-2 noselect">&nbsp;</span>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <div *ngIf="displaySelectDropdown" style="height: 230px;"></div>
    </form>
  </ng-container>
  <ng-template #displayLoading>
    <app-loading></app-loading>
  </ng-template>

</div>
<div class="modal-footer" *ngIf="!loading">
  <button (click)="submit()" [disabled]="groupForm.invalid||isSaving" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      Updating</span>
    <span *ngIf="!isSaving">Update</span>
  </button>
</div>
