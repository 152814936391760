import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-community-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class CommunityVideosComponent implements OnInit {
  @Input() communityDetails: Community
  loading: boolean = true
  videoItems: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchCommunityVideos()
    })
  }

  fetchCommunityVideos() {
    const callable = this.functions.httpsCallable('getCommunityVideos');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.videoItems = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }
}
