import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  loading: boolean = true;
  showUserSearch: boolean = true;
  selectedUserDetails
  overviewDetails
  error
  constructor(
    private functions: AngularFireFunctions,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    this.fetchAdminOverview()
    this.testExampleFunction()
  }

  testExampleFunction() {
    this.backendService.fetchExampleEndpoint().subscribe({
      next: (res) => {
        console.log(res);
        this.loading = false;
      },
      error: (err) => {
        console.warn(err);
        this.error = err.message;
      },
    });
  }

  fetchAdminOverview() {
    this.loading = true
    const callable = this.functions.httpsCallable('getAdminOverview');
    const obs = callable({});
    obs.subscribe({
      next: (res) => {
        this.overviewDetails = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  openLink($event) {
    this.selectedUserDetails = $event.result
    this.showUserSearch = false
  }

  resetSearch() {
    this.selectedUserDetails = null
    this.showUserSearch = true
  }

}
