import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit {
  @Input() communityDetails
  campaignForm: FormGroup
  loadingGroups: boolean = true
  groups: any[] = []
  error
  constructor(
    public modalRef: MdbModalRef<CreateCampaignComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.fetchGroups()
    this.campaignForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      description: new FormControl('', Validators.compose([Validators.minLength(0), Validators.required])),
      groups: new FormControl([], Validators.compose([Validators.minLength(1), Validators.required])),
    });
  }

  fetchGroups() {
    this.loadingGroups = true
    const callable = this.functions.httpsCallable('getGroups');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.groups = res
        this.loadingGroups = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

}
