import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-link-tv',
  templateUrl: './link-tv.component.html',
  styleUrls: ['./link-tv.component.scss']
})
export class LinkTVComponent implements OnInit {
  tvLinked: boolean = false
  isSaving: boolean = false
  testMode: boolean = false
  givenName: string = null
  linkTVForm: FormGroup
  error
  constructor(
    private fb: FormBuilder,
    private functions: AngularFireFunctions,
    private userService: UserService,
    ) { }

  ngOnInit(): void {
    // Check if test endpoint
    const isTestEndpoint = window.location.href.includes('test-link-tv')
    if(isTestEndpoint) {
      this.givenName = "Test User"
      this.testMode = true
      this.linkTVForm = this.fb.group({
        setupCode: new FormControl('', Validators.compose([Validators.minLength(6), Validators.required])),
        login: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
        pass: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
      });
    } else {
      this.testMode = false
      this.userService.userChanged.subscribe((user: any) => {
        this.givenName = user.given_name
      });
      this.linkTVForm = this.fb.group({
        setupCode: new FormControl('', Validators.compose([Validators.minLength(6), Validators.required])),
      });
    }

  }


  submit() {
    const setupCode = this.linkTVForm.controls.setupCode.value
    this.isSaving = true
    const callable = this.functions.httpsCallable('authorizeTVAuthCode');
    let obs = callable({
      setupCode: setupCode,
    });
    if(this.testMode) {
      const user = this.linkTVForm.controls.login.value
      const pass = this.linkTVForm.controls.pass.value
      obs = callable({
        setupCode: setupCode,
        isTestMode: true,
        user: user,
        pass: pass
      })
    }
    obs.subscribe({
      next: (res) => {
        console.log(res)
        this.tvLinked = true
        this.isSaving = false
      },
      error: (err) => {
        this.isSaving = false
        this.error = err.message
        console.warn(err)
      },
    })
  }

}
