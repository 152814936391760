import { Component, Input, OnInit } from '@angular/core';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-community-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class CommunityHeaderComponent implements OnInit {
  @Input() communityDetails: Community
  @Input() previewImage
  constructor() { }

  ngOnInit(): void {
    if(!this.previewImage) {
      if(this.communityDetails.backgroundImg) {
        this.previewImage = this.communityDetails.backgroundImg
      }
    }
  }

  ngOnChanges() {
  }

}
