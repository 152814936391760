<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">New Message</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body" #messageContainer>

</div>
<div class="modal-footer">

</div>
