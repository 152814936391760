<mdb-tabs [vertical]="isVertical" [pills]="true" (activeTabChange)="onTabChange($event)" #tabs>
  <mdb-tab title="Active">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-user-check me-2"></i> Active</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-active-list [communityDetails]="communityDetails" [activeTabViews]="activeTabViews"></app-active-list>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Pending">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-user-clock me-2"></i> Pending</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-pending-list [communityDetails]="communityDetails" [pendingTabViews]="pendingTabViews"></app-pending-list>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Groups">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-users me-2"></i> Groups</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-manage-groups [communityDetails]="communityDetails"></app-manage-groups>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Invite Campaigns">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-bullhorn me-2"></i> Invite Campaigns</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-campaign-list [communityDetails]="communityDetails"></app-campaign-list>
    </ng-template>
  </mdb-tab>
</mdb-tabs>
