<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Create Tax Profile</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="taxProfileForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
          <label mdbLabel class="form-label" for="name">Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ taxProfileForm.controls.name.value.length }} / 50</div>
            <div *ngIf="taxProfileForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="taxProfileForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="taxProfileForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="taxProfileForm.controls.name.value.length < 3 || taxProfileForm.controls.name.value.length === 50"
      class="noselect">



  <div class="input-group mt-2">
      <mdb-form-control>
          <input mdbInput [mdbValidate] type="number" formControlName="taxRateValue" id="taxRateValue"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Tax Rate Value" />
          <label mdbLabel class="form-label" for="taxRateValue">Tax Percentage</label>
      </mdb-form-control>
  </div>
  </form>


</div>
<div class="modal-footer">
  <button (click)="submit()" [disabled]="taxProfileForm.invalid||isSaving" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Creating</span>
    <span *ngIf="!isSaving">Create</span>
  </button>
</div>
