import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal/modal-ref';
import { JoinCommunityWithCodeComponent } from 'src/app/components/modals/join-community-with-code/join-community-with-code.component';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Title } from '@angular/platform-browser';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';
import { Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NgxGlideComponent } from 'ngx-glide';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss']
})
export class CommunitiesComponent implements OnInit {
  launchedJoinCommunity: boolean = false
  joinWithCodeModalRef: MdbModalRef<JoinCommunityWithCodeComponent> | null = null;
  joinedCommunities: any[] = []
  loggedInUser: boolean = false
  joinedCommunitiesLoading: boolean = false
  showCarouselItems: boolean = false
  isDragging: boolean = false
  private subscription: Subscription;
  private authSubscription: Subscription;
  environmentName: string = ''
  perView: number = 1
  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustCarouselPerView()
  }
  constructor(
    private titleService: Title,
    private afAuth: AngularFireAuth,
    private joinedCommunitiesService: JoinedCommunitiesService,
    private route: ActivatedRoute,
    private router: Router,
    private envService: EnvironmentService,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.environmentName = this.envService.getEnvironmentName();
    this.titleService.setTitle('Join a Community | Event Odyssey')
    this.route.queryParams.subscribe(params => {
      let join = params['join'];
      let accessCode = params['accessCode'];
      this.authSubscription = this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.loggedInUser = true
          if (join && accessCode) {
            this.joinWithCode(join, accessCode)
          }
          this.subscription = this.joinedCommunitiesService.joinedCommunitiesChanged.subscribe((joinedCommunities: any[]) => {

            if (joinedCommunities[0]?.loading) {
              this.joinedCommunitiesLoading = true
            } else {
              this.joinedCommunitiesLoading = false
              this.joinedCommunities = joinedCommunities;
              setTimeout(() => {
                this.showCarouselItems = true
                this.adjustCarouselPerView()
              }, 100);
            }
            this.joinedCommunities = joinedCommunities;
          });
        } else {
          if (join && accessCode) {
            this.router.navigate(['/not-authenticated'], {
              queryParams: {
                redirectUrl: `/communities?join=${join}&accessCode=${accessCode}`
              }
            });
          }
        }
      })
    });
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  adjustCarouselPerView() {
    const screenWidth = window.innerWidth;
    switch (true) {
      case screenWidth < 768:
        this.perView = 1;
        break;
      case screenWidth < 992:
        this.perView = 2;
        break;
      case screenWidth < 1200:
        this.perView = 3;
        break;
      default:
        this.perView = 4
        break;
    }
    if (this.ngxGlide) {
      this.ngxGlide.recreate();
    }
  }


  joinWithCode(campaignId: string, validationString?: string) {
    if (!this.launchedJoinCommunity) {
      this.joinWithCodeModalRef = this.modalService.open(JoinCommunityWithCodeComponent, {
        modalClass: 'modal-dialog-scrollable modal-md',
        ignoreBackdropClick: true,
        data: {
          campaignId: campaignId,
          validationString: validationString
        }
      })
      this.launchedJoinCommunity = true
    }
  }

  getRandomImageNumber(): number {
    return Math.floor(Math.random() * 4) + 1; // Generates a random number between 1 and 4.
  }

  swipeStarted(): void {
    this.isDragging = true;
  }

  swipeEnded(): void {
    this.isDragging = false;
  }

  openLink($event) {
    this.router.navigateByUrl(`/communities/${$event.id}`)
  }

}
