<div class="toast bg-dark mx-auto mb-1" id="basic-primary-example" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-body text-white">
      <table class="w-100">
          <tr>
              <td>
                <span *ngIf="type === 'error'">
                  <i class="fas fa-exclamation me-2"></i> <b>Error</b>
                </span>
                <span *ngIf="type !== 'error'">
                  <i class="me-2" [class]="iconClass"></i> <b>{{title}}</b>
                </span>
              </td>
              <td class="float-end"><button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="notificationRef.close()"></button></td>
          </tr>
          <tr>
            {{content}}
          </tr>
      </table>
  </div>
</div>
