<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Add Card Reader</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <form [formGroup]="cardReaderForm">

    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="terminalName" id="terminalName"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
          <label mdbLabel class="form-label" for="terminalName">Reader Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ cardReaderForm.controls.terminalName.value.length }} / 50</div>
            <div *ngIf="cardReaderForm.controls.terminalName.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="cardReaderForm.controls.terminalName.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="cardReaderForm.controls.terminalName.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="cardReaderForm.controls.terminalName.value.length < 3 || cardReaderForm.controls.terminalName.value.length === 50"
      class="noselect">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="locationName" id="locationName"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
          <label mdbLabel class="form-label" for="locationName">Location Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ cardReaderForm.controls.locationName.value.length }} / 50</div>
            <div *ngIf="cardReaderForm.controls.locationName.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="cardReaderForm.controls.locationName.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="cardReaderForm.controls.locationName.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="cardReaderForm.controls.locationName.value.length < 3 || cardReaderForm.controls.locationName.value.length === 50"
      class="noselect">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" [mdbValidate] type="text" formControlName="registrationCode"
            id="registrationCode" class="form-control form-control-lg" [validateSuccess]="false"
            placeholder="Registration Code" />
          <label mdbLabel class="form-label" for="registrationCode">Registration Code</label>
          <div class="form-helper">
            <div *ngIf="cardReaderForm.controls.registrationCode.invalid" class="form-counter">Registration code must be in the correct format ex: 'light-stage-ship'
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="cardReaderForm.controls.registrationCode.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
  </form>

</div>
<div class="modal-footer">
  <button (click)="modalRef.close()" type="button" class="btn btn-grey btn-rounded"
    data-mdb-dismiss="modal">Close</button>
    <button (click)="createReader()" [disabled]="((cardReaderForm.invalid)||(creatingReader))" type="button" class="btn btn-primary btn-rounded">Add card reader<span *ngIf="creatingReader">&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span></button>
</div>
