

<section>
  <div [ngClass]="{'container': !chapterDetails?.length, 'container-xxl': chapterDetails?.length}" class="container-xxl text-center text-md-start mt-2">
    <div class="row">
  <ng-container *ngIf="!loading; else displayLoading">
    <div class=" text-center text-md-start mb-8">
      <div class="row">
        <ng-container *ngIf="videoDetails.collection; else videoView">
          <div class="datatable mt-5">
            <div class="d-flex justify-content-between" style="margin-bottom: 20px">
              <div>
                <div class="input-group">
                  <input *ngIf="videoDetails.collectionVideos.length>0" type="text" class="form-control form-control-lg" placeholder="Filter videos"
                    (keyup)="search($event)" #searchInput size=25 />
                </div>
              </div>
            </div>

            <div class="row" mdbTable mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true"
            [dataSource]="videoDetails.collectionVideos" [pagination]="pagination" [filterFn]="filterTable">

            <div class="col-md-4 col-lg-3 col-xl-3" *ngFor="let item of table.data; let i = index">
              <div>
                <app-list-item [itemDetails]="item"></app-list-item><br>
              </div>
            </div>
            <ng-container *ngIf="videoDetails.collectionVideos.length>0; else noVideos">
              <app-no-results *ngIf="pagination._total===0&&searchText" [message]="'No videos found matching: ' + searchText"></app-no-results>
            </ng-container>
            <ng-template #noVideos>
              <app-no-results message="No purchased videos found"></app-no-results>
            </ng-template>
            <mdb-table-pagination class="mt-3" #pagination [entries]="12" rowsPerPageText="Results"></mdb-table-pagination>


          </div>
          </div>
        </ng-container>
        <ng-template #videoView>
          <div class="col-md-12 col-lg-12 col-xl-12 mx-auto" style="margin-bottom: 15px; margin-top: 15px;">
          </div>
          <div
          [ngClass]="createChapters ? 'col-md-11 col-lg-10 col-xl-9' : (chapterDetails?.length ? 'col-md-11 col-lg-10 col-xl-9' : 'col-md-10 col-lg-10 col-xl-10')"
            class="mx-auto">
            <div class="mb-2" *ngIf="videoDetails.preOrderAvailableDate">
              <h4>{{videoDetails.preOrderAvailableMessage}}</h4>
              <div class="text-muted">It will be available on {{(videoDetails.preOrderAvailableDate*1000) | date:'mediumDate'}}.</div>
            </div>
            <ng-container *ngIf="!((streamStatus.status==='idle')||(streamStatus.status==='created')); else streamOffline">
              <media-player #mediaPlayer autoplay crossorigin class="mb-2" aspect-ratio="16/9"
              (progress)="playerChanged($event)" (play)="played($event)" (pause)="paused($event)"
              (play-fail)="playError($event)" (auto-play-fail)="playError($event)" (hls-error)="hlsError($event)"
              (google-cast-load-start)="castLoadStart($event)" (google-cast-loaded)="castLoaded($event)"
              (google-cast-prompt-open)="castPromptOpen($event)" (google-cast-prompt-close)="castPromptClose($event)"
              [artist]="videoDetails.communityDetails.name" [title]="videoDetails.name" [googleCast]="googleCastConfig"
              [streamType]="playerStreamType" minLiveDVRWindow="30"
              [src]="sourceUrl" [poster]="poster" [title]="videoDetails.name" viewType="video">
                <media-provider>
                  <track *ngIf="chapterDetails?.length" [src]="chapters" srclang="en-US" kind="chapters" default="default" />
                </media-provider>
                <media-video-layout *ngIf="playerStreamType.includes('live')"></media-video-layout>
                <media-video-layout  *ngIf="!playerStreamType.includes('live')" [thumbnails]="thumbnailUrl"></media-video-layout>
              </media-player>
            </ng-container>
            <ng-template #streamOffline>
              <div [ngSwitch]="streamStatus.status" class="text-center mb-2">
                <div *ngSwitchCase="'created'" class="">
                  <i class="fa fa-calendar-plus fa-3x text-primary mb-2"></i><br>
                  <span>Stream is scheduled but has not yet begun.</span><br>
                  <small class="text-muted">Check the schedule for more details.</small>
                </div>
                <div *ngSwitchCase="'idle'" class="">
                  <i class="fa fa-clock fa-3x text-warning mb-2"></i><br>
                  <span>The stream has stopped.</span><br>
                  <small class="text-muted">Stay tuned for possible restarts (refer to schedule).</small>
                </div>
                <div *ngSwitchDefault class="">
                  <i class="fa fa-ban fa-3x text-danger mb-2"></i><br>
                  <span>The stream has stopped.</span>
                </div>
              </div>
            </ng-template>
            <app-currently-playing [videoDetails]="videoDetails" [currentChapter]="currentChapter"
            [nextChapter]="nextChapter" (seekTo)="seekTo($event)" [streamStatus]="streamStatus"></app-currently-playing>
          </div>
          <div *ngIf="createChapters || ((chapterDetails?.length > 0) && displaySidebar)" class="col-md-12 col-lg-12 col-xl-3 mx-auto">
            <div *ngIf="createChapters" class="card chapterCard mb-3" mdbScrollbar>
              <div class="card-body">
                <app-add-chapter [playerTime]="playerTime" [maxTimeInSeconds]="duration" [minTimeInSeconds]="lastChapterEnd" (output)="outputNewChapter($event)"></app-add-chapter>
              </div>
            </div>
            <div class="card chapterCard" mdbScrollbar style="max-height: 800px; overflow: auto;">
              <app-chapters-list   [videoDetails]="videoDetails" [chapters]="chapterDetails"
                [currentChapter]="currentChapter" [nextChapter]="nextChapter"
                (seekTo)="seekTo($event)"></app-chapters-list>
            </div>
          </div>
        </ng-template>
      </div>
    </div>


  </ng-container>
  <ng-template #displayLoading>
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <ng-container *ngIf="!error; else displayError">
            <app-loading></app-loading>
          </ng-container>
          <ng-template #displayError>

            <ng-container *ngIf="!suppressErrorMessage; else displayRetryLoader">
              <app-https-error *ngIf="!suppressErrorMessage" [error]="error"></app-https-error>
            </ng-container>
            <ng-template #displayRetryLoader>
              <app-retry-video-fetch-timer [timer]="retryPurchaseTimer" timerLength="30"></app-retry-video-fetch-timer>
            </ng-template>

          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
  </div>
  </div>
</section>
