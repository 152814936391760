import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Chapter } from 'src/app/interfaces/chapter';
import { Video } from 'src/app/interfaces/video';

@Component({
  selector: 'app-currently-playing',
  templateUrl: './currently-playing.component.html',
  styleUrls: ['./currently-playing.component.scss']
})
export class CurrentlyPlayingComponent implements OnInit {
  @Input() videoDetails
  @Input() streamStatus
  @Input() currentChapter: Chapter
  @Input() nextChapter: Chapter
  @Output('seekTo') seekTo: EventEmitter<any> = new EventEmitter();
  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.currentChapter) {
      this.titleService.setTitle(this.currentChapter.name + ' - ' + this.videoDetails.name + ' (' + this.videoDetails.communityDetails.name + ')')
    } else {
      this.titleService.setTitle(this.videoDetails.name + ' (' + this.videoDetails.communityDetails.name + ')')
    }
  }

  triggerSeekTo(seconds: number) {
    this.seekTo.emit(seconds)
  }

}
