<div class="modal-body">
  <div class="h-100 d-flex flex-row justify-content-center align-items-center">
    <div class="align-self-start flex-fill">
      <span style="font-weight: 500;">{{notification.name}}</span><br>
      <small class="text-muted">{{notification.description}}</small>
    </div>
    <div class="align-self-start">
      <i class="ms-3 fas fa-trash pointer" title="Delete Notification" (click)="deleteNotification()"></i>
    </div>
  </div>
</div>
