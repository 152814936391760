<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel"><span *ngIf="!paymentSuccessful">Purchase </span>{{itemDetails.name}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<ng-container *ngIf="!paymentSuccessful; else success">
  <div class="modal-body">
    <mdb-stepper #stepper [orientation]="'vertical'" [linear]="true" (stepChange)="stepChange($event)">
      <mdb-step [name]="'Select Product'" [stepForm]="selectProductForm">
        <form [formGroup]="selectProductForm"><input type="hidden" formGroupName="valid"></form>
        <app-select-product [itemDetails]="itemDetails" (isValid)="validateselectProduct($event)"
          (output)="outputselectProduct($event)"></app-select-product>
      </mdb-step>
      <mdb-step [name]="'Select Customer'" [stepForm]="selectCustomerForm">
        <form [formGroup]="selectCustomerForm"><input type="hidden" formGroupName="valid"></form>
        <app-select-customer [communityDetails]="communityDetails" [selectedUid]="selectedUid" [purchasing]="purchasing" (output)="selectCustomer($event)" (guestOutput)="guestOutput($event)"></app-select-customer>
        <!-- <app-list-cards (isValid)="validateselectCustomer($event)" (output)="outputselectCustomer($event)" (nextStep)="next()"></app-list-cards> -->
        <p class="card-text" *ngIf="paymentError">
          <app-card-reader-unreachable [content]="paymentError"></app-card-reader-unreachable>
        </p>
        <p class="card-text" *ngIf="!paymentError">
          <app-card-reader-status [cardReaderId]="cardReaderId" [lastReaderFunctionResponse]="lastReaderFunctionResponse" (output)="handleReaderStatusOutput($event)"></app-card-reader-status>
        </p>
      </mdb-step>
    </mdb-stepper>
    <div class="modal-footer">
      <button *ngIf="step>1" (click)="previous()"  type="button"
        class="btn btn-grey btn-rounded">Go Back</button>
      <!-- <button *ngIf="step===1" (click)="testCardReader()" [disabled]="!selectProductValid" type="button"
        class="btn btn-grey btn-rounded">Test Card Reader</button> -->
      <button *ngIf="step===1" (click)="next()" [disabled]="!selectProductValid" type="button"
        class="btn btn-primary btn-rounded">Next</button>

      <button *ngIf="step>1" (click)="chargeCardReader()" [disabled]="purchasing" type="button"
        class="btn btn-primary btn-rounded">
        <span *ngIf="purchasing">Waiting for Reader&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span>
        <span *ngIf="!purchasing">Charge</span>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #success>
  <div class="modal-body">
    <app-payment-successful></app-payment-successful>

    <div class="modal-footer">
      <button (click)="modalRef.close()" type="button"
      class="btn btn-grey btn-rounded">Close</button>
      <a (click)="modalRef.close()"  [routerLink]="'/videos/watch'" [queryParams]="{v: itemDetails.id}"
      class="btn btn-primary btn-rounded text-start"><i class="fas fa-play me-2"></i> Watch</a>
    </div>
  </div>

</ng-template>
