<!-- Header -->
<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">Learn More</h4>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <!-- Content -->
        <h4 class="text-uppercase fw-bold mb-4">Introduction</h4>
        <p>
          Welcome to Event Odyssey! We specialize in providing seamless event management solutions for schools and
          communities. Our platform offers a range of features to help you streamline event organization and engage with
          your audience effectively.
        </p>

        <h4 class="text-uppercase fw-bold mt-5 mb-4">Features and Benefits</h4>
        <ul>
          <li>Effortless event ticketing with our intuitive platform.</li>
          <li>Provide on-demand video access to your performances for a wider audience.</li>
          <li>User and group management to easily organize and coordinate events.</li>
          <li>Engage your community through interactive posts and discussions.</li>
        </ul>

        <h4 class="text-uppercase fw-bold mt-5 mb-4">Use Cases and Success Stories</h4>
        <p>
          At Event Odyssey, we have helped numerous schools and communities achieve their event management goals. Here
          are some examples:
        </p>
        <ul>
          <li>ABC High School: Successfully sold out their annual musical with our ticketing platform, resulting in
            increased revenue and a seamless ticketing experience for attendees.</li>
          <li>XYZ Community Theater: Expanded their audience reach by offering video on demand for their performances,
            allowing people from around the world to enjoy their shows.</li>
        </ul>

        <h4 class="text-uppercase fw-bold mt-5 mb-4">Onboarding Process</h4>
        <p>
          Getting started with Event Odyssey is easy. Follow these steps to begin your event management journey:
        </p>
        <ol>
          <li>Create an account on our platform.</li>
          <li>Explore the features and familiarize yourself with the tools.</li>
          <li>Reach out to our dedicated support team for any assistance or guidance you may need.</li>
        </ol>

        <h4 class="text-uppercase fw-bold mt-5 mb-4">Connect with a Sales Representative</h4>
        <p>
          If you have any questions or would like to learn more about how Event Odyssey can benefit your school or
          community, our sales representatives are ready to assist you. Please reach out to us using the contact details
          below.
        </p>
        <p>Contact: <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a></p>

        <div class="text-center mt-5">
          <!-- <a routerLink="/pricing" class="btn btn-primary btn-rounded">View Pricing</a> -->
            <button [routerLink]="'/pricing'" class="btn btn-medium btn-rounded me-3 mb-2 btn-lg text-capitalize">View Pricing</button>
            <button [routerLink]="'/get-started'" class="btn btn-primary btn-rounded me-3 mb-2 btn-lg text-capitalize">Get Started</button>
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->

  </div>
</section>
