<ng-container *ngIf="!loading; else displayLoading">
  <div class="row" style="margin-bottom: 20px;">
    <div class="d-flex justify-content-end align-items-center">
      <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
        <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle>
          <span><i class="fas fa-cog me-2"></i>Actions</span>
        </button>
        <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
          <li (click)="createProduct()"><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Add Price</a>
          </li>
          <li (click)="fetchVideoPrices()"><a class="dropdown-item pointer"><i class="fas fa-sync-alt me-2"></i>Refresh</a></li>

        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="products.length===0">
    <app-no-results message="No prices for this product exist"></app-no-results>
  </div>

  <table class="table align-middle mb-0 bg-white" *ngIf="products.length>0">
    <thead class="bg-light">
      <tr>
        <th>Name</th>
        <th>Cost</th>
        <th class="text-end">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of products; let i = index">
        <td>
          <div class="d-flex align-items-center">
            <div class="">
              <p class="fw-bold mb-1">{{item.product.name}}</p>
              <p class="text-muted mb-0">{{item.product.description}}</p>
            </div>
          </div>
        </td>
        <td>
          <p class="fw-normal mb-1">{{(item.price.unit_amount/100) | currency}}</p>
        </td>
        <td style="width: 50px" class="text-end">
          <span *ngIf="item.price.active" class="badge badge-success rounded-pill d-inline">Active</span>
          <span *ngIf="!item.price.active" class="badge badge-danger rounded-pill d-inline">Inactive</span>
          <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
            <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
              class="dropdown-toggle hidden-arrow tableDropdown text-center " role="button" mdbDropdownToggle aria-expanded="false">
              <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
            </span>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a (click)="editPrice(item)" class="dropdown-item pointer">Edit Price</a>
                <a (click)="deletePrice(item)" class="dropdown-item pointer">Delete Price</a>
              </li>
            </ul>
          </span>
        </td>
      </tr>


    </tbody>
  </table>












  <div class="row">

    <div  >
      {{ item | json }}
      <br>
    </div>
  </div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
