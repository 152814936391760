<ng-container *ngIf="!loading; else displayLoading">

  <div class="datatable" *ngIf="!guestContact">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="input-group">
          <input *ngIf="members.length>0" type="text" class="form-control form-control-lg" placeholder="Filter members"
            (keyup)="search($event)" #searchInput size=25 />
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-end align-items-center">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li (click)="addGuest()"><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Sell to Guest</a></li>
              <li (click)="fetchMembers()"><a class="dropdown-item pointer"><i class="fas fa-sync-alt me-2"></i>Refresh</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="members.length===0">
      <app-no-results message="No registered members found"></app-no-results>
    </div>
    <div *ngIf="members.length>0">
      <table responsive class="table align-middle mb-0 bg-white"
        class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
        mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true" [dataSource]="members"
        [pagination]="pagination" [sort]="sort" [filterFn]="filterTable">

        <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
        <thead class="bg-light">
          <tr>
            <th [mdbTableSortHeader]="'displayName'">Member</th>
            <th [mdbTableSortHeader]="'group'">Group(s)</th>
            <th></th>
          </tr>
        </thead>
      </ng-container>
      <ng-template #noResultsFound>
        <div class="mb-5"></div>
        <app-no-results [message]="'No customers found matching: ' + searchText"></app-no-results>
        <div class="mb-5"></div>
      </ng-template>
        <tbody>


          <tr *ngFor="let item of table.data; let i = index">
            <td>
              <div class="d-flex align-items-center">
                <img referrerPolicy="no-referrer" [src]="item.profilePic" (error)="item.profilePic = './assets/user-profile.png'" alt="" style="width: 45px; height: 45px;" class="rounded-circle" />
                <div class="ms-3">
                  <p class="fw-bold mb-1">{{item.displayName}}</p>
                  <p class="text-muted mb-0">{{item.email}}</p>
                </div>
              </div>
            </td>
            <td>
              <p class="fw-normal mb-1">
                <span style="text-transform: Capitalize" *ngFor="let group of item.groups; index as i">
                  {{group.name}}<span *ngIf="((item.groups.length!==1)&&((i+1)!==item.groups.length))">,</span>
                </span>
              </p>
            </td>
            <td class="text-end">
              <button *ngIf="item.uid===selectedUid" (click)="selectCustomer(item.uid)" type="button"
                class="btn btn-dark btn-rounded">
                <!-- <span>Selected</span> -->
                <span *ngIf="!purchasing">Selected</span>
                <span *ngIf="purchasing">Waiting&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span>

              </button>
              <button [disabled]="purchasing" *ngIf="item.uid!==selectedUid" (click)="selectCustomer(item.uid)" type="button"
                class="btn btn-grey btn-rounded">
                <span *ngIf="!purchasing">Select</span>
                <span *ngIf="purchasing">Waiting&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span>
              </button>
            </td>
          </tr>


        </tbody>
      </table>
      <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
    </div>
  </div>
  <div *ngIf="guestContact">
    Name: {{guestContact.name}}<br>
    <span *ngIf="guestContact.phoneNumber">Phone Number: +1 {{guestContact.phoneNumber | mask: '(000) 000-0000'}}<br></span>
    <span *ngIf="guestContact.email">Email: {{guestContact.email}}<br></span>
    <br>
    <a (click)="resetGuestContact()" class="text-primary hoverUnderline pointer">Clear Customer</a>
  </div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
