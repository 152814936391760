import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-edit-ensemble',
  templateUrl: './edit-ensemble.component.html',
  styleUrls: ['./edit-ensemble.component.scss']
})
export class EditEnsembleComponent implements OnInit {
  ensembleForm: FormGroup
  isSaving: boolean = false

  @Input() communityDetails: Community
  @Input() ensembleDetails: any
  error
  constructor(
    public modalRef: MdbModalRef<EditEnsembleComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.ensembleForm = new FormGroup({
      name: new FormControl(this.ensembleDetails.name, Validators.compose([Validators.minLength(3), Validators.required])),
      description: new FormControl(this.ensembleDetails.description),
    });
  }


  submit() {
    const name = this.ensembleForm.controls.name.value
    const description = this.ensembleForm.controls.description.value
    this.isSaving = true
    const callable = this.functions.httpsCallable('updateCommunityEnsemble');
    const obs = callable({
      communityId: this.communityDetails.id,
      id: this.ensembleDetails.id,
      name: name,
      description: description
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('updated')
        this.isSaving = false
      },
      error: (err) => {
        this.error = err.message
        this.isSaving = false
        console.warn(err)
      },
    })
  }

}
