import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { Community } from 'src/app/interfaces/community';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CreateCampaignComponent } from '../../campaigns/create-campaign/create-campaign.component';
import { EditCampaignComponent } from '../../campaigns/edit-campaign/edit-campaign.component';
import { ViewCampaignLinksComponent } from '../../campaigns/view-campaign-links/view-campaign-links.component';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent implements OnInit {
  @Input() communityDetails: Community
  @ViewChild('table') campaignsTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  modalRef: MdbModalRef<CreateCampaignComponent> | null = null;
  editModalRef: MdbModalRef<EditCampaignComponent> | null = null;
  viewCampaignModalRef: MdbModalRef<ViewCampaignLinksComponent> | null = null;
  @Input() stripeAccountDetails: any
  searchText: string
  loading: boolean = true
  campaigns: any[] = []
  error

  constructor(
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchCampaigns()
    })
  }

  fetchCampaigns() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCampaigns');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.campaigns = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  createCampaign() {
    this.modalRef = this.modalService.open(CreateCampaignComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((data: any) => {
      if(data) {
        this.fetchCampaigns()
      }
  })
  }


  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.campaignsTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'name':
          let name = data[key]
          return name.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  editCampagin(campaignData: any) {
    this.editModalRef = this.modalService.open(EditCampaignComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        campaignData: campaignData
      }
    })
    this.editModalRef.onClose.subscribe((data: any) => {
      if(data) {
        this.fetchCampaigns()
      }
  })
  }

  viewCampagin(campaignData: any) {
    console.log(campaignData)
    this.viewCampaignModalRef = this.modalService.open(ViewCampaignLinksComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        campaignData: campaignData
      }
    })
    this.viewCampaignModalRef.onClose.subscribe((data: any) => {
      if(data) {
        this.fetchCampaigns()
      }
  })
  }



}
