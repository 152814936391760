import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationService, MdbNotificationRef, MdbNotificationConfig } from 'mdb-angular-ui-kit/notification';
import { ToastComponent } from '../toast/toast.component';
import { environment } from '../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  notificationRef: MdbNotificationRef<ToastComponent>;
  toastOptions = environment.toastOptions.success as MdbNotificationConfig<{}>;
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  loginView = 'menu'
  phoneForm: FormGroup;
  phoneVerificationForm: FormGroup;
  initialPhoneResult
  phoneVerificationError
  loginWithPhoneError
  loginWithPhoneErrorType
  sendingPhoneVerificationCode: boolean = false
  validatingCode: boolean = false
  constructor(
    public modalRef: MdbModalRef<LoginComponent>,
    private toastService: MdbNotificationService,
    private afAuth: AngularFireAuth
  ) { }


  ngOnInit(): void {
    this.phoneForm = new FormGroup({
      phoneNumber: new FormControl('', [USPhoneValidator()]),
    });
    this.phoneVerificationForm = new FormGroup({
      verificationCode: new FormControl('', [ValidationCodeValidator()]),
    });
  }

  loginWithPhone() {
    if(this.phoneForm.invalid) { return }
    this.sendingPhoneVerificationCode = true
    this.loginWithPhoneError = 'Waiting for Captcha.'
    this.loginWithPhoneErrorType = 'warning'
    const phoneNumber = this.phoneForm.controls.phoneNumber.value

    const appVerifier = new firebase.auth.RecaptchaVerifier('reCaptcha', {
      size: 'invisible',
      siteKey: '6LdaSqckAAAAAKb4vHFa3ynj3xcdiZN6x907hFpH',
    });
    new Promise<any>((resolve, reject) => {
      this.afAuth.setPersistence('local').then(() => {
        this.afAuth.signInWithPhoneNumber('+1' + phoneNumber, appVerifier)
          .then(async (confirmationResult) => {
            this.sendingPhoneVerificationCode = false
            this.initialPhoneResult = confirmationResult
            this.loginView = 'verifyPhone'
            this.phoneVerificationForm.controls.verificationCode.setValue(undefined)
          })
          .catch((error) => {
            this.sendingPhoneVerificationCode = false
            this.loginWithPhoneError = 'reCaptcha Failed!'
            this.loginWithPhoneErrorType = 'danger'
          });
      })
    })
  }

  verifyPhoneNumber() {
    if(this.phoneVerificationForm.invalid) { return }
    this.validatingCode = true
    this.initialPhoneResult.confirm(this.phoneVerificationForm.controls.verificationCode.value)
      .then(result => {
        this.loginSuccessfulToast()
        this.validatingCode = false
      })
      .catch(error => {
        this.validatingCode = false
        this.phoneVerificationError = 'The verification code you entered is invalid'
      });
  }

  socialLogin(provider: string) {
    switch (provider) {
      case 'google':
        this.firebaseSocialLogin('GoogleAuthProvider')
        break;
      case 'facebook':
        this.firebaseSocialLogin('FacebookAuthProvider')
        break;
      case 'twitter':
        this.firebaseSocialLogin('TwitterAuthProvider')
        break;
      case 'apple':
        this.firebaseSocialLogin("apple.com")
        break;
      case 'microsoft':
        this.firebaseSocialLogin("microsoft.com")
        break;
      case 'yahoo':
        this.firebaseSocialLogin("yahoo.com")
        break;
      default:
        this.errorToast('Unable to process login request.')
        break;
    }
  }

  login(provider: string) {
    switch (provider) {
      case 'phone':
        if(this.loginView === 'phone') {
          this.loginView = 'none'
          setTimeout(() => {
            this.loginView = 'phone'
          }, 150);
        } else {
          this.loginView = 'phone'
        }
        this.phoneForm.controls.phoneNumber.setValue(undefined)
        this.sendingPhoneVerificationCode = false
        this.loginWithPhoneError = ''
        break;
      case 'email':
        this.loginView = 'email'
        break;
      default:
        this.errorToast('Unable to process login request.')
        break;
    }
  }


  firebaseSocialLogin(provider: string) {
    new Promise<any>((resolve, reject) => {
      if(provider.includes('.com')) {
        this.afAuth.setPersistence('local').then(() => {
          const oAuthProvider = new firebase.auth.OAuthProvider(provider);
          if(provider==='apple.com') {
            oAuthProvider.addScope('email');
            oAuthProvider.addScope('name');
          } else if(provider==='microsoft.com') {
            oAuthProvider.addScope('user.read')
            oAuthProvider.addScope('user.readbasic.all')
            oAuthProvider.addScope('openid')
          }
          this.afAuth.signInWithPopup(oAuthProvider)
            .then(
              res => {
                this.loginSuccessfulToast(res.additionalUserInfo.profile['given_name'])
              },
              err => {
                if (err.message.includes('popup')) {
                  this.firebaseSocialLoginWithRedirect(provider)
                } else {
                  this.errorToast(err.message)
                }
              })
        })
      } else {
        this.afAuth.setPersistence('local').then(() => {
          let socialProvider = new firebase.auth[provider]()
          socialProvider.addScope('email')
          this.afAuth.signInWithPopup(socialProvider)
            .then(
              res => {
                this.loginSuccessfulToast(res.additionalUserInfo.profile['given_name'])
              },
              err => {
                if (err.message.includes('popup')) {
                  this.firebaseSocialLoginWithRedirect(provider)
                } else {
                  this.errorToast(err.message)
                }
              })
        })
      }
    })
  }

  firebaseSocialLoginWithRedirect(provider: string) {
    new Promise<any>((resolve, reject) => {
      this.afAuth.setPersistence('local').then(() => {
        this.afAuth.signInWithRedirect(new firebase.auth[provider]())
          .then(
            res => {

            },
            err => {
              this.errorToast(err.message)
            })
      })
    })
  }

  loginSuccessfulToast(name?: string) {
    this.modalRef.close()
    var data = [
      [0, 4, "Welcome back"],
      [5, 11, "Good morning"],
      [12, 16, "Good afternoon"],
      [17, 24, "Good evening"]
    ]
    var hr: Number = new Date().getHours();
    for (var i = 0; i < data.length; i++) {
      if (hr >= data[i][0] && hr <= data[i][1]) {
        if(name) {
          this.toastOptions.data['content'] = data[i][2] + ', ' + name + '.';
        } else {
          this.toastOptions.data['content'] = data[i][2] + '!';
        }
        this.toastOptions.data['title'] = 'Login Successful';
        this.toastOptions.data['sound'] = '';
        this.toastService.open(ToastComponent, this.toastOptions)
      }
    }
  }

  errorToast(message: string) {
    if (message.includes('Firebase')) {
      message = message.replace('Firebase: ', '')
      message = message.replace(/Firebase/g, '')
    }

    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

}

export function USPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneRegex = /^\d{10}$/;
    const isValid = phoneRegex.test(control.value);
    return isValid ? null : { invalidPhone: { value: control.value } };
  }
}

export function ValidationCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const smsCodeRegex = /^[0-9]{6}$/; // 6 digits only
    const isValid = smsCodeRegex.test(control.value);
    return isValid ? null : { invalidPhone: { value: control.value } };
  }
}

