import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { Community } from 'src/app/interfaces/community';
import { InviteMemberComponent } from '../../invite-member/invite-member.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';

@Component({
  selector: 'app-pending-list',
  templateUrl: './pending-list.component.html',
  styleUrls: ['./pending-list.component.scss']
})
export class PendingListComponent implements OnInit {
  confirmActionModalRef: MdbModalRef<ConfirmActionComponent> | null = null;
  @Input() communityDetails: Community
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  modalRef: MdbModalRef<InviteMemberComponent> | null = null;
  @Input() stripeAccountDetails: any
  @Input() pendingTabViews: any
  searchText: string
  loading: boolean = true
  members: any[] = []
  error

  constructor(
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchMembers()
    })
  }

  ngOnChanges() {
    this.fetchMembers()
  }

  fetchMembers() {
    this.loading = true
    const callable = this.functions.httpsCallable('getPendingMembers');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.members = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  createMember() {
    this.modalRef = this.modalService.open(InviteMemberComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'displayName':
          let displayName = data[key]
          return displayName.toLowerCase().includes(searchTerm.toLowerCase());
        case 'email':
          let email = data[key]
          return email.toLowerCase().includes(searchTerm.toLowerCase());
        case 'provider':
          let provider = data[key]
          return provider.toLowerCase().includes(searchTerm.toLowerCase());
        case 'requestText':
          let requestText = data[key]
          return requestText.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  handleDecision(type: string, item: any) {
    let typeName = ''; let confirmButtonPlural= ''
    switch(type) {
      case 'approve':
        typeName = 'Approve'
        confirmButtonPlural = 'Approving'
        break;
      case 'deny':
        typeName = 'Deny'
        confirmButtonPlural = 'Denying'
        break;
      case 'block':
        typeName = 'Block'
        confirmButtonPlural = 'Blocking'
        break;
    }
    let content = `<div class="text-center">Are you sure you want to ${type} ${item.name}?</p></div>`;
    this.confirmActionModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: `${typeName} Member`,
        content: content,
        confirmButton: typeName,
        confirmButtonPlural: confirmButtonPlural,
        type: `${type}Member`,
        miscData: {
          communityId: this.communityDetails.id,
          id: item.id
        }
      }
    })
    this.confirmActionModalRef.onClose.subscribe((message: any) => {
      if (message === 'handled') {
        this.fetchMembers()
      }
    });
  }

}
