import { Component, Input, OnInit } from '@angular/core';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-eo-subscription-overview',
  templateUrl: './eo-subscription-overview.component.html',
  styleUrls: ['./eo-subscription-overview.component.scss']
})
export class EoSubscriptionOverviewComponent implements OnInit {
  @Input() communityDetails: Community
  constructor() { }

  ngOnInit(): void {
  }

}
