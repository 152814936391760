<div class="card">
  <ng-container *ngIf="!itemDetails.gif.includes('processing'); else processingImg">

      <!-- <img [routerLink]="'/videos/watch'" [queryParams]="{v: itemDetails.id}"
  [src]="itemDetails.thumbnail"
  class="imgRound img-fluid static pointer"
  hoverGifLoader
  [gifSrc]="itemDetails.gif" /> -->
    <img [src]="itemDetails.thumbnail" class="imgRound img-fluid static pointer" hoverGifLoader (gifClick)="purchase()"
      [gifSrc]="itemDetails.gif" />


  </ng-container>
  <ng-template #processingImg>
    <div class="bg-image hover-overlay" mdbRipple rippleColor="light">
      <img [src]="'./assets/tides/1.svg'" class="img-fluid processingImg" />
      <a>
        <div class="mask" style="background-color: rgba(0, 0, 0, 0.25);"></div>
      </a>
    </div>
  </ng-template>

  <div class="card-body">
    <h5 class="card-title">{{itemDetails.name}}</h5>
    <p class="card-text">{{itemDetails.length}}</p>

    <ng-container *ngIf="!itemDetails.gif.includes('processing') else videoProcessing">
      <!-- <a [routerLink]="'/videos/watch'" [queryParams]="{v: itemDetails.id}"
        class="btn btn-primary btn-rounded text-capitalize text-start"><i class="fas fa-play me-2"></i> Watch</a> -->
      <a (click)="purchase()" class="btn btn-primary btn-rounded text-capitalize text-start"><i
          class="fas fa-dollar-sign me-2"></i> Purchase</a>

      <!-- <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
        <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;" class="dropdown-toggle hidden-arrow"
          role="button" mdbDropdownToggle aria-expanded="false">
          <i style="height: 60px; margin-left: 16px; margin-top: 17px"
            class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
        </span>
        <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
          <li>
            <a (click)="purchase()" class="dropdown-item pointer">Purchase</a>
          </li>
        </ul>
      </span> -->
    </ng-container>
    <ng-template #videoProcessing>
      <div class=""><span class="spinner-border spinner-border-sm me-2"></span>Processing Video</div>
    </ng-template>
  </div>



</div>
