import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { guestContact } from 'src/app/interfaces/guestContact';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { AddGuestComponent } from '../add-guest/add-guest.component';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss']
})
export class SelectCustomerComponent implements OnInit {
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  modalRef: MdbModalRef<AddGuestComponent> | null = null;
  @Output() output = new EventEmitter<any>();
  @Output() guestOutput = new EventEmitter<any>();
  @Input() purchasing
  @Input() selectedUid
  @Input() communityDetails: Community
  @Input() stripeAccountDetails: any
  searchText: string
  guestContact: guestContact
  loading: boolean = true
  members: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchMembers()
    })
  }

  fetchMembers() {
    this.loading = true
    const callable = this.functions.httpsCallable('getMembers');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.members = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'displayName':
          let displayName = data[key]
          return displayName.toLowerCase().includes(searchTerm.toLowerCase());
        case 'email':
          let email = data[key]
          return email.toLowerCase().includes(searchTerm.toLowerCase());
        case 'provider':
          let provider = data[key]
          return provider.toLowerCase().includes(searchTerm.toLowerCase());
        case 'groups':
          let groupNames = ''
          data[key].forEach((group) => {
            groupNames += group.name + ' '
          })
          return groupNames.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  selectCustomer(uid: string) {
    this.output.emit(uid)
  }

  addGuest() {
    this.modalRef = this.modalService.open(AddGuestComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
    })
    this.modalRef.onClose.subscribe((data: guestContact) => {
      if(data) {
        this.guestContact = data
        this.output.emit(null)
        this.guestOutput.emit(this.guestContact)
      }
    })
  }

  resetGuestContact() {
    this.guestContact = null
    this.guestOutput.emit()
  }

}
