<div class="d-flex justify-content-center align-items-center h-100" style="margin-top: 20px; margin-bottom: 20px">
  <div class="row">
    <div #iconWrapper>
      <i [@tadaEnter] class="fas fas fa-check fa-3x bg-success bg-gradient text-light"></i>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center align-items-center h-100">
  <div class="row">
    <h5 class="display-5 mb-3">{{content}}</h5>
  </div>
</div>