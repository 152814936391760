import { Component, Input, OnInit } from '@angular/core';
import { Community } from 'src/app/interfaces/community';
import { PurchaseEoSubscriptionComponent } from '../../modals/purchase-eo-subscription/purchase-eo-subscription.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-eo-subscription-get-started',
  templateUrl: './eo-subscription-get-started.component.html',
  styleUrls: ['./eo-subscription-get-started.component.scss']
})
export class EoSubscriptionGetStartedComponent implements OnInit {
  @Input() communityDetails: Community
  @Input() message: string
  eoSubscribeModalRef: MdbModalRef<PurchaseEoSubscriptionComponent> | null = null;
  constructor(
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
  }

  openSubscribeModal() {
      this.eoSubscribeModalRef = this.modalService.open(PurchaseEoSubscriptionComponent, {
        modalClass: 'modal-dialog-scrollable modal-xl',
        ignoreBackdropClick: true,
        data: {
          communityDetails: this.communityDetails
        }
      })
      this.eoSubscribeModalRef.onClose.subscribe((data: any) => {
        if (data) {
          console.log('subscription purchased')
          console.log(data)
        }
      })

  }

}
