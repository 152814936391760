<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Browser Unsupported</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  The browser you're currently using doesn't support adding passes to Apple Wallet.<br><br>
  Please copy the URL below and paste it into Safari to retry adding the passes to your Apple Wallet.
</div>
<div class="modal-footer">
    <button (click)="copyURL()" type="button" class="btn btn-primary btn-rounded">Copy URL</button>
</div>
