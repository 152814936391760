// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  domain: 'dev.eventodyssey.com',
  backendUrl: 'https://dev.eventodyssey.com/api/backend',
  name: 'production',
  stripe: 'pk_test_51N6Jc6DeQOoFvZ2C0szroW3Kxm0xsgLzzcldVTEvLJCGHcGEcbpoRxnZHjPUj4FAnt4qfMdqTszx8WBiFtFMuzFX00fMSo7E7J',
  production: true,
  gcpRegion: 'us-central1',
  muxEnv: 'lhccrkfcm333l8oj1fp2uju1m',
  googleMapsKey: 'AIzaSyDeHqCSmQGza53z7_oNJbzSEhXNK4uCAF8',
  googleCastAppId: 'DFE085E1',
  firebaseConfig: {
    apiKey: "AIzaSyAVm_cOQ10ufkUJj6_DUizJVRTUvOro6VU",
    authDomain: "dev.eventodyssey.com",
    projectId: "eventodyssey-dev",
    storageBucket: "storage.dev.eventodyssey.com",
    messagingSenderId: "412748150428",
    appId: "1:412748150428:web:02cdb5d6072f04b8291f59",
    measurementId: "G-3JG64QSPP0"
  },
  toastOptions: {
    generic: {
      position: 'top-right',
      delay: 4000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'notification',
        content: '',
        type: 'generic'
      },
    },
    notification: {
      position: 'top-right',
      delay: 6000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'notification',
        content: '',
        title: 'New Notification',
        type: 'notification'
      },
    },
    success: {
      position: 'top-right',
      delay: 4000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'notification',
        content: '',
        title: 'Success',
        type: 'success'
      },
    },
    error: {
      position: 'top-right',
      delay: 5000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'error',
        content: '',
        type: 'error'
      },
    }
  }
};

