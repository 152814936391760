import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { Community } from 'src/app/interfaces/community';
import { InviteMemberComponent } from '../../invite-member/invite-member.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DateTime, Settings } from 'luxon';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material'
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';
import { saveAs } from 'file-saver';
import { CurrencyPipe } from '@angular/common';
import { PaymentDetailsComponent } from 'src/app/components/modals/payment-details/payment-details.component';
import { CustomerBillingInfoComponent } from 'src/app/components/modals/customer-billing-info/customer-billing-info.component';



@Component({
  selector: 'app-community-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class CommunityTransactionsListComponent implements OnInit {
  @Input() communityDetails: Community
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  searchText: string
  modalRef: MdbModalRef<InviteMemberComponent> | null = null;
  @Input() stripeAccountDetails: any
  loading: boolean = true
  savingExport: boolean = false
  transactions: any[] = []
  error

  confirmDeleteModalRef: MdbModalRef<ConfirmActionComponent>;
  paymentDetailsModalRef: MdbModalRef<PaymentDetailsComponent>;
  customerBillingInfoModalRef: MdbModalRef<CustomerBillingInfoComponent> | null = null;


  // Datepicker
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  startYear = DateTime.local().toObject().month > 8 ? DateTime.local().toObject().year : DateTime.local().toObject().year - 1;
  endYear = DateTime.local().toObject().month > 8 ? DateTime.local().toObject().year + 1 : DateTime.local().toObject().year;
  ranges: any = {
    'Current month': [DateTime.local().startOf('month'), DateTime.local().endOf('month')],
    'Last month': [DateTime.local().minus({ 'months': 1 }).startOf('month'), DateTime.local().minus({ 'months': 1 }).endOf('month')],
    'Current year': [DateTime.local().startOf('year'), DateTime.local().endOf('year')],
    'Last year': [DateTime.local().minus({ 'year': 1 }).startOf('year'), DateTime.local().minus({ 'year': 1 }).endOf('year')],
  }
  startDate: string = DateTime.local().startOf('month').toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('month').toFormat("MM/dd/yyyy");

  constructor(
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    private currencyPipe: CurrencyPipe
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchMembers()
    })
  }


  openDatepicker() {
    this.pickerDirective.open();
  }

  updateDates(range) {
    if (range.startDate && range.endDate) {
      let newEndDate = range.endDate['$d'].toLocaleString('en-US', { timeZone: 'America/New_York' });
      range.endDate['$d'] = new Date(newEndDate);
      this.startDate = DateTime.fromJSDate(range.startDate['$d']).plus({ 'days': 1 }).toFormat("MM/dd/yyyy");
      this.endDate = DateTime.fromJSDate(range.endDate['$d']).toFormat("MM/dd/yyyy");
      this.fetchMembers()
    }
  }

  fetchMembers() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityTransactions');
    const obs = callable({
      communityId: this.communityDetails.id,
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000)
    });
    obs.subscribe({
      next: (res) => {
        this.transactions = res.transactions
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = String(searchTerm)
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'customer':
          let customerName = data[key].name
          return customerName.toLowerCase().includes(searchTerm.toLowerCase());
        case 'payment_method_details':
          let paymentMethod
          if (data[key].card_present) {
            paymentMethod = data[key].card_present.brand + ' ' + data[key].card_present.last4 + + ' ' + data[key].card_present.network
          } else {
            paymentMethod = data[key].card.brand + ' ' + data[key].card.last4 + + ' ' + data[key].card.network
          }
          return paymentMethod.toLowerCase().includes(searchTerm.toLowerCase());

        case 'calculated_statement_descriptor':
          let paymentDescription = data[key]
          if (paymentDescription.card_present) {

          } else {
            return paymentDescription.toLowerCase().includes(searchTerm.toLowerCase());
          }
      }
    });
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async export() {
    this.savingExport = true
    const csvArray = [
      [
        "ID",
        "Customer",
        "Status",
        "Refund Status",
        "Date",
        "Gross Amount",
        "Fees",
        "Sales Tax",
        "Net Amount",
        "Amount Transferred",
        "Payment Network",
        "Last 4"
      ],
      ...this.transactions.map(item => [
        item.id.replace('ch_', ''),
        item.customer.name,
        item.status,
        item.refunded,
        DateTime.fromSeconds(item.created).toFormat("MM/dd/yyyy"),
        (item.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        (item.fees / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        ((item.tax || 0) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        ((item.net- item.tax) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        (item.net / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        (item.payment_method_details.card ? item.payment_method_details.card.brand : item.payment_method_details.card_present.brand || ''),
        (item.payment_method_details.card ? item.payment_method_details.card.last4 : item.payment_method_details.card_present.last4 || '')
      ])
    ].map(e => e.join(","))
      .join("\n");

    var blob = new Blob(['\ufeff' + csvArray.replace(/"/g, "")], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'Transactions Export  - ' + this.startDate + ' - ' + this.endDate + '.csv');
    await this.delay(500);
    this.savingExport = false
  }


  refund(item: any) {
    const formattedAmount = this.currencyPipe.transform((item.amount / 100), 'USD', 'symbol', '1.2-2');
    let content = `<div class="text-center">Are you sure you want to issue a refund of ${formattedAmount} to ${item.customer.name}?</p></div>`;
    this.confirmDeleteModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Refund Transaction',
        content: content,
        confirmButton: 'Refund',
        confirmButtonPlural: 'Refunding',
        type: 'refund',
        miscData: {
          communityId: this.communityDetails.id,
          id: item.id
        }
      }
    })
    this.confirmDeleteModalRef.onClose.subscribe((message: any) => {
      if (message === 'refunded') {
        this.fetchMembers()
      }
    });
  }

  details(item: any) {
    this.paymentDetailsModalRef = this.modalService.open(PaymentDetailsComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        paymentId: item.id,
        communityId: this.communityDetails.id
      }
    })
  }

  viewCustomer(item: any) {
    this.customerBillingInfoModalRef = this.modalService.open(CustomerBillingInfoComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityId: this.communityDetails.id,
        customerId: item.userId
      }
    })
  }


}
