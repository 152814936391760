import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {
  accountForm: FormGroup
  creatingAccount: boolean = false
  @Input() communityDetails: Community
  @Input() stripeAccountDetails: any

  constructor(
    public modalRef: MdbModalRef<AddAccountComponent>,
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.accountForm = new FormGroup({
      accountName: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      description: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required]))
    });
  }

  createAccount() {
    this.creatingAccount = true
    const callable = this.functions.httpsCallable('createAccount');
    const obs = callable({
      communityId: this.communityDetails.id,
      accountName: this.accountForm.get('accountName').value,
      description: this.accountForm.get('description').value
    });
    obs.subscribe({
      next: (res) => {
        console.log(res)
        this.creatingAccount = false
        this.modalRef.close('created')
      },
      error: (err) => {
        console.log(err)
        console.warn(err)

      },
    })
  }

}
