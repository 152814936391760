<h6>Billing</h6>

<div class="card mb-4">
  <mdb-accordion [flush]="true">
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>What payment methods can my customers use?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          We partner with stripe to enable your customers to securely and conveniently make payments using a variety of methods.<br>
          We support major credit cards, including Visa, Mastercard, American Express, and Discover. In addition to credit cards, customers can also use popular digital wallets such as Google Pay&trade; and Apple Pay&trade; for a seamless checkout experience.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>How do payouts work?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Payouts for your sales are automatically deposited into your linked bank account. In most cases, payouts are processed within 2 business days after the funds are settled.<br>
          We use Stripe as our payment provider, so you can have peace of mind knowing that your payouts are handled securely and efficiently.<br>
          For more details regarding payout schedules and other payout-related information, you can refer to the Stripe documentation or contact our support team.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>What reports are available to me?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Our platform offers comprehensive reporting features to keep you informed about your transactions and payouts.<br>
          You can view detailed reports that cover any date range of your choice right from your dashboard. Additionally, you have the ability to export the data as CSV files for further analysis or integration with other tools. We also provide an account overview section giving you a clear visualization of your financial performance.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>Can I sell in person?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Absolutely! We offer payment terminals that allow you to sell videos in person, and we'll be adding support for selling event tickets later this year.<br>
          All fees, except card processing fees, remain the same for in-person sales. Card processing fees for in-person transactions drop to 2.7% + $0.05.<br>
          Please note that we do not support cash transactions at this time.<br><br>
          For more information on in-person sales and the available features, feel free to reach out to our support team.
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
