import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {
  @Input() notification
  @Input() uid = ''
  constructor(
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
  }

  async deleteNotification() {
    await this.notificationsService.deleteNotification(this.notification.id)
  }

}
