import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  isSaving: boolean = false
  givenName: string = null
  getStartedForm = this.fb.group({
    name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
    accountType: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
  });

  accountTypes = [
    { type: 'School', icon: 'fas fa-school' },
    { type: 'Community Theater', icon: 'fas fa-theater-masks' },
    { type: 'Independent Videographer', icon: 'fas fa-video' }
  ];

  constructor(
    private fb: FormBuilder,
    private functions: AngularFireFunctions,
    private router: Router,
    private userService: UserService,
    private joinedCommunitiesService: JoinedCommunitiesService
    ) { }

  ngOnInit(): void {
    this.userService.userChanged.subscribe((user: any) => {
      this.givenName = user.given_name
    });
  }

  selectAccountType(type: string) {
    this.getStartedForm.get('accountType').setValue(type);
  }

  submit() {
    const name = this.getStartedForm.controls.name.value
    const accountType = this.getStartedForm.controls.accountType.value
    this.isSaving = true
    const callable = this.functions.httpsCallable('createCommunity');
    const obs = callable({
      accountType: accountType,
      name: name,
    });
    obs.subscribe({
      next: (res) => {
        this.joinedCommunitiesService.fetchJoinedCommunities()
        this.isSaving = false
        this.router.navigateByUrl('communities/'+res.community+'/manage')
      },
      error: (err) => {
        this.isSaving = false
        console.warn(err)
      },
    })
  }

}
