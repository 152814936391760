<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">My Purchases</h4>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <!-- Content -->
        <ng-container *ngIf="!loading; else displaySkeleton">
          <ng-container *ngIf="videoItems.length===0; else displayPurchases">
            <app-no-results message="No purchases found"></app-no-results>
          </ng-container>
          <ng-template #displayPurchases>
            <div class="datatable">
              <th scope="col">
                <div class="input-group" style="margin-bottom: 20px;">
                  <input type="text" class="form-control form-control-lg" placeholder="Filter purchases"
                    (keyup)="search($event)" #searchInput size=25 />
                </div>
              </th>
              <table responsive class="table align-middle mb-0 bg-white" *ngIf="videoItems.length>0"
                class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;"
                mdbTable mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true"
                [dataSource]="videoItems" [sort]="sort" [pagination]="pagination" [filterFn]="filterTable">
                <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
                <thead class="bg-light">
                  <tr>
                    <th [mdbTableSortHeader]="'calculated_statement_descriptor'">Purchase</th>
                    <th [mdbTableSortHeader]="'created'">Date</th>
                    <th [mdbTableSortHeader]="'amount'">Cost</th>
                    <th [mdbTableSortHeader]="'status'" class="text-end">Status</th>
                  </tr>
                </thead>
              </ng-container>
              <ng-template #noResultsFound>
                <div class="mb-5"></div>
                <app-no-results [message]="'No purchases found matching: ' + searchText"></app-no-results>
                <div class="mb-5"></div>
              </ng-template>
                <tbody>


                  <tr *ngFor="let item of table.data; let i = index">
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="">
                          <p class="fw-bold mb-1">{{item.calculated_statement_descriptor}}</p>
                          <p class="text-muted mb-0">{{item.video.name}}</p>
                          <p class="text-muted mb-0"> <app-card-detail
                              [cardDetails]="item.payment_method_details"></app-card-detail> </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="fw-normal mb-1">{{(item.created*1000) | timeAgo}}</p>
                      <p class="fw-normal mb-1">{{(item.created*1000) | date:'medium'}}</p>
                    </td>
                    <td>
                      <p class="fw-normal mb-1">{{(item.amount/100) | currency}}</p>
                    </td>
                    <td class="text-end">
                      <span *ngIf="item.status==='succeeded'&&item.refunded===false"
                        class="badge badge-success rounded-pill d-inline">Complete</span>
                      <span *ngIf="item.status==='pending'"
                        class="badge badge-warning rounded-pill d-inline">Pending</span>
                      <span *ngIf="item.status==='refunded'||item.refunded===true"
                        class="badge badge-danger rounded-pill d-inline">Refunded</span>

                      <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                        <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
                          class="dropdown-toggle hidden-arrow tableDropdown text-center " role="button" mdbDropdownToggle aria-expanded="false">
                          <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                            class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                        </span>
                        <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                          aria-labelledby="navbarDropdownMenuLink">
                          <li *ngIf="item.metadata.videoId">
                            <a [routerLink]="'/videos/watch'" [queryParams]="{v: (item.metadata.videoId)}"
                              class="dropdown-item pointer">Watch Video</a>
                          </li>
                          <li>
                            <a [href]="item.receipt_url" target="_blank" class="dropdown-item pointer">Receipt</a>
                          </li>
                          <li>
                            <a (click)="contactSupport(item)" class="dropdown-item pointer">Contact Support</a>
                          </li>
                        </ul>
                      </span>



                    </td>
                  </tr>


                </tbody>
              </table>
              <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>

            </div>
          </ng-template>





        </ng-container>
        <ng-template #displaySkeleton>
          <ng-container *ngIf="!error; else displayError">
            <div class="row">
              <app-loading></app-loading>
            </div>
          </ng-container>
          <ng-template #displayError>
            <app-https-error [error]="error"></app-https-error>
          </ng-template>
        </ng-template>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>
