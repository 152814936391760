import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { Video } from 'src/app/interfaces/video';
import { Chapter } from 'src/app/interfaces/chapter';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { environment } from 'src/environments/environment';
import mux from "mux-embed";
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { MediaRemoteControl } from 'vidstack';
import { defineCustomElement, MediaPlayerElement } from "vidstack/elements";
import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
const remote = new MediaRemoteControl();
defineCustomElement(MediaPlayerElement);

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  // Item Details
  videoId: string = ''
  chapterName: string = ''
  poster: string = ''
  videoDetails: Video
  chapterDetails: Chapter[] = []
  currentChapter: Chapter
  nextChapter: Chapter
  loading: boolean = true
  createChapters: boolean = false
  afterFirstStatus: boolean = false
  playerTime
  error
  version: string = 'unknown'
  userId: string = 'unknown'
  muxStreamType: string = 'on-demand'
  playerStreamType: string = 'on-demand'
  // Video Player
  @ViewChild('mediaPlayer', { static: false }) mediaPlayer: any;
  videoPlayed: boolean = false
  updatingChapterProgress: boolean = false
  sourceUrl
  chapters
  thumbnailUrl
  retryLaunchDelay: number = 250
  googleCastConfig = {receiverApplicationId: environment.googleCastAppId, }
  // Height Control
  displaySidebar: boolean
  componentActive: boolean = false
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.displaySidebar = window.innerWidth >= 1200;
  }

  // Awaiting purchase confirmation
  purchasedTime
  retryPurchaseTimer: number = 0;
  retryPurchaseInterval: any;
  suppressErrorMessage

  // Stream Status Subscription
  streamStatusSubscription: Subscription
  streamStatus: any = { status: 'vod' }
  videoStartTrace: any

  // Collection Table
  @ViewChild('table') videoCollectionTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  searchText: string

  constructor(
    private analytics: AngularFireAnalytics,
    private route: ActivatedRoute,
    private http: HttpClient,
    private functions: AngularFireFunctions,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private performance: AngularFirePerformance
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.userId = user.uid
      this.getVideoDetails()
    })
    this.displaySidebar = window.innerWidth >= 1200;
  }

  ngOnDestroy() {
    console.log('destroying component')
    this.componentActive = false
    clearInterval(this.retryPurchaseInterval);
    if(this.streamStatusSubscription) { this.streamStatusSubscription.unsubscribe() }
  }

  getVideoDetails() {
    this.checkVersion()
    this.loading = true
    this.error = null
    this.route.queryParams
      .subscribe(params => {
        this.videoId = params.v
        if (params.purchased) {
          this.purchasedTime = params.purchased
        }
        const callable = this.functions.httpsCallable('getVideoDetails');
        const obs = callable({
          id: this.videoId
        });
        obs.subscribe({
          next: async (res) => {
            this.afterFirstStatus = false
            this.videoStartTrace = await this.performance.trace('videoStart')
            this.videoStartTrace.start()
            console.log(res)
            console.log(res.dataJwt)
            if (res.status === 'livestream') {
              this.getStreamStatus(res.streamStatusId)
              this.muxStreamType = 'live'
              this.playerStreamType = 'live:dvr'
            }
            if (window.location.href.includes('editChapters')) { this.createChapters = res.permissions.editChapters }
            this.chapterDetails = res.chapters
            const chapters = convertToWebVTT(res.chapters)
            this.chapters = getBase64Chapters(chapters)
            this.videoDetails = res
            // this.sourceUrl = this.videoDetails.hlsUrl.split('.m3u8')[0] + '-test-fail.m3u8'
            this.sourceUrl = this.videoDetails.hlsUrl
            this.thumbnailUrl = 'https://image.mux.com/' + this.videoDetails.muxId + '/storyboard.vtt?format=webp '
            this.loading = false
            this.setInitialChapterProcess()
          },
          error: (err) => {
            console.warn(err)
            if (this.purchasedTime) {
              this.startRetryPurchaseTimer()
            }
            this.error = err.message
          },
        })
      });
  }

  getStreamStatus(streamId: string) {
    const streamStatusRef = this.db.collection('streamStatus').doc(streamId).valueChanges()
    this.streamStatusSubscription = streamStatusRef.subscribe(async (res: any) => {
      console.log(res)
      this.streamStatus = res
      switch (this.streamStatus.status) {
        case 'connected':
          // Stream is live - might not have content to play yet
          break;
        case 'active':
          // Stream is live - has content to play (video player will be launched)
          break;
        case 'disconnected':
        // Stream disconnected - might have more chapters to play
        case 'idle':
          // Stream idle - no content to play
          // Hide videoplayer and show message to user saying stream isn't active
          break;
        default:
          break;
      }
      if(this.afterFirstStatus) {
        if(res.streamDisabled) {
          this.afterFirstStatus = false
          this.getVideoDetails()
         }
      }
      this.afterFirstStatus = true
    })
  }

  startRetryPurchaseTimer() {
    this.suppressErrorMessage = true
    const isWithinThreeMinutes = new Date().getTime() - this.purchasedTime <= 180000;
    if (isWithinThreeMinutes) {
      this.retryPurchaseTimer = 30;
      this.retryPurchaseInterval = setInterval(() => {
        this.retryPurchaseTimer -= 1;
        if (this.retryPurchaseTimer === 0) {
          clearInterval(this.retryPurchaseInterval);
          this.getVideoDetails();
        }
      }, 1000);
    } else {
      this.suppressErrorMessage = false
    }
  }

  setInitialChapterProcess() {
    this.route.queryParams
      .subscribe(params => {
        if (params.t) {
          this.poster = 'https://image.mux.com/' + this.videoDetails.muxId + '/thumbnail.png?time=' + params.t
          this.updateChapterProgress(Number(params.t))
          setTimeout(() => {
            try {
              const player = this.mediaPlayer.nativeElement;
                player.currentTime = Number(params.t)
            } catch {
              this.setInitialChapterProcess()
            }
          }, 5);
        } else {
          this.poster = 'https://image.mux.com/' + this.videoDetails.muxId + '/thumbnail.png?time=' + this.videoDetails.posterStart
          this.updateChapterProgress(0)
        }
      })
  }


  generatePlayerMarkers() {
    let marketPoints = []
    this.chapterDetails.forEach((chapter: any) => {
      marketPoints.push({
        time: chapter.start,
        label: chapter.name
      })
    })
  }

  updateChapterProgress(playerTime: number) {
    this.playerTime = playerTime
    let currentChapter; let nextChapter
    for (let i = 0; i < this.chapterDetails.length; i++) {
      const chapterDetail = this.chapterDetails[i]
      if (!nextChapter) {
        if (playerTime >= chapterDetail.start) {
          if (playerTime <= chapterDetail.end) {
            currentChapter = this.chapterDetails[i]
            nextChapter = this.chapterDetails[i + 1]
          }
        } else {
          nextChapter = chapterDetail
        }
      }
    }
    // console.log(nextChapter )
    this.nextChapter = nextChapter
    this.currentChapter = currentChapter
    this.updatingChapterProgress = false
  }

  seekTo(seconds: number) {
    setTimeout(() => {
      try {
        const player = this.mediaPlayer.nativeElement;
          player.currentTime = Number(seconds)
          if (!player.playing) {
            player.play()
          }
      } catch {
        this.setInitialChapterProcess()
      }
    }, 5);
    if (!this.videoPlayed) {
      this.videoPlayed = true
      setTimeout(() => {
        this.seekTo(seconds)
        this.updateChapterProgress(seconds)
      }, 500);
    }
  }

  checkVersion() {
    this.http.get('./assets/version.txt', { responseType: 'text' }).subscribe((data: any) => {
      this.version = data
    })
  }

  started($event) {
    mux.monitor($event.trigger?.target,  { 
      errorTranslator: this.muxErrorTranslator,
      data: { env_key: environment.muxEnv, viewer_user_id: this.userId, video_id: this.videoId, video_title: this.videoDetails.name, player_name: 'Event Odyssey Web App', player_version: this.version, video_series: this.videoDetails.communityDetails.name, video_stream_type: this.muxStreamType,  } 
    })

  }

  muxErrorTranslator (error) {
    if(this.componentActive) {
      return error
    } else {
      // Component Destoryed - Ignore Error
      return false
    }
  }

  async played($event) {
    this.analytics.logEvent('video_played', { video: this.videoId })
    if (!this.videoPlayed) {
      this.started($event)
      this.videoStartTrace.stop()
      this.analytics.logEvent('video_started', { video: this.videoId })
      this.videoPlayed = true
    } else {
      this.analytics.logEvent('video_resumed', { video: this.videoId })
    }
  }

  paused($event) {
    this.analytics.logEvent('video_paused', { video: this.videoId })
  }

  videoPlayerError($event) {
    console.log('video player error')
    console.log($event)
  }

  playError($event) {
    console.log('play error')
  }

  hlsError($event) {
    console.log('hls playback error')
    // if(this.muxStreamType === 'live') {
    //   console.log('reloading video')
    //   this.getVideoDetails()
    // }
    // TODO: Add Toast Message
  }

  castLoadStart($event) {
    console.log('cast load start')
  }

  castLoaded($event) {
    console.log('cast loaded')
  }

  castPromptOpen($event) {
    console.log('cast load start')
  }

  castPromptClose($event) {
    console.log('cast loaded')
  }

  playerChanged($event) {
    const player = this.mediaPlayer.nativeElement;
    const unsubscribe = player.subscribe(({ currentTime }) => {
      if(!this.updatingChapterProgress) {
        this.updatingChapterProgress = true
        this.updateChapterProgress(currentTime)
      }
    });
    unsubscribe();
  }

  outputNewChapter($event) {
    this.chapterDetails.push($event)
    this.generatePlayerMarkers()
    const callable = this.functions.httpsCallable('addChapterToVideo');
    const obs = callable({
      id: this.videoId, chapters: this.chapterDetails
    });
    obs.subscribe({
      next: (res) => {
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

search(event: Event): void {
  const searchTerm = (event.target as HTMLInputElement).value;
  this.videoCollectionTable.search(searchTerm);
  this.searchText = searchTerm
}

filterTable(data: any, searchTerm: string): boolean {
  return Object.keys(data).some((key: any) => {
    switch (key) {
      case 'name':
        let name = data[key]
        return name.toLowerCase().includes(searchTerm.toLowerCase());
    }
  });
}

}



function convertToWebVTT(data: any[]): string {
  let webVTT = "WEBVTT\n\n";
  for (let i = 0; i < data.length; i++) {
    const entry = data[i];
    const start = formatTime(entry.start);
    const end = formatTime(entry.end);
    webVTT += `${start} --> ${end}\n`;
    webVTT += `${entry.name}\n`;
    webVTT += "\n";
  }
  return webVTT;
}

function formatTime(time: number | string): string {
  const seconds = Number(time);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const formattedHours = padZero(Math.floor(hours));
  const formattedMinutes = padZero(Math.floor(minutes));
  const formattedSeconds = padZero(Math.floor(remainingSeconds));
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.000`;
}

function padZero(value: number): string {
  return value.toString().padStart(2, '0');
}

function getBase64Chapters(chapters) {
  const encoder = new TextEncoder();
  const chaptersData = encoder.encode(chapters);
  const base64Chapters = base64ArrayBuffer(chaptersData.buffer);
  return `data:text/vtt;base64,${base64Chapters}`;
}

function base64ArrayBuffer(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}
