import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';

@Component({
  selector: 'app-join-community-with-code',
  templateUrl: './join-community-with-code.component.html',
  styleUrls: ['./join-community-with-code.component.scss']
})
export class JoinCommunityWithCodeComponent implements OnInit {
  codeForm: FormGroup
  loadingResult: boolean = false
  @Input() validationString: string
  @Input() communityId: string
  @Input() campaignId: string
  error
  toastOptionsSuccess = environment.toastOptions.success as MdbNotificationConfig<{}>;
  constructor(
    public modalRef: MdbModalRef<JoinCommunityWithCodeComponent>,
    private router: Router,
    private toastService: MdbNotificationService,
    private joinedCommunitiesService: JoinedCommunitiesService,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    if(this.campaignId && this.validationString) {
      this.joinCampaign(this.campaignId, this.validationString)
    }
    this.codeForm = new FormGroup({
      code: new FormControl('', Validators.compose([Validators.minLength(5), Validators.required])),
    });
  }

  submit() {
    const validationString = this.codeForm.controls.code.value
    this.joinCommunity(this.communityId, validationString)
  }

  joinCommunity(communityId: string, validationString: string) {
    this.loadingResult = true
    const callable = this.functions.httpsCallable('joinCommunityWithCode');
    const obs = callable({
      communityId: communityId,
      validationString: validationString
    });
    obs.subscribe({
      next: (res) => {
        this.handleSuccess(res.communityId)
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  joinCampaign(campaignId: string, validationString: string) {
    this.loadingResult = true
    const callable = this.functions.httpsCallable('joinCommunityWithCode');
    const obs = callable({
      campaignId: campaignId,
      validationString: validationString
    });
    obs.subscribe({
      next: (res) => {
        this.handleSuccess(res.communityId)
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  handleSuccess(communityId: string) {
    this.router.navigateByUrl('communities/'+communityId)
    this.joinedCommunitiesService.fetchJoinedCommunities()
    this.modalRef.close()
    this.toastOptionsSuccess.data['content'] = `You\'ve joined a new community!`;
    this.toastService.open(ToastComponent, this.toastOptionsSuccess)
  }

}
