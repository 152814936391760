import { Component, Input, OnInit } from '@angular/core';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
})
export class SuccessComponent implements OnInit {
  @Input() content: string

  constructor() { }

  ngOnInit(): void {
  }

}
