<div style="width: 100%">
    <div class="d-flex">
        <!-- Video Information Section -->
        <div class="p-2 flex-grow-1">
            <div>
                <h6 [innerHtml]="itemDetails.video_title.snippet" class="result-content"></h6>
                <p *ngIf="itemDetails.video_series" class="text-muted mb-0">Series: <span [innerHtml]="itemDetails.video_series.snippet"></span></p>
                <p *ngIf="itemDetails.video_stream_type" class="text-muted mb-0">Stream Type: {{ itemDetails.video_stream_type.raw }}</p>
            </div>
        </div>

        <!-- Viewer Details Section -->
        <div class="p-2 flex-grow-1">
            <div>
                <p *ngIf="itemDetails.viewer_os_family" class="text-muted mb-0">OS: {{ itemDetails.viewer_os_family.raw }}</p>
                <p *ngIf="itemDetails.viewer_device_category" class="text-muted mb-0">Device Category: {{ itemDetails.viewer_device_category.raw }}</p>
                <p *ngIf="itemDetails.viewer_application_name" class="text-muted mb-0">Application: {{ itemDetails.viewer_application_name.raw }}</p>
            </div>
        </div>

        <!-- Timing Information Section -->
        <div class="p-2 small text-end">
            Start: {{ itemDetails.view_start.raw | date:'medium' }}<br>
            End: {{ itemDetails.view_end.raw | date:'medium' }}<br>
            Watch Time: {{ (itemDetails.watch_time.raw/1000) | elapsedTIme }}
        </div>
    </div>
</div>
