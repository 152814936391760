import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-add-tax-profile',
  templateUrl: './add-tax-profile.component.html',
  styleUrls: ['./add-tax-profile.component.scss']
})
export class AddTaxProfileComponent implements OnInit {
  taxProfileForm: FormGroup
  isSaving: boolean = false

  @Input() communityDetails: Community

  constructor(
    public modalRef: MdbModalRef<AddTaxProfileComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.taxProfileForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      taxRateType: new FormControl('percentage', Validators.required),
      taxRateValue: new FormControl(0, [Validators.required, this.validTaxRate])
    });
}



  submit() {
    const name = this.taxProfileForm.controls.name.value;
    const taxRateName = this.taxProfileForm.controls.name.value;
    const taxRateType = this.taxProfileForm.controls.taxRateType.value;
    const taxRateValue = this.taxProfileForm.controls.taxRateValue.value;
    console.log(name, taxRateName, taxRateType, taxRateValue)
    this.isSaving = true;
    const callable = this.functions.httpsCallable('createCommunityTaxProfile');
    const obs = callable({
      name: name,
      communityId: this.communityDetails.id,
      taxRates: [{
        name: taxRateName,
        type: taxRateType,
        value: taxRateValue
      }]
    });

    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created');
        this.isSaving = false;
      },
      error: (err) => {
        console.log(err)
        this.isSaving = false;
        console.warn(err);
      },
    });


}



validTaxRate(control: FormControl) {
  const value = control.value;

  if (value === null || value === undefined) {
    return null; // Don't show an error if the value is empty
  }

  if (isNaN(value) || value < 0 || value > 1) {
    return { invalidTaxRateValue: true };
  }

  return null;
}


}
