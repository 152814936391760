<div class="card">
  <img
  [src]="itemDetails.thumbnail"
  class="imgRound img-fluid static pointer"
  hoverGifLoader [routerLink]="'/videos/watch'" [queryParams]="{v: itemDetails.id}"
  [gifSrc]="itemDetails.gif" />
  <div class="card-body">
    <h5 style="margin-bottom: 0px;"  class="card-title">{{itemDetails.name}}</h5>
    <p style="margin-top: 0px;" class="card-text">{{itemDetails.description}}
    </p>
    <p class="card-text">{{itemDetails.communityDetails.name}}
    </p>
    <p class="card-text">{{itemDetails.length}}</p>

    <a [routerLink]="'/videos/watch'" [queryParams]="{v: itemDetails.id}" class="btn btn-primary btn-rounded text-start"><i class="fas fa-play me-2"></i> Watch</a>

    <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
      <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;" class="dropdown-toggle hidden-arrow" role="button"
        mdbDropdownToggle aria-expanded="false">
        <i style="height: 60px; margin-left: 16px; margin-top: 17px" class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
    </span>
      <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
        <li>
          <a (click)="download()" class="dropdown-item pointer">Download</a>
          <!-- <a class="dropdown-item pointer">View Receipt</a> -->
        </li>
      </ul>
    </span>


  </div>



</div>
