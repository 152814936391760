import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-messages-list',
  templateUrl: './user-messages-list.component.html',
  styleUrls: ['./user-messages-list.component.scss']
})
export class UserMessagesListComponent implements OnInit {
  @Input() lookupUid: string
  showSearch: boolean = true
  error
  constructor() { }

  ngOnInit(): void {
  }

}
