import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elapsedTIme'
})
export class ElapsedTImePipe implements PipeTransform {

    transform(value: number): string {
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const seconds = Math.ceil(value % 60);
      let time = "";
      if (hours > 0) {
          time += `${hours} hour${hours !== 1 ? 's' : ''} `;
      }
      if (minutes > 0) {
          time += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
      }
      if (seconds > 0 || (hours === 0 && minutes === 0)) {
          time += `${seconds} second${seconds !== 1 ? 's' : ''}`;
      }
      return time;
    }

}
