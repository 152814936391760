<app-landing-header></app-landing-header>
<section class="">
  <div class="container text-md-start mt-5">
    <div class="row mt-3">
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-8">
        <div class="row mb-3">
          <div class="col-md-6 col-lg-6 col-xl-6">

              <div class="row mb-4">
                <h3 class="fw-bold">Embark on Unforgettable Adventures</h3>
                <div class="text-muted"><span>Hoist the anchor and elevate your events to new horizons!</span> </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-1 col-2">
                  <i class="fas fa-calendar-check text-primary fa-2x"></i>
                </div>
                <div class="col-md-11 col-10">
                  <h5>Event Management</h5>
                  <p>Turn event chaos into organized success. Whether it's ticket sales, seat assignments, or mobile ticketing, we've got you covered.
                    <span *ngIf="environmentName!=='production'" [routerLink]="'/learn-more'" class="text-primary pointer hoverUnderline">Learn more</span>
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-1 col-2">
                  <i class="fas fa-film text-primary fa-2x" style="margin-left: -2px;"></i>
                </div>
                <div class="col-md-11 col-10">
                  <h5>Video on Demand</h5>
                  <p>Showcase every performance, whether captured by you or a third-party videographer. All the while, you hold the reins to access and sales
                    <span *ngIf="environmentName!=='production'" [routerLink]="'/learn-more'" class="text-primary pointer hoverUnderline">Learn more</span>
                  </p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-1 col-2">
                  <i class="fas fa-users text-primary fa-2x" style="margin-left: -6px;"></i>
                </div>
                <div class="col-md-11 col-10">
                  <h5>Group Management</h5>
                  <p>
                    Fine-tune your community's access. Set up groups, decide on viewable content, and extend exclusive offers with ease.
                    <span *ngIf="environmentName!=='production'" [routerLink]="'/learn-more'" class="text-primary pointer hoverUnderline">Learn more</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <ng-container *ngIf="environmentName==='production'; else developmentView">
                  <div class="card mt-1">
                    <div class="card-body">
                      <h5 class="card-title">New Sign-ups Temporarily On Hold</h5>
                      <div class="card-text mb-2">
                          We're in a special preview phase at the moment, but we'll be welcoming new communities shortly.
                          <div class="mt-2 small">Stay tuned for future updates!</div>
                      </div>
                  </div>

                  </div>
                </ng-container>
                <ng-template #developmentView>
                  <div class="text-center">
                    <button [routerLink]="'/pricing'" class="btn btn-medium btn-rounded me-3 mb-2 btn-lg text-capitalize">Pricing</button>
                    <button [routerLink]="'/get-started'" class="btn btn-primary btn-rounded me-3 mb-2 btn-lg text-capitalize">Get Started</button>
                  </div>
                </ng-template>
              </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6">
            <ng-container *ngIf="isDarkMode; else lightMode">
              <div class="bg-image bouncing-image" mdbRipple rippleColor="light">
                <img src="./assets/dark-phone-tickets.png" class="img-fluid" />
              </div>
            </ng-container>
            <ng-template #lightMode>
              <div class="bg-image hover-overlay bouncing-image" mdbRipple rippleColor="light">
                <img src="./assets/phone-tickets.png" class="img-fluid" />
                <a>
                  <div class="mask" style="background-color: rgba(251, 251, 251, 0.15);"></div>
                </a>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
