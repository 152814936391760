import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { fader } from './route-animations'
import { JoinedCommunitiesService } from './services/joined-communities.service';
import { UserService } from './services/user.service';
import { EnvironmentService } from './services/environment.service';
import { environment } from 'src/environments/environment';
import { AcceptAnalyticsComponent } from './components/modals/accept-analytics/accept-analytics.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fader
  ]
})
export class AppComponent {
  title = 'eventodyssey';
  loggedIn: string = 'not-loaded'
  launchedAcceptAnalytics: boolean = false
  acceptAnalyticsModalRef: MdbModalRef<AcceptAnalyticsComponent>;
  joinedCommunitiesLoading: boolean = true
  userProfile
  joinedCommunities: any[] = []
  environmentName: string
  hideNavigation: boolean = false
  globalAdmin: boolean = false
  routeSub: Subscription;

  constructor(
    private afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private joinedCommunitiesService: JoinedCommunitiesService,
    private userService: UserService,
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private router: Router,
    private envService: EnvironmentService
  ) { }

  ngOnInit() {
    // console.clear()
    this.disableAnimationsForChromeOnAndroid()
    this.checkHideNavigation()
    this.getUserData()
    this.environmentName = this.envService.getEnvironmentName();
  }

  async checkHideNavigation() {
    this.routeSub = this.activatedRoute.queryParams.subscribe(async (params) => {
      const hideNavigation = params['hideNavigation'];
      if (hideNavigation) {
        this.hideNavigation = true;
      } else {
        this.checkAnalyticsAcceptanceState()
      }
    });
  }
  disableAnimationsForChromeOnAndroid() {
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/chrome/i.test(userAgent) && /android/i.test(userAgent)) {
      document.body.classList.add("disable-svg-animation");
    }
  }

  getUserData() {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        let name = 'User'
        if (user.displayName) {
          name = (user.displayName.split(' ')[0])
        }
        this.userProfile = {
          id: user.uid,
          given_name: name,
          full_name: user.displayName,
          ...(user.email && { email: user.email }),
          ...(user.providerData[0].email && { email: user.providerData[0].email }),
          provider: user.providerData[0].providerId,
          profilePicture: user.photoURL
        }
        this.loggedIn = 'true'
        const redirectUrl = this.activatedRoute.snapshot.queryParamMap.get(
          'redirectUrl'
        );
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl)
        }
        this.userService.setUser(this.userProfile)
        this.joinedCommunitiesService.fetchJoinedCommunities()
        this.joinedCommunitiesService.joinedCommunitiesChanged.subscribe((joinedCommunities: any[]) => {
          if (joinedCommunities[0]?.loading) {
            this.joinedCommunitiesLoading = true
          } else {
            this.joinedCommunitiesLoading = false
            this.joinedCommunities = joinedCommunities;
          }

        });
        this.joinedCommunitiesService.globalAdminChanged.subscribe((isGlobalAdmin: any) => {
          if(typeof isGlobalAdmin === 'boolean') {
            this.globalAdmin = isGlobalAdmin
          }
        });
      } else {
        this.loggedIn = 'false'
      }
    })
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  async checkAnalyticsAcceptanceState() {
    await this.delay(750);
    if(!this.hideNavigation) {
      const storedState = localStorage.getItem('analyticsEnabled')
      switch (storedState) {
        case 'true':
          window[`ga-disable-${environment.firebaseConfig.measurementId}`] = false;
          break
        case 'false':
          // Do Nothing (Analytics are already disabled by default in main.ts)
          break
        case null:
        default:
          this.launchAnalyticsConsentModal()
          break
      }
    }

  }

  launchAnalyticsConsentModal() {
    if(!this.launchedAcceptAnalytics) {
          this.acceptAnalyticsModalRef = this.modalService.open(AcceptAnalyticsComponent, {
      containerClass: 'frame bottom',
      ignoreBackdropClick: true,
      modalClass: 'modal-frame modal-bottom',
      data: {}
    })
    this.acceptAnalyticsModalRef.onClose.subscribe((message: any) => {
      switch (message) {
        case 'true':
          localStorage.setItem('analyticsEnabled', 'true')
          window[`ga-disable-${environment.firebaseConfig.measurementId}`] = false;
          break;
        case 'false':
        default:
          localStorage.setItem('analyticsEnabled', 'false')
          break;
      }
    });
    this.launchedAcceptAnalytics = true
    }

  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

toggle($sidenav) {
  console.log('toggle it')
  $sidenav.toggle()
}

}
