<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Share Campaign</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <app-notice [content]="'This campaign is active and can onboard new members using the methods provided below.'"
    type="info"></app-notice>
  <mdb-tabs #tabs [justified]="true">
    <mdb-tab title="Link">
      <ng-template mdbTabContent>
        <div class="card-body">
          <div class="text-center">
            <h3 class="sms-title mb-1">Share this Link</h3>
            <div class="sms-instructions p-3 rounded">
              <div class="keyword-box p-2 bg-light rounded shadow-sm">
                <br class="noselect">
                <form [formGroup]="linkForm">
                  <div class="input-group mt-2">
                    <div class="input-container">
                      <mdb-form-control>
                        <input mdbInput [mdbValidate] type="text" formControlName="link" id="link"
                          class="form-control form-control-lg bg-light" [validateSuccess]="false" placeholder="Invite Link"
                          [readonly]="true" />
                        <label mdbLabel class="form-label" for="link">Invite Link</label>
                      </mdb-form-control>
                    </div>
                    <span class="input-group-text border-0">
                      <i (click)="copyLink()" class="far fa-copy pointer me-2" title="Copy Link" placement="right"></i></span>
                  </div>
                </form>
                <br class="noselect">
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mdb-tab>
    <mdb-tab title="QR Code">
      <ng-template mdbTabContent>
          <div class="card-body">
            <div class="text-center">
              <h3 class="sms-title mb-1">Share via QR Code</h3>
              <div class="sms-instructions p-3 rounded">
                <p class="instruction-text mb-2">Download the image below to share on posters and event programs:</p>
                <div class="keyword-box p-2 bg-light rounded shadow-sm mt-1 mb-1">
                  <ngx-qrcode-styling [config]="config" [data]="link"></ngx-qrcode-styling>
                </div>
              </div>
            </div>
          </div>
      </ng-template>
    </mdb-tab>
    <mdb-tab title="SMS Keyword">
      <ng-template mdbTabContent>
        <div class="card-body">
          <div class="text-center">
            <h3 class="sms-title mb-1">Share via SMS</h3>
            <div class="sms-instructions p-3 rounded">
              <p class="instruction-text mb-2">To share this link, send a text message:</p>
              <div class="keyword-box p-2 bg-light rounded shadow-sm">
                <span class="keyword-text">Text "<strong>{{campaignData.name}}</strong>" to</span>
                <span class="phone-number font-weight-bold">+1 (317) 483-0027</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mdb-tab>
  </mdb-tabs>
  <br class="noselect">


</div>
