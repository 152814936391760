<ng-container *ngIf="communityDetails.subscription; else subscriptionError">
  <ng-container [ngSwitch]="communityDetails.subscription.status">
    <div *ngSwitchCase="'Never Setup'">
      <app-eo-subscription-get-started [communityDetails]="communityDetails" [message]="'Never Setup'"></app-eo-subscription-get-started>
    </div>
    <div *ngSwitchCase="'Active'">
      Account Active
    </div>
    <div *ngSwitchCase="'Inactive'">
      <app-eo-subscription-get-started [communityDetails]="communityDetails"  [message]="'Inactive / Expired'"></app-eo-subscription-get-started>
    </div>
  </ng-container>

</ng-container>
<ng-template #subscriptionError>
  <app-https-error [error]="'Cannot fetch ' + communityDetails.name + '\'s subscription details!'"></app-https-error>
</ng-template>
