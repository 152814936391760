import { Directive, Input, Renderer2, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[hoverGifLoader]'
})
export class HoverGifLoaderDirective {
  @Input() routerLink: any;
  @Input() queryParams: any;
  @Input() click: any;
  @Output() gifClick = new EventEmitter<any>();

  @Input() gifSrc: string;
  private imgElem: any;
  private isLoading: boolean = false;
  private isMouseOver: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router) {}

  @HostListener('mouseover') onMouseOver() {
    this.isMouseOver = true;
    if (!this.imgElem && !this.isLoading) {
      this.isLoading = true;

      // Preload the GIF
      const preloadedImg = new Image();
      preloadedImg.src = this.gifSrc;
      preloadedImg.onload = () => {
        this.isLoading = false;
        if (this.isMouseOver) {
          this.imgElem = this.renderer.createElement('img');
          this.renderer.setAttribute(this.imgElem, 'src', this.gifSrc);
          this.renderer.setStyle(this.imgElem, 'position', 'absolute');
          this.renderer.setStyle(this.imgElem, 'borderTopLeftRadius', '8px');
          this.renderer.setStyle(this.imgElem, 'borderTopRightRadius', '8px');
          this.renderer.setStyle(this.imgElem, 'filter', 'brightness(0.9)');
          this.renderer.addClass(this.imgElem, 'img-fluid');
          this.renderer.addClass(this.imgElem, 'gif');
          this.renderer.addClass(this.imgElem, 'pointer');
          this.renderer.setStyle(this.imgElem, 'z-index', '1');  // Adjust z-index to be above static image
          this.renderer.appendChild(this.el.nativeElement.parentElement, this.imgElem);
          this.renderer.listen(this.imgElem, 'click', () => {
            if (this.routerLink) {
              this.router.navigate([this.routerLink], { queryParams: this.queryParams });
            } else {
              this.gifClick.emit('use_method');
            }
          });
          this.renderer.listen(this.imgElem, 'mouseout', () => {
            this.renderer.removeChild(this.el.nativeElement.parentElement, this.imgElem);
            this.imgElem = null;
          });
        }
      };
    } else if (this.imgElem) {
      this.renderer.appendChild(this.el.nativeElement.parentElement, this.imgElem);
    }
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event: MouseEvent) {
    this.isMouseOver = false;
    if (this.imgElem && !this.isLoading && event.relatedTarget !== this.imgElem) {
      this.renderer.removeChild(this.el.nativeElement.parentElement, this.imgElem);
      this.imgElem = null;
    }
  }
}
