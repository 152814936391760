

<ng-container *ngIf="loadingNotificationSettings; else showContent">
  <app-loading></app-loading>
</ng-container>
<ng-template #showContent>
  <div class="mb-4">
    <ng-container *ngIf="!error; else displayError">
      <div class="mb-3">
        <app-notice [content]="noticeContent" type="warning" ></app-notice>
      </div>
      <form [formGroup]="groupForm">
        <div *ngFor="let group of notificationGroups" class="form-check ">
          <input mdbCheckbox class="form-check-input" type="checkbox" [id]="group.id" [value]="group.id"
            [disabled]="!group.editable" [checked]="isSelected(group.id)" (change)="onGroupChange($event, group.id)" />
          <label class="form-check-label" [for]="group.id">
            <b>{{group.name}}</b><span *ngIf="!group.editable" class="badge badge-primary ms-2">Always Enabled</span><br>
            {{group.description}}
          </label>
        </div>
      </form>
    </ng-container>
    <ng-template #displayError>
        <app-https-error [error]="error"></app-https-error>
    </ng-template>

  </div>
  <div class="modal-footer"  *ngIf="!error">
    <button (click)="submit()" [disabled]="groupForm.invalid||isSaving" type="button" class="btn btn-primary btn-rounded">
      <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
        Updating</span>
      <span *ngIf="!isSaving">Update</span>
    </button>
  </div>
</ng-template>
