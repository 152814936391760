import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { Community } from 'src/app/interfaces/community';
import { InviteMemberComponent } from '../../invite-member/invite-member.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';
import { UserService } from 'src/app/services/user.service';
import { EditUserGroupsComponent } from 'src/app/components/modals/edit-user-groups/edit-user-groups.component';

@Component({
  selector: 'app-active-list',
  templateUrl: './active-list.component.html',
  styleUrls: ['./active-list.component.scss']
})
export class ActiveListComponent implements OnInit {
  modalRef: MdbModalRef<InviteMemberComponent> | null = null;
  editUserGroupsModalRef: MdbModalRef<EditUserGroupsComponent> | null = null;
  confirmActionModalRef: MdbModalRef<ConfirmActionComponent> | null = null;
  @Input() communityDetails: Community
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  searchText: string
  user
  @Input() stripeAccountDetails: any
  @Input() activeTabViews: any
  loading: boolean = true
  members: any[] = []
  error

  constructor(
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private userService: UserService,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.userService.userChanged.subscribe((userProfile: any) => {
        this.user = userProfile
      });
      this.fetchMembers()
    })
  }

  ngOnChanges() {
    this.fetchMembers()
  }

  fetchMembers() {
    this.loading = true
    const callable = this.functions.httpsCallable('getMembers');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.members = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  createMember() {
    this.modalRef = this.modalService.open(InviteMemberComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
  }

  editUserGroups(item) {
    this.editUserGroupsModalRef = this.modalService.open(EditUserGroupsComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        userDetails: item
      }
    })
    this.editUserGroupsModalRef.onClose.subscribe((message: any) => {
      if (message === 'updated') {
        this.fetchMembers()
      }
    });
  }


  removeMember(item: any) {
    let content = `<div class="text-center">Are you sure you want to remove ${item.displayName}?</p></div>`;
    this.confirmActionModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Remove Member',
        content: content,
        confirmButton: 'Remove Member',
        confirmButtonPlural: 'Removing Member',
        type: 'removeMember',
        miscData: {
          communityId: this.communityDetails.id,
          id: item.uid
        }
      }
    })
    this.confirmActionModalRef.onClose.subscribe((message: any) => {
      if (message === 'removed') {
        this.fetchMembers()
      }
    });
  }

  blockMember(item: any) {
    let content = `<div class="text-center">Are you sure you want to block ${item.displayName}?</p></div>`;
    this.confirmActionModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Block Member',
        content: content,
        confirmButton: 'Block Member',
        confirmButtonPlural: 'Blocking Member',
        type: 'blockExistingMember',
        miscData: {
          communityId: this.communityDetails.id,
          id: item.uid
        }
      }
    })
    this.confirmActionModalRef.onClose.subscribe((message: any) => {
      if (message === 'blocked') {
        this.fetchMembers()
      }
    });
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'displayName':
          let displayName = data[key]
          return displayName.toLowerCase().includes(searchTerm.toLowerCase());
        case 'email':
          let email = data[key]
          return email.toLowerCase().includes(searchTerm.toLowerCase());
        case 'provider':
          let provider = data[key]
          return provider.toLowerCase().includes(searchTerm.toLowerCase());
        case 'groups':
          let groupNames = ''
          data[key].forEach((group) => {
            groupNames += group.name + ' '
          })
          return groupNames.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }
}
