import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { DownloadComponent } from '../download/download.component';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
  modalRef: MdbModalRef<DownloadComponent> | null = null;
  @Input() itemDetails = {
    name: '',
    description: '',
    communityDetails: {
      name: ''
    },
    length: '',
    id: '',
    gif: 'https://image.mux.com/S3klOSZ00YuWV02002lIPd9fU6C7EDlD1bQ6oB00c3yjCsA/animated.gif?start=30&end=40',
    thumbnail: 'https://image.mux.com/S3klOSZ00YuWV02002lIPd9fU6C7EDlD1bQ6oB00c3yjCsA/thumbnail.png?time=30'
  }
  constructor(
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {

  }

  download() {
    console.log('lets download it')
    this.modalRef = this.modalService.open(DownloadComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        videoDetails: this.itemDetails
      }
    })
  }

}
