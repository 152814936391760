<div class="card">
  <img
  click=true
  (gifClick)="purchase()"
  [src]="itemDetails.thumbnail"
  class="imgRound img-fluid static pointer"
  hoverGifLoader
  [gifSrc]="itemDetails.gif" />
  <div class="card-body">
    <h5 style="margin-bottom: 0px;" class="card-title">{{itemDetails.name}}</h5>
    <div class="card-text small mb-2">{{itemDetails.communityDetails.name}}</div>
    <div style="margin-top: 0px;" class="card-text ">{{itemDetails.description || "&nbsp;"}}</div>

    <ng-container *ngIf="!(itemDetails.preOrder); else preOrderContent">
      <span class="small">{{itemDetails.length}}</span>
    </ng-container>
    <ng-template #preOrderContent>
      <ng-container *ngIf="itemDetails.access === true; else notPreOrder">
        <span class="badge badge-success  ml-2"><span class=""><i class="fas fa-check"></i> Already Purchased</span></span>
      </ng-container>
      <ng-template #notPreOrder>
        <span class="badge badge-warning ml-2"><span class="">Pre-Order</span></span>
      </ng-template>
      <div *ngIf="itemDetails.preOrderAvailableDate" class="small"><i class="ms-1 fas fa-clock"></i> Available {{(itemDetails.preOrderAvailableDate*1000) | date:'mediumDate'}} </div>
    </ng-template>
    <div class="mt-2">
      <ng-container *ngIf="itemDetails.access === true; else purchaseLink">
        <ng-container *ngIf="!(itemDetails.preOrder); else preOrderContent">
          <a (click)="modalRef.close()"  [routerLink]="'/videos/watch'" [queryParams]="{v: itemDetails.id}"
          class="btn btn-primary btn-rounded text-start"><i class="fas fa-play me-2"></i> Watch</a>
          <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
            <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;" class="dropdown-toggle hidden-arrow" role="button"
              mdbDropdownToggle aria-expanded="false">
              <i style="height: 60px; margin-left: 16px; margin-top: 17px" class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
          </span>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a (click)="download()" class="dropdown-item pointer">Download</a>
                <!-- <a class="dropdown-item pointer">View Receipt</a> -->
              </li>
            </ul>
          </span>
        </ng-container>
        <ng-template #preOrderContent>
          <a class="disabled text-capitalize btn btn-dark btn-rounded text-start">Available Soon</a>
        </ng-template>



      </ng-container>
      <ng-template #purchaseLink>
        <a (click)="purchase()" class="btn btn-primary btn-rounded text-start"><i class="fas fa-dollar-sign me-2"></i> Purchase</a>
      </ng-template>
    </div>
  </div>
</div>
