<div class="modal-header">
  <h5 class="modal-title">Accept Terms & Conditions</h5>
  <ng-container *ngIf="!loading">
    <button *ngIf="termsData.accepted.value" type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
  </ng-container>
</div>
<form [formGroup]="termsForm">
  <ng-container *ngIf="loading; else termsLoaded">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #termsLoaded>
    <div class="modal-body">
      <div *ngIf="termsData.content" mdbScrollbar class="viewTerms mb-2">
        <app-view-terms [termsContent]="termsData.content"></app-view-terms>
        <div class="modal-body">
          <h5><strong>Last Updated: {{termsData.created*1000 | date}}</strong></h5>
          This Terms of Service was last updated on {{termsData.created*1000 | date:'long'}}. Any changes or
          modifications made to the Terms will be effective as of that date.
        </div>
      </div>
      <div class="mb-3 ms-2">
        <small>A copy of these terms and conditions is available at
          <span><a class="text-muted hoverUnderline pointer" href="https://eventodyssey.com/terms-of-service"
              target="_blank">https://eventodyssey.com/terms-of-service</a></span>
        </small>
      </div>
      <ng-container *ngIf="termsData.accepted.value; else displayForm">
        <div class="mb-3 ms-2">
          <app-notice [content]="'Accepted on ' + (termsData.accepted.completed | date:'long')" type="warning"></app-notice>
        </div>
      </ng-container>
      <ng-template #displayForm>
        <div class="mb-1 ms-2">
          <div class="input-group mt-2">
            <div class="input-container">
              <input formControlName="clickwrap" mdbCheckbox [mdbValidate] [validateSuccess]="false"
                class="form-check-input" type="checkbox" value="" checked="false" id="clickwrap" />
              <label class="form-check-label" for="clickwrap">
                <strong>I have read and agree to the Event Odyssey Terms and Conditions.</strong>
              </label>
            </div>
            <span class="input-group-text border-0"><app-validation-icon
                [inputValid]="termsForm.controls.clickwrap.invalid"></app-validation-icon>
              <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
          </div>
        </div>
        <div *ngIf="termsData.requireSignature">
          <div class="input-group mt-1">
            <div class="input-container">
              <div>
                <span class="ms-2">Sign below<span *ngIf="fullName"> as {{fullName}}</span>:</span>
              </div>
            </div>
            <span class="input-group-text border-0"><app-validation-icon
                [inputValid]="termsForm.controls.signatureProvided.invalid"></app-validation-icon>
              <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
          </div>
          <div>
            <!-- <signature-pad class="signaturePad" #signature [options]="signaturePadOptions"
              (drawEnd)="drawComplete($event)"></signature-pad>
            <div class="container">
              <div class="signatureSettings">
                <div class="row">
                  <div class="col text-start">
                    <i [ngClass]="(drawnLines.length>0) ? 'pointer' : 'buttonDisabled'" (click)="clearSignature()"
                      class="fas fa-trash ms-1"></i>
                  </div>
                  <div class="col text-end">
                    <i [ngClass]="(drawnLines.length>0) ? 'pointer' : 'buttonDisabled'" (click)="undoSignature()"
                      class="fas fa-undo-alt me-2"></i>
                    <i [ngClass]="(undoneLines.length>0) ? 'pointer' : 'buttonDisabled'" (click)="redoSignature()"
                      disabled class="fas fa-redo-alt me-1"></i>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </ng-template>

    </div>
    <div class="modal-footer">
      <ng-container *ngIf="termsData.accepted.value; else displayFormButtons">
        <button (click)="modalRef.close()" type="button" class="btn btn-rounded">
          <span>Close</span>
        </button>
      </ng-container>
      <ng-template #displayFormButtons>
        <button *ngIf="enableCancelButton" (click)="modalRef.close()" type="button" class="btn btn-rounded">
          <span>Cancel</span>
        </button>
        <button (click)="submit()" [disabled]="termsForm.invalid||isSaving" type="button"
          class="btn btn-primary btn-rounded">
          <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"
              aria-hidden="true"></span>
            Saving</span>
          <span *ngIf="!isSaving">Agree</span>
        </button>
      </ng-template>

    </div>

  </ng-template>
</form>
