import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications = []
  private notificationsSubscription: Subscription;
  notificationsLoaded: boolean = false
  constructor(
    public modalRef: MdbModalRef<NotificationsComponent>,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.notificationsSubscription = this.notificationsService.notificationsChanged.subscribe((notifications: any[]) => {
      if (!(notifications[0]?.loading)) {
        this.notifications = notifications
        this.notificationsLoaded = true
      }
    });
  }

  orderNotifications(i, doc) {
    return doc.id;
  }

}
