import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss']
})
export class AcceptTermsComponent implements OnInit {
  // @ViewChild('signature')
  @Input() communityDetails: Community
  @Input() termsId: string = 'terms-of-service'
  @Input() enableCancelButton: boolean = true
  isSaving: boolean = false
  loading: boolean = true
  termsForm: FormGroup

  drawnLines: string[] = [];
  undoneLines: string[] = [];
  fullName: string
  termsData: any
  constructor(
    public modalRef: MdbModalRef<AcceptTermsComponent>,
    private functions: AngularFireFunctions,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getTermsData()
    this.termsForm = new FormGroup({
      clickwrap: new FormControl(false, mustBeTrue)
    });
  }

  ngAfterViewInit() {
    this.userService.userChanged.subscribe((user: any) => {
      this.fullName = user.full_name
    });
  }

  getTermsData() {
    const callable = this.functions.httpsCallable('getTerms');
    const obs = callable({
      id: this.termsId,
      ...(this.communityDetails && { communityId: this.communityDetails.id }),
    });
    obs.subscribe({
      next: (res) => {
        this.termsData = res
        if(this.termsData.requireSignature) {
          this.termsForm = new FormGroup({
            clickwrap: new FormControl(false, mustBeTrue),
            signatureProvided: new FormControl(false, mustBeTrue),
          });
          try { 
            // this.signaturePad.clear();
           } catch {}
        }
        this.loading = false
      },
      error: (err) => {
        console.warn(err)

      },
    })
  }

  drawComplete(event: MouseEvent | Touch) {
    // this.drawnLines.push(this.signaturePad.toDataURL());
    this.termsForm.controls.signatureProvided.setValue(true)
    this.undoneLines = [];
  }

  clearSignature() {
    this.termsForm.controls.signatureProvided.setValue(false)
    // this.signaturePad.clear();
    this.drawnLines = [];
    this.undoneLines = [];
  }

  undoSignature() {
    if (this.drawnLines.length > 0) {
      this.undoneLines.push(this.drawnLines.pop());
      this.redrawLines();
    }
  }

  redoSignature() {
    if (this.undoneLines.length > 0) {
      this.termsForm.controls.signatureProvided.setValue(true)
      this.drawnLines.push(this.undoneLines.pop());
      this.redrawLines();
    }
  }

  private redrawLines() {
    // this.signaturePad.clear();
    if (this.drawnLines.length > 0) {
      for (const line of this.drawnLines) {
        // this.signaturePad.fromDataURL(line);
      }
      this.termsForm.controls.signatureProvided.setValue(true)
    } else {
      this.termsForm.controls.signatureProvided.setValue(false)
    }
  }

  submit() {
    const clickwrapValue = this.termsForm.controls.clickwrap.value
    let signature
    if(this.termsData.requireSignature) { 
      // signature = this.signaturePad.toDataURL()
     }
    this.isSaving = true
    const callable = this.functions.httpsCallable('acceptTerms');
    const obs = callable({
      id: this.termsData.id,
      accepted: clickwrapValue,
      ...(this.communityDetails && { communityId: this.communityDetails.id }),
      signature: signature
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created')
        this.isSaving = false
      },
      error: (err) => {
        this.isSaving = false
        console.warn(err)
      },
    })
  }

}

function mustBeTrue(control: any): { [key: string]: boolean } | null {
  if (control.value !== true) {
    return { 'mustBeTrue': true };
  }
  return null;
}
