import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-logo',
  templateUrl: './provider-logo.component.html',
  styleUrls: ['./provider-logo.component.scss']
})
export class ProviderLogoComponent implements OnInit {
  @Input() provider: string;
  providerName: string = ''
  providerIconMap: { [key: string]: string } = {
    'yahoo.com': 'fab fa-yahoo',
    'google.com': 'fab fa-google',
    'microsoft.com': 'fab fa-microsoft',
    'apple.com': 'fab fa-apple',
    'facebook.com': 'fab fa-facebook',
    'twitter.com': 'fab fa-twitter'
  };

  constructor() { }

  ngOnInit(): void {
    this.providerName = this.provider.split('.')[0]
  }

  getProviderIcon(provider: string): string {
    return this.providerIconMap[provider] || 'fas fa-globe';
  }
}
