import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-link-community',
  templateUrl: './link-community.component.html',
  styleUrls: ['./link-community.component.scss']
})
export class LinkCommunityComponent implements OnInit {

  constructor(
    public modalRef: MdbModalRef<LinkCommunityComponent>,
  ) { }

  ngOnInit(): void {
  }

}
