import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user;
  userChanged = new BehaviorSubject<any[]>([{ }]);

  constructor() { }

  async setUser(user: any) {
    this.user = user
    this.userChanged.next(this.user)
  }



}
