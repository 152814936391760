import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { DateTime, Settings } from 'luxon';
import { saveAs } from 'file-saver';
import { PaymentDetailsComponent } from '../payment-details/payment-details.component';

@Component({
  selector: 'app-payout-details',
  templateUrl: './payout-details.component.html',
  styleUrls: ['./payout-details.component.scss']
})
export class PayoutDetailsComponent implements OnInit {
  @Input() payoutDetails
  @Input() communityDetails
  paymentDetailsModalRef: MdbModalRef<PaymentDetailsComponent>;
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  loading: boolean = true
  savingExport: boolean = false
  searchText: string
  enhancedPayoutDetails
  payoutTransactions
  error

  constructor(
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<PayoutDetailsComponent>,
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.fetchEnhancedPayoutDetails()
  }


  fetchEnhancedPayoutDetails() {
    this.loading = true
    const callable = this.functions.httpsCallable('getEnhancedPayoutDetails');
    const obs = callable({
      communityId: this.communityDetails.id,
      id: this.payoutDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.payoutTransactions = res.data
        this.enhancedPayoutDetails = res.payout
        console.log(res)
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }


  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'status':
          let status = data[key]
          return status.toLowerCase().includes(searchTerm.toLowerCase());
        case 'id':
          let id = data[key]
          return id.toLowerCase().includes(searchTerm.toLowerCase());
        case 'type':
          let type = data[key]
          return type.toLowerCase().includes(searchTerm.toLowerCase());
        case 'source':
          let source = data[key]
          return source.toLowerCase().includes(searchTerm.toLowerCase());
        case 'description':
          let description = data[key] || 'CHARGE'
          return description.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async export() {
    this.savingExport = true
    const csvArray = [
      [
        "ID",
        "Type",
        "Status",
        "Created Date",
        "Available Date",
        "Gross",
        "Fees",
        "Net",
        "Total",
      ],
      ...this.payoutTransactions.map(item => [
        item.id,
        item.type,
        item.status,
        DateTime.fromSeconds(item.created).toFormat("MM/dd/yyyy"),
        DateTime.fromSeconds(item.available_on).toFormat("MM/dd/yyyy"),
        (item.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        (-item.fee / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        (item.net / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
        (item.currentTotal / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace(/,/g, ''),
      ])
    ].map(e => e.join(","))
      .join("\n");

    var blob = new Blob(['\ufeff' + csvArray.replace(/"/g, "")], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'Payout Transactions Export  - ' + DateTime.fromSeconds(this.payoutDetails.arrival_date).toFormat("MM/dd/yyyy") + ' - ' + this.communityDetails.name + '.csv');
    await this.delay(500);
    this.savingExport = false
  }


  details(item: any) {
    this.paymentDetailsModalRef = this.modalService.open(PaymentDetailsComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        paymentId: item.source,
        communityId: this.communityDetails.id
      }
    })
  }


}
