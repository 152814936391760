import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.scss']
})
export class SelectProductComponent implements OnInit {
  productForm: FormGroup
  @Input() itemDetails
  @Output() isValid = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  selectedPrice: any = {
    id: ''
  }
  products: any[] = []
  loading: boolean = true
  constructor(
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.getProducts()
    this.productForm = new FormGroup({
      product: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
    });
    this.onFormChanges()
  }

  getProducts() {
    const callable = this.functions.httpsCallable('getVideoProducts');
    const obs = callable({
      videoId: this.itemDetails.id
    });
    obs.subscribe({
      next: (res) => {
        console.log(res)

      this.products = res.sort((a, b) => a.price.unit_amount - b.price.unit_amount);
        this.loading = false
      },
      error: (err) => {
        console.warn(err)

      },
    })
  }

  onFormChanges() {
    this.productForm.valueChanges.subscribe(val => {
      this.isValid.emit(this.productForm.valid);
      this.output.emit((JSON.parse(JSON.stringify(this.productForm.value))));
    })
  }


  selectPrice(item) {
    this.selectedPrice = item.price
    this.productForm.get('product').setValue(item.product)
    this.productForm.get('price').setValue(item.price)
  }

}
