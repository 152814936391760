import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-card-reader-approved',
  templateUrl: './card-reader-approved.component.html',
  styleUrls: ['./card-reader-approved.component.scss'],
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
})
export class CardReaderApprovedComponent implements OnInit {
  @ViewChild('iconWrapper') iconWrapper: ElementRef;
  iconVisible = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          this.iconVisible = true;
        } else {
          this.iconVisible = false;
        }
      });
    });

    observer.observe(this.iconWrapper.nativeElement);
  }

}
