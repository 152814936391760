<!-- Header -->
<div class="p-5 text-center bg-image bg-gradient-animation" style="height: 350px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h3 class="display-3 mb-3">Connect and Explore!</h3>
        <h5 class="mb-3">Join a community to unlock access to their events and video catalog.</h5>
      </div>
    </div>
  </div>
</div>

<section class="">
  <div class="container text-center text-md-start mt-5" *ngIf="loggedInUser">
    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div>
        <div class="mt-1 mb-2">
          <ngx-glide #ngxGlide class="row" [perView]="perView" [bound]="true" focusAt="0" [rewind]="false"
            swipeThreshold="50" dragThreshold="100" [showArrows]="joinedCommunities.length>perView"
            [showBullets]="joinedCommunities.length>perView" [arrowLeftTemplate]="arrowLeftTemplate"
            [arrowRightTemplate]="arrowRightTemplate" listenToEvents="true" (swipeMoved)="swipeStarted()"
            (swipeEnded)="swipeEnded()">

            <ng-container *ngFor="let community of joinedCommunities; let i = index"
              class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-1">
              <div class="card mt-1 noselect" [ngClass]="(joinedCommunities.length>perView) ? 'mb-7' : 'mb-3'">
                <div *ngIf="showCarouselItems" [ngClass]="(!isDragging) ? '' : 'disabled-clicks'">
                  <div class="bg-image hover-overlay" mdbRipple rippleColor="light">
                    <img [src]="community.backgroundImg"
                      (error)="community.backgroundImg = './assets/tides/' + getRandomImageNumber() + '.svg'"
                      class="img-fluid communityBg" />
                    <a [routerLink]="'/communities/'+community.id">
                      <div class="mask" style="background-color: rgba(0, 0, 0, 0.25);"></div>
                    </a>
                  </div>
                  <div class="card-body">
                    <div class="text-wrap mb-2">
                      <h5 style="margin-bottom: 0px; padding-bottom: 0px;">{{community.name}}</h5>
                      <small class="text-wrap">{{community.communityType}}</small>
                    </div>
                    <p class="card-text">
                      <button [routerLink]="'/communities/'+community.id" type="button"
                        class="btn btn-primary btn-rounded text-capitalize me-2 mb-2">View</button>
                      <span *ngIf="community.admin===true" style="margin-left: 5px; bottom: 7px" mdbDropdown
                        class="dropdown dropend">
                        <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
                          class="dropdown-toggle hidden-arrow" role="button" mdbDropdownToggle aria-expanded="false">
                          <i style="height: 60px; margin-left: 16px; margin-top: 17px"
                            class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                        </span>
                        <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                          aria-labelledby="navbarDropdownMenuLink">
                          <li>
                            <!-- <a [routerLink]="'/communities/'+community.id" class="dropdown-item pointer">Posts</a>
                            <a [routerLink]="'/communities/'+community.id+'/events'"
                              class="dropdown-item pointer">Events</a>
                            <a [routerLink]="'/communities/'+community.id+'/videos'"
                              class="dropdown-item pointer">Videos</a>
                            <a [routerLink]="'/communities/'+community.id+'/manage/billing'"
                              class="dropdown-item pointer">Billing</a>
                            <a [routerLink]="'/communities/'+community.id+'/manage/members'"
                              class="dropdown-item pointer">Members</a> -->
                            <a [routerLink]="'/communities/'+community.id+'/manage/settings'"
                              class="dropdown-item pointer">Settings</a>
                          </li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </ngx-glide>
          <ng-template #arrowLeftTemplate>
            <div *ngIf="showCarouselItems" class="custom-arrow custom-arrow-left">
              <div class="btn btn-rounded btn-primary">
                <i class="fas fa-chevron-left"></i>
              </div>
            </div>
          </ng-template>
          <ng-template #arrowRightTemplate>
            <div *ngIf="showCarouselItems" class="custom-arrow custom-arrow-right">
              <div class="btn btn-rounded btn-primary">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="!showCarouselItems">
          <app-loading></app-loading>
        </div>
      </div>
    </div>
  </div>

  <div class="container text-center text-md-start mb-8">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div>
        <div class="row">
          <div class="col-md-8 col-lg-8 col-xl-8">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Search and Join</h5>
                <p class="card-text">Find your school's fine arts group and request access.
                </p>
                <app-search engine="communities" (output)="openLink($event)"></app-search>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-body">
                <ng-container *ngIf="environmentName==='production'; else dev">
                  <app-work-in-progress></app-work-in-progress>
                </ng-container>
                <ng-template #dev>
                  <h5 class="card-title">Add your community</h5>
                  <p class="card-text">Some text will go here eventually, and link to the learn more page.</p>
                  <button routerLink="/learn-more" type="button"
                    class="btn btn-primary text-capitalize btn-rounded">Learn More</button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>
