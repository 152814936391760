import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit {
  postForm: FormGroup
  @Input() communityDetails
  savingPost: boolean = false
  editorStyle = {
    height: '300px'
  };
  config = {
    toolbar: [
      ['bold', 'italic', 'underline',],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ]
  };
  constructor(
    public modalRef: MdbModalRef<CreatePostComponent>,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.postForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      message: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
    });
  }

  submit() {
    const name = this.postForm.controls.name.value
    const message = this.postForm.controls.message.value
    this.savingPost = true
    const callable = this.functions.httpsCallable('addCommunityPost');
    const obs = callable({
      communityId: this.communityDetails.id,
      name: name,
      message: message
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created')
        this.savingPost = false
      },
      error: (err) => {
        console.warn(err)
      },
    })
  }

}
