import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DateTime, Settings } from 'luxon';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { CreatePostComponent } from '../../modals/create-post/create-post.component';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { EditPostComponent } from '../../modals/edit-post/edit-post.component';

@Component({
  selector: 'app-community-member-home',
  templateUrl: './member-home.component.html',
  styleUrls: ['./member-home.component.scss'],
  animations: [fadeInEnterAnimation({duration: 200}),],
})
export class CommunityMemberHomeComponent implements OnInit {
  currentView: string = 'blogList'
  postDetails: any
  modalRef: MdbModalRef<CreatePostComponent> | null = null;
  editPostModalRef: MdbModalRef<EditPostComponent> | null = null;
  @Input() id
  @Input() initialLoad
  @Input() communityDetails
  blogPosts: any[] = []
  loading: boolean = true
  loadingPost: boolean = false
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 6 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");
  limit: number = 5
  error
  errorPost

  constructor(
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.getRecentPosts()
  }

  ngOnChanges() {
    this.route.queryParams
      .subscribe(params => {
        const postId = params.post
        if (postId) {
          this.getPost(postId)
          this.location.replaceState('/communities/' + this.communityDetails.id + '/welcome?post=' + postId)
        }
      })
  }

  getRecentPosts() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityPosts');
    const obs = callable({
      communityId: this.communityDetails.id,
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000),
      limit: this.limit
    });
    obs.subscribe({
      next: (res) => {
        this.blogPosts = res.data
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  getPost(postId) {
    this.currentView = 'postView'
    this.loadingPost = true
    const callable = this.functions.httpsCallable('getCommunityPost');
    const obs = callable({
      communityId: this.communityDetails.id,
      id: postId
    });
    obs.subscribe({
      next: (res) => {
        this.postDetails = res
        this.loadingPost = false
        this.location.replaceState('/communities/' + this.communityDetails.id + '/welcome?post=' + postId)
      },
      error: (err) => {
        console.warn(err)
        this.errorPost = err.message
      },
    })
  }

  createPost() {
    this.modalRef = this.modalService.open(CreatePostComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.getRecentPosts()
      }
    })
  }

  editPost() {
    this.editPostModalRef = this.modalService.open(EditPostComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        postDetails: this.postDetails
      }
    })
    this.editPostModalRef.onClose.subscribe((data: any) => {
      if (data) {
      }
    })
  }

  viewDetails(item: any) {
    this.currentView = 'postView'
    this.postDetails = item
    this.errorPost = null
    this.loadingPost = false
    this.location.replaceState('/communities/' + this.communityDetails.id + '/welcome?post=' + item.id)
  }

  returnToList() {
    this.currentView = 'blogList'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/welcome')
  }

}
