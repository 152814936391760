<ng-container *ngIf="loading; else notloading">
  <app-loading></app-loading>
</ng-container>
<ng-template #notloading>
<!-- add input for email -->
<form [formGroup]="additionalDetailsForm">
  <div class="input-group mt-2">
    <div class="input-container">
      <mdb-form-control>
        <input mdbInput [mdbValidate] type="text" formControlName="email" id="email"
          class="form-control form-control-lg" [validateSuccess]="false" placeholder="Email for Receipt" maxLength="75" />
        <label mdbLabel class="form-label" for="email">Email for Receipt</label>
        <div class="form-helper">
          <div *ngIf="additionalDetailsForm.controls.email.errors?.required" class="form-counter">Email is required</div>
          <div *ngIf="additionalDetailsForm.controls.email.errors?.email" class="form-counter">Please enter a valid email address</div>
        </div>
      </mdb-form-control>
    </div>
    <span class="input-group-text border-0"><app-validation-icon
        [inputValid]="additionalDetailsForm.controls.email.invalid"></app-validation-icon>
      <i class="far fa-question-circle" mdbTooltip="This is the email that we'll send a receipt to once the purchase is complete." [html]="true" placement="right"></i></span>
  </div>
  <br class="noselect">

<!-- <div class="input-group mt-2">
  <div #group class="input-container">
    <mdb-form-control>
      <ng-select  [virtualScroll]="false"  mdbInput formControlName="communities"
        (open)="displaySelectDropdown=true" (close)="displaySelectDropdown=false" [items]="communities" [multiple]="true"
        placeholder="Communities" bindLabel="name" bindValue="id" appearance="outline" [closeOnSelect]="true"
        [clearable]="true" [searchable]="true" notFoundText="No communities found" autocomplete="off" required>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div [ngOptionHighlight]="search" class="">
            <div class="">{{item.name}}</div>
          </div>
        </ng-template>
      </ng-select>
    </mdb-form-control>
  </div>
  <span class="input-group-text border-0"><app-validation-icon  [inputValid]="additionalDetailsForm.controls.communities.invalid"></app-validation-icon>
  <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
</div> -->
<!-- <div *ngIf="displaySelectDropdown" [style.height]="calculateHeight()"></div> -->

</form>
<!-- add dropdown for schools -->

</ng-template>
