import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-icon',
  templateUrl: './validation-icon.component.html',
  styleUrls: ['./validation-icon.component.scss']
})
export class ValidationIconComponent implements OnInit {
  @Input() displayEmpty: boolean = false
  @Input() inputValid: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

}
