import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { MdbTabChange } from 'mdb-angular-ui-kit/tabs/tabs.component';

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss']
})
export class EditVideoComponent implements OnInit {
  @ViewChild(MdbTabsComponent) tabs: MdbTabsComponent
  @Input() section: string
  @Input() itemDetails
  @Input() communityDetails
  firstTabChange: boolean = false
  constructor(
    public modalRef: MdbModalRef<EditVideoComponent>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.setActiveSection()
  }

  async setActiveSection() {
    if (this.tabs) {
      switch (this.section) {
        case 'details':
          this.tabs.setActiveTab(0)
          break;
        case 'pricing':
          this.tabs.setActiveTab(1)
          break;
        case 'chapters':
          this.tabs.setActiveTab(2)
          break;
      }
    } else {
      // Keep retrying until tabs available
      setTimeout(() => {
        this.setActiveSection()
      }, 10);
    }

  }

}
