<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Join Community</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<ng-container *ngIf="loadingResult; else provideCode">
  <div class="modal-body">
    <ng-container *ngIf="!error; else displayError">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </div>
</ng-container>
<ng-template #provideCode>
  <div class="modal-body">
    <form [formGroup]="codeForm">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="text" formControlName="code" id="code"
              class="form-control form-control-lg" [validateSuccess]="false" placeholder="Code" maxLength="30"/>
            <label mdbLabel class="form-label" for="code">Enrollment Code</label>
            <div class="form-helper">
              <div class="form-counter">{{ codeForm.controls.code.value.length }} / 30</div>
              <div *ngIf="codeForm.controls.code.value.length < 5" class="form-counter">Minimum 5 characters required
              </div>
              <div *ngIf="codeForm.controls.code.value.length === 30" class="form-counter">Maximum 30 characters allowed
              </div>
            </div>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon [inputValid]="codeForm.controls.code.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect">
      <br *ngIf="codeForm.controls.code.value.length < 5 || codeForm.controls.code.value.length === 30"
        class="noselect">
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="submit()" [disabled]="codeForm.invalid" type="button" class="btn btn-primary btn-rounded">
      <span>Submit</span>
    </button>
  </div>
</ng-template>
