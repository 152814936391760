<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{userDetails.displayName}}'s Groups</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!error; else displayError">
    <form [formGroup]="groupForm">

      <div *ngFor="let group of groups" class="form-check ">
        <input mdbCheckbox class="form-check-input" type="checkbox" [id]="group.id" [value]="group.id"
          [checked]="isSelected(group.id)" (change)="onGroupChange($event, group.id)" />
        <label class="form-check-label" [for]="group.id">
          <b>{{group.name}}</b><br>
          {{group.description}}
        </label>
      </div>
    </form>
  </ng-container>
  <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
  </ng-template>

</div>
<div class="modal-footer" *ngIf="!error">
  <button (click)="submit()" [disabled]="groupForm.invalid||isSaving" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      Updating</span>
    <span *ngIf="!isSaving">Update</span>
  </button>
</div>
