<div class="modal-header">
    <span>
        <i class="fas fa-arrow-left"></i><span style="text-align: start;">Test</span>

    </span>
</div>
<div class="modal-body">
    <div class="d-grid gap-2">
        <button (click)="restartApp()" class="btn btn-primary" type="button" mdbRipple>Restart App</button>
    </div>
</div>
