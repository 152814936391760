<ng-container *ngIf="!loading; else displayLoading">

  <div class="datatable">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="input-group">
          <input *ngIf="venues.length>0" type="text" class="form-control form-control-lg" placeholder="Filter communities"
            (keyup)="search($event)" #searchInput size=25 />
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-end align-items-center">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li (click)="linkCommunity()"><a class="dropdown-item pointer"><i class="fas fa-link me-2"></i>Link Community</a>
              </li>
              <li (click)="fetchLinkedCommunities()"><a class="dropdown-item pointer"><i
                    class="fas fa-sync-alt me-2"></i>Refresh</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="venues.length===0">
      <app-no-results message="No linked communities found"></app-no-results>
    </div>
    <div *ngIf="venues.length>0">
      <table responsive class="table align-middle mb-0 bg-white"
        class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
        mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true" [dataSource]="venues"
        [pagination]="pagination" [sort]="sort" [filterFn]="filterTable">

        <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
          <thead class="bg-light">
            <tr>
              <th [mdbTableSortHeader]="'name'">Community</th>
              <!-- <th></th> -->
            </tr>
          </thead>
        </ng-container>
        <ng-template #noResultsFound>
          <div class="mb-5"></div>
          <app-no-results [message]="'No venues found matching: ' + searchText"></app-no-results>
          <div class="mb-5"></div>
        </ng-template>
        <tbody>


          <tr *ngFor="let item of table.data; let i = index">
            <td>
              {{item | json}}
            </td>
          </tr>


        </tbody>
      </table>
      <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
    </div>
  </div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
