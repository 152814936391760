<ng-container *ngIf="chargeDetails; else noDetails">
  <div #pdfLoaded id="pdfLoaded">
    <div class="mt-3 ms-4 me-4 mb-4">
      <div>
        <div class="d-flex">
          <div class="flex-fill">
            <h5>Receipt from {{chargeDetails.calculated_statement_descriptor}}</h5>
            <div class="small">ID: {{chargeDetails.id.replace('ch_', '')}}</div>
          </div>
          <div class="flex-fill">
            <div class="text-end">
              <img class="" src="./assets/logo.png" height="30" alt="" loading="lazy" /><br>
              support&#64;eventodyssey.com
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 ms-4 me-4 mb-5">
      <div class="">
        <div class="d-flex">
          <div class="flex-fill">
            <div class="small">Amount Paid</div>
            <h5>{{(chargeDetails.amount/100) | currency}}</h5>
          </div>
          <div class="flex-fill">
            <div class="small">Date Paid</div>
            <h5>{{(chargeDetails.created*1000) | date:'medium'}}</h5>
          </div>
          <div class="flex-fill">
            <div class="small">Payment Method</div>
            <h5><app-card-detail [cardDetails]="chargeDetails.payment_method_details"></app-card-detail></h5>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-5 ms-4 me-4">
      <div class="">
        <div class="row border border-3">
          <div class="col-6">
            <div class="small">Product</div>
          </div>
          <div class="col-2">
            <div class="small">Price</div>

          </div>
          <div class="col-2">
            <div class="small">Tax</div>
          </div>
          <div class="col-2">
            <div class="small">Total</div>
          </div>
        </div>
        <div class="row border-start border-end border-bottom border-3">
          <div class="col-6">
            <h5 style="margin-bottom: 0px">{{priceDetails.name}}</h5>
            <div>{{priceDetails.description}}</div>
          </div>
          <div class="col-2">
            {{(priceDetails.amount/100) | currency}}
          </div>
          <div class="col-2">
            {{(priceDetails.tax/100) | currency}}
          </div>
          <div class="col-2">
            {{((priceDetails.tax + priceDetails.amount)/100) | currency}}
          </div>
        </div>
        <div class="row border-start border-end border-bottom border-3" style="height: 5px;">

        </div>
        <div class="row border border-3">
          <div class="col-6">
          </div>
          <div class="col-2">
          </div>
          <div class="col-2">
            Grand Total
          </div>
          <div class="col-2">
            {{((chargeDetails.amount)/100) | currency}}
          </div>
        </div>
      </div>
    </div>


    <div class="mt-5 ms-4 me-4">
      <div>
        <div class="d-flex">
          <div class="flex-fill">
            <h6>If you have any questions, contact us at <a
                href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a> or call at <a
                href="tel:+13174830027">+1 317-483-0027</a></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noDetails>
  No Details
</ng-template>
