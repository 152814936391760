import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-invoice-item',
  templateUrl: './video-invoice-item.component.html',
  styleUrls: ['./video-invoice-item.component.scss']
})
export class VideoInvoiceItemComponent implements OnInit {
  @Input() details

  constructor() { }

  ngOnInit(): void {
  }

}
