import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { CreateGroupComponent } from '../../communities/manage/members/groups/create-group/create-group.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-admin-permissions',
  templateUrl: './edit-admin-permissions.component.html',
  styleUrls: ['./edit-admin-permissions.component.scss']
})
export class EditAdminPermissionsComponent implements OnInit {
  createGroupModalRef: MdbModalRef<CreateGroupComponent> | null = null;
  groupForm: FormGroup
  isSaving: boolean = false
  @Input() userDetails
  @Input() communityDetails: Community
  groups: any[] = [
    { "id": "admin", "name": "Admin", "description": "global admin (contains all permissions)" },
    { "id": "delete_community", "name": "Delete Community", "description": "Gives the ability to permanently delete a community." },
    { "id": "transfer_community", "name": "Transfer Community", "description": "Allows transferring a community to another organization or merging with sub-communities." },
    { "id": "self_blog_management", "name": "Manage Own Blog Posts", "description": "Grants permission to post, edit, and delete blog posts written by oneself." },
    { "id": "others_blog_management", "name": "Manage Others' Blog Posts", "description": "Permits editing and deleting blog posts written by other members." },
    { "id": "event_management", "name": "Manage Events", "description": "Enables creating and editing community events." },
    { "id": "event_pricing_management", "name": "Manage Event/Ticket Pricing", "description": "Provides the ability to set and modify pricing for events and tickets." },
    { "id": "video_creation", "name": "Create Videos", "description": "Allows the user to create and upload videos to the community." },
    { "id": "video_pricing_management", "name": "Manage Video Pricing", "description": "Enables setting and modifying pricing for videos." },
    { "id": "view_transactions", "name": "View Transactions", "description": "Provides access to view all community financial transactions." },
    { "id": "refund_transactions", "name": "Refund Transactions", "description": "Allows the admin to process refunds for community transactions." },
    { "id": "view_members", "name": "View Members", "description": "Permits viewing a list of all community members." },
    { "id": "manage_member_requests", "name": "Manage Membership Requests", "description": "Grants permission to approve or deny requests to join the community." },
    { "id": "manage_member_groups", "name": "Manage Member Groups", "description": "Allows creation, editing, and management of member group structures within the community." },
    { "id": "invite_members", "name": "Invite Members", "description": "Grants the ability to send out invites for others to join the community." },
    { "id": "venue_management", "name": "Manage Venues", "description": "Provides the ability to create and edit venues for community events." },
    { "id": "admin_access_management", "name": "Manage Admin Access", "description": "Allows the user to grant or revoke administrative privileges to other members of the community." }
]
  error
  constructor(
    private functions: AngularFireFunctions,
    private router: Router,
    public modalRef: MdbModalRef<EditAdminPermissionsComponent>,
  ) { }

  ngOnInit(): void {
    this.groupForm = new FormGroup({
      groups: new FormControl(this.userDetails.permissions),
    });
  }

  isSelected(id: string): boolean {
    return this.groupForm.get('groups').value.includes(id);
  }

  onGroupChange(event: any, groupId: string): void {
    const groupsControl = this.groupForm.get('groups');
    if (event.target.checked) {
      groupsControl.value.push(groupId);
    } else {
      const index = groupsControl.value.indexOf(groupId);
      if (index > -1) {
        groupsControl.value.splice(index, 1);
      }
    }
  }


  submit() {
    const groups = this.groupForm.controls.groups.value
    this.isSaving = true
    const callable = this.functions.httpsCallable('updateCommunityAdminPermissions');
    const obs = callable({
      communityId: this.communityDetails.id,
      permissions: groups,
      id: this.userDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('updated')
        this.isSaving = false
      },
      error: (err) => {
        this.isSaving = false
        this.error = err.message
        console.warn(err)
      },
    })
  }

}
