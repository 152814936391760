<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">{{community.name}}</h4>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div>
        <!-- Content -->
        <ng-container *ngIf="!loading; else displaySkeleton">
          <div class="row mt-3 mb-3">
            <ngx-glide #ngxGlide class="row" [perView]="perView"
            [bound]="true"
            focusAt="0"
            [rewind]="false"
            swipeThreshold="50"
            dragThreshold="100"
            [showArrows]="tickets.length>perView"
            [showBullets]="tickets.length>perView"
            [arrowLeftTemplate]="arrowLeftTemplate"
            [arrowRightTemplate]="arrowRightTemplate"
            >

              <ng-container *ngFor="let ticket of tickets; let i = index" class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                <div class="card mt-3 mb-3 noselect">
                  <div class="card-body">
                    <div class="text-start">
                      <h6>{{event.venueName}}</h6>
                      <h4 class="mb-2">{{event.name}}</h4>
                    </div>
                    <div class="d-flex align-items-start mb-3">
                      <div class="col text-start">
                        <span>Date</span>
                        <h6>{{event.startTime | date:'mediumDate':'-0'}}</h6>
                      </div>
                      <div class="col text-end">
                        <span>Time</span>
                        <h6>{{event.startTime | date:'shortTime':'-0'}}</h6>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div class="d-flex align-items-start mb-3">
                      <div class="col text-start">
                        <span>Section</span>
                        <h5>{{ticket.section}}</h5>
                      </div>
                      <div class="col text-end">
                        <span>Row / Seat</span>
                        <h5>{{ticket.row}} / {{ticket.seat}}</h5>
                      </div>
                    </div>
                    <div class="text-center">
                      <ngx-qrcode-styling [config]="config" [data]="ticket.id + validatorCode"></ngx-qrcode-styling>
                    </div>
                    <div class="text-center">
                      <p>{{ticket.type}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ngx-glide>
            <ng-template #arrowLeftTemplate>
              <div class="custom-arrow custom-arrow-left">
                <div class="btn btn-rounded btn-primary">
                  <i class="fas fa-chevron-left"></i>
                </div>
              </div>
            </ng-template>
            <ng-template #arrowRightTemplate>
              <div class="custom-arrow custom-arrow-right">
                <div class="btn btn-rounded btn-primary">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </ng-template>
          </div>

          <div class="row mb-5">
            <ng-container class="row" *ngIf="!loadingWalletLink; else loadingWallet">
              <div class="text-center">
                <a class="me-3" (click)="addToGoogleWallet()" role="button"><img class="walletIcon mb-2"
                    [src]="'./assets/add-to-google-wallet.svg'"></a>
                <a *ngIf="isSafari" class="me-3" (click)="addToAppleWallet()" role="button"><img class="walletIcon mb-2"
                    [src]="'./assets/add-to-apple-wallet.svg'"></a>
                <a *ngIf="!isSafari&&isAppleDevice" class="me-3" (click)="addToAppleWalletUnsupported()"
                  role="button"><img class="walletIcon mb-2" [src]="'./assets/add-to-apple-wallet.svg'"></a>
              </div>
            </ng-container>
            <ng-template #loadingWallet>
              <div class="text-center">
                <div class="mb-2"></div>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading Wallet Link...</span>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
        <ng-template #displaySkeleton>
          <ng-container *ngIf="!error; else displayError">
            <div class="row">
              <app-loading></app-loading>
            </div>
          </ng-container>
          <ng-template #displayError>
            <app-https-error [error]="error"></app-https-error>
          </ng-template>
        </ng-template>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>
