import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { CreateGroupComponent } from '../../communities/manage/members/groups/create-group/create-group.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-user-groups',
  templateUrl: './edit-user-groups.component.html',
  styleUrls: ['./edit-user-groups.component.scss']
})
export class EditUserGroupsComponent implements OnInit {
  createGroupModalRef: MdbModalRef<CreateGroupComponent> | null = null;
  groupForm: FormGroup
  isSaving: boolean = false
  displaySelectDropdown: boolean = false
  loading: boolean = true
  @Input() userDetails
  @Input() communityDetails: Community
  groups: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<EditUserGroupsComponent>,
  ) { }

  ngOnInit(): void {
    let currentGroups = []
    this.userDetails.groups.forEach((group) => {
      currentGroups.push(group.id)
    })
    this.fetchGroups()
    this.groupForm = new FormGroup({
      groups: new FormControl(currentGroups),
    });
  }

  fetchGroups() {
    this.loading = true
    const callable = this.functions.httpsCallable('getGroups');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.groups = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  addGroup() {
    this.createGroupModalRef = this.modalService.open(CreateGroupComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.createGroupModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.fetchGroups()
      }
    })
  }

  submit() {
    const groups = this.groupForm.controls.groups.value
    this.isSaving = true
    const callable = this.functions.httpsCallable('updateCommunityMemberGroups');
    const obs = callable({
      communityId: this.communityDetails.id,
      groups: groups,
      id: this.userDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('updated')
        this.isSaving = false
      },
      error: (err) => {
        this.isSaving = false
        console.warn(err)
      },
    })
  }


}
