<ng-container *ngIf="loading; else notloading">
  <app-loading></app-loading>
</ng-container>
<ng-template #notloading>
  <div class="row">
    <div mdbRipple *ngFor="let item of products" (click)="selectPrice(item)" class="col-md-12 col-lg-6 col-xl-4 card" [ngClass]="(selectedPrice.id === item.price.id) ? 'border border-primary' : 'pointer'">
      <div class="card-body">
        <h5 class="card-title">{{item.product.name}}</h5>
        <p class="card-text">
          {{item.product.description}}<br>
          {{(item.price.unit_amount/100)| currency}}<br>
        </p>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="products.length===0">
    <app-notice content="No products found for this video. It's unavailable to purchase." type="danger"></app-notice>
  </div>
</ng-template>
