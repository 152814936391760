<div *ngIf="lastReaderFunctionResponse">
  <ng-container *ngIf="readerState; else noCurrentReaderState">
    <ng-container *ngIf="((lastReaderFunctionResponse)<(readerState.time)); else answerOnReader">
      <app-card-reader-decline *ngIf="readerState.action.status==='failed'"
        [content]="readerState.failure_message"></app-card-reader-decline>
      <app-card-reader-approved *ngIf="readerState.action.status==='succeeded'"></app-card-reader-approved>
    </ng-container>
    <ng-template #answerOnReader>
      <app-card-reader-wait></app-card-reader-wait>
    </ng-template>
  </ng-container>
  <ng-template #noCurrentReaderState>
    <app-card-reader-wait></app-card-reader-wait>
  </ng-template>
</div>
