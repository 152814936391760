import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-video-pricing-calculator',
  templateUrl: './video-pricing-calculator.component.html',
  styleUrls: ['./video-pricing-calculator.component.scss']
})
export class VideoPricingCalculatorComponent implements OnInit {
  priceForm: FormGroup;
  paymentProcessingFees: number;
  platformFees: number;
  ticketsSold: number;
  grossSales: number;
  totalFees: number;
  totalRevenue: number;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this.priceForm = this.formBuilder.group({
      price: ['25.00', Validators.required],
      seats: ['150', Validators.required],
      events: ['4', Validators.required],
    });

    // Subscribe to form value changes
    this.priceForm.valueChanges.subscribe(val => {
      if (this.priceForm.valid) {
        this.calculateFees(val);
      }
    });
  }

  ngOnInit(): void {
    this.calculateFees({
      price: this.priceForm.controls.price.value,
      seats: this.priceForm.controls.seats.value,
      events: this.priceForm.controls.events.value,
    });
  }

  calculateFees(val: any) {
    this.ticketsSold = Math.round(val.seats * val.events);
    this.grossSales = val.price * this.ticketsSold;
    this.paymentProcessingFees = this.grossSales * 0.029 + this.ticketsSold * 0.30;


      this.platformFees = this.grossSales * 0.15 + this.ticketsSold * 0.50

    this.totalFees = this.paymentProcessingFees + this.platformFees;
    this.totalRevenue = this.grossSales - this.totalFees;
  }


}
