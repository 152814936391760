<mdb-tabs [vertical]="isVertical" [pills]="true" (activeTabChange)="onTabChange($event)" #tabs>
  <mdb-tab title="General">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-cog me-2"></i> General</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-edit-general-details [communityDetails]="communityDetails"></app-edit-general-details>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Ensembles" *ngIf="environmentName!=='production'&&communityDetails.communityType.includes('School')">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-theater-masks me-2"></i> Ensembles</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-ensembles [communityDetails]="communityDetails"></app-list-ensembles>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Students" *ngIf="environmentName!=='production'&&communityDetails.communityType.includes('School')">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-theater-masks me-2"></i> Students</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-students [communityDetails]="communityDetails"></app-list-students>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Videographers" *ngIf="communityDetails.communityType.includes('School')">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-video me-2"></i> Videographers</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-videographers [communityDetails]="communityDetails"></app-list-videographers>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Communities" *ngIf="communityDetails.communityType.includes('Videographer')">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-users me-2"></i> Communities</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-linked-communities [communityDetails]="communityDetails"></app-list-linked-communities>
    </ng-template>
  </mdb-tab>

  <mdb-tab title="Venues" *ngIf="environmentName!=='production'&&communityDetails.communityType.includes('School')">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-compass me-2"></i> Venues</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-venues [communityDetails]="communityDetails"></app-list-venues>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Access">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-shield-alt me-2"></i> Access</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-list-access [communityDetails]="communityDetails"></app-list-access>
    </ng-template>
  </mdb-tab>
</mdb-tabs>
