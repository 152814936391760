<ng-container *ngIf="!loading; else displaySkeleton">
  <div *ngIf="currentView==='blogList'" [@fadeInEnter]>
    <div style="margin-bottom: 20px">
      <div>
        <div style="text-align:right;">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li (click)="createPost()"><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Create Post</a>
              </li>
              <li (click)="getRecentPosts()"><a class="dropdown-item pointer"><i
                    class="fas fa-sync-alt me-2"></i>Refresh</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let item of blogPosts; let i = index" class="col-xs-12 col-md-6 col-lg-4 col-xl-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{item.title}}</h5>
            <p class="card-text" [innerHTML]="item.body | htmlTruncate:300"></p>
            <div class="card-text">
              <div class="d-flex align-items-center">
                <img referrerPolicy="no-referrer" [src]="item.editors[0].profilePic" alt=""
                  style="width: 40px; height: 40px;" class="rounded-circle" />
                <div class="ms-3">
                  <p class="lh-1 fw-bold mb-1">{{item.editors[0].name}}</p>
                  <p class="lh-1 text-muted mb-0">
                    {{(item.created*1000) | date:'mediumDate'}}&nbsp;({{(item.created*1000) | timeAgo}})
                  </p>
                </div>
              </div>
            </div>
            <br class="noselect">
            <p class="card-text">
              <button (click)="viewDetails(item)" type="button" class="btn btn-primary btn-rounded">Details</button>
              <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;" class="dropdown-toggle hidden-arrow tableDropdown text-center "
                  role="button" mdbDropdownToggle aria-expanded="false">
                  <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                    class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                </span>
                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <a  class="dropdown-item pointer">Edit Post</a>
                    <a  class="dropdown-item pointer">Delete Post</a>
                  </li>
                </ul>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="blogPosts.length===0" class="row">
      <app-no-results [message]="'No posts found for ' + communityDetails.name + '.'"></app-no-results>
    </div>
  </div>
  <div *ngIf="currentView==='postView'" [@fadeInEnter]>
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="d-flex align-items-center">
        <div (click)="returnToList()" class="pointer hoverUnderline"><i class="fas fa-arrow-left"></i> Back to recent posts</div>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-end align-items-center">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li (click)="editPost()"><a class="dropdown-item pointer"><i class="fas fa-edit me-2"></i>Edit Post</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!loadingPost; else displayPostLoading">
      <app-blog-detail [postDetails]="postDetails"></app-blog-detail>
    </ng-container>
    <ng-template #displayPostLoading>
      <ng-container *ngIf="!errorPost; else displayErrorPost">
        <app-loading></app-loading>
      </ng-container>
      <ng-template #displayErrorPost>
        <app-https-error [error]="errorPost"></app-https-error>
      </ng-template>
    </ng-template>
  </div>
</ng-container>
<ng-template #displaySkeleton>
  <ng-container *ngIf="!error; else displayError">
    <div class="row">
      <div *ngFor="let item of [].constructor(6); let i = index" class="col-xs-12 col-md-6 col-lg-4 col-xl-4">
        <app-list-item-loading></app-list-item-loading><br>
      </div>
    </div>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
