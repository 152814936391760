<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Create Event</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="eventForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50" />
          <label mdbLabel class="form-label" for="name">Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ eventForm.controls.name.value.length }} / 50</div>
            <div *ngIf="eventForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="eventForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="eventForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br *ngIf="eventForm.controls.name.value.length < 3 || eventForm.controls.name.value.length === 50"
      class="noselect">
    <div class="input-group mt-2">
      <div class="input-container">
        <!-- <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="description" id="description"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Description" />
          <label mdbLabel class="form-label" for="description">Description</label>
        </mdb-form-control> -->
        <mdb-form-control>
          <textarea mdbInput [mdbValidate] type="text" formControlName="description"
            id="description" type="text" class="form-control" placeholder="" aria-label=""
            aria-describedby="button-addon2" aria-describedby="characterCounter" maxlength="250"></textarea>
          <label mdbLabel class="form-label" for="description">Description</label>
          <div class="form-helper">
            <div class="form-counter">{{ eventForm.controls.description.value.length }} / 250</div>
            <div *ngIf="eventForm.controls.description.value.length < 5" class="form-counter">Minimum 5 characters
              required</div>
            <div *ngIf="eventForm.controls.description.value.length === 250" class="form-counter">Maximum 250 characters
              allowed</div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="eventForm.controls.description.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <br class="noselect">
    <br *ngIf="eventForm.controls.description.value.length < 5 || eventForm.controls.description.value.length === 250"
      class="noselect">

    <div class="row">
      <div class="col-md-6 col-lg-6 col-xl-6">

        <div class="card card border border-none shadow-0 mb-2">
          <ng-container *ngIf="timeDetails; else noTimeSelected">
            <div class="card-body">
              <dl class="row" class="mb-n1">
                <dt class="col-sm-12 ">Date</dt>
                <dd class="col-sm-12">{{timeDetails.date | date:'fullDate'}}</dd>
                <dt class="col-sm-12">Doors Open</dt>
                <dd class="col-sm-12">
                  {{timeDetails.doorsOpenTime}}
                </dd>
                <dt class="col-sm-12">Start Time</dt>
                <dd class="col-sm-12">
                  {{timeDetails.startTime}}
                </dd>
              </dl>
            </div>
          </ng-container>
          <ng-template #noTimeSelected>
            <div class="card-body">
              <div class="text-center">
                <i class="far fa-clock fa-4x mb-2"></i>

                <br>No Date Selected <span><span class="text-danger me-2">*</span>
                <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span></div>
            </div>
          </ng-template>
          <div class="card-footer bg-transparent border-gray">
              <ng-container *ngIf="timeDetails; else timeNotSet">
                <span (click)="editEventTimes()" class="hoverUnderline pointer">
                Edit Time/Date</span>
              </ng-container>
              <ng-template #timeNotSet>
                <span (click)="editEventTimes()" class="hoverUnderline pointer">
                  Select Date</span>
              </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 mb-2">
        <div class="card card border border-none shadow-0 mb-2">
          <ng-container *ngIf="locationDetails.id; else noLocationSelected">
            <div class="card-body">
              <dt class="col-sm-12">{{locationDetails.name}}</dt>
              <dd class="col-sm-12">
                {{locationDetails.address}}
              </dd>
              <dt class="col-sm-12"><span class="text-capitalize">{{locationDetails.seatmapDetails.seatType}}</span>
                Seating</dt>
              <dd class="col-sm-12">
                {{locationDetails.seatmapDetails.seats}} Tickets
              </dd>
              <div *ngIf="locationDetails.notes">
                <dt class="col-sm-12">Notes</dt>
                <dd class="col-sm-12">
                  {{locationDetails.notes}}
                </dd>
              </div>
            </div>
            <app-view-map height="400px" width="100%" [zoom]="15" [mapStyles]="mapStyles" [lat]="lat" [lng]="lng">
            </app-view-map>
          </ng-container>
          <ng-template #noLocationSelected>
            <div class="card-body">
              <div class="text-center">
                <i class="far fa-compass fa-4x mb-2"></i>

                <br>No Venue Selected <span><span class="text-danger me-2">*</span>
                <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span></div>
            </div>
          </ng-template>
          <div class="card-footer bg-transparent border-gray">
              <ng-container *ngIf="locationDetails.id; else locationNotSet">
                <span (click)="editEventLocation()" class="hoverUnderline pointer">
                Edit Venue</span>
              </ng-container>
              <ng-template #locationNotSet>
                <span (click)="editEventLocation()" class="hoverUnderline pointer">
                  Select Venue</span>
              </ng-template>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="submit()" [disabled]="eventForm.invalid||savingEvent" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="savingEvent"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Saving</span>
    <span *ngIf="!savingEvent">Save</span>
  </button>
</div>
