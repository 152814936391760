<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 350px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
            <h1 class="mb-3 px-3">Welcome aboard!</h1>
            <h4 class="mb-5 px-3 text-wrap">
              <span class="typewriter">{{ textToDisplay }}</span>
            </h4>
            <a class="btn btn-outline-light text-capitalize btn-rounded mb-2" (click)="router.navigateByUrl('communities')" mdbRipple
              style="z-index: 10" rippleColor="dark" role="button">Explore our communities</a>

      </div>
    </div>
  </div>
</div>
