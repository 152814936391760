import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { guestContact } from 'src/app/interfaces/guestContact';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { DateTime, Settings } from 'luxon';
import { MessageDetailsComponent } from '../inbox/message-details/message-details.component';
import { ContactComponent } from 'src/app/components/support/contact/contact.component';

@Component({
  selector: 'app-support-inbox',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportInboxComponent implements OnInit {
  @ViewChild('table') messagesTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  modalRef: MdbModalRef<ContactComponent> | null = null;
  supportModalRef: MdbModalRef<MessageDetailsComponent> | null = null;
  @Output() output = new EventEmitter<any>();
  @Output() guestOutput = new EventEmitter<any>();
  @Input() purchasing
  @Input() selectedUid
  @Input() communityDetails: Community
  @Input() stripeAccountDetails: any
  searchText: string
  uid: string
  guestContact: guestContact
  loading: boolean = true
  messages: any[] = []
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 2 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('month').toFormat("MM/dd/yyyy");
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.uid = user.uid
      this.fetchMessages()
    })
  }

  fetchMessages() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunitySupportInbox');
    const obs = callable({
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000),
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.messages = res.data
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.messagesTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'displayName':
          let displayName = data[key]
          return displayName.toLowerCase().includes(searchTerm.toLowerCase());
        case 'email':
          let email = data[key]
          return email.toLowerCase().includes(searchTerm.toLowerCase());
        case 'provider':
          let provider = data[key]
          return provider.toLowerCase().includes(searchTerm.toLowerCase());
        case 'groups':
          let groupNames = ''
          data[key].forEach((group) => {
            groupNames += group.name + ' '
          })
          return groupNames.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }




  details(item) {
    this.supportModalRef = this.modalService.open(MessageDetailsComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        inboxMessage: item,
        uid: this.uid
      }
    })
    this.modalRef.onClose.subscribe((data: guestContact) => {
      if(data) {
        this.guestContact = data
        this.output.emit(null)
        this.guestOutput.emit(this.guestContact)
      }
    })
  }


  contactSupport() {
    this.modalRef = this.modalService.open(ContactComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      data: {
        communityDetails: this.communityDetails
      },
      ignoreBackdropClick: true,
    })
  }



}
