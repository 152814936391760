<div class="datatable">
  <table class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;"   mdbTable mdbTableSort #table="mdbTable"
    #sort="mdbTableSort"  [responsive]="true" [dataSource]="chapters" [pagination]="pagination" [fixedHeader]="true"
    [filterFn]="filterTable">
    <thead class="datatable-header" >
      <tr>
        <th scope="col" class="searchHeader" >
          <div class="input-group">
            <input type="text" class="form-control form-control-lg" placeholder="Filter songs" (keyup)="search($event)"
              #searchInput size=999 />
          </div>
        </th>
      </tr>
    </thead>
    <ng-container *ngIf="!(pagination._total===0); else noResultsFound">
    <tbody  class="datatable-body" style="max-height: 600px; overflow: auto;">
      <ng-container *ngFor="let chapter of table.data">
        <tr scope="row" [ngStyle]="{'border-color': 'inherit'}" (click)="triggerSeekTo(chapter.start)"
          style="cursor: pointer !important" [ngClass]="(chapter.name === currentChapterName) ? 'active' : ''">
          <td>
            <ng-container>
              <h5 class='text-wrap'>{{chapter.name}}</h5>
              <ng-container *ngFor="let group of chapter.groups; index as i">
                <span>{{group.name}}<span *ngIf="((chapter.groups.length!==1)&&((i+1)!==chapter.groups.length))">,
                  </span></span>
              </ng-container><br>
              <div>{{(chapter.end - chapter.start) | elapsedTIme}}<span class="badge bg-light bg-outline text-dark" style="margin-left: 0.5em">{{chapter.start | timecode}}</span></div>
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
    </ng-container>
    <ng-template #noResultsFound>
      <div class="mb-5"></div>
      <app-no-results *ngIf="true" [message]="searchText ? 'No chapters found matching: ' + searchText : 'No chapters exist'"></app-no-results>
      <div class="mb-5"></div>
    </ng-template>

  </table>
  <mdb-table-pagination  #pagination [entries]="1000" rowsPerPageText="Results" style="display: none"></mdb-table-pagination>
</div>

