import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { PurchaseVideoComponent } from '../../modals/purchase-video/purchase-video.component';
import { DownloadComponent } from '../download/download.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-community-list-item',
  templateUrl: './community-list-item.component.html',
  styleUrls: ['./community-list-item.component.scss']
})
export class CommunityListItemComponent implements OnInit {
  modalRef: MdbModalRef<PurchaseVideoComponent> | null = null;
  downloadmodalRef: MdbModalRef<DownloadComponent> | null = null;

  @Input() itemDetails = {
    preOrder: false,
    access: false,
    name: '',
    description: '',
    communityDetails: {
      name: ''
    },
    length: '',
    id: '',
    gif: 'https://image.mux.com/S3klOSZ00YuWV02002lIPd9fU6C7EDlD1bQ6oB00c3yjCsA/animated.gif?start=30&end=40',
    thumbnail: 'https://image.mux.com/S3klOSZ00YuWV02002lIPd9fU6C7EDlD1bQ6oB00c3yjCsA/thumbnail.png?time=30'
  }
  constructor(
    private modalService: MdbModalService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  purchase() {
    if (this.itemDetails.access) {
      this.router.navigateByUrl('videos/watch?v=' + this.itemDetails.id)
    } else {
      this.modalRef = this.modalService.open(PurchaseVideoComponent, {
        modalClass: 'modal-dialog-scrollable modal-xl',
        ignoreBackdropClick: true,
        data: {
          itemDetails: this.itemDetails
        }
      })
    }

  }

  download() {
    console.log('lets download it')
    this.downloadmodalRef = this.modalService.open(DownloadComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        videoDetails: this.itemDetails
      }
    })
  }

}
