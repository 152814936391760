<section class="">
  <ng-container *ngIf="!loading; else displaySkeleton">
    <app-community-header [communityDetails]="communityDetails"></app-community-header>
    <div class="container-lg text-center text-md-start mt-3">
      <div class="row mt-1">
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <mdb-tabs (activeTabChange)="onTabChange($event)" #tabs>
            <mdb-tab title="Welcome">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-manage-home [communityDetails]="communityDetails" [isVertical]="isVertical"
                  [environmentName]="environmentName"></app-community-manage-home>
              </ng-template>
            </mdb-tab>
            <mdb-tab title="Billing">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-manage-sales [communityDetails]="communityDetails" [isVertical]="isVertical"
                [environmentName]="environmentName"></app-community-manage-sales>
              </ng-template>
            </mdb-tab>
            <mdb-tab title="Videos" *ngIf="communityDetails.communityType.includes('Videographer')">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-manage-videos [communityDetails]="communityDetails" [isVertical]="isVertical"
                  [environmentName]="environmentName"></app-community-manage-videos>
              </ng-template>
            </mdb-tab>
            <mdb-tab title="Members" *ngIf="communityDetails.communityType.includes('School')">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-manage-members [communityDetails]="communityDetails"
                  [isVertical]="isVertical"></app-community-manage-members>
              </ng-template>
            </mdb-tab>
            <!-- <mdb-tab title="Messages" *ngIf="environmentName!=='production'">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-manage-messages [communityDetails]="communityDetails"
                [isVertical]="isVertical"></app-community-manage-messages>
              </ng-template>
            </mdb-tab> -->
            <mdb-tab title="Settings">
              <ng-template mdbTabContent>
                <br class="noselect">
                <app-community-manage-settings [communityDetails]="communityDetails" [isVertical]="isVertical"
                  [environmentName]="environmentName"></app-community-manage-settings>
              </ng-template>
            </mdb-tab>
            <mdb-tab title="MemberArea" *ngIf="communityDetails.communityType.includes('School')">
              <ng-template mdbTabTitle>
                <span><i class="fas fa-lg fa-users me-2"></i> Member Area</span>
              </ng-template>
            </mdb-tab>
          </mdb-tabs>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #displaySkeleton>
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <ng-container *ngIf="!error; else displayError">
            <app-loading></app-loading>
          </ng-container>
          <ng-template #displayError>
            <app-https-error [error]="error"></app-https-error>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</section>
