<div class="row">
  <div class="col">


    <div class="card">
      <!-- <div class="card-header">
        <div class="h-100 d-flex flex-row justify-content-center align-items-center">
          <div class="align-self-start flex-fill">
            Account Details
          </div>
          <i class="fas fa-pen pointer"></i>
        </div>
      </div> -->
      <div class="card-body">
        <div class="mb-3">
          <div class="text-black font-weight-bold">Account Name</div>
          <div class="text-muted">{{communityDetails.name}}</div>
        </div>
        <div>
          <div class="text-black font-weight-bold">Type</div>
          <div class="text-muted">{{communityDetails.communityType}}</div>
        </div>
        <!-- <button (click)="editHeader()" type="button" class="btn btn-rounded btn-primary">Edit Header</button><br> -->
      </div>
    </div>


  </div>
  <div class="col">
    <div class="card">
      <div class="card-body">
        <app-eo-subscription-overview [communityDetails]="communityDetails"></app-eo-subscription-overview>
      </div>
    </div>
  </div>
</div>
<br class="noselect"><br class="noselect">
<ng-container *ngIf="loadingCommunityActionsCard; else loadingActionsCard;">
  <div class="row" *ngIf="hasDeletePermission||hasTransferPermission">
    <div class="col">
      <div class="card card border border-none shadow-0">
        <div class="card-body text-center">
          <ng-container *ngIf="communityDetails.parentCommunity">
            <p>
              {{communityDetails.name}} is managed by <a class="text-reset hoverUnderline pointer"
                [routerLink]="'/communities/'+(communityDetails.parentCommunity[(communityDetails.parentCommunity.length-1)].id)">{{communityDetails.parentCommunity[(communityDetails.parentCommunity.length-1)].name}}</a>
            </p>
          </ng-container>
          <a class="text-reset hoverUnderline pointer" *ngIf="hasTransferPermission">Transfer Ownership</a>
          <span *ngIf="hasTransferPermission&&hasDeletePermission">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a (click)="deleteCommunity()" class="text-danger hoverUnderline pointer" *ngIf="hasDeletePermission">Delete
            Community</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingActionsCard>
  <div style="width: 100%; height: 80px;" class="skeleton"></div>
</ng-template>
