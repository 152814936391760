<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit {{itemDetails.name || 'Video'}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <mdb-tabs #tabs>
    <mdb-tab  title="Details">
      <ng-template mdbTabContent>
        <br class="noselect">
        <app-manage-video-details [itemDetails]="itemDetails" [communityDetails]="communityDetails"></app-manage-video-details>
      </ng-template>
    </mdb-tab>
    <mdb-tab title="Pricing">
      <ng-template mdbTabContent>
        <br class="noselect">
        <app-manage-video-pricing [itemDetails]="itemDetails" [communityDetails]="communityDetails"></app-manage-video-pricing>
      </ng-template>
    </mdb-tab>
    <mdb-tab title="Chapters">
      <ng-template mdbTabContent>
        <br class="noselect">
        <app-manage-video-chapters [itemDetails]="itemDetails" [communityDetails]="communityDetails"></app-manage-video-chapters>
      </ng-template>
    </mdb-tab>
  </mdb-tabs>
</div>
