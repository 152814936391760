<h6>Event Tickets</h6>

<div class="card mb-4">
  <mdb-accordion [flush]="true">
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>How do I manage ticket inventory and availability?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Easily manage your ticket inventory and availability by choosing between festival seating or assigned seating with a linked venue map. When customers select their seats, we'll reserve them for 5 minutes to allow them time to complete their purchase. <br><br>
          Rest assured, once a seat is reserved or purchased, it cannot be selected by another customer.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>Are there additional fees for venue mapping?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Enjoy our complimentary venue mapping services, provided to all customers upon completing their account onboarding. Simply provide our team with an architectural plan of your venue, and we'll recreate it within Event Odyssey. <br><br>
          You can expect the process to be completed within 1-2 weeks after we receive the drawings.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>Can I issue complementary or free tickets?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Absolutely! With Event Odyssey, you have the flexibility to issue complementary tickets in two ways: <br>
          <ul class="list-unstyled">
            <li class="mb-1 ms-2"><i class="fas fa-chevron-right me-2"></i>Directly issue tickets by providing the recipient's email address.</li>
            <li class="mb-1 ms-2"><i class="fas fa-chevron-right me-2"></i>Create redemption codes that allow users to select their own seats.</li>
          </ul>
          It's important to note that when issuing complementary tickets, all fees are waived regardless of the method chosen.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>Can I restrict access or offer deals to groups of customers?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Our platform provides comprehensive tools that empower your community to manage its users effectively.<br>
          Whether you want to grant parents special access to purchase tickets early, provide discounts to students, or restrict purchasing exclusively to members within your community, Event Odyssey offers the necessary features to meet your needs.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>What if I need to cancel or reschedule an event?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          While canceling a show is not ideal, we understand that circumstances may require it. Event Odyssey offers flexible options in such cases. You can choose to:<br>
          <ul class="list-unstyled">
            <li class="mb-1 ms-2"><i class="fas fa-chevron-right me-2"></i>Refund all transactions</li>
            <li class="mb-1 ms-2"><i class="fas fa-chevron-right me-2"></i>Reschedule the event to another date/time while keeping the seat assignments intact</li>
          </ul>
          Whichever option you select, rest assured that we will promptly notify your audience members about the change.
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
