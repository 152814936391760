import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timecode'
})
export class TimecodePipe implements PipeTransform {

  transform(value: number): string {
    if(!value) { value = 0 }
    const hours = (Math.floor(value / 3600))
    const minutes = ('0' + Math.floor((value % 3600) / 60)).slice(-2);
    const seconds = ('0' + Math.floor(value % 60)).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  }

}
