<div class="modal-header">
  <h6 class="modal-title" id="exampleModalLabel">
    Notifications
    <span class="ms-1">
      <i class="fas fa-bell fa-lg"></i>
      <span *ngIf="notifications.length>0"
        class="badge rounded-pill badge-notification bg-danger">{{notifications.length}}</span>
    </span>
  </h6>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body" style="margin: 0px !important; padding: 0px !important" mdbScrollbar>
  <ng-container *ngIf="notificationsLoaded; else loading">
    <ng-container *ngIf="notifications.length===0; else displayNotifications">
      <div class="modal-body">
        <app-no-results message="No notifications found"></app-no-results>
      </div>
    </ng-container>
    <ng-template #displayNotifications>
      <div *ngFor="let notification of notifications; index as x; first as isFirst; trackBy: orderNotifications"
        [ngClass]="(x % 2 == 0) ? 'odd' : 'even'">
        <app-notification-item [notification]="notification"></app-notification-item>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div class="modal-body">
      <app-loading></app-loading>
    </div>
  </ng-template>
</div>
