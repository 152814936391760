import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-messages-list-item',
  templateUrl: './user-messages-list-item.component.html',
  styleUrls: ['./user-messages-list-item.component.scss']
})
export class UserMessagesListItemComponent implements OnInit {
  @Input() itemDetails
  constructor() { }

  
  ngOnInit(): void {
  }

}
