<div class="container-fluid full-height p-0">
<div class="row text-center">
  <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" (click)="setReaderInputs()">
    <div>Set Inputs</div>
  </div>
  <div mdbRipple class="col-4 d-flex justify-content-center align-items-center" style="background-color: gold;" (click)="setReaderDisplay()">
    <div>Set Display</div>
  </div>
  <div mdbRipple class="col-4 d-flex justify-content-center align-items-center">
    <div>Button 3</div>
  </div>
</div>
<div class="row text-center">
  <div mdbRipple class="col-4 d-flex justify-content-center align-items-center">
    <div>Button 1</div>
  </div>
  <div mdbRipple class="col-4 d-flex justify-content-center align-items-center">
    <div>Button 2</div>
  </div>
  <div mdbRipple class="col-4 d-flex justify-content-center align-items-center"  style="background-color: teal;">
    <div>Button 3</div>
  </div>
</div>
</div>
