<div class="container text-center">
  <h3>Validating Payment</h3>
  <h5>We are currently verifying your payment with our processor.</h5>
  <h6 class="mb-4">This process typically takes under 2 minutes, and once completed, you will be automatically redirected to your video.</h6>
  <div class="progress-bar" role="progressbar" [style.width]="progress + '%'" [attr.aria-valuenow]="progress"
  aria-valuemin="0" aria-valuemax="100">
  <span>{{timer}}<span *ngIf="progress>25"> seconds</span></span>
</div>
</div>

