import { Component, Input, OnInit, Inject, NgZone, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Community } from 'src/app/interfaces/community';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-community-manage-overview-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class CommunityManageOverviewChartComponent implements OnInit {
  @Input() chartData: any[] = []
  @Input() isLoadingOverviewData: boolean = true
  @Input() CommunityDetails: Community
  showChart: boolean = false;
  isLoadingChart: boolean = false;
  private chart: am5xy.XYChart;
  private root: am5.Root;

  constructor(
    @Inject(PLATFORM_ID) private platformId, private zone: NgZone,
    private cdRef: ChangeDetectorRef
  ) { }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit(): void {
    console.warn = () => {
      this.root = am5.Root.new("overviewchartdiv");
      this.root._logo.dispose();
      this.root.dateFormatter.set("dateFormat", "yyyy-MM-dd")
      this.root.numberFormatter.set("numberFormat", "#,###.00");
      this.root.setThemes([
        am5themes_Animated.new(this.root)
      ]);
      this.generateGraph(this.chartData)
    };
  }

  async ngOnChanges() {
    if (!this.isLoadingOverviewData && this.chartData.length) {
      this.generateGraph(this.chartData);
    } else {
      this.showChart = false
    }
  }

  generateGraph

  ngAfterViewInit() {
    this.generateGraph = async (data) => {
      this.browserOnly(async () => {
        if (this.chart) { await this.chart.dispose() }
        this.showChart = true;
        this.cdRef.detectChanges();
        this.root = am5.Root.new("overviewchartdiv");
        this.root._logo.dispose();
        this.root.dateFormatter.set("dateFormat", "MM/dd/yyyy")
        this.root.numberFormatter.set("numberFormat", "#,###.00");
        this.root.setThemes([
          am5themes_Animated.new(this.root)
        ]);
        let chart = this.root.container.children.push(
          am5xy.XYChart.new(this.root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: this.root.verticalLayout,
          })
        );
        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(this.root, {
            renderer: am5xy.AxisRendererY.new(this.root, {}),
            extraTooltipPrecision: 1,
          })
        );
        let xAxis = chart.xAxes.push(
          am5xy.DateAxis.new(this.root, {
            renderer: am5xy.AxisRendererX.new(this.root, {}),
            paddingTop: 10,
            baseInterval: {
              timeUnit: "day",
              count: 1
            }
          })
        );
        chart.set("cursor", am5xy.XYCursor.new(this.root, {
          behavior: "zoomXY",
          xAxis: xAxis
        }));

        // Set scrollbar
        let scrollbarX = am5.Scrollbar.new(this.root, {
          orientation: "horizontal"
        });
        chart.set("scrollbarX", scrollbarX);
        chart.bottomAxesContainer.children.push(scrollbarX)

        // Zoom out button styling
        chart.zoomOutButton.get("background").set("fill", am5.color("#1266f1"));
        chart.zoomOutButton.get('icon').setAll({
          stroke: am5.color("#ffffff"),
          strokeWidth: 2,
        });
        chart.zoomOutButton.get('background').states.create('hover', {}).setAll({
          // 10% shade of #1266f1
          fill: am5.color("#105cd9"),
          stroke: am5.color("#ffffff"),
        });
        chart.zoomOutButton.get('background').states.create('down', {}).setAll({
          // 20% shade of #1266f1
          fill: am5.color("#0e52c1"),
          stroke: am5.color("#ffffff"),
        });
        chart.zoomOutButton.get('icon').states.create('hover', {}).setAll({
          stroke: am5.color("#ffffff"),
        });
        // Data processing
        data.forEach(resource => {
          let series = null;
          const lineColor = am5.color(0x1266f1);
          const fillColor = am5.color(0x1266f1);
          series = chart.series.push(
            am5xy.LineSeries.new(this.root, {
              name: resource.series,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "net",
              valueXField: "date",
              valueYGrouped: "sum",
              locationX: 0,
              stacked: false,
              tooltip: am5.Tooltip.new(this.root, {}),
              connect: true,
              autoGapCount: 3.1,
              stroke: lineColor,
              fill: fillColor,
            })
          );
          series.fills.template.setAll({ visible: true, fillOpacity: 0.2 });
          series.strokes.template.setAll({ strokeWidth: 3 });
          series.get("tooltip").label.set("text", "Net Revenue\n[bold]${valueY.formatNumber()}[/]\n\nDate\n [bold]{valueX.formatDate()}[/]");
          series.data.processor = am5.DataProcessor.new(this.root, {
            numericFields: ["value"],
            dateFields: ["date"],
            dateFormat: "yyyy-MM-dd",
          });
          series.data.setAll(resource.data);
        });
        this.chart = chart;
        chart.appear(0);
      });
    };
  }



}
