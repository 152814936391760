<div style="width: 100%">
    <div class="d-flex">
        <div class="p-2">
        <div class="d-flex align-items-center">
            <ng-container *ngIf="itemDetails.profilepic; else noProfilePic">
                <img referrerPolicy="no-referrer" [src]="itemDetails.profilepic.raw" alt=""
                    style="width: 45px; height: 45px;" class="rounded-circle"
                    (error)="itemDetails.profilepic.raw = './assets/user-profile.png'" />
            </ng-container>
            <ng-template #noProfilePic>
                <img referrerPolicy="no-referrer" alt="" style="width: 45px; height: 45px;" class="rounded-circle"
                    [src]="'./assets/user-profile.png'" />
            </ng-template>

        </div>
        </div>
        <div class="p-2 flex-grow-1">
            <div>
                <h6 [innerHtml]="itemDetails.displayname.snippet" class="result-content"></h6>
                <p *ngIf="itemDetails.provider" class="text-muted mb-0"><app-provider-logo [provider]="itemDetails.provider.raw"></app-provider-logo></p>
            </div>
        </div>
        <div class="p-2 small text-end">
            {{(itemDetails.lastsignin.raw) | timeAgo}}<br>
            {{(itemDetails.lastsignin.raw) | date:'medium'}}
        </div>
      </div>
</div>
