<div class="container">
</div>
<div class="row">
  <div class="col-md-6 col-lg-6 col-xl-6">
    <form [formGroup]="priceForm">
      <div class="form-group">
        <h6 class="fw-bold">{{priceForm.controls.price.value | currency}}</h6>
        <div class="mb-2 text-primary small"> <i class="fas fa-dollar-sign me-2"></i><span>Ticket Price</span> </div>
        <mdb-range [min]="5" [max]="75" [step]="1" [formControlName]="'price'"></mdb-range>
      </div>

      <div class="form-group">
        <h6 class="fw-bold">{{priceForm.controls.seats.value}}</h6>
        <div class="mb-2 text-primary small"> <i class="fas fa-chair me-2"></i><span>Seats</span> </div>
        <!-- <label for="seats">Seats </label> -->
        <!-- <input type="number" id="seats" class="form-control" [[formControlName]="'seats'" ] required> -->
        <mdb-range [min]="10" [max]="1500" [step]="5" [formControlName]="'seats'"></mdb-range>

      </div>

      <div class="form-group">
        <h6 class="fw-bold">{{priceForm.controls.events.value}}</h6>
        <div class="mb-2 text-primary small"> <i class="far fa-calendar me-2"></i><span>Events per year</span> </div>
        <mdb-range [min]="1" [max]="10" [step]="1" [formControlName]="'events'"></mdb-range>
      </div>

      <div class="form-group">
        <h6 class="fw-bold">{{priceForm.controls.averageSold.value | percent}}</h6>
        <div class="mb-2 text-primary small"> <i class="fas fa-percentage me-2"></i><span>Average percentage sold</span> </div>
        <mdb-range [min]="0.05" [max]="1" [step]="0.05" [formControlName]="'averageSold'"></mdb-range>
      </div>
    </form>
  </div>
  <div class="col-md-6 col-lg-6 col-xl-6">
    <div class="card card-body mb-4" *ngIf="totalFees && totalRevenue">
      <h6>SALES</h6>
        <div class="row mb-4">
          <div class="col-md text-start">
            {{ticketsSold | number}} tickets sold
          </div>
          <div class="col-md text-end">
            <h6>{{grossSales | currency}}</h6>
          </div>
        </div>
      <h6>FEES</h6>
        <div class="row mb-2">
          <div class="col-md text-start">
            Platform Fees
          </div>
          <div class="col-md text-end">
            <h6>{{platformFees | currency}}</h6>
            {{(platformFees/ticketsSold) | currency}} per ticket sold
          </div>
        </div>
        <div class="row">
          <div class="col-md text-start">
            Payment Processing Fees <i class="far fa-question-circle" mdbTooltip="Real processing fees may be slightly lower when multiple tickets are purchased together." [html]="true" placement="right"></i>
          </div>
          <div class="col-md text-end">
            <h6>{{paymentProcessingFees | currency}}</h6>
            {{(paymentProcessingFees/ticketsSold) | currency}} per ticket sold
          </div>
        </div>
        <hr class="hr" />
      <h6>NET REVENUE</h6>
      <div class="row">
        <div class="col-md text-start">
          Net Revenue <i class="far fa-question-circle" mdbTooltip="Your organization might be obligated to collect sales tax affecting these results." [html]="true" placement="right"></i>
        </div>
        <div class="col-md text-end">
          <h6>{{totalRevenue | currency}}</h6>
          {{(totalRevenue/grossSales) | percent}} profit margin<br>
          {{(totalRevenue/ticketsSold) | currency}} per ticket sold
        </div>
      </div>


      <!-- <p class="mb-4">{{totalRevenue | currency}} (You keep {{(totalRevenue/grossSales) | percent}})</p> -->
      <!-- <div class="row">
        <div class="col-md-6 col-lg-6 col-xl-6">
          <h6>{{(totalRevenue/grossSales) | percent}}</h6>
          Profit Margin
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6">
          <h6>{{(totalRevenue/ticketsSold) | currency}}</h6>
          Per Ticket
        </div>
      </div> -->
    </div>
  </div>
</div>
