import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-video-list',
  templateUrl: './user-video-list.component.html',
  styleUrls: ['./user-video-list.component.scss']
})
export class UserVideoListComponent implements OnInit {
  @Input() lookupUid: string
  constructor() { }

  ngOnInit(): void {
  }

}
