import { Component, OnInit } from '@angular/core';
import { MdbNotificationRef } from 'mdb-angular-ui-kit/notification';
import { Howl } from 'howler';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
})

export class ToastComponent implements OnInit {
    public content: string = '';
    public title: string = '';
    public type: string = 'generic';
    public sound: string = '';
    public iconClass: string = 'fas fa-check';
    constructor(public notificationRef: MdbNotificationRef<ToastComponent>) {}
    private confirmationSound: Howl;

    ngOnInit() {
      if(this.sound) {
        this.playConfirmationSound(this.sound)
      }
    }

    playConfirmationSound(sound: string) {
      if (!this.confirmationSound) {
        this.confirmationSound = new Howl({
          src: [`assets/sounds/${sound}.mp3`],
        });
      }
      this.confirmationSound.play();
    }    

}