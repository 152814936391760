import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-pos-view',
  templateUrl: './mobile-view.component.html',
  styleUrls: ['./mobile-view.component.scss']
})
export class MobilePOSViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
