import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { Video } from 'src/app/interfaces/video';
import { Chapter } from 'src/app/interfaces/chapter';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-manage-video-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss']
})
export class ManageVideoChaptersComponent implements OnInit {
  @Input() itemDetails
  // Item Details
  chapterName: string = ''
  videoDetails: Video
  chapterDetails: Chapter[] = []
  currentChapter: Chapter
  nextChapter: Chapter
  loading: boolean = true
  videoPlayed: boolean = false
  error
  duration: number
  lastChapterEnd: number
  playerTime: number
  // Video Player
  poster = '';



  constructor(
    private route: ActivatedRoute,
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.getVideoDetails()
    })
  }

  getVideoDetails() {
    const callable = this.functions.httpsCallable('getVideoDetails');
    const obs = callable({
      id: this.itemDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.videoDetails = res
        this.chapterDetails = res.chapters
        this.generatePlayerMarkers()
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  updateChapterProgress(playerTime: number) {

    this.playerTime = playerTime
    let currentChapter; let nextChapter
    for (let i = 0; i < this.chapterDetails.length; i++) {
      const chapterDetail = this.chapterDetails[i]
      if (!nextChapter) {
        if (playerTime >= chapterDetail.start) {
          if (playerTime <= chapterDetail.end) {
            currentChapter = this.chapterDetails[i]
            nextChapter = this.chapterDetails[i + 1]
          }
        } else {
          nextChapter = chapterDetail
        }
      }
    }
    this.nextChapter = nextChapter
    this.currentChapter = currentChapter
  }

  generatePlayerMarkers() {
    let markerPoints = []
    this.chapterDetails.forEach((chapter: any) => {
      markerPoints.push({
        time: chapter.start,
        label: chapter.name
      })
      this.lastChapterEnd = chapter.end
    })
  }

  seekTo(seconds: number) {
    if (!this.videoPlayed) {
      this.videoPlayed = true
      setTimeout(() => {
        this.seekTo(seconds)
        this.updateChapterProgress(seconds)
      }, 500);
    }
  }


  outputNewChapter($event) {
    this.chapterDetails.push($event)
    this.generatePlayerMarkers()
    const callable = this.functions.httpsCallable('addChapterToVideo');
    const obs = callable({
      id: this.itemDetails.id, chapters: this.chapterDetails
    });
    obs.subscribe({
      next: (res) => {
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

}
