<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Header</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
  <div class="modal-body">
    <input type="file" (change)="fileChangeEvent($event)" />
    <image-cropper
    style="max-height: 450px"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="8 / 4"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
    ></image-cropper>
    <app-community-header [communityDetails]="communityDetails" [previewImage]="croppedImage"></app-community-header>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.close()" type="button" class="btn btn-grey btn-rounded"
      data-mdb-dismiss="modal">Close</button>
  </div>


