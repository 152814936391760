import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { Options } from 'ngx-qrcode-styling';
import { FormControl, FormGroup } from '@angular/forms';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';

@Component({
  selector: 'app-view-campaign-links',
  templateUrl: './view-campaign-links.component.html',
  styleUrls: ['./view-campaign-links.component.scss']
})
export class ViewCampaignLinksComponent implements OnInit {
  toastOptionsSuccess = environment.toastOptions.success as MdbNotificationConfig<{}>;
  linkForm: FormGroup;
  @Input() communityDetails: Community
  @Input() campaignData: any
  link = ''
  public config: Options = {
    width: 265,
    height: 265,
    margin: 2,
    dotsOptions: {
      color: "#4d4d4d",
      type: "extra-rounded"
    },
    shape: 'square',
    backgroundOptions: {
      color: "#ffffff00",
    },
    cornersSquareOptions: {
      color: '#000000'
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  constructor(
    public modalRef: MdbModalRef<ViewCampaignLinksComponent>,
    private toastService: MdbNotificationService,
  ) { }

  ngOnInit(): void {
    this.link = `https://${environment.domain}/communities?join=${this.campaignData.id}&accessCode=${this.campaignData.validationCode}`
    this.linkForm = new FormGroup({
      link: new FormControl(this.link),
    });
  }

  copyLink() {
    navigator.clipboard.writeText(this.link)
    this.successToast('Link copied to clipboard.')
  }

  successToast(message: string) {
    this.toastOptionsSuccess.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsSuccess)
  }

}
