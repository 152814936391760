import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-pos-controls',
  templateUrl: './pos-controls.component.html',
  styleUrls: ['./pos-controls.component.scss']
})
export class PosControlsComponent implements OnInit {
  purchasing: boolean = false
  cardReaderId: string = 'tmr_FhzTXA2DOmA77c'
  constructor(
    private functions: AngularFireFunctions,
  ) { }

  ngOnInit(): void {
  }

  setReaderDisplay() {
    console.log('set reader display')
    this.purchasing = true
    const callable = this.functions.httpsCallable('setReaderDisplay');
    const obs = callable({
      customerUid: 'test',
      guestContact: 'test',
      priceId: 'test',
      videoId: 'test',
      cardReaderId: this.cardReaderId
    });
    obs.subscribe({
      next: (res) => {
        this.purchasing = false
      },
      error: (err) => {
        this.purchasing = false
        console.warn(err)
      },
    })
  }

  setReaderInputs() {
    console.log('set reader inputs')
    this.purchasing = true
    const callable = this.functions.httpsCallable('setTerminalInputs');
    const obs = callable({
      cardReaderId: this.cardReaderId,
      stripeId: 'acct_1MMq2xIiGcU3SbwG'
    });
    obs.subscribe({
      next: (res) => {
        this.purchasing = false
      },
      error: (err) => {
        this.purchasing = false
        console.log(err)
        // console.warn(err)
      },
    })
  }

}
