<!-- Header -->
<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 350px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h3 class="display-3 mb-3">
          <ng-container *ngIf="loading; else displayCommunityName">
            Loading...
          </ng-container>
          <ng-template #displayCommunityName>
            {{communityDetails.name}}
          </ng-template>
        </h3>
      </div>
    </div>
  </div>
</div>
<section *ngIf="!loading">
  <div class="container text-center text-md-start mt-5">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <!-- Content -->
        <div class="row">
          <div class="col-md-8 col-lg-8 col-xl-8">
            <div class="card">

              <div class="card-body" [@fadeInEnter]>
                This is a {{communityDetails.communityType}} account associated with Event Odyssey.<br><br>
                Account Status:
                <span class="badge"
                  [ngClass]="{'badge-success': communityDetails.status, 'badge-danger': !communityDetails.status}">
                  {{ communityDetails.status ? 'Good Standing' : 'Inactive' }}
                </span><br>
                Payment Status:
                <span class="badge"
                  [ngClass]="{'badge-success': communityDetails.paymentActive, 'badge-danger': !communityDetails.paymentActive}">
                  {{ communityDetails.paymentActive ? 'Active' : 'Inactive' }}
                </span><br><br>
                {{communityDetails.sells}}
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Contact Information</h5>
                <p>If you need assistance, you can reach out to the {{communityDetails.communityType}} through the following channels:</p>
                <ul class="list-unstyled">
                  <li>
                    <button (click)="contactSupport()" type="button" class="btn btn-primary btn-rounded">Submit
                      Request</button>
                  </li><br>
                  <li>Email: <a
                      [href]="'mailto:${communityDetails.supportMail}'">{{communityDetails.supportMail}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>