<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{inboxMessage.subject}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body" #messageContainer>
  <ng-container *ngFor="let message of inboxMessage.messages">
    <div class="message-container" [ngClass]="{'sent d-flex align-items-end flex-column': message.uid === uid, 'received d-flex align-items-start flex-column': message.uid !== uid}">
      <div class="message-bubble">{{ message.body }}</div>
      <div class="message-sender" [ngClass]="{'text-end': message.uid === uid}">
        <img referrerPolicy="no-referrer" [src]="getParticipantProfilePic(inboxMessage.participants, message.uid)" alt="" style="width: 20px; height: 20px;" class="rounded-circle" />

        {{ message.uid === uid ? 'You' :  getParticipantName(inboxMessage.participants, message.uid) }}<br>
        <span class="text-muted">{{ (message.created*1000) | timeAgo}}</span>
      </div>
    </div>
  </ng-container>
  <div *ngIf="sendingMessage" class="text-center">
    <div class="ellipsis">Sending message</div>
  </div>
</div>
<div class="modal-footer">
  <form [formGroup]="messageForm" style="width: 100%;">
  <div class="input-group" style="width: 100% !important" >
    <div class="input-container">
    <mdb-form-control>
      <textarea mdbInput [mdbValidate] type="text" formControlName="message" id="message"  type="text" class="form-control" placeholder="" aria-label=""
      aria-describedby="button-addon2" (keyup.enter)="sendMessage()"></textarea>
    </mdb-form-control>
  </div>
    <button (click)="sendMessage()" [disabled]="messageForm.invalid||sendingMessage" class="btn btn-primary btn-rounded" type="button" id="button-addon2" mdbRipple>
      Send
    </button>
  </div>
  </form>
</div>
