<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Contact <span *ngIf="communityName">{{communityName}}</span><span *ngIf="!communityName">Support</span></h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="contactForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="email" id="email"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Email" />
          <label mdbLabel class="form-label" for="email">Email</label>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="contactForm.controls.email.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <mdb-select id="category" formControlName="category">
            <mdb-option value="billing">Billing / Order Issue</mdb-option>
            <mdb-option value="playback">Playback Support</mdb-option>
            <mdb-option value="other">Other</mdb-option>
          </mdb-select>
          <label mdbLabel class="form-label">Category</label>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="contactForm.controls.category.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <textarea mdbInput [mdbValidate] type="text" formControlName="details" id="details"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Details"></textarea>
          <label mdbLabel class="form-label" for="details">Details</label>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="contactForm.controls.details.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br>
    <button (click)="submit()" [disabled]="contactForm.invalid" type="button" class="btn btn-primary btn-rounded">Submit
    </button>
  </form>
</div>
