import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbAccordionComponent } from 'mdb-angular-ui-kit/accordion';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmActionComponent } from '../../modals/confirm-action/confirm-action.component';

@Component({
  selector: 'app-list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.scss']
})
export class ListCardsComponent implements OnInit {
  cardsForm: FormGroup
  @Output() isValid = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  @Output() nextStep = new EventEmitter<any>();
  @ViewChild(MdbAccordionComponent) cardsAccordion: MdbAccordionComponent
  toastOptionsSuccess = environment.toastOptions.success as MdbNotificationConfig<{}>;
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  selectedCard: any
  cards: any[] = []
  loading: boolean = true
  displaySelectDropdown: boolean = false
  isSelectCardRunning: boolean = false
  confirmActionModalRef: MdbModalRef<ConfirmActionComponent> | null = null;

  constructor(
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    private toastService: MdbNotificationService,
  ) { }

  ngOnInit(): void {
    this.cardsForm = new FormGroup({
      paymentMethod: new FormControl(null, Validators.compose([Validators.required])),
    });

    this.getSavedCards()
  }

  onFormChanges() {
    this.cardsForm.valueChanges.subscribe(val => {
      this.validateOutput()
    })
  }

  validateOutput() {
    this.isValid.emit(this.cardsForm.valid);
    let returnValue = this.cardsForm.value
    returnValue.paymentMethodId = returnValue.paymentMethod.id
    this.output.emit((JSON.parse(JSON.stringify(returnValue))));
  }

  getSavedCards(goToNextStep?: boolean) {
    this.loading = true
    const callable = this.functions.httpsCallable('getPaymentMethods');
    const obs = callable({});
    obs.subscribe({
      next: (res) => {
        this.loading = false
        if(res.data) { this.cards = res.data }
        if(this.cards.length>0) {
          this.cardsForm.controls.paymentMethod.setValue(this.cards[0])
          this.validateOutput()
          if(goToNextStep) {
            this.nextStep.emit(true);
          }
        }
        this.onFormChanges()
        return
      },
      error: (err) => {
        this.loading = false
        console.warn(err)
        return
      },
    })
  }


  selectCard() {
    // If already running, return early
    if (this.isSelectCardRunning) return;

    this.isSelectCardRunning = true;
    console.count('select card');
    let found = false;

    for (let i = 0; i < this.cardsAccordion.items.length; i++) {
      const item = this.cardsAccordion.items[i];
      if (item && (item['_collapsed'] === false)) {
          if (i < this.cards.length) {
            this.selectedCard = this.cards[i]
            this.cardsForm.get('paymentMethodId').setValue(this.selectedCard.id)
            this.cardsForm.get('paymentMethod').setValue(this.selectedCard)
            return
          } else {
            this.cardsForm.get('paymentMethodId').setValue('')
            this.cardsForm.get('paymentMethod').setValue(null)
          }

            found = true;
            break;
        }
    }
    this.isSelectCardRunning = false;
}



  deleteCard() {
    const paymentMethod = this.cardsForm.controls.paymentMethod.value
    let content = `<div class="text-center">Are you sure you want to delete the ${paymentMethod.card.brand} ending in ${paymentMethod.card.last4}?</div>`;
    this.confirmActionModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Delete Card',
        content: content,
        confirmButton: 'Delete Card',
        confirmButtonPlural: 'Deleting Card',
        type: 'deletePaymentMethod',
        miscData: {
          paymentMethodId: paymentMethod.id,
        }
      }
    })
    this.confirmActionModalRef.onClose.subscribe((message: any) => {
      if (message === 'removed') {
        this.getSavedCards()
      }
    });
  }



  errorToast(message: string) {
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

  successToast(message: string) {
    this.toastOptionsSuccess.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsSuccess)
  }

  async outputAddedCard($event) {
    this.getSavedCards(true)
  }

  calculateHeight(): string {
    const baseHeight = 230;
    const minimumHeight = 60;
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    return Math.max(minimumHeight, heights[this.cards.length] || baseHeight) + 'px';
  }


}
