<ul id="container" mdbScrollbar class="sidenav-menu mb-4 noselect">
  <mdb-sidenav-item>
    <a class="sidenav-link" routerLink="/home" (click)="toggle()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>
      <i class="fas fa-home fa-fw me-3"></i><span>Home</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item *ngIf="globalAdmin">
    <a class="sidenav-link" routerLink="/admin" (click)="toggle()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>
      <i class="fas fa-id-card-alt fa-fw me-3"></i><span>Admin Area</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item *ngIf="userProfile">
    <a class="sidenav-link" routerLink="/videos" (click)="toggle()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>
      <i class="fas fa-video fa-fw me-3"></i><span>My Videos</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item *ngIf="userProfile&&environmentName!=='production'">
    <a class="sidenav-link" routerLink="/tickets" (click)="toggle()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>
      <i class="fas fa-ticket-alt fa-fw me-3"></i><span>My Tickets</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item>
    <a class="sidenav-link" routerLink="/communities" (click)="toggle()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>
      <i class="fas fa-users fa-fw me-3"></i><span>Search Communities</span>
    </a>
  </mdb-sidenav-item>
  <span *ngIf="userProfile&&(joinedCommunities.length!==0||joinedCommunitiesLoading)" class="sidenav-subheading text-muted">My Communities</span>
  <div *ngIf="userProfile&&(joinedCommunitiesLoading)" style="width: 85%; height: 75px; margin-left: 10%" class="skeleton"></div>
  <mdb-sidenav-item *ngFor="let community of joinedCommunities">
    <a class="sidenav-link text-wrap " [class.active]="rla.isActive" >
      <i class="fas fa-users fa-fw me-3"></i><span>{{community.name | htmlTruncate:30}}</span>
    </a>
    <ul class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive" #rla="routerLinkActive"  routerLinkActive>
      <ng-container *ngIf="community.communityType.includes('School'); else VideographerCommunity">
        <li class="sidenav-item" (click)="toggle()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/communities/'+community.id+'/videos'" mdbWavesEffect>
          <a class="sidenav-link">Videos</a>
        </li>
        <li *ngIf="userProfile&&environmentName!=='production'" class="sidenav-item" (click)="toggle()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/communities/'+community.id+'/events'" mdbWavesEffect>
          <a class="sidenav-link">Events</a>
        </li>
        <mdb-sidenav-item *ngIf="community.admin===true">
          <a class="sidenav-link">
            <span>Manage</span>
          </a>
          <ul class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive" #rla="routerLinkActive" routerLinkActive>
            <li class="sidenav-item" (click)="toggle()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/communities/'+community.id+'/manage/welcome'" mdbWavesEffect>
              <a class="sidenav-link">Welcome</a>
            </li>
            <mdb-sidenav-item *ngIf="community.communityType.includes('School')">
              <a class="sidenav-link ms-3">
                <span>Members</span>
              </a>
              <ul class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive" #rla="routerLinkActive" routerLinkActive>
                <li class="sidenav-item" (click)="toggle()">
                  <a class="sidenav-link ms-4"  [routerLink]="'/communities/'+community.id+'/manage/members'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Active</a>
                </li>
                <li class="sidenav-item" (click)="toggle()">
                  <a class="sidenav-link ms-4"  [routerLink]="'/communities/'+community.id+'/manage/members/pending'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Pending</a>
                </li>
                <li class="sidenav-item" (click)="toggle()">
                  <a class="sidenav-link ms-4"  [routerLink]="'/communities/'+community.id+'/manage/members/groups'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Groups</a>
                </li>
                <li class="sidenav-item" (click)="toggle()">
                  <a class="sidenav-link ms-4"  [routerLink]="'/communities/'+community.id+'/manage/members/invite-campaigns'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Invite Campaigns</a>
                </li>
              </ul>
            </mdb-sidenav-item>
            <mdb-sidenav-item>
              <a class="sidenav-link ms-3">
                <span>Settings</span>
              </a>
              <ul class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive" #rla="routerLinkActive" routerLinkActive>
                <li class="sidenav-item" (click)="toggle()">
                  <a class="sidenav-link ms-4"  [routerLink]="'/communities/'+community.id+'/manage/settings'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>General</a>
                </li>
                <li class="sidenav-item" (click)="toggle()">
                  <a class="sidenav-link ms-4"  [routerLink]="'/communities/'+community.id+'/manage/settings/access'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Access</a>
                </li>
                <li *ngIf="community.communityType.includes('School')" class="sidenav-item" (click)="toggle()">
                  <a class="sidenav-link ms-4"  [routerLink]="'/communities/'+community.id+'/manage/settings/venues'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Venues</a>
                </li>
              </ul>
            </mdb-sidenav-item>
          </ul>
        </mdb-sidenav-item>
      </ng-container>
      <ng-template #VideographerCommunity>
        <li class="sidenav-item" (click)="toggle()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/communities/'+community.id+'/manage/welcome'" mdbWavesEffect>
          <a class="sidenav-link">Welcome</a>
        </li>
        <mdb-sidenav-item *ngIf="community.communityType.includes('Videographer')">
          <a class="sidenav-link">
            <span>Billing</span>
          </a>
          <ul class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive" #rla="routerLinkActive" routerLinkActive>
            <li class="sidenav-item" (click)="toggle()">
              <a class="sidenav-link ms-3"  [routerLink]="'/communities/'+community.id+'/manage/billing/'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Overview</a>
            </li>
            <li class="sidenav-item" (click)="toggle()">
              <a class="sidenav-link ms-3"  [routerLink]="'/communities/'+community.id+'/manage/billing/payouts'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Payouts</a>
            </li>
            <li class="sidenav-item" (click)="toggle()">
              <a class="sidenav-link ms-3"  [routerLink]="'/communities/'+community.id+'/manage/billing/transactions'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Transactions</a>
            </li>
            <li class="sidenav-item" (click)="toggle()">
              <a class="sidenav-link ms-3"  [routerLink]="'/communities/'+community.id+'/manage/billing/card-readers'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Card Readers</a>
            </li>
          </ul>
        </mdb-sidenav-item>
        <li class="sidenav-item" (click)="toggle()" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/communities/'+community.id+'/manage/videos'" mdbWavesEffect>
          <a class="sidenav-link">Videos</a>
        </li>
        <mdb-sidenav-item>
          <a class="sidenav-link">
            <span>Settings</span>
          </a>
          <ul class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive" #rla="routerLinkActive" routerLinkActive>
            <li class="sidenav-item" (click)="toggle()">
              <a class="sidenav-link ms-3"  [routerLink]="'/communities/'+community.id+'/manage/settings'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>General</a>
            </li>
            <li class="sidenav-item" (click)="toggle()">
              <a class="sidenav-link ms-3"  [routerLink]="'/communities/'+community.id+'/manage/settings/communities'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Communities</a>
            </li>
            <li class="sidenav-item" (click)="toggle()">
              <a class="sidenav-link ms-3"  [routerLink]="'/communities/'+community.id+'/manage/settings/access'" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>Access</a>
            </li>
          </ul>
        </mdb-sidenav-item>
      </ng-template>
    </ul>
  </mdb-sidenav-item>
  <span *ngIf="userProfile" class="sidenav-subheading text-muted">Important Links</span>
  <mdb-sidenav-item>
    <a class="sidenav-link" routerLink="/support" (click)="toggle()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>
      <i class="fas fa-question-circle fa-fw me-3"></i><span>Support</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item *ngIf="userProfile">
    <a class="sidenav-link" routerLink="/purchases" (click)="toggle()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" mdbWavesEffect>
      <i class="fas fa-dollar-sign fa-fw me-3"></i><span>My Purchases</span>
    </a>
  </mdb-sidenav-item>
  <mdb-sidenav-item>
    <a class="sidenav-link" [class.active]="rla.isActive">
      <i class="fas fa-balance-scale fa-fw me-3"></i><span>Legal</span>
    </a>

    <ul class="sidenav-collapse" mdbCollapse [collapsed]="!rla.isActive" #rla="routerLinkActive" routerLinkActive>
      <li class="sidenav-item" (click)="toggle()">
        <a class="sidenav-link ms-3"  [routerLink]="'/privacy-policy'" mdbWavesEffect>Privacy Policy</a>
      </li>
      <li class="sidenav-item" (click)="toggle()">
        <a class="sidenav-link ms-3"  [routerLink]="'/terms-of-service'" mdbWavesEffect>Terms of Service</a>
      </li>
    </ul>
  </mdb-sidenav-item>
</ul>
<div class="mb-3 text-center">
  <small><b>Event Odyssey</b><br>Version {{version}}</small>
</div>
