import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  @Input() itemDetails
  @Input() productDetails
  billingForm: FormGroup;
  displaySelectDropdown: boolean = false
  editingProduct: boolean = false
  minDate;
  loadingGroups: boolean = true
  groups: any[] = []
  error
  constructor(
    public modalRef: MdbModalRef<EditProductComponent>,
    private functions: AngularFireFunctions,
  ) { }

  ngOnInit(): void {
    this.fetchGroups()
    const yesterdayDate = (new Date((this.productDetails.product.created * 1000)).getMonth()+1)+'/'+(new Date((this.productDetails.product.created * 1000)).getDate()-1)+'/'+new Date((this.productDetails.product.created * 1000)).getFullYear()
    this.minDate = new Date(yesterdayDate);
    let enableDate = new Date((this.productDetails.product.created * 1000));
    if (this.productDetails.product.dateAvailable) { enableDate = new Date((this.productDetails.product.dateAvailable)) }

    this.billingForm = new FormGroup({
        name: new FormControl(this.productDetails.product.name, Validators.compose([Validators.minLength(3), Validators.required])),
        description: new FormControl((this.productDetails.product.description || '')),
        groups: new FormControl(this.productDetails.product.allowedGroups, Validators.compose([Validators.minLength(1), Validators.required])),
        enableDate: new FormControl(enableDate, [Validators.required]),
        price: new FormControl((this.productDetails.price.unit_amount/100), Validators.compose([Validators.min(0.50), Validators.required])),
    });
  }

  fetchGroups() {
    this.loadingGroups = true
    const callable = this.functions.httpsCallable('getGroups');
    const obs = callable({
      communityId: this.itemDetails.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        res.unshift({
          communityId: this.itemDetails.communityDetails.id,
          description: `All registered members of ${this.itemDetails.communityDetails.name}.`,
          name: 'All community members',
          id: `${this.itemDetails.communityDetails.id}-allcommunitymembers`
        })
        this.groups = res
        this.loadingGroups = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  editProduct() {
    this.editingProduct = true
    const callable = this.functions.httpsCallable('editProduct');
    const obs = callable({
      priceId: this.productDetails.price.id,
      productId: this.productDetails.product.id,
      communityId: this.itemDetails.communityDetails.id,
      productName: this.billingForm.controls.name.value,
      productDescription: this.billingForm.controls.description.value,
      dateAvailable: this.billingForm.controls.enableDate.value,
      price: this.billingForm.controls.price.value,
      groups: this.billingForm.controls.groups.value
    });
    obs.subscribe({
      next: (res) => {
        this.editingProduct = false
        this.modalRef.close('created')
      },
      error: (err) => {
        console.warn(err)
        this.editingProduct = false
      },
    })
  }

}
