import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-edit-event-time-date',
  templateUrl: './edit-event-time-date.component.html',
  styleUrls: ['./edit-event-time-date.component.scss']
})
export class EditEventTimeDateComponent implements OnInit {
  timeDateForm: FormGroup
  @Input() timeDetails
  minDate = new Date();
  constructor(
    public modalRef: MdbModalRef<EditEventTimeDateComponent>,
  ) { }

  ngOnInit() {
    if(this.timeDetails) {
      this.timeDateForm = new FormGroup({
        date: new FormControl(new Date(this.timeDetails.date), Validators.compose([Validators.minLength(1), Validators.required])),
        doorsOpenTime: new FormControl(this.timeDetails.doorsOpenTime, Validators.compose([Validators.minLength(1), Validators.required, this.validateTime])),
        startTime: new FormControl(this.timeDetails.startTime, Validators.compose([Validators.minLength(1), Validators.required, this.validateTime])),
      }, { validators: this.checkDoorsOpenBeforeStartTime });
    } else {
      this.timeDateForm = new FormGroup({
        date: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
        doorsOpenTime: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required, this.validateTime])),
        startTime: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required, this.validateTime])),
      }, { validators: this.checkDoorsOpenBeforeStartTime });
    }
  }

  submit() {
    const formData = JSON.parse(JSON.stringify(this.timeDateForm.value))
    this.modalRef.close(formData);
  }

  validateTime(control: AbstractControl): { [key: string]: any } | null {
    const timePattern = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
    if (!control.value || !timePattern.test(control.value)) {
      return { invalidTimeFormat: true };
    }
    return null;
  }

  checkDoorsOpenBeforeStartTime(group: FormGroup): { [key: string]: any } | null {
    const doorsOpenTime = group.get('doorsOpenTime')?.value;
    const startTime = group.get('startTime')?.value;

    // Convert time strings to JavaScript Date objects for comparison
    const doorsOpenDate = new Date(`2000-01-01 ${doorsOpenTime}`);
    const startDate = new Date(`2000-01-01 ${startTime}`);

    if (doorsOpenDate >= startDate) {
      return { doorsOpenAfterStartTime: true };
    }

    return null;
  }

}
