import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MdbNotificationService, MdbNotificationRef, MdbNotificationConfig } from 'mdb-angular-ui-kit/notification';

import { EnvironmentService } from 'src/app/services/environment.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pos-navigation',
  templateUrl: './pos-navigation.component.html',
  styleUrls: ['./pos-navigation.component.scss']
})
export class PosNavigationComponent implements OnInit {
  @Input() sidenav
  constructor(
    private modalService: MdbModalService,
    private afAuth: AngularFireAuth,
    private toastService: MdbNotificationService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    ) {}


  ngOnInit(): void {

  }

  ngOnChanges() {

  }

}
