import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent implements OnInit {
  redeemSuccessful: boolean = false;
  isSaving: boolean = false;
  givenName: string = null;
  redeemForm: FormGroup;
  error;

  constructor(
    private fb: FormBuilder,
    private functions: AngularFireFunctions,
    private route: ActivatedRoute,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.userService.userChanged.subscribe((user: any) => {
      this.givenName = user.given_name;
    });

    this.redeemForm = this.fb.group({
      setupCode: new FormControl('', Validators.compose([Validators.minLength(16), Validators.required])),
    });
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      if (code) {
        const formattedCode = code.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        this.redeemForm.controls.setupCode.setValue(formattedCode);
        if (this.redeemForm.valid) {
          this.isSaving = true
          this.submit()
        }
      }
    });
  }

  submit() {
    const redemptionCode = this.redeemForm.controls.setupCode.value;
    console.log(redemptionCode);
    this.isSaving = true;
    const callable = this.functions.httpsCallable('authorizeRedemption');
    let obs = callable({ redemptionCode });
    obs.subscribe({
      next: (res) => {
        this.redeemSuccessful = true;
        this.isSaving = false;
      },
      error: (err) => {
        this.isSaving = false;
        this.error = err.message;
        console.warn(err);
      },
    });
  }
}
