import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';

@Component({
  selector: 'app-add-card-reader',
  templateUrl: './add-card-reader.component.html',
  styleUrls: ['./add-card-reader.component.scss']
})
export class AddCardReaderComponent implements OnInit {
  cardReaderForm: FormGroup
  creatingReader: boolean = false
  @Input() communityDetails: Community
  @Input() stripeAccountDetails: any

  constructor(
    public modalRef: MdbModalRef<AddCardReaderComponent>,
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.cardReaderForm = new FormGroup({
      registrationCode: new FormControl('', [Validators.required, this.validCode]),
      terminalName: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      locationName: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required]))
    });
  }

  createReader() {
    this.creatingReader = true
    const callable = this.functions.httpsCallable('createCardReader');
    const obs = callable({
      communityId: this.communityDetails.id,
      terminalRegistrationCode: this.cardReaderForm.get('registrationCode').value,
      terminalName: this.cardReaderForm.get('terminalName').value,
      locationName: this.cardReaderForm.get('locationName').value
    });
    obs.subscribe({
      next: (res) => {
        this.creatingReader = false
        this.modalRef.close('created')
      },
      error: (err) => {
        console.warn(err)

      },
    })
  }


  validCode(control: FormControl) {
  const validCodeRegex = /^[a-zA-Z]{3,}-[a-zA-Z]{3,}-[a-zA-Z]{3,}$/;
  if (validCodeRegex.test(control.value)) {
    return null;
  }
  return { invalidCode: true };
}

}
