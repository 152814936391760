<div class="d-flex justify-content-center align-items-center h-100">
  <div class="text-white">
    <h5 class="mb-3">
      <div class="spinner-eclipse">
        <div class="spin">
          <div></div>
        </div>
      </div>
    </h5>
  </div>
</div>
