<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">My Tickets</h4>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <!-- Content -->
        <ng-container *ngIf="!loading; else displaySkeleton">
        <div class="row">
          <div *ngFor="let item of tickets; let i = index" class="col-md-8 col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-body">
                <h3>{{item.event.name}}</h3>
                <h5>{{item.event.venueName}}</h5>
                <p>
                  {{item.community.name}}<br>
                  {{item.event.startTime | date:'mediumDate':'-0'}} at {{item.event.startTime | date:'shortTime':'-0'}}
                </p>
                <div class="btn btn-rounded btn-primary" [routerLink]="'/tickets/'+item.tickets[0].id">Get Ticket<span *ngIf="item.tickets.length>1">s</span></div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tickets.length===0">
          <app-no-results message="No tickets found"></app-no-results>
        </div>
      </ng-container>
      <ng-template #displaySkeleton>
        <ng-container *ngIf="!error; else displayError">
          <div class="row">
            <div *ngFor="let item of [].constructor(3); let i = index" class="col-md-4 col-lg-3 col-xl-3">
              <app-list-item-loading></app-list-item-loading><br>
            </div>
          </div>
        </ng-container>
        <ng-template #displayError>
          <app-https-error [error]="error"></app-https-error>
        </ng-template>
      </ng-template>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>
