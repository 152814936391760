import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-video-view-list',
  templateUrl: './user-video-view-list.component.html',
  styleUrls: ['./user-video-view-list.component.scss']
})
export class UserVideoViewListComponent implements OnInit {
  @Input() lookupUid: string
  showSearch: boolean = true
  error
  constructor() { }

  ngOnInit(): void {
  }

  openLink() {

  }

}
