import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AddCardReaderComponent } from '../add-card-reader/add-card-reader.component';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { EditAdminPermissionsComponent } from 'src/app/components/modals/edit-admin-permissions/edit-admin-permissions.component';
import { Subscription } from 'rxjs';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';

@Component({
  selector: 'app-list-access',
  templateUrl: './list-access.component.html',
  styleUrls: ['./list-access.component.scss']
})
export class ListAccessComponent implements OnInit {
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  editUserPermissionsModalRef: MdbModalRef<EditAdminPermissionsComponent> | null = null;

  @Input() communityDetails: Community
  @Input() stripeAccountDetails: any
  searchText: string
  currentUser
  loading: boolean = true
  hasEditAdminPermissions: boolean = false
  private adminCommunitiesSubscription: Subscription;
  members: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private joinedCommunitiesService: JoinedCommunitiesService,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.currentUser = user['_delegate']
      this.fetchCommunityAccess()
    })
    this.adminCommunitiesSubscription = this.joinedCommunitiesService.joinedAdminCommunitiesChanged.subscribe((joinedAdminCommunities: any[]) => {
      if (!(joinedAdminCommunities[0]?.loading)) {
        this.hasEditAdminPermissions = joinedAdminCommunities.some(community => community.permissions.includes('admin_access_managementgf'));
      }
    });
  }

  fetchCommunityAccess() {
    this.loading = true
    const callable = this.functions.httpsCallable('getAccess');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.members = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'displayName':
          let displayName = data[key]
          return displayName.toLowerCase().includes(searchTerm.toLowerCase());
        case 'email':
          let email = data[key]
          return email.toLowerCase().includes(searchTerm.toLowerCase());
        case 'provider':
          let provider = data[key]
          return provider.toLowerCase().includes(searchTerm.toLowerCase());
        case 'groups':
          let groupNames = ''
          data[key].forEach((group) => {
            groupNames += group.name + ' '
          })
          return groupNames.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  editUserPermissions(item) {
    this.editUserPermissionsModalRef = this.modalService.open(EditAdminPermissionsComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        userDetails: item
      }
    })
    this.editUserPermissionsModalRef.onClose.subscribe((message: any) => {
      if (message === 'updated') {
        this.fetchCommunityAccess()
      }
    });
  }


}
