<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Create Post</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="postForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" />
          <label mdbLabel class="form-label" for="name">Name</label>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="postForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <br class="noselect">
    <div class="row">
      <quill-editor formControlName="message" [style]="editorStyle" [modules]="config"></quill-editor>
    </div>
  </form>
  <br class="noselect"><br class="noselect">
</div>
<div class="modal-footer">
  <button (click)="submit()" [disabled]="postForm.invalid||savingPost" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="savingPost"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Creating</span>
    <span *ngIf="!savingPost">Create</span>
  </button>
</div>
