<!-- Navbar-->
<nav class="navbar sticky-top navbar-expand-lg noselect">
  <div class="container-fluid justify-content-between">
    <div class="d-flex">
      <!-- Mobile Nav -->
      <ul class="navbar-nav flex-row d-">
        <li class="nav-item me-3 me-lg-1 active">
          <a (click)="openMenu()" class="nav-link pointer">
            <span><i class="fas fa-bars fa-lg text-dark"></i></span>
          </a>
        </li>
      </ul>

      <a class="navbar-brand me-2 mb-1 d-flex align-items-center" style="padding-top: 9px">
        <img src="./assets/logo.png" height="20" alt="" loading="lazy" style="margin-top: 2px;" />
      </a>
    </div>
    <!-- Right elements -->
    <ul class="navbar-nav flex-row">
      <li class="nav-item me-1 me-lg-1">
        <a class="nav-link d-sm-flex align-items-sm-center pointer">
          <span class="text-dark">{{currentTime}}</span>
        </a>
      </li>
      <ng-container *ngIf="networkConfig">
        <ng-container *ngIf="networkConfig.type==='WIFI'">
          <li class="nav-item ms-1 me-1 me-lg-1">
            <a class="nav-link d-sm-flex align-items-sm-center pointer text-dark">
              <img style="height: 1.1em; vertical-align: middle; margin-bottom: 0.1em;"
              [src]="'./assets/device-icons/wifi/'+networkConfig.strength+'.svg'" aria-hidden="true">
            </a>
          </li>
        </ng-container>
      </ng-container>
      <li class="nav-item me-lg-1">
        <a class="nav-link d-sm-f lex align-items-sm-center pointer">
          <div *ngIf="batteryInfo" class="text-dark">
            <img style="height: 1.1em; vertical-align: middle; margin-bottom: 0.1em;"
              [src]="getBatteryIcon(batteryInfo.value, batteryInfo.charging)" aria-hidden="true">
            <span class="small">{{batteryInfo.value | percent}}</span>
          </div>
        </a>
      </li>
    </ul>


  </div>
</nav>