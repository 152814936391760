import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit  {
  @Input() engine: string = ''
  @Input() overrideUid: string = ''
  @Output() output = new EventEmitter<any>();
  loading: boolean = false
  searchControl = new FormControl('');
  searchResults: any
  searchPageCurrent: number = 1
  searchResultsTotal: number = 0
  searchSize: number = 10
  error
  searchValue: string = ''
  constructor(
    private functions: AngularFireFunctions,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(1000), // Increased to 1000 milliseconds
        distinctUntilChanged() // Ensures the function is only triggered if the value has changed
      )
      .subscribe(value => {
        this.searchResultsTotal = 0
        this.searchResults = []
        this.searchValue = value
        if(value.length>0) { this.triggerSearch(value) }
      });
      console.log('lets see if we should trigger search!', this.engine)
      switch(this.engine) {
        case 'videoviews':
          this.triggerSearch('')
        default:
          break;
      }
  }


  click(resultId: string, $event: any): void {
    this.output.emit({result: $event, id: resultId})
    const callable = this.functions.httpsCallable('clickSearchResult');
    const obs = callable({
      query: this.searchValue,
      documentId: resultId,
      engine: this.engine,
      ...(this.overrideUid && { overrideUid: this.overrideUid }),
    });
    obs.subscribe({
      next: (res) => {
        return
      },
      error: (err) => {
        console.warn(err)
        return
      },
    })
  }

  triggerSearch(searchValue: string, startAt: number = 1, ignoreLoading: boolean = false): void {
    console.log('lets trigger it')
    if(!ignoreLoading) { this.loading = true }
    const callable = this.functions.httpsCallable('getSearch');
    const obs = callable({
      query: searchValue,
      engine: this.engine,
      startPage: startAt,
      pageSize: this.searchSize,
      ...(this.overrideUid && { overrideUid: this.overrideUid }),
    });
    obs.subscribe({
      next: (res) => {
        console.log(res)
        this.loading = false
        if(startAt>1) {
          this.searchResults = this.searchResults.concat(res.results)
        } else {
          this.searchResults = res.results
        }
        this.searchPageCurrent = res.meta.page.current
        this.searchSize = res.meta.page.size
        this.searchResultsTotal = res.meta.page.total_results
        return
      },
      error: (err) => {
        this.loading = false
        this.error = err.message
        console.warn(err)
        return
      },
    })
  }

  moreResults() {
    this.triggerSearch(this.searchValue, (this.searchPageCurrent+1), true)
  }

  trackById(i, details) {
    return details.id.raw;
  }

}
