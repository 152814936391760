import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal/modal-ref';
import { JoinCommunityWithCodeComponent } from 'src/app/components/modals/join-community-with-code/join-community-with-code.component';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ContactComponent } from 'src/app/components/support/contact/contact.component';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  animations: [fadeInEnterAnimation({ duration: 200 }),],
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  modalRef: MdbModalRef<ContactComponent> | null = null;

  currentView: string = 'search'
  selectedArticleId: string
  joinWithCodeModalRef: MdbModalRef<JoinCommunityWithCodeComponent> | null = null;
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Support | Event Odyssey')
    this.route.queryParams
      .subscribe(params => {
        const postId = params.id
        if (postId) {
          this.openArticle(postId)
          this.location.replaceState('/support?id=' + postId)
        }
      })
  }


  contactSupport() {
    this.modalRef = this.modalService.open(ContactComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
    })
  }

  openArticle($event) {
    this.selectedArticleId = $event.id
    this.currentView = 'article'
  }

  toSearchList() {
    this.currentView = 'search'
    this.titleService.setTitle('Support | Event Odyssey')
    this.location.replaceState('/support')
  }


}
