import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements AfterViewInit {
  private timeouts: any[] = [];

  textToDisplay = '';
  texts = [
    'Creating seamless event management solutions for schools and community theaters...',
    'Transforming the way schools and theaters organize and promote events...',
    'Elevate your audience\'s experiences with advanced ticketing and on-demand video!'
  ];
  textIndex = 0;
  typingSpeed = 90;
  delayBetweenTexts = 2000;
  delayBetweenDeletionTexts = 35;
  delayBeforeDeletion = 1750;

  constructor(
    public router: Router
  ) { }

  ngAfterViewInit() {
    this.typeText();
  }

  typeText() {
    const text = this.texts[this.textIndex];
    this.textToDisplay = '';
    for (let i = 0; i < text.length; i++) {
      const timeout = setTimeout(() => {
        this.textToDisplay += text.charAt(i);
        if (i === text.length - 1) {
          this.setDelayedAction(this.deleteText, this.delayBeforeDeletion);
        }
      }, i * this.typingSpeed);

      this.timeouts.push(timeout);
    }
  }

  deleteText() {
    const currentText = this.textToDisplay;
    for (let i = 0; i < currentText.length; i++) {
      const timeout = setTimeout(() => {
        this.textToDisplay = this.textToDisplay.substring(0, this.textToDisplay.length - 1);
        if (this.textToDisplay.length === 0) {
          this.setDelayedAction(() => {
            this.textIndex = (this.textIndex + 1) % this.texts.length;
            this.typeText();
          }, this.delayBetweenTexts);
        }
      }, i * this.delayBetweenDeletionTexts);

      this.timeouts.push(timeout);
    }
  }

  setDelayedAction(callback: Function, delay: number) {
    const timeout = setTimeout(() => {
      callback.call(this);
    }, delay);
    this.timeouts.push(timeout);
  }

  ngOnDestroy() {
    this.timeouts.forEach(clearTimeout);
  }


}
