import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { MdbStepperComponent } from 'mdb-angular-ui-kit/stepper';
import { StripeInstance, StripeFactoryService } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../shared/toast/toast.component';
import { guestContact } from 'src/app/interfaces/guestContact';

@Component({
  selector: 'app-purchase-video-with-card-reader',
  templateUrl: './purchase-video-with-card-reader.component.html',
  styleUrls: ['./purchase-video-with-card-reader.component.scss']
})
export class PurchaseVideoWithCardReaderComponent implements OnInit {
  @Input() itemDetails
  @Input() communityDetails
  @ViewChild('stepper') stepper!: MdbStepperComponent;
  step: number = 1
  paymentSuccessful: boolean = false;
  paymentError: string = ''
  paymentErrorType: string = 'warning'
  allStepsValid: boolean = false;
  allStepsForm: FormGroup;
  selectProductForm: FormGroup;
  selectProductValid: boolean = false
  selectCustomerForm: FormGroup;
  selectCustomerValid: boolean = false
  checkoutForm: FormGroup;
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  selectProduct: any = {
    price: {},
    product: {}
  }
  paymentMethod: any = {};
  checkout = {};
  lastSelectedItemId: string;
  purchasing: boolean = false
  stripe: StripeInstance;
  selectedGuest: guestContact;
  cardReaderId: string = 'tmr_E9TE7gDGGF7gti'
  selectedUid
  // selectedUid = 'lxbsBfnb7QSjOMJV89ZJlxRnrpq1'
  // selectedUid
  lastReaderFunctionResponse
  constructor(
    public modalRef: MdbModalRef<PurchaseVideoWithCardReaderComponent>,
    private functions: AngularFireFunctions,
    private toastService: MdbNotificationService,
    private stripeFactory: StripeFactoryService
  ) { }

  ngOnInit(): void {
    this.setupForms()
  }

  setupForms() {
    this.selectProductForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.selectCustomerForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.checkoutForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.allStepsForm = new FormGroup({
      selectProductForm: this.selectProductForm,
      selectCustomerForm: this.selectCustomerForm,
      checkoutForm: this.checkoutForm,
    });
  }


  validateselectProduct(isValid) {
    isValid ? this.allStepsForm.controls.selectProductForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectProductForm.get('valid').setValue(null);
    if (!isValid) {
      this.selectProduct = {}
      this.selectProductValid = false
    } else {
      this.selectProductValid = true
    }
    this.validateAllSteps();
  }

  outputselectProduct($event) {
    this.selectProduct = $event;
    if (!(this.selectProduct.price.id === this.lastSelectedItemId)) {
      this.setReaderDisplay()
      this.lastSelectedItemId = this.selectProduct.price.id
    }
  }

  validateselectCustomer(isValid) {
    isValid ? this.allStepsForm.controls.selectCustomerForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectCustomerForm.get('valid').setValue(null);
    if (!isValid) {
      this.paymentMethod = {}
      this.selectCustomerValid = false
    } else {
      this.selectCustomerValid = true
    }
    this.validateAllSteps();
  }

  outputselectCustomer($event) {
    this.paymentMethod = $event;
  }

  validateAllSteps() {
    this.allStepsValid = this.allStepsForm.valid;
  }

  stepChange(event) {
    this.clearPaymentError()
    this.step = event.activeStepIndex + 1
  }

  next() {
    this.stepper.next()
  }

  previous() {
    this.stepper.previous()
  }

  purchase() {
    this.paymentError = ''
    this.purchasing = true
    const callable = this.functions.httpsCallable('createPaymentIntent');
    const obs = callable({
      priceId: this.selectProduct.price.id,
      videoId: this.itemDetails.id,
      paymentId: this.paymentMethod.paymentMethodId
    });
    obs.subscribe({
      next: (res) => {
        this.handlePaymentIntent(res)
      },
      error: (err) => {
        this.handlePaymentError(err)
      },
    })
  }

  async handlePaymentIntent(paymentIntent) {
    this.stripe = await this.stripeFactory.create(environment.stripe, { stripeAccount: paymentIntent.stripeId })
    switch (paymentIntent.status) {
      case 'succeeded':
      case 'processing':
        this.purchasing = false
        this.paymentSuccessful = true
        break;
      case 'requires_action':
        this.paymentError = 'Your payment method requires additional information to process the payment successfully.'
        this.stripe.confirmCardPayment(paymentIntent.client_secret).subscribe(result => {
          if (result.error) { this.handlePaymentError(result.error.message, 'danger') }
          else {
            this.handlePaymentIntent(result.paymentIntent)
          }
        });

        break;
      case 'requires_payment_method':
        this.handlePaymentError(paymentIntent.error.message)
        break;
    }
  }

  handlePaymentError(message: string, type?: string) {
    if (!type) { type = 'warning' }
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    if (String(message).includes('unreachable')) { type = 'danger' }
    this.purchasing = false
    this.paymentErrorType = type
    this.paymentError = message
  }

  clearPaymentError() {
    this.paymentError = ''
    this.paymentErrorType = 'warning'
  }

  chargeCardReader() {
    this.purchasing = true
    if (this.lastReaderFunctionResponse) { this.lastReaderFunctionResponse = new Date().getTime() }
    this.clearPaymentError()
    const callable = this.functions.httpsCallable('processPaymentIntent');
    const obs = callable({
      customerUid: this.selectedUid,
      guestContact: this.selectedGuest,
      priceId: this.selectProduct.price.id,
      videoId: this.itemDetails.id,
      cardReaderId: this.cardReaderId
    });
    obs.subscribe({
      next: (res) => {
        this.lastReaderFunctionResponse = new Date().getTime()
        this.purchasing = false
      },
      error: (err) => {
        this.purchasing = false
        this.handlePaymentError(err.message)
        console.warn(err)
      },
    })
  }

  setReaderDisplay() {
    this.purchasing = true
    this.clearPaymentError()
    const callable = this.functions.httpsCallable('setReaderDisplay');
    const obs = callable({
      customerUid: this.selectedUid,
      guestContact: this.selectedGuest,
      priceId: this.selectProduct.price.id,
      videoId: this.itemDetails.id,
      cardReaderId: this.cardReaderId
    });
    obs.subscribe({
      next: (res) => {
        this.purchasing = false
      },
      error: (err) => {
        this.purchasing = false
        this.handlePaymentError(err.message)
        this.errorToast(err)
        console.warn(err)
      },
    })
  }

  clearReaderDisplay() {
    this.purchasing = true
    this.clearPaymentError()
    const callable = this.functions.httpsCallable('clearReaderDisplay');
    const obs = callable({
      communityId: this.communityDetails.id,
      cardReaderId: this.cardReaderId
    });
    obs.subscribe({
      next: (res) => {
        this.purchasing = false
      },
      error: (err) => {
        this.purchasing = false
        this.errorToast(err)
      },
    })
  }

  errorToast(message: string) {
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

  ngOnDestroy() {
    this.clearReaderDisplay()
  }

  handleReaderStatusOutput($event) {
    switch ($event) {
      case 'declined': {
        this.setReaderDisplay()
        break;
      }
    }
  }

  selectCustomer($event) {
    this.selectedGuest = null
    this.selectedUid = $event
    this.setReaderDisplay()
  }

  guestOutput($event) {
    this.selectedUid = null
    this.selectedGuest = $event
    this.setReaderDisplay()
  }

}
