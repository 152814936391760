<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Post</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <br class="noselect"><br class="noselect">
</div>
<div class="modal-footer">

</div>
