import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-support-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class SupportDetailComponent implements OnInit {
  @Input() articleId: string;
  view: string = 'showArticle'
  loading: boolean = true
  articleData
  postForm: FormGroup
  editorStyle = {
    height: '300px'
  };
  config = {
    toolbar: [
      [{ 'header': [5, false] }],
      ['bold', 'italic', 'underline',],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ]
  };
  constructor(
    private functions: AngularFireFunctions,
    private titleService: Title,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getSupportArticleDetails()
  }

  ngOnChanges() {
    this.location.replaceState('/support?id=' + this.articleId)
  }

  getSupportArticleDetails() {
    const callable = this.functions.httpsCallable('getSupportArticleDetails');
    const obs = callable({
      id: this.articleId
    });
    obs.subscribe({
      next: (res) => {
        this.loading = false
        this.articleData = res
        this.titleService.setTitle(`Support - ${this.articleData.name} | Event Odyssey`)
          this.postForm = new FormGroup({
            name: new FormControl(res.name, Validators.compose([Validators.minLength(3), Validators.required])),
            message: new FormControl(res.content, Validators.compose([Validators.minLength(3), Validators.required])),
          });
      },
      error: (err) => {
        this.loading = false
        console.warn(err)

      },
    })
  }

}
