import { Component, Input, OnInit } from '@angular/core';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-retry-video-fetch-timer',
  templateUrl: './retry-video-fetch-timer.component.html',
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
  styleUrls: ['./retry-video-fetch-timer.component.scss']
})
export class RetryVideoFetchTimerComponent implements OnInit {
  @Input() timer: number
  @Input() timerLength: number
  progress:number = 100
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.progress = (this.timer / this.timerLength) * 100
  }

}
