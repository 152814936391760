import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { Community } from 'src/app/interfaces/community';
import { InviteMemberComponent } from '../../invite-member/invite-member.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DateTime, Settings } from 'luxon';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material'
import { saveAs } from 'file-saver';
import { PayoutDetailsComponent } from 'src/app/components/modals/payout-details/payout-details.component';

@Component({
  selector: 'app-community-payouts-list',
  templateUrl: './payouts-list.component.html',
  styleUrls: ['./payouts-list.component.scss']
})
export class CommunityPayoutsListComponent implements OnInit {
  @Input() communityDetails: Community
  savingExport: boolean = false
  @ViewChild('table') membersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  modalRef: MdbModalRef<InviteMemberComponent> | null = null;
  @Input() stripeAccountDetails: any
  loading: boolean = true
  searchText: string
  payouts: any[] = []
  error
  payoutDetailsModalRef: MdbModalRef<PayoutDetailsComponent>;


  // Datepicker
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  startYear = DateTime.local().toObject().month > 8 ? DateTime.local().toObject().year : DateTime.local().toObject().year - 1;
  endYear = DateTime.local().toObject().month > 8 ? DateTime.local().toObject().year + 1 : DateTime.local().toObject().year;
  ranges: any = {
    'Current month': [DateTime.local().startOf('month'), DateTime.local().endOf('month')],
    'Last month': [DateTime.local().minus({ 'months': 1 }).startOf('month'), DateTime.local().minus({ 'months': 1 }).endOf('month')],
    'Current year': [DateTime.local().startOf('year'), DateTime.local().endOf('year')],
    'Last year': [DateTime.local().minus({ 'year': 1 }).startOf('year'), DateTime.local().minus({ 'year': 1 }).endOf('year')],
  }
  startDate: string = DateTime.local().startOf('year').toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('month').toFormat("MM/dd/yyyy");
  totalPending: number = 0

  constructor(
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private modalService: MdbModalService
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchPayouts()
    })
  }

  ngOnChanges() {
    if(this.stripeAccountDetails) {
      this.totalPending = ((this.stripeAccountDetails.balance.available[0].amount)+(this.stripeAccountDetails.balance.pending[0].amount))
    } else {
      this.totalPending = 0
    }
  }


  openDatepicker() {
    this.pickerDirective.open();
  }

  updateDates(range) {
    if (range.startDate && range.endDate) {
      let newEndDate = range.endDate['$d'].toLocaleString('en-US', { timeZone: 'America/New_York' });
      range.endDate['$d'] = new Date(newEndDate);
      this.startDate = DateTime.fromJSDate(range.startDate['$d']).plus({ 'days': 1 }).toFormat("MM/dd/yyyy");
      this.endDate = DateTime.fromJSDate(range.endDate['$d']).toFormat("MM/dd/yyyy");
      this.fetchPayouts()
    }
  }

  fetchPayouts() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityPayouts');
    const obs = callable({
      communityId: this.communityDetails.id,
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000)
    });
    obs.subscribe({
      next: (res) => {
        this.payouts = res.data
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.membersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'status':
          let status = data[key]
          return status.toLowerCase().includes(searchTerm.toLowerCase());
        case 'id':
          let id = data[key]
          return id.toLowerCase().includes(searchTerm.toLowerCase());
        case 'type':
          let type = data[key]
          return type.toLowerCase().includes(searchTerm.toLowerCase());
        case 'description':
          let description = data[key]
          return description.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
  async export() {
    this.savingExport = true
    const csvArray = [
      [
          "ID",
          "Amount",
          "Status",
          "Creation Date",
          "Arrival Date"
      ],
      ...this.payouts.map(item => [
          item.id,
          (item.amount / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'}),
          item.status,
          DateTime.fromSeconds(item.created).toFormat("MM/dd/yyyy"),
          DateTime.fromSeconds(item.arrival_date).toFormat("MM/dd/yyyy"),
      ])
      ].map(e => e.join(","))
      .join("\n");
      var blob = new Blob(['\ufeff' + csvArray], {type: 'text/csv;charset=utf-8;' })
      saveAs(blob, 'Payouts Export  - ' + this.startDate + ' - '+ this.endDate +'.csv');
      await this.delay(500);
      this.savingExport = false
  }

  details(item: any) {
    this.payoutDetailsModalRef = this.modalService.open(PayoutDetailsComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        payoutDetails: item,
        communityDetails: this.communityDetails
      }
    })
  }

}
