import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-notifications',
  templateUrl: './edit-notifications.component.html',
  styleUrls: ['./edit-notifications.component.scss']
})
export class EditNotificationsComponent implements OnInit {
  groupForm: FormGroup
  noticeContent: string = ''
  isSaving: boolean = false
  loadingNotificationSettings: boolean = true
  @Input() userProfile
  notificationGroups: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
  ) { }

  ngOnInit(): void {
    this.noticeContent = `These settings impact what types of emails we\'ll send to <em class=\'text-muted\'>${this.userProfile.email}</em>.`
    this.fetchNotificationSettings()
  }


  fetchNotificationSettings() {
    this.loadingNotificationSettings = true
    const callable = this.functions.httpsCallable('getUserNotificationSettings');
    const obs = callable({

    });
    obs.subscribe({
      next: (res) => {
        this.notificationGroups = res.notificationGroups
        this.groupForm = new FormGroup({
          groups: new FormControl(res.enabledNotifications),
        });
        this.loadingNotificationSettings = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        this.loadingNotificationSettings = false
      },
    })
  }

  isSelected(id: string): boolean {
    return this.groupForm.get('groups').value.includes(id);
  }

  onGroupChange(event: any, groupId: string): void {
    const groupsControl = this.groupForm.get('groups');
    if (event.target.checked) {
      groupsControl.value.push(groupId);
    } else {
      const index = groupsControl.value.indexOf(groupId);
      if (index > -1) {
        groupsControl.value.splice(index, 1);
      }
    }
  }


  submit() {
    const acceptNotifications = this.groupForm.controls.groups.value;
    const ignoreNotifications = this.notificationGroups
      .filter(group => !acceptNotifications.includes(group.id))
      .map(group => group.id);
    this.isSaving = true
    const callable = this.functions.httpsCallable('updateUserNotificationSettings');
    const obs = callable({
      ignoreNotifications: ignoreNotifications,
    });
    obs.subscribe({
      next: (res) => {
        this.fetchNotificationSettings()
        this.isSaving = false
      },
      error: (err) => {
        this.isSaving = false
        this.error = err.message
        console.warn(err)
      },
    })
  }


}
