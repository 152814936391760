<ng-container *ngIf="loading; else notloading">
  <app-loading></app-loading>
</ng-container>
<ng-template #notloading>

  <div class="row">
    <div  *ngFor="let item of products"  class="col-md-12 col-lg-6 col-xl-4">
    <div mdbRipple (click)="selectPrice(item)" style="width: 100%" class="card mb-3" [ngClass]="(selectedPrice.id === item.price.id) ? 'border border-primary' : 'pointer'">
      <div class="card-body">
        <h5 class="card-title">
          <span *ngIf="item.product.iconName">
            <i class="fas" [ngClass]="'fa-'+item.product.iconName"></i>
          </span>
          {{item.product.name}}</h5>
        <div class="card-text">
          <div class="small" [innerHTML]="item.product.description"></div>
          {{(item.price.unit_amount/100)| currency}}
          <span *ngIf="item.product.ignoredVideoIds?.includes(itemDetails.id)" class="badge badge-warning ml-2"><span class="">{{itemDetails.name}} not included</span> </span>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="row" *ngIf="products.length===0">
    <app-notice content="No products found for this video. It's unavailable to purchase." type="danger"></app-notice>
  </div>
</ng-template>
