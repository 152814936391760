import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';
import { AddTaxProfileComponent } from '../../add-tax-profile/add-tax-profile.component';

@Component({
  selector: 'app-community-sales-tax',
  templateUrl: './sales-tax.component.html',
  styleUrls: ['./sales-tax.component.scss']
})
export class SalesTaxComponent implements OnInit {
  @ViewChild('table') taxProfilesTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  confirmDeleteModalRef: MdbModalRef<ConfirmActionComponent>;
  modalRef: MdbModalRef<AddTaxProfileComponent> | null = null;
  @Input() communityDetails: Community
  searchText: string
  loading: boolean = true
  taxProfiles: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchTaxProfiles()
    })
  }

  fetchTaxProfiles() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityTaxProfiles');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.taxProfiles = res.map(profile => {
          const taxRate = profile.taxRates[0];
          return {
              ...profile,
              taxRateName: taxRate.name,
              taxRateType: taxRate.type,
              taxRateValue: taxRate.value
          };
      });
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  createTaxProfile() {
    this.modalRef = this.modalService.open(AddTaxProfileComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((message: any) => {
      if (message === 'created') {
        this.fetchTaxProfiles()
      }
    });
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.taxProfilesTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'taxRates':
          let taxRatesNames = ''
          data[key].forEach((rate) => {
            taxRatesNames += rate.name + ' '
          })
          return taxRatesNames.toLowerCase().includes(searchTerm.toLowerCase());
        case 'name':
          let name = data[key]
          return name.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  deleteTaxProfile(item) {
    let content = `<div class="text-center">Are you sure you want to delete <b>${item.name}</b>?</p></div>`;
    this.confirmDeleteModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Delete Tax Profile',
        content: content,
        confirmButton: 'Delete',
        confirmButtonPlural: 'Deleting',
        type: 'deleteTaxProfile',
        miscData: {
          communityId: this.communityDetails.id,
          id: item.id
        }
      }
    })
    this.confirmDeleteModalRef.onClose.subscribe((message: any) => {
      if (message === 'deleted') {
        this.fetchTaxProfiles()
      }
    });
  }

}
