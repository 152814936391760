<div style="width: 100%">
    <div class="d-flex">
        <!-- Message Details Section -->
        <div class="p-2 flex-grow-1">
            <div>
                <h6>To: {{ itemDetails.to.raw }}</h6>
                <p class="text-muted mb-0">Message: <span [innerHtml]="itemDetails.message.snippet"></span></p>
                <p class="text-muted mb-0">Type: {{ itemDetails.type.raw }}</p>
                <p class="text-muted mb-0">Category: {{ itemDetails.category.raw }}</p>
            </div>
        </div>

        <!-- State and Creation Date Section -->
        <div class="p-2 small text-end">
            <div>
                <p>State: {{ itemDetails.state.raw }}</p>
                <p>Created: {{ itemDetails.created.raw | date:'medium' }}</p>
            </div>
        </div>
    </div>
</div>
