<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Create Video</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<ng-container *ngIf="!isLoadingPrecheck; else displayLoading">
  <ng-container *ngIf="preCheckPassed; else preCheckFailed">
    <div class="modal-body">
      <form [formGroup]="videoDetailsForm">
        <div class="input-group mt-2">
          <div class="input-container">
            <mdb-form-control>
              <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
                class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50" />
              <label mdbLabel class="form-label" for="name">Name</label>
              <div class="form-helper">
                <div class="form-counter">{{ videoDetailsForm.controls.name.value.length }} / 50</div>
                <div *ngIf="videoDetailsForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters
                  required
                </div>
                <div *ngIf="videoDetailsForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters
                  allowed
                </div>
              </div>
            </mdb-form-control>
          </div>
          <span class="input-group-text border-0"><app-validation-icon
              [inputValid]="videoDetailsForm.controls.name.invalid"></app-validation-icon>
            <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
        </div>
        <br class="noselect">
        <br *ngIf="videoDetailsForm.controls.name.value.length < 3 || videoDetailsForm.controls.name.value.length === 50"
          class="noselect">
        <div class="input-group mt-2">
          <div class="input-container">
            <mdb-form-control>
              <textarea mdbInput [mdbValidate] type="text" formControlName="description" id="description"
                class="form-control form-control-lg" [validateSuccess]="false" maxlength="250"
                placeholder="Description"></textarea>
              <label mdbLabel class="form-label" for="description">Description</label>
              <div class="form-helper">
                <div class="form-counter">{{ videoDetailsForm.controls.description.value.length }} / 250</div>
                <div *ngIf="videoDetailsForm.controls.description.value.length < 3" class="form-counter">Minimum 3 characters
                  required
                </div>
                <div *ngIf="videoDetailsForm.controls.description.value.length === 250" class="form-counter">Maximum 250
                  characters allowed
                </div>
              </div>
            </mdb-form-control>
          </div>
          <span class="input-group-text border-0"><app-validation-icon [inputValid]="videoDetailsForm.controls.description.invalid"></app-validation-icon>
            <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
        </div>
        <br class="noselect">
        <br *ngIf="videoDetailsForm.controls.description.value.length < 3 || videoDetailsForm.controls.description.value.length === 250"
          class="noselect">
          <div class="input-group mt-2">
            <div #group class="input-container">
              <mdb-form-control>
                <ng-select  [virtualScroll]="false"  mdbInput formControlName="communities"
                  (open)="displaySelectDropdown=true" (close)="displaySelectDropdown=false" [items]="communities" [multiple]="true"
                  placeholder="Communities" bindLabel="name" bindValue="id" appearance="outline" [closeOnSelect]="true"
                  [clearable]="true" [searchable]="true" notFoundText="No communities found" autocomplete="off" required>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div [ngOptionHighlight]="search" class="">
                      <div class="">{{item.name}}</div>
                    </div>
                  </ng-template>
                </ng-select>
              </mdb-form-control>
            </div>
            <span class="input-group-text border-0"><app-validation-icon  [inputValid]="videoDetailsForm.controls.communities.invalid"></app-validation-icon>
            <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
          </div>
          <div *ngIf="displaySelectDropdown" [style.height]="calculateHeight()"></div>
          <div class="input-group mt-2">
            <div #group class="input-container">
              <div *ngIf="uploadProgress$ | async as progress" class="progress" style="height: 20px">
                <div class="progress-bar" role="progressbar" [style.width]="progress + '%'" [attr.aria-valuenow]="progress"
                  aria-valuemin="0" aria-valuemax="100">
                  {{ progress | number: '1.0-0' }}%
                </div>
              </div>
              <ng-container *ngIf="!fileName; else fileUploaded">
                <div class="dragZone mb-2">
                  <ngx-file-drop dropZoneClassName="filedrop" (onFileDrop)="dropped($event)" (multiple)="false"
                    (directory)="false">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                      <p><button type="button" class="btn btn-md btn-primary text-capitalize btn-rounded btn-fill mt-3"
                          (click)="openFileSelector()"><i class="fas fa-upload me-3"></i>Upload video</button></p><br>
                    </ng-template>
                  </ngx-file-drop>
                </div>
              </ng-container>
              <ng-template #fileUploaded>
                <span><small>{{fileName}}</small>
                    <span *ngIf="!(videoDetailsForm.controls.filePath.invalid)" (click)="deleteUpload()" class="ms-2 me-2 text-danger hoverUnderline pointer">delete</span>
                </span>
              </ng-template>
              <app-notice *ngIf="fileError"
                content="Uploaded file is not a video.<br><small>We accept most file types like mp4, m4v, mov, avi, wmv, ogv, webm...</small>"
                type="danger"></app-notice>
            </div>
            <span class="input-group-text border-0"><app-validation-icon  [inputValid]="videoDetailsForm.controls.filePath.invalid"></app-validation-icon>
            <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="submit()" [disabled]="videoDetailsForm.invalid||isSaving" type="button"
        class="btn btn-primary btn-rounded text-capitalize">
        <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"
            aria-hidden="true"></span> Creating</span>
        <span *ngIf="!isSaving">Create</span>
      </button>
    </div>
  </ng-container>
  <ng-template #preCheckFailed>
    <div class="modal-body">
      <div class="row mb-2">
        <h4>You don't have access to create videos yet</h4>
        <p>Finish the tasks below to gain access.</p>
      </div>
      <div class="row mb-4">
      <div *ngFor="let todo of accountTodos; let i=index" class="col-md-4 col-sm-6 mb-4">
        <div class="card text-center border border-3">
          <div class="card-body">
            <i class="{{todo.icon}} fa-3x mb-3"></i>
            <h6 class="mb-1">{{todo.name}}</h6>
            <small>{{todo.description}}</small><br class="noselect"><br class="noselect">
            <button [disabled]="launchingToDo" (click)="launchTodoAction(todo.type)" class="btn btn-rounded btn-primary align-self-center">
              <ng-container *ngIf="launchingToDo; else notLaunching">
                <span class="spinner-border spinner-border-sm"></span>
              </ng-container>
              <ng-template #notLaunching>
                <i class="fas fa-long-arrow-alt-right"></i>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #displayLoading>
  <div class="modal-body">
    <ng-container *ngIf="!error; else displayError">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </div>
</ng-template>
