<mdb-tabs [vertical]="isVertical" [pills]="true" (activeTabChange)="onTabChange($event)" #tabs>
  <mdb-tab title="Inbox">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-envelope me-2"></i> Inbox</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-inbox [communityDetails]="communityDetails"></app-inbox>
    </ng-template>
  </mdb-tab>
  <mdb-tab title="Support">
    <ng-template mdbTabTitle>
      <span><i class="fas fa-lg fa-headset me-2"></i> Support</span>
    </ng-template>
    <ng-template mdbTabContent>
      <app-support-inbox [communityDetails]="communityDetails"></app-support-inbox>
    </ng-template>
  </mdb-tab>
</mdb-tabs>
