<div class="text-center mb-4">
  <div class="row">
    <div class="row mb-4">
      <div>
        <div *ngIf="!loading" style="text-align:right;" class="text-right">
          <input class="button-input-no-outline" class="datepicker" type="text" ngxDaterangepickerMd [ranges]="ranges"
            [alwaysShowCalendars]="true" (datesUpdated)="updateDates($event)" [maxDate]="maxDate"/>
          <button class="ngx-daterangepicker-action btn btn-rounded btn-white pointer"
            style="position: relative;z-index: 200;" (click)="openDatepicker();">
            <span class="pointer">{{startDate}} <i class="fas fa-long-arrow-alt-right mx-2"
                style="padding-top:0.15rem"></i>
              {{endDate}}</span>

          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loading">
    <div class="row">
      <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative">
        <i class="fas fa-file-invoice-dollar fa-3x text-primary mb-4"></i>
        <h5 class="text-primary fw-bold mb-3">{{communityOverview.sales | number}}</h5>
        <h6 class="fw-normal mb-0">Sales</h6>
        <div class="vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0"></div>
      </div>

      <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative">
        <i class="fas fa-money-bill-wave fa-3x text-primary mb-4"></i>
        <h5 class="text-primary fw-bold mb-3">{{(communityOverview.revenue/100) | currency}}</h5>
        <h6 class="fw-normal mb-0">Gross Revenue</h6>
        <div class="vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0"></div>
      </div>

      <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative pointer "
        [routerLink]="'/communities/'+communityDetails.id+'/manage/members/active'">
        <i class="fas fa-hand-holding-usd fa-3x text-primary mb-4"></i>
        <h5 class="text-primary fw-bold mb-3">({{(communityOverview.fees/100) | currency}})</h5>
        <h6 class="fw-normal mb-0">Fees</h6>
        <div class="vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0"></div>
      </div>

      <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative pointer"
        [routerLink]="'/communities/'+communityDetails.id+'/manage/members/pending'">
        <i class="fas fa-money-bill-wave fa-3x text-primary mb-4"></i>
        <h5 class="text-primary fw-bold mb-3">{{(communityOverview.net/100) | currency}}</h5>
        <h6 class="fw-normal mb-0">Net Revenue</h6>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="loading">
    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative">
      <div style="width: 100%; height: 225px;" class="skeleton"></div>
    </div>

    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative">
      <div style="width: 100%; height: 225px;" class="skeleton"></div>
    </div>

    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative">
      <div style="width: 100%; height: 225px;" class="skeleton"></div>
    </div>

    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative">
      <div style="width: 100%; height: 225px;" class="skeleton"></div>
    </div>
  </div>
</div>
<app-community-manage-overview-chart [chartData]="chartData" [isLoadingOverviewData]="loading"></app-community-manage-overview-chart>
