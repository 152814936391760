<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
</div>
<div class="modal-body">
<div *ngIf="!error" [innerHTML]="content">
</div>
<div *ngIf="error">
  <app-https-error [error]="error"></app-https-error>
</div>
<div *ngIf="confirmText">
  <div class="text-center mt-3 mb-2">
    <small>Type <b>{{confirmText}}</b> to continue:</small>
  </div>
  <form [formGroup]="confirmActionForm">
    <mdb-form-control>
      <input mdbInput [mdbValidate] type="text" formControlName="confirmText" id="confirmText"
        class="form-control form-control-lg" [validateSuccess]="false" placeholder="" maxLength="50"/>
      <label mdbLabel class="form-label" for="confirmText">{{confirmTextLabel}}</label>
    </mdb-form-control>
  </form>
</div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-xl btn-rounded btn-white btn-fill" (click)="modalRef.close()">Cancel</button>
  <button *ngIf="confirming&&!error" type="button" class="btn btn-xl btn-rounded btn-dark btn-fill" disabled>
      <span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span>
      {{confirmButtonPlural}} <span class="blink_me">...</span>
  </button>
  <button  [disabled]="confirmActionForm.controls.confirmText.value!==confirmText" *ngIf="!confirming&&!error" type="button" class="btn btn-xl btn-rounded btn-dark btn-fill" (click)="confirm()">
    {{confirmButton}}
  </button>
</div>
