import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  @ViewChild(MdbTabsComponent) tabs: MdbTabsComponent
  @Input() userProfile
  @Input() section: string = 'profile'

  constructor(
    public modalRef: MdbModalRef<UserSettingsComponent>,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    console.log(`👤 EO Account ID: ${this.userProfile.id}`)
    this.setActiveSection()
  }

  async setActiveSection() {
    if (this.tabs) {
      switch (this.section) {
        case 'profile':
          this.tabs.setActiveTab(0)
          break;
        case 'notifications':
          this.tabs.setActiveTab(1)
          break;
      }
    } else {
      // Keep retrying until tabs available
      setTimeout(() => {
        this.setActiveSection()
      }, 10);
    }

  }

}
