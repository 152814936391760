import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-video-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class ManageVideoDetailsComponent implements OnInit {
  @Input() itemDetails
  videoDetailsForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.videoDetailsForm = new FormGroup({
      name: new FormControl(this.itemDetails.name, Validators.compose([Validators.minLength(3), Validators.required])),
      description: new FormControl(this.itemDetails.description),
    });
  }

}
