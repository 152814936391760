import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DateTime, Settings } from 'luxon';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { CreateEventComponent } from '../../modals/create-event/create-event.component';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { EditEventComponent } from '../../modals/edit-event/edit-event.component';
import { TicketScannerComponent } from '../../ticket-scanner/ticket-scanner.component';
import { PurchaseSeatsComponent } from '../../modals/purchase-seats/purchase-seats.component';

@Component({
  selector: 'app-community-events-home',
  templateUrl: './events-home.component.html',
  animations: [fadeInEnterAnimation({ duration: 200 }),],
  styleUrls: ['./events-home.component.scss']
})
export class CommunityEventsHomeComponent implements OnInit {
  ticketScannerModalRef: MdbModalRef<TicketScannerComponent> | null = null;
  currentView: string = 'eventList'
  postDetails: any
  modalRef: MdbModalRef<CreateEventComponent> | null = null;
  seatCheckoutModalRef: MdbModalRef<PurchaseSeatsComponent> | null = null;
  editEventModalRef: MdbModalRef<EditEventComponent> | null = null;
  @Input() communityDetails: Community
  @Input() id
  @Input() initialLoad
  selectedSeats = []
  seatData = []
  events: any[] = []
  loading: boolean = true
  loadingEvent: boolean = false
  isLoadingSeatData: boolean = true
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 6 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");
  limit: number = 5
  error
  errorEvent
  orderDetails
  constructor(
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private location: Location
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.getEvents()
  }

  ngOnChanges() {
    this.route.queryParams
      .subscribe(params => {
        const eventId = params.id
        if (eventId) {
          this.getEvent(eventId)
          this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + eventId)
        }
      })
  }

  refreshseatData() {
    this.isLoadingSeatData = true
    const callable = this.functions.httpsCallable('getEventSeatmap');
    const obs = callable({
      communityId: this.communityDetails.id,
      id: this.postDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.seatData = res.seatmap
        this.isLoadingSeatData = false
      },
      error: (err) => {
        console.warn(err)
        this.errorEvent = err.message
      },
    })
  }

  getEvents() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityEvents');
    const obs = callable({
      communityId: this.communityDetails.id,
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000),
      limit: this.limit
    });
    obs.subscribe({
      next: (res) => {
        this.events = res.data
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }


  getEvent(eventId) {
    this.currentView = 'eventView'
    this.loadingEvent = true
    const callable = this.functions.httpsCallable('getCommunityEvent');
    const obs = callable({
      communityId: this.communityDetails.id,
      id: eventId
    });
    obs.subscribe({
      next: (res) => {
        this.postDetails = res
        this.loadingEvent = false
        this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + eventId)
      },
      error: (err) => {
        console.warn(err)
        this.errorEvent = err.message
      },
    })
  }

  viewSeatmap() {
    this.refreshseatData()
    this.selectedSeats = []
    this.currentView = 'seatMap'
  }

  checkout() {
    this.seatCheckoutModalRef = this.modalService.open(PurchaseSeatsComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails,
        selectedSeats: this.selectedSeats
      }
    })
    this.seatCheckoutModalRef.onClose.subscribe((data: any) => {
      this.selectedSeats = []
      if (data) {
        switch (data.message) {
          case 'purchaseComplete':
            this.orderDetails = data.orderDetails
            break;
          case 'seatsUnavailable':
            break;
        }
      } else {
        // User cancelled or closed modal - refresh seatmap
        this.refreshseatData()
      }
    });
  }

  ticketScanner() {
    this.ticketScannerModalRef = this.modalService.open(TicketScannerComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.ticketScannerModalRef.onClose.subscribe((data: any) => {
      if (data === 'reset') {
        this.ticketScanner()
      }
    })
  }


  createEvent() {
    this.modalRef = this.modalService.open(CreateEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.getEvents()
      }
    })
  }

  selectSeat(seatData) {
    switch (seatData.type) {
      case 'selected':
        this.selectedSeats.push({
          section: seatData.section,
          id: seatData.row + seatData.seat,
          priceArray: seatData.priceArray,
          row: seatData.row,
          seat: seatData.seat
        })
        break;
      case 'unselected':
        const index = this.selectedSeats.findIndex(seat => seat.id === seatData.id);
        if (index !== -1) {
          this.selectedSeats.splice(index, 1);
        }
        break;
    }
    this.cdRef.detectChanges()
  }

  editEvent() {
    this.editEventModalRef = this.modalService.open(EditEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails
      }
    })
    this.editEventModalRef.onClose.subscribe((data: any) => {
      if (data === 'created') {
        this.getEvent(this.postDetails.id)
      }
    })
  }


  viewDetails(item: any) {
    this.postDetails = item
    this.errorEvent = null
    this.loadingEvent = false
    this.currentView = 'eventView'
    this.postDetails = item
    this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + item.id)
  }


  returnToList() {
    this.currentView = 'eventList'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/events')
  }



}
