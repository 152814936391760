import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-add-guest',
  templateUrl: './add-guest.component.html',
  styleUrls: ['./add-guest.component.scss']
})
export class AddGuestComponent implements OnInit {
  inviteForm: FormGroup;

  constructor(
    public modalRef: MdbModalRef<AddGuestComponent>,
  ) { }

  ngOnInit(): void {
    this.inviteForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      phoneNumber: new FormControl('', [USPhoneValidator()]),
    });
  }

  submit() {
    this.modalRef.close({name: this.inviteForm.controls.name.value, phoneNumber: this.inviteForm.controls.phoneNumber.value})
  }

}

export function USPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneRegex = /^\d{10}$/;
    const isValid = phoneRegex.test(control.value);
    return isValid ? null : { invalidPhone: { value: control.value } };
  }
}
