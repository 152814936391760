import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-terms',
  templateUrl: './view-terms.component.html',
  styleUrls: ['./view-terms.component.scss']
})
export class ViewTermsComponent implements OnInit {
  @Input() termsContent: string

  constructor() { }

  ngOnInit(): void {
  }

}
