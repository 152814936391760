<section class="">
  <ng-container *ngIf="!loading; else displayLoading">




    <!-- <div class="row"> -->
      <!-- Need to replace with vidstack player -->
    <!-- </div><br> -->
    <app-add-chapter [playerTime]="playerTime" [maxTimeInSeconds]="duration" [minTimeInSeconds]="lastChapterEnd" (output)="outputNewChapter($event)"></app-add-chapter>
    <app-chapters-list [videoDetails]="videoDetails" [chapters]="chapterDetails" [currentChapter]="currentChapter"
    [nextChapter]="nextChapter" (seekTo)="seekTo($event)"></app-chapters-list>
  </ng-container>
  <ng-template #displayLoading>
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <ng-container *ngIf="!error; else displayError">
            <app-loading></app-loading>
          </ng-container>
          <ng-template #displayError>
            <app-https-error [error]="error"></app-https-error>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</section>
