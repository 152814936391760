import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbStepperComponent } from 'mdb-angular-ui-kit/stepper';
import { StripeInstance, StripeFactoryService } from 'ngx-stripe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-video',
  templateUrl: './purchase-video.component.html',
  styleUrls: ['./purchase-video.component.scss']
})
export class PurchaseVideoComponent implements OnInit {
  @Input() itemDetails
  @ViewChild('stepper') stepper!: MdbStepperComponent;
  step: number = 1
  paymentSuccessful: boolean = false;
  purchasedTime
  paymentError: string = ''
  paymentErrorType: string = 'warning'
  allStepsValid: boolean = false;
  allStepsForm: FormGroup;
  selectProductForm: FormGroup;
  selectProductValid: boolean = false
  selectPaymentForm: FormGroup;
  selectPaymentValid: boolean = false
  additionalInfoForm: FormGroup;
  additionalInfoValid: boolean = false
  checkoutForm: FormGroup;
  selectProduct: any = {
    price: {},
    product: {}
  }
  additionalInfo: any = {};
  paymentMethod: any = {};
  checkout = {};
  purchasing: boolean = false
  stripe: StripeInstance;
  constructor(
    public modalRef: MdbModalRef<PurchaseVideoComponent>,
    private functions: AngularFireFunctions,
    private stripeFactory: StripeFactoryService,
    private analytics: AngularFireAnalytics
  ) { }

  ngOnInit(): void {
    this.setupForms()
  }

  setupForms() {
    this.selectProductForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.selectPaymentForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.additionalInfoForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.checkoutForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.allStepsForm = new FormGroup({
      selectProductForm: this.selectProductForm,
      selectPaymentForm: this.selectPaymentForm,
      additionalInfoForm: this.additionalInfoForm,
      checkoutForm: this.checkoutForm,
    });
  }


  validateselectProduct(isValid) {
    isValid ? this.allStepsForm.controls.selectProductForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectProductForm.get('valid').setValue(null);
    if (!isValid) {
      this.selectProduct = {}
      this.selectProductValid = false
    } else {
      this.selectProductValid = true
    }
    this.validateAllSteps();
  }

  outputselectProduct($event) {
    this.selectProduct = $event;
  }

  validateselectPayment(isValid) {
    isValid ? this.allStepsForm.controls.selectPaymentForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectPaymentForm.get('valid').setValue(null);
    if (!isValid) {
      this.paymentMethod = {}
      this.selectPaymentValid = false
    } else {
      this.selectPaymentValid = true
    }
    this.validateAllSteps();
  }

  outputselectPayment($event) {
    this.paymentMethod = $event;
  }

  validateadditionalInfo(isValid) {
    isValid ? this.allStepsForm.controls.additionalInfoForm.get('valid').setValue("valid") : this.allStepsForm.controls.additionalInfoForm.get('valid').setValue(null);
    if (!isValid) {
      this.additionalInfo = {}
      this.additionalInfoValid = false
    } else {
      this.additionalInfoValid = true
    }
    this.validateAllSteps();
  }

  outputadditionalInfo($event) {
    this.additionalInfo = $event;
  }

  validatecheckout(isValid) {
    isValid ? this.allStepsForm.controls.checkoutForm.get('valid').setValue("valid") : this.allStepsForm.controls.checkoutForm.get('valid').setValue(null);
    if (!isValid) this.checkout = {};
    this.validateAllSteps();
  }

  outputcheckout($event) {
    this.checkout = $event;
  }

  validateAllSteps() {
    this.allStepsValid = this.allStepsForm.valid;
  }

  stepChange(event) {
    this.clearPaymentError()
    this.step = event.activeStepIndex + 1
  }

  next() {
    this.stepper.next()
  }

  previous() {
    this.stepper.previous()
  }

  purchase() {
    this.logStartCheckoutAnalytics()
    this.paymentError = ''
    this.purchasing = true
    const callable = this.functions.httpsCallable('createPaymentIntent');
    const obs = callable({
      priceId: this.selectProduct.price.id,
      videoId: this.itemDetails.id,
      paymentId: this.paymentMethod.paymentMethodId,
      ...(this.additionalInfo.email && {email: this.additionalInfo.email})
    });
    obs.subscribe({
      next: (res) => {
        this.handlePaymentIntent(res)
      },
      error: (err) => {
        this.handlePaymentError(err)
      },
    })
  }

  async handlePaymentIntent(paymentIntent) {
    this.stripe = await this.stripeFactory.create(environment.stripe, { stripeAccount: paymentIntent.stripeId })
    switch (paymentIntent.status) {
      case 'succeeded':
      case 'processing':
        this.purchasing = false
        this.purchasedTime = new Date().getTime()
        this.paymentSuccessful = true
        this.logPurchaseAnalytics(paymentIntent)
        break;
      case 'requires_action':
        this.paymentError = 'Your payment method requires additional information to process the payment successfully.'
        this.stripe.confirmCardPayment(paymentIntent.client_secret).subscribe(result => {
          if (result.error) { this.handlePaymentError(result.error.message, 'danger') }
          else {
            this.handlePaymentIntent(result.paymentIntent)
          }
        });

        break;
      case 'requires_payment_method':
        this.handlePaymentError(paymentIntent.error.message)
        break;
    }
  }

  handlePaymentError(message: string, type?: string) {
    if (!type) { type = 'warning' }
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    if (String(message).includes('declined')) { type = 'danger' }
    this.purchasing = false
    this.paymentErrorType = type
    this.paymentError = message
  }

  clearPaymentError() {
    this.paymentError = ''
    this.paymentErrorType = 'warning'
  }

  logPurchaseAnalytics(paymentIntent) {
    this.analytics.logEvent('purchase', {
      video: this.itemDetails.id,
      price: this.selectProduct.price.id,
      transaction_id: paymentIntent.id,
      value: (paymentIntent.amount/100),
      currency: paymentIntent.currency,
      items: [{
        item_id: this.selectProduct.price.id,
        item_name: this.selectProduct.product.name + '(' + this.itemDetails.name + ')',
        affiliation: this.itemDetails.communityDetails.name
      }]
    })
  }

  logStartCheckoutAnalytics() {
    this.analytics.logEvent('begin_checkout', {
      value: (this.selectProduct.price.amount/100),
      currency: this.selectProduct.price.currency,
      payment_type: 'Credit Card',
      items: [{
        item_id: this.selectProduct.price.id,
        item_name: this.selectProduct.product.name + '(' + this.itemDetails.name + ')',
        affiliation: this.itemDetails.communityDetails.name
      }]
    })
  }




}
