<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Payment Details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">


  <ng-container *ngIf="!loading; else displayLoading">
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="card mb-6">
          <div class="card-body text-center">
            <h5 class="card-title">{{(paymentDetails.amount / 100) | currency}}</h5>
            <p class="card-text">
              Amount
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="card mb-6">
          <div class="card-body text-center">
            <h5 class="card-title">{{(paymentDetails.created*1000) | date}}</h5>
            <p class="card-text">
              Created
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="card mb-6">
          <div class="card-body text-center">
            <h5 class="card-title">{{paymentDetails.customer.name}}</h5>
            <p class="card-text">
              <app-card-detail [cardDetails]="paymentDetails.payment_method_details"></app-card-detail>
            </p>
          </div>
        </div>
      </div>
    </div>
    <app-notice *ngIf="paymentDetails.refunded" content="This payment was refunded" type="danger"></app-notice>
    <div class="text-end mb-3">
      <a *ngIf="!paymentDetails.refunded" (click)="refund(paymentDetails)" class="pointer hoverUnderline text-body">Refund</a> <span *ngIf="(!paymentDetails.refunded)&&(paymentDetails.userId!=='guest')" > | </span> <a *ngIf="paymentDetails.userId!=='guest'" (click)="viewCustomer()" class="pointer hoverUnderline text-body">View Customer</a>
    </div>
    <div *ngIf="paymentDetails.lineItems">
      <div *ngFor="let lineItem of paymentDetails.lineItems">
        <app-video-invoice-item *ngIf="lineItem.type==='video'" [details]="lineItem"></app-video-invoice-item>
      </div>
    </div>

  </ng-container>
  <ng-template #displayLoading>
    <ng-container *ngIf="!error; else displayError">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </ng-template>



</div>
