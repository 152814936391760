<ng-container *ngIf="!loading; else displayLoading">
  <app-pending-payout [totalPending]="totalPending"></app-pending-payout>
  <div class="datatable">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="input-group">
          <input *ngIf="payouts.length>0" type="text" class="form-control form-control-lg" placeholder="Filter payouts"
            (keyup)="search($event)" #searchInput size=25 />
        </div>
      </div>
      <div>
        <div style="text-align:right;" class="text-right">
          <input class="button-input-no-outline" class="datepicker" type="text" ngxDaterangepickerMd [ranges]="ranges"
            [alwaysShowCalendars]="true" (datesUpdated)="updateDates($event)" />
          <button class="ngx-daterangepicker-action btn btn-rounded btn-white pointer"
            style="position: relative;z-index: 200;" (click)="openDatepicker();">
            <span class="pointer">{{startDate}} <i class="fas fa-long-arrow-alt-right mx-2"
                style="padding-top:0.15rem"></i>
              {{endDate}}</span>

          </button>&nbsp;
          <button (click)="export()" class="btn btn-rounded btn-white pointer">
            <span class="pointer"><i class="fas fa-download me-2"></i>Export
              <span *ngIf="savingExport">&nbsp;&nbsp;&nbsp;<span
                  class="spinner-border spinner-border-sm me-2"></span></span>
            </span>
          </button>
        </div>
      </div>

    </div>
    <div *ngIf="payouts.length===0">
      <app-no-results message="No payouts found for the specified date range."></app-no-results>
    </div>
    <div *ngIf="payouts.length>0">
      <table responsive class="table align-middle mb-0 bg-white"
        class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
        mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true" [dataSource]="payouts"
        [pagination]="pagination" [sort]="sort" [filterFn]="filterTable">

        <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
        <thead class="bg-light">
          <tr>
            <th [mdbTableSortHeader]="'id'">Payout</th>
            <th [mdbTableSortHeader]="'arrival_date'">Arrival Date</th>
            <th [mdbTableSortHeader]="'amount'">Amount</th>
            <th [mdbTableSortHeader]="'status'">Status</th>
            <th></th>
          </tr>
        </thead>
      </ng-container>
      <ng-template #noResultsFound>
        <div class="mb-5"></div>
        <app-no-results [message]="'No payouts found matching: ' + searchText"></app-no-results>
        <div class="mb-5"></div>
      </ng-template>
      <tbody>

          <tr *ngFor="let item of table.data; let i = index">
            <td>
              <p class="fw-normal mb-1"><b>{{item.description}}</b></p>
              <p class="fw-normal mb-1">ID: {{item.id.substring(3)}}</p>
            </td>
            <td>
              <p class="fw-normal mb-1">{{(item.arrival_date*1000) | timeAgo}}</p>
              <p class="fw-normal mb-1">{{(item.arrival_date*1000) | date:'fullDate'}}</p>
            </td>
            <td>
              <p class="fw-normal mb-1">{{(item.amount/100) | currency}}</p>
            </td>
            <td style="width:100px;">
              <span *ngIf="item.status==='paid'"
                [ngClass]="item.amount < 0 ? 'badge badge-danger rounded-pill d-inline' : 'badge badge-success rounded-pill d-inline'">
                {{ item.amount < 0 ? 'Withdrawn' : 'Paid' }} </span>
                  <span *ngIf="item.status==='in_transit'"
                    class="badge badge-warning rounded-pill d-inline">In-Transit</span>
                  <span *ngIf="item.status==='pending'" class="badge badge-warning rounded-pill d-inline">Pending</span>
                  <span *ngIf="item.status==='refunded'"
                    class="badge badge-danger rounded-pill d-inline">Refunded</span>
            </td>
            <td style="width:50px" class="text-end">
              <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
                  class="dropdown-toggle hidden-arrow tableDropdown text-center " role="button" mdbDropdownToggle aria-expanded="false">
                  <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                    class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                </span>
                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuLink">
                  <li (click)="details(item)">
                    <a class="dropdown-item pointer">Details</a>
                  </li>
                </ul>
              </span>
            </td>
          </tr>


        </tbody>
      </table>
      <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
    </div>
  </div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
