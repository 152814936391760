<!-- Header -->
<div class="p-5 text-center bg-gradient-animation bg-image" style="
      height: 200px;
    ">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">Pricing</h4>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-8">
        <!-- Content -->
        <div *ngIf="environmentName!=='production'">
          <div class="d-flex align-items-center">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li routerLink="/learn-more" class="breadcrumb-item pointer hoverUnderline"><a>Learn More</a></li>
                <li routerLink="/pricing" class="breadcrumb-item noselect">Pricing</li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- <h4 class="fw-bold mt-5 mb-4">Transparent Pricing</h4> -->
        <div class="row">
          <div class="col-md-6 col-lg-4 col-xl-4">
            <div class="card mt-1 mb-3">
              <div class="card-body">
                <h5 class="card-title text-center"><i class="fas fa-ticket fa-3x mb-2"></i></h5>
                <h5 class="card-title text-center">Event Tickets</h5>
                <h3 class="card-title text-center">Starts at $0.50

                  <i class="far fa-question-circle fa-xs"
                    mdbTooltip="$0.50 for tickets priced at $25.00 and below, $0.75 for tickets priced between $25.00 and $50.00, and $1.00 for tickets priced above $50.00."
                    [html]="true" placement="right"></i>

                  <h6>per ticket sold</h6>
                </h3>

              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-4">
            <div class="card mt-1 mb-3">
              <div class="card-body">
                <h5 class="card-title text-center"><i class="fas fa-play-circle fa-3x mb-2"></i></h5>
                <h5 class="card-title text-center">Video Streaming</h5>
                <h3 class="card-title text-center">15% + $0.50 <h6>per video sold</h6>
                </h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-4">
            <div class="card mt-1 mb-3">
              <div class="card-body">

                <h5 class="card-title text-center"><i class="fas fa-credit-card fa-3x mb-2"></i></h5>
                <h5 class="card-title text-center">Payment Processing</h5>
                <h3 class="card-title text-center">2.9% + $0.30 <h6>per transaction</h6>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <h4 class="fw-bold mt-5 mb-4">Pricing Calculator</h4>
        <mdb-tabs #tabs>
          <mdb-tab title="Event Ticketing">
            <ng-template mdbTabContent>
              <br class="noselect">
              <app-ticket-pricing-calculator></app-ticket-pricing-calculator>
            </ng-template>
          </mdb-tab>
          <mdb-tab title="Video Streaming">
            <ng-template mdbTabContent>
              <br class="noselect">
              <app-video-pricing-calculator></app-video-pricing-calculator>
            </ng-template>
          </mdb-tab>
        </mdb-tabs>
        <h4 class="fw-bold mt-5 mb-4">Frequently Asked Questions</h4>
        <app-pricing-faq></app-pricing-faq>
        <br class="noselect"><br class="noselect">
        <div *ngIf="environmentName!=='production'" class="text-center">
            <button [routerLink]="'/learn-more'" class="btn btn-medium btn-rounded me-3 mb-2 btn-lg text-capitalize">Learn More</button>
            <button [routerLink]="'/get-started'" class="btn btn-primary btn-rounded me-3 mb-2 btn-lg text-capitalize">Get Started</button>
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>
