import { Component, OnInit } from '@angular/core';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-work-in-progress',
  templateUrl: './work-in-progress.component.html',
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
  styleUrls: ['./work-in-progress.component.scss']
})
export class WorkInProgressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
