<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">Terms of Service</h4>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <div class="row mt-3">
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <h4 class="text-uppercase fw-bold mb-4">
          Terms of Service
        </h4>
        <p>
          Please read these Terms of Service ("Terms") carefully before using our website and/or mobile application (collectively referred to as the "Service") provided by Event Odyssey ("us," "we," or "our"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of the Terms, you may not use the Service.
        </p>

        <h5 class="fw-bold mt-4">1. Account Creation and Use</h5>
        <p>
          1.1 Eligibility:
        </p>
        <p>
          In order to use our Service, you must be at least 18 years old and have the legal capacity to enter into a contract. By using the Service, you represent and warrant that you meet these eligibility requirements.
        </p>

        <p>
          1.2 Account Creation:
        </p>
        <p>
          You may need to create an account to access certain features of the Service. When creating an account, you agree to provide accurate and complete information and to keep your account credentials secure. You are responsible for all activities that occur under your account.
        </p>

        <h5 class="fw-bold mt-4">2. Intellectual Property</h5>
        <p>
          2.1 Ownership:
        </p>
        <p>
          All content, logos, trademarks, and other materials on the Service are owned or licensed by Event Odyssey and are protected by intellectual property laws. You may not use, reproduce, modify, or distribute any content from the Service without our prior written consent.
        </p>

        <h5 class="fw-bold mt-4">3. User Conduct</h5>
        <p>
          3.1 Prohibited Activities:
        </p>
        <p>
          When using the Service, you agree not to engage in any unlawful, harmful, or abusive conduct. This includes but is not limited to: violating any applicable laws, infringing intellectual property rights, distributing malware, harassing others, or engaging in fraudulent activities.
        </p>

        <h5 class="fw-bold mt-4">4. Disclaimer of Warranties</h5>
        <p>
          The Service is provided on an "as is" and "as available" basis. We make no warranties or representations, express or implied, regarding the Service's reliability, accuracy, availability, or fitness for a particular purpose. Your use of the Service is at your own risk.
        </p>

        <h5 class="fw-bold mt-4">5. Limitation of Liability</h5>
        <p>
          To the extent permitted by applicable laws, Event Odyssey shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or in connection with the use or inability to use the Service, even if we have been advised of the possibility of such damages.
        </p>

        <h5 class="fw-bold mt-4">6. Governing Law and Jurisdiction</h5>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [your jurisdiction]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in [your jurisdiction].
        </p>
        <h5 class="fw-bold mt-4">7. Changes to the Terms</h5>
        <p>
          We reserve the right to modify or update these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Service. By continuing to use the Service after such changes, you agree to be bound by the modified Terms.
        </p>

        <h5 class="fw-bold mt-4">8. Contact Us</h5>
        <p>
          If you have any questions or concerns about these Terms, please contact us at <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a>.
        </p><br>
        <h5 class="fw-bold mt-4">Last Updated: {{lastUpdated | date}}</h5>
        <p>
          This Terms of Service was last updated on {{lastUpdated | date:'long'}}. Any changes or modifications made to the Terms will be effective as of that date.
        </p>
      </div>
    </div>
  </div>
</section>
