<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Settings</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body" style="padding-top: 0px; margin-top: 0px">
  <mdb-tabs #tabs>
    <mdb-tab title="Profile">
      <ng-template mdbTabContent>
        <div class="noselect mb-4"></div>
        <app-profile [userProfile]="userProfile"></app-profile>
      </ng-template>
    </mdb-tab>
    <mdb-tab title="Notifications">
      <ng-template mdbTabContent>
        <div class="noselect mb-4"></div>
        <app-edit-notifications [userProfile]="userProfile"></app-edit-notifications>
      </ng-template>
    </mdb-tab>
  </mdb-tabs>
</div>
