<h6>Video Streaming</h6>
<div class="card mb-4">
  <mdb-accordion [flush]="true">
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>What video formats are supported for uploads? Any filesize
        limits?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Rest assured that our platform supports a wide range of video formats such as MP4, MOV, AVI, and more.<br>
          We'll ensure compatibility and optimal streaming performance for all your users regardless of initial codec or
          filesize.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>How do I track engagement metrics?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Event Odyssey provides robust tracking of engagement metrics for your videos.<br>
          You can easily monitor total view counts and view counts over time per day, allowing you to gain valuable
          insights into audience engagement and video performance.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>Can I restrict access to groups of customers?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          By default, only community members can view or purchase videos on our platform.<br>
          However, we also offer flexible configurations to meet your specific needs allowing you to easily restrict
          access or offer exclusive deals to specific groups of customers.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>How do customers watch their purchased videos?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Customers can conveniently stream their purchased videos directly in their web browser.<br>
          Additionally, they have the option to cast the content to their TV using Google Cast or AirPlay.<br><br>
          In the future, we will also be launching native SmartTV apps to provide an even more seamless and enjoyable
          viewing experience.
        </div>
      </ng-template>
    </mdb-accordion-item>
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>Can my users search for songs/scenes within an uploaded concert
        video?</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="ms-4">
          Absolutely! Our platform offers advanced search functionality within uploaded concert videos.<br>
          You can identify chapters in your video and add additional data, such as the ensembles that performed, the
          creators involved (directors, choreographers, writers, arrangers), and more! This allows parents and viewers
          to easily find specific songs or scenes of interest within the concert video.
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
