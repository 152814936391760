import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal/modal-ref';
import { JoinCommunityWithCodeComponent } from 'src/app/components/modals/join-community-with-code/join-community-with-code.component';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ContactComponent } from 'src/app/components/support/contact/contact.component';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-public-community-profile',
  templateUrl: './public-community-profile.component.html',
  animations: [fadeInEnterAnimation({ duration: 200 }),],
  styleUrls: ['./public-community-profile.component.scss']
})
export class PublicCommunityProfileComponent implements OnInit {
  modalRef: MdbModalRef<ContactComponent> | null = null;
  communityDetails: any
  loading: boolean = true
  selectedArticleId: string
  joinWithCodeModalRef: MdbModalRef<JoinCommunityWithCodeComponent> | null = null;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private functions: AngularFireFunctions,
    private router: Router,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Support | Event Odyssey')
    this.route.queryParams
      .subscribe(params => {
        const communityId = params.id
        if (communityId) {
          this.getCommunityDetails(communityId)
        } else {
          this.router.navigateByUrl('/support')
        }
      })
  }


  contactSupport() {
    this.modalRef = this.modalService.open(ContactComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      data: {
        communityName: this.communityDetails.name,
        communityId: this.communityDetails.id,
      },
      ignoreBackdropClick: true,
    })
  }

  getCommunityDetails(communityId: string) {
    const callable = this.functions.httpsCallable('getPublicCommunityDetails');
    const obs = callable({
      id: communityId
    });
    obs.subscribe({
      next: (res) => {
        this.communityDetails = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.router.navigateByUrl('/support')
        // Submit Error Toast
        this.launchErrorToast()
      },
    })
  }

  launchErrorToast() {
    console.log('trigger error toast')
  }


}
