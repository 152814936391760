import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent implements OnInit {
  additionalDetailsForm: FormGroup
  @Input() itemDetails
  @Output() isValid = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();

  // loading: boolean = true
  loading: boolean = false
  displaySelectDropdown: boolean = false

  constructor(
    private functions: AngularFireFunctions,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.additionalDetailsForm = new FormGroup({
      email: new FormControl('', [Validators.required, emailValidator()]),
    });
    this.onFormChanges()
    this.userService.userChanged.subscribe((user: any) => {
      if(user.email) { this.additionalDetailsForm.get('email').setValue(user.email) }
    });
  }


  onFormChanges() {
    this.additionalDetailsForm.valueChanges.subscribe(val => {
      this.isValid.emit(this.additionalDetailsForm.valid);
      this.output.emit((JSON.parse(JSON.stringify(this.additionalDetailsForm.value))));
    })
  }


}

function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for validation
    const valid = emailRegex.test(control.value);
    return valid ? null : { 'invalidEmail': { value: control.value } };
  };
}
