import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-trustpilot-badge',
  templateUrl: './trustpilot-badge.component.html',
  styleUrls: ['./trustpilot-badge.component.scss']
})
export class TrustpilotBadgeComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.loadTrustpilotScript();
  }

  ngOnDestroy() {
    this.removeTrustpilotScript();
  }

  loadTrustpilotScript() {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    script.id = 'trustpilot-widget-script'; // Add an ID to identify later for removal
    this.document.head.appendChild(script);
    try {
      const trustboxRef = document.getElementById('trustbox');
      //@ts-expect-error
      window.Trustpilot.loadFromElement(trustboxRef);
    } catch { }
  }

  removeTrustpilotScript() {
    const script = this.document.getElementById('trustpilot-widget-script');
    if (script) {
      this.document.head.removeChild(script);
    }
  }
}