import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { AcceptTermsComponent } from 'src/app/components/modals/accept-terms/accept-terms.component';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';
import { Community } from 'src/app/interfaces/community';
import { environment } from 'src/environments/environment';
import { CreateVideoComponent } from '../videos/create-video/create-video.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-community-todos',
  templateUrl: './todos.component.html',
  styleUrls: ['./todos.component.scss']
})
export class TodosComponent implements OnInit {
  toastOptionsSuccess = environment.toastOptions.success as MdbNotificationConfig<{}>;
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  termsModalRef: MdbModalRef<AcceptTermsComponent> | null = null;
  createVideoModalRef: MdbModalRef<CreateVideoComponent> | null = null;
  launchingToDo: boolean = false
  @Input() communityDetails: Community
  toDoList = []
  loading: boolean = true
  error
  user
  removedItemCount: number = 0
  constructor(
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    private toastService: MdbNotificationService,
    private afAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user: any) => {
      this.user = user._delegate
      this.getCommunityToDos()
    })
  }

  getCommunityToDos() {
    this.loading = true
    this.launchingToDo = false
    const callable = this.functions.httpsCallable('getCommunityTodos');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.removedItemCount = 0
        const filteredData = res.filter(item => {
          if (!item.needs) {
            return true;
          }
          const dependentItem = res.find(depItem => depItem.id === item.needs);
          if (dependentItem && dependentItem.value === true) {
            return true;
          } else {
            this.removedItemCount++;
            return false;
          }
        });
        this.toDoList = filteredData
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  async launchTodo(toDo: any) {
    this.launchingToDo = true
    switch (toDo.type) {
      case 'setupStripe':
        const callable = this.functions.httpsCallable('getStripeAccountLink');
        const obs = callable({
          communityId: this.communityDetails.id,
          returnUrl: window.location.origin + '/communities/' + this.communityDetails.id + '/manage',
          refreshUrl: window.location.origin + '/communities/' + this.communityDetails.id + '/manage',
        });
        obs.subscribe({
          next: (res) => {
            window.location = res.url
          },
          error: (err) => {
            this.errorToast(err.message)
            this.launchingToDo = false
          },
        })

        break;
      case 'routerLink':
        const link = toDo.link.replace('$communityId', this.communityDetails.id)
        this.router.navigateByUrl(link)
        await this.delay(250);
        this.launchingToDo = false
        break;
      case 'createVideo':
        this.createVideoModalRef = this.modalService.open(CreateVideoComponent, {
          modalClass: 'modal-dialog-scrollable modal-xl',
          ignoreBackdropClick: true,
          data: {
            communityDetails: this.communityDetails,
            user: this.user
          }
        })
        this.createVideoModalRef.onClose.subscribe((message: any) => {
          if (message === 'created') {
            this.getCommunityToDos()
          }
        });
        await this.delay(250);
        this.launchingToDo = false
        break;
      case 'acceptTerms':
        this.termsModalRef = this.modalService.open(AcceptTermsComponent, {
          modalClass: 'modal-dialog-scrollable modal-lg',
          ignoreBackdropClick: true,
          data: {
            termsId: toDo.id,
            communityDetails: this.communityDetails
          }
        })
        this.termsModalRef.onClose.subscribe((message: any) => {
          if (message === 'created') {
            this.getCommunityToDos()
          }
        });
        await this.delay(250);
        this.launchingToDo = false
        break;
    }
  }


  errorToast(message: string) {
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

  successToast(message: string) {
    this.toastOptionsSuccess.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsSuccess)
  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
