<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Purchase Ticket<span *ngIf="selectedSeats.length>1">s</span></h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<ng-container *ngIf="!paymentSuccessful; else success">
  <div class="modal-body">
    <div class="text-center" *ngIf="countdownMinutes > 0 || countdownSeconds > 0">
      Seats reserved: Please order within {{ countdownMinutes }}:{{ countdownSeconds | number: '2.0' }}.
    </div>
    <div class="text-center" *ngIf="countdownMinutes <= 0 && countdownSeconds <= 0">
      <app-notice content="Reservation expired! You can continue making a purchase, but it may fail as your seats are no longer guaranteed." type="danger"></app-notice>
    </div>
    <mdb-stepper #stepper [orientation]="'vertical'" [linear]="true" (stepChange)="stepChange($event)">
      <mdb-step [name]="getSelectSeatTypeStepName()" [stepForm]="selectProductForm">
        <form [formGroup]="selectProductForm"><input type="hidden" formGroupName="valid"></form>
        <app-select-seat-prices [selectedSeats]="selectedSeats" (isValid)="validateselectProduct($event)"
          (output)="outputselectProduct($event)"></app-select-seat-prices>
      </mdb-step>
      <mdb-step [name]="'
      Payment Method'" [stepForm]="selectPaymentForm">
        <form [formGroup]="selectPaymentForm"><input type="hidden" formGroupName="valid"></form>
        <app-list-cards (isValid)="validateselectPayment($event)" (output)="outputselectPayment($event)" (nextStep)="next()"></app-list-cards>
      </mdb-step>
      <mdb-step [name]="'Checkout'" [stepForm]="checkoutForm">
        <form [formGroup]="checkoutForm"><input type="hidden" formGroupName="valid"></form>
        <app-checkout [eventDetails]="eventDetails" [ticketDetails]="selectProduct" [paymentMethod]="paymentMethod" [itemDetails]="selectedSeats"  (isValid)="validatecheckout($event)" (output)="outputcheckout($event)"
          (previous)="previous()" [paymentError]="paymentError" [paymentErrorType]="paymentErrorType" type="tickets"></app-checkout>
      </mdb-step>
    </mdb-stepper>
    <div class="modal-footer">
      <button *ngIf="step>1" (click)="previous()"  type="button"
        class="btn btn-grey btn-rounded">Go Back</button>
      <button *ngIf="step===1" (click)="next()" [disabled]="!selectProductValid" type="button"
        class="btn btn-primary btn-rounded">Next</button>
      <button *ngIf="step===2" (click)="next()" [disabled]="!paymentMethod.paymentMethod" type="button"
        class="btn btn-primary btn-rounded">Next</button>
      <button *ngIf="step>2" (click)="purchase()" [disabled]="purchasing" type="button"
        class="btn btn-primary btn-rounded">
        <span *ngIf="purchasing">Purchasing&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span>
        <span *ngIf="!purchasing">Purchase</span>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #success>
  <div class="modal-body">
    <app-payment-successful></app-payment-successful>

    <div class="modal-footer">
      <button (click)="modalRef.close()" type="button"
      class="btn btn-grey btn-rounded">Close</button>
      <a (click)="modalRef.close()"  [routerLink]="'/tickets/'+'41WaLIInigT4tcKNXkBh'"
      class="btn btn-primary btn-rounded text-start"><i class="fas fa-ticket-alt me-2"></i> View Tickets</a>
    </div>
  </div>

</ng-template>
