import { Component, Input, OnInit } from '@angular/core';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-https-error',
  templateUrl: './https-error.component.html',
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
  styleUrls: ['./https-error.component.scss']
})
export class HttpsErrorComponent implements OnInit {
  @Input() error
  constructor() { }

  ngOnInit(): void {
  }

}
