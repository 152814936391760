<div class="d-flex justify-content-center align-items-center h-100" style="margin-top: 20px; margin-bottom: 20px">
  <div class="row">
    <div #iconWrapper>
      <i
        *ngIf="iconVisible"
        [@tadaEnter]
        class="fas fas fa-exclamation-circle fa-2x bg-danger bg-gradient text-light"
      ></i>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center align-items-center h-100">
  <div class="row">
    <h5 class="display-5 mb-3">Card Declined</h5>
  </div>
</div>
<!-- <div class="d-flex justify-content-center align-items-center h-100">
  <div class="row">
    <p [innerHTML]="content" class="mb-3 "></p>
  </div>
</div> -->
