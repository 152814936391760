<app-pos-navigation [sidenav]="sidenav"></app-pos-navigation>
<mdb-sidenav-layout>
  <mdb-sidenav #sidenav="mdbSidenav" mode="over" scrollContainer="#scroll-container" [transitionDuration]="225">
    <div class="modal-body">
      <div class="h-100 d-flex flex-row justify-content-center align-items-center">
        <div class="align-self-start flex-fill">
          <img class="ms-1" src="./assets/logo.png" height="20" alt="" loading="lazy" />
        </div>
        <div class="align-self-center">
          <button type="button" class="btn-close" aria-label="Close" (click)="sidenav.toggle()"></button>
        </div>
      </div>
    </div>
    <ul class="sidenav-menu" id="scroll-container" mdbScrollbar>
      Sidenav goes here
    </ul>
    <div class="btn btn-rounded" (click)="modalRef.close()">Exit Point of Sale</div>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent>
    <ng-container *ngIf="displayMobilePOS; else displayMainView">
      <app-mobile-pos-view></app-mobile-pos-view>
    </ng-container>
    <ng-template #displayMainView>
      <app-main-pos-view></app-main-pos-view>
    </ng-template>
  </mdb-sidenav-content>
</mdb-sidenav-layout>
<em></em>
