import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AcceptTermsComponent } from 'src/app/components/modals/accept-terms/accept-terms.component';
import { Router } from '@angular/router';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';
import { EditVideoComponent } from 'src/app/components/modals/edit-video/edit-video.component';

@Component({
  selector: 'app-create-video',
  templateUrl: './create-video.component.html',
  styleUrls: ['./create-video.component.scss']
})
export class CreateVideoComponent implements OnInit {
  editModalRef: MdbModalRef<EditVideoComponent> | null = null;
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  public files: NgxFileDropEntry[] = [];
  videoDetailsForm: FormGroup;
  communities: any[] = []
  termsModalRef: MdbModalRef<AcceptTermsComponent> | null = null;
  displaySelectDropdown: boolean = false
  isLoadingPrecheck: boolean = true
  launchingToDo: boolean = false
  isSaving: boolean = false
  fileError: boolean = false
  preCheckPassed: boolean = false
  uploadProgress$: Observable<number>;
  fileName: string
  filePath: string
  @Input() user
  @Input() communityDetails
  accountTodos: any[]
  error
  constructor(
    public modalRef: MdbModalRef<CreateVideoComponent>,
    private functions: AngularFireFunctions,
    private modalService: MdbModalService,
    private toastService: MdbNotificationService,
    private router: Router,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.fetchCreateVideoPrecheck()
    this.videoDetailsForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      description: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      communities: new FormControl([], Validators.compose([Validators.minLength(1), Validators.required])),
      filePath: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required]))
    });
  }

  fetchCreateVideoPrecheck() {
    this.isLoadingPrecheck = true
    const callable = this.functions.httpsCallable('createVideoPrecheck');
    const obs = callable({
      communityId: this.communityDetails.id,
    });
    obs.subscribe({
      next: (res) => {
        this.communities = res.linkedCommunities
        this.accountTodos = [
          {icon: 'fas fa-file-invoice-dollar', type: 'setupStripe', name: 'Setup Billing', description: 'Needed to process payments/sell videos.', value: res.billingEnabled},
          {icon: 'fas fa-file-contract', type: 'acceptVideographerTerms', name: 'Accept Videographer Terms', description: 'Required to publish videos on Event Odyssey.', value: Boolean(res.completedTerms)},
          { icon: 'fas fa-link', type: 'linkCommunity', name: 'Link Community', description: 'Required to publish videos to communities.', value: res.linkedCommunities.length > 0 }
        ]
        this.accountTodos = this.accountTodos.filter(todo => todo.value !== true);
        this.preCheckPassed = this.accountTodos.length === 0
        this.isLoadingPrecheck = false
      },
      error: (err) => {
        this.error = err.message
        console.warn(err)
      },
    })
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = [files[0]];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.type.startsWith("video/")) {
            this.fileError = false
            this.uploadToFirebaseStorage(file);
          } else {
            this.fileError = true
          }
        });
      }
    }
  }

  private uploadToFirebaseStorage(file: File) {
    this.fileName = file.name
    this.filePath = `videos/${this.user.uid}/${new Date().getTime()}_${file.name}`;
    this.videoDetailsForm.controls.filePath.setValue('')
    const task = this.storage.upload(this.filePath, file);
    this.uploadProgress$ = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize(() => {
        this.videoDetailsForm.controls.filePath.setValue(this.filePath)
      })
    ).subscribe();
  }

  submit() {
    const name = this.videoDetailsForm.controls.name.value
    const description = this.videoDetailsForm.controls.description.value
    const filePath = this.videoDetailsForm.controls.filePath.value

    this.isSaving = true
    const callable = this.functions.httpsCallable('createVideo');
    const obs = callable({
      communityId: this.communityDetails.id,
      name: name,
      description: description,
      filePath: filePath
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created')
        this.isSaving = false
        this.editModalRef = this.modalService.open(EditVideoComponent, {
          modalClass: 'modal-dialog-scrollable modal-xl',
          ignoreBackdropClick: true,
          data: {
            section: 'details',
            itemDetails: res
          }
        })
      },
      error: (err) => {
        this.isSaving = false
        console.warn(err)
      },
    })
  }

  deleteUpload() {
    this.videoDetailsForm.get('filePath').setValue('')
    this.fileName = null
    this.filePath = null
    this.uploadProgress$ = null
  }


  calculateHeight(): string {
    const baseHeight = 230;
    const minimumHeight = 60;
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    return Math.max(minimumHeight, heights[this.communities.length] || baseHeight) + 'px';
  }

  async launchTodoAction(type: string) {
    this.launchingToDo = true
    switch(type) {
      case 'acceptVideographerTerms':
        this.termsModalRef = this.modalService.open(AcceptTermsComponent, {
          modalClass: 'modal-dialog-scrollable modal-lg',
          ignoreBackdropClick: true,
          data: {
            termsId: 'videographer-terms-of-service',
            communityDetails: this.communityDetails
          }
        })
        this.termsModalRef.onClose.subscribe((message: any) => {
            this.fetchCreateVideoPrecheck()
        });
        await this.delay(250);
        this.launchingToDo = false
        break;
      case 'setupStripe':
        const callable = this.functions.httpsCallable('getStripeAccountLink');
        const obs = callable({
          communityId: this.communityDetails.id,
          returnUrl: window.location.origin + '/communities/' + this.communityDetails.id + '/manage',
          refreshUrl: window.location.origin + '/communities/' + this.communityDetails.id + '/manage',
        });
        obs.subscribe({
          next: (res) => {
            window.location = res.url
          },
          error: (err) => {
            this.errorToast(err.message)
            this.launchingToDo = false
          },
        })
        break;
      case 'linkCommunity':
        this.router.navigateByUrl(`/communities/${this.communityDetails.id}/manage/settings/communities`)
        this.modalRef.close()
        break;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  errorToast(message: string) {
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }


}
