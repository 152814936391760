import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  @Input() chargeId
  @Input() communityName
  constructor(
    public modalRef: MdbModalRef<ContactComponent>,
  ) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      details: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      category: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
    });
  }

  submit() {
    const contactFormData = {
      details: this.contactForm.controls.details.value,
      email: this.contactForm.controls.email.value,
      category: this.contactForm.controls.category.value,
      ...(this.chargeId && { chargeId: this.chargeId }),
    }
  }

}

