import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { LinkCommunityComponent } from 'src/app/components/modals/link-community/link-community.component';
import { AddEnsembleComponent } from 'src/app/components/modals/add-ensemble/add-ensemble.component';
import { EditEnsembleComponent } from 'src/app/components/modals/edit-ensemble/edit-ensemble.component';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';

@Component({
  selector: 'app-list-ensembles',
  templateUrl: './list-ensembles.component.html',
  styleUrls: ['./list-ensembles.component.scss']
})
export class ListEnsemblesComponent implements OnInit {
  @ViewChild('table') ensemblesTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  confirmDeleteModalRef: MdbModalRef<ConfirmActionComponent>;
  addEnsembleModalRef: MdbModalRef<AddEnsembleComponent> | null = null;
  editEnsembleModalRef: MdbModalRef<EditEnsembleComponent> | null = null;
  linkCommunityModalRef: MdbModalRef<LinkCommunityComponent> | null = null;
  @Input() selectLocation: boolean = false
  @Input() communityDetails: Community
  @Input() stripeAccountDetails: any
  @Output() output = new EventEmitter<any>();

  searchText: string
  loading: boolean = true
  ensembles: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchCommunityEnsembles()
    })
  }

  fetchCommunityEnsembles() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityEnsembles');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.ensembles = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  selectVenueLocation(item) {

    this.output.emit((JSON.parse(JSON.stringify(item))));

  }

  linkCommunity() {
    this.linkCommunityModalRef = this.modalService.open(LinkCommunityComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.linkCommunityModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.fetchCommunityEnsembles()
      }
    })
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.ensemblesTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'name':
          let name = data[key]
          return name.toLowerCase().includes(searchTerm.toLowerCase());
        case 'address':
          let address = data[key]
          return address.toLowerCase().includes(searchTerm.toLowerCase());
        case 'seatmapDetails':
          let seats = data[key].seats + ' ' + data[key].seatType
          return seats.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  addEnsemble() {
    this.addEnsembleModalRef = this.modalService.open(AddEnsembleComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.addEnsembleModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.fetchCommunityEnsembles()
      }
    })
  }

  editEnsemble(ensembleDetails: any) {
    this.editEnsembleModalRef = this.modalService.open(EditEnsembleComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        ensembleDetails: ensembleDetails
      }
    })
    this.editEnsembleModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.fetchCommunityEnsembles()
      }
    })
  }


  deleteEnsemble(item: any) {
    let content = `<div class="text-center">Are you sure you want to delete ${item.name}?</p></div>`;
    this.confirmDeleteModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Delete Ensemble',
        content: content,
        confirmButton: 'Delete',
        confirmButtonPlural: 'Deleting',
        type: 'deleteEnsemble',
        miscData: {
          communityId: this.communityDetails.id,
          id: item.id
        }
      }
    })
    this.confirmDeleteModalRef.onClose.subscribe((message: any) => {
      if (message === 'deleted') {
        this.fetchCommunityEnsembles()
      }
    });
  }

}
