<div class="row">
  <div style="width: 406px; margin-right: 0px; padding-right: 0px">
    <div class="mb-7"></div>
    <div>
      <div class="text-center subText">Redemption Code</div>
    </div>
    <div style="background-color: black;">
      <div class="text-center text-white"><span class="activationText">{{activationCode}}</span></div>
    </div>
    <div class="mb-4"></div>
    <div>
      <div class="labelDescription subText ms-1">
        {{eventName}}<br>
        {{compName}}
      </div>
    </div>
  </div>
  <div style="width: 258px; padding-left: 0px; margin-left: 0px;">
    <ngx-qrcode-styling [config]="config" [data]="'https://eventodyssey.com/redeem?code='+activationCode"></ngx-qrcode-styling>
  </div>
</div>
