<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Campaign</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="campaignForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" />
          <label mdbLabel class="form-label" for="name">Name</label>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="campaignForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="description" id="description"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Description" />
          <label mdbLabel class="form-label" for="description">Description</label>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="campaignForm.controls.description.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>
  </form>


</div>
<div class="modal-footer">
  <button (click)="modalRef.close()" type="button" class="btn btn-grey btn-rounded"
    data-mdb-dismiss="modal">Close</button>
</div>
