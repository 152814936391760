import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';
import { CreateProductComponent } from 'src/app/components/modals/create-product/create-product.component';
import { EditProductComponent } from 'src/app/components/modals/edit-product/edit-product.component';

@Component({
  selector: 'app-manage-video-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class ManageVideoPricingComponent implements OnInit {
  modalRef: MdbModalRef<CreateProductComponent> | null = null;
  editPriceModalRef: MdbModalRef<EditProductComponent> | null = null;
  confirmDeleteModalRef: MdbModalRef<ConfirmActionComponent> | null = null;
  @Input() itemDetails
  @Input() communityDetails
  loading: boolean = true
  products: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchVideoPrices()
    })
  }

  fetchVideoPrices() {
    this.loading = true
    const callable = this.functions.httpsCallable('getVideoProducts');
    const obs = callable({
      videoId: this.itemDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.products = res
        this.loading = false
      },
      error: (err) => {
        this.loading = false
        console.warn(err)
        this.error = err.message
      },
    })
  }

  createProduct() {
    this.modalRef = this.modalService.open(CreateProductComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        itemDetails: this.itemDetails,
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((message: any) => {
      if (message) {
        this.fetchVideoPrices()
      }
    });
  }

  editPrice(priceDetails: any) {
    this.editPriceModalRef = this.modalService.open(EditProductComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        itemDetails: this.itemDetails,
        productDetails: priceDetails
      }
    })
    this.editPriceModalRef.onClose.subscribe((message: any) => {
      if (message) {
        this.fetchVideoPrices()
      }
    });
  }

  deletePrice(priceDetails: any) {
    let content = `<div class="text-center">Are you sure you want to delete <b>${priceDetails.product.name}</b>?</p></div>`;
    this.confirmDeleteModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Delete Card Reader',
        content: content,
        confirmButton: 'Delete',
        confirmButtonPlural: 'Deleting',
        type: 'deleteVideoPrice',
        miscData: {
          communityId: this.itemDetails.communityDetails.id,
          priceId: priceDetails.price.id,
          productId: priceDetails.product.id
        }
      }
    })
    this.confirmDeleteModalRef.onClose.subscribe((message: any) => {
      if (message === 'deleted') {
        this.fetchVideoPrices()
      }
    });
  }

}
