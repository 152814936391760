import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AddLocationComponent } from '../add-location/add-location.component';

@Component({
  selector: 'app-event-location',
  templateUrl: './event-location.component.html',
  styleUrls: ['./event-location.component.scss']
})
export class EventLocationComponent implements OnInit {
  addLocationModalRef: MdbModalRef<AddLocationComponent> | null = null;
  @Input() communityDetails

  constructor(
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<EventLocationComponent>,
  ) { }

  ngOnInit(): void {
  }

  outputSelectLocation(event: any) {
    this.modalRef.close(event)
  }
}
