<ng-container *ngIf="!loading; else displaySkeleton">
  <div class="row">
    <div *ngFor="let item of videoItems; let i = index" class="col-md-4 col-lg-3 col-xl-3">
      <app-community-list-item [itemDetails]="item"></app-community-list-item><br>
    </div>
    <div *ngIf="videoItems.length===0" class="row">
      <app-no-results [message]="'No videos found for ' + communityDetails.name + '.'"></app-no-results>
    </div>
  </div>
</ng-container>
<ng-template #displaySkeleton>
  <ng-container *ngIf="!error; else displayError">
    <div class="row">
      <div *ngFor="let item of [].constructor(6); let i = index" class="col-md-4 col-lg-3 col-xl-3">
        <app-list-item-loading></app-list-item-loading><br>
      </div>
    </div>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
