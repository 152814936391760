import { Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { pausable, PausableObservable } from 'rxjs-pausable';
import { Subscription, interval } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';

@Component({
  selector: 'app-redeem-confirmation',
  templateUrl: './redeem-confirmation.component.html',
  styleUrls: ['./redeem-confirmation.component.scss'],
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
})
export class RedeemConfirmationComponent implements OnInit {
  @Input() content: string
  width: any;
  paused = true;
  pausable: PausableObservable<number>;
  private subscription: Subscription;
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.width = window.innerWidth;
    //@ts-expect-error
    this.pausable = interval(800).pipe(pausable()) as PausableObservable<number>;
    //@ts-expect-error
    this.subscription = this.pausable.subscribe(this.launch.bind(this));
    this.pausable.pause();
    this.loadConfettiScript().then(() => {
      this.launchConfetti();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    // Remove the dynamically loaded script
    const script = this.document.getElementById('confetti-script');
    if (script) {
      this.renderer.removeChild(this.document.head, script);
    }
  }

  onResize(event) {
    this.width = window.innerWidth;
  }

  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async launch() {
    this.confetti({
      colors: ['#1266f1', '#ffa900', '#00b74a', '#f93154'],
      angle: this.random(60, 140),
      zIndex: 2000,
      spread: this.random(80, 150),
      particleCount: this.random(100, 250),
      origin: { y: 0.5 },
    });
  }

  async launchConfetti() {
    this.pausable.resume();
    await this.delay(4500);
    this.pausable.pause();
  }

  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  confetti(args: any) {
    if (typeof window['confetti'] === 'function') {
      window['confetti'].apply(this, arguments);
    } else {
      console.warn('Confetti library is not loaded yet.');
    }
  }

  // Function to dynamically load the confetti script
  loadConfettiScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('confetti-script')) {
        resolve();
        return;
      }
      const script = this.renderer.createElement('script');
      script.id = 'confetti-script';
      script.src = 'assets/confetti.js'; // Adjust the path to your confetti script
      script.onload = () => resolve();
      script.onerror = () => reject();
      this.renderer.appendChild(this.document.head, script);
    });
  }
}
