import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-ticket-pricing-calculator',
  templateUrl: './ticket-pricing-calculator.component.html',
  styleUrls: ['./ticket-pricing-calculator.component.scss']
})
export class TicketPricingCalculatorComponent implements OnInit {
  priceForm: FormGroup;
  paymentProcessingFees: number;
  platformFees: number;
  ticketsSold: number;
  grossSales: number;
  totalFees: number;
  totalRevenue: number;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this.priceForm = this.formBuilder.group({
      price: ['10.00', Validators.required],
      seats: ['1000', Validators.required],
      events: ['4', Validators.required],
      averageSold: ['0.8', Validators.required],
    });

    // Subscribe to form value changes
    this.priceForm.valueChanges.subscribe(val => {
      if (this.priceForm.valid) {
        this.calculateFees(val);
      }
    });
  }

  ngOnInit(): void {
    this.calculateFees({
      price: this.priceForm.controls.price.value,
      seats: this.priceForm.controls.seats.value,
      events: this.priceForm.controls.events.value,
      averageSold: this.priceForm.controls.averageSold.value,
    });
  }

  calculateFees(val: any) {
    this.ticketsSold = Math.round(val.seats * val.averageSold * val.events);
    this.grossSales = val.price * this.ticketsSold;
    this.paymentProcessingFees = this.grossSales * 0.029 + this.ticketsSold * 0.30;

    // Calculate platform fees based on price sliding scale
    if (val.price <= 24.99) {
      this.platformFees = this.ticketsSold * 0.50;
    } else if (val.price <= 49.99) {
      this.platformFees = this.ticketsSold * 0.75;
    } else {
      this.platformFees = this.ticketsSold * 1.00;
    }

    this.totalFees = this.paymentProcessingFees + this.platformFees;
    this.totalRevenue = this.grossSales - this.totalFees;
  }


}
