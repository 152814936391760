<div class="modal-body">
  <div class="d-flex flex-column flex-md-row align-items-center">
    <div class="p-2 flex-grow-1">
      <h6>Cookie Policy</h6>
      <small>Event Odyssey and our digital partners use cookies on this website. Some are necessary for the site to work, and others help us analyze site traffic to enhance your experience.</small>
    </div>
    <div class="p-2 d-flex justify-content-center">
      <div (click)="modalRef.close('false')" class="btn btn-rounded btn-dark m-2">Decline</div>
      <div (click)="modalRef.close('true')" class="btn btn-rounded btn-primary m-2">Accept</div>
    </div>
  </div>
</div>
