<form [formGroup]="videoDetailsForm">
  <div class="input-group mt-2">
    <div class="input-container">
      <mdb-form-control>
        <input mdbInput [mdbValidate] type="text" formControlName="name" id="name" class="form-control form-control-lg"
          [validateSuccess]="false" placeholder="Name" maxLength="50"/>
        <label mdbLabel class="form-label" for="name">Name</label>
        <div class="form-helper">
          <div class="form-counter">{{ videoDetailsForm.controls.name.value.length }} / 50</div>
          <div *ngIf="videoDetailsForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
          </div>
          <div *ngIf="videoDetailsForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
          </div>
        </div>
      </mdb-form-control>
    </div>
    <span class="input-group-text border-0"><app-validation-icon [inputValid]="videoDetailsForm.controls.name.invalid"></app-validation-icon>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>
  <br class="noselect">
  <br *ngIf="videoDetailsForm.controls.name.value.length < 3 || videoDetailsForm.controls.name.value.length === 50"
    class="noselect">
  <div class="input-group mt-2">
    <div class="input-container">
      <mdb-form-control>
        <textarea mdbInput [mdbValidate] type="text" formControlName="description"
          id="description" class="form-control form-control-lg" [validateSuccess]="false"
          placeholder="Description"></textarea>
        <label mdbLabel class="form-label" for="name">Description</label>
      </mdb-form-control>
    </div>
    <span class="input-group-text border-0"><span class="text-light me-2 noselect">&nbsp;</span>
      <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
  </div>




  <br>
  <div class="text-center">
    <button [disabled]="videoDetailsForm.invalid" type="button" class="btn btn-primary btn-rounded">Update</button>
  </div>
</form>
<br>
