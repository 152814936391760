<div class="container mt-4">
    <div class="row text-center">
      <div class="col" *ngFor="let app of apps" (click)="click(app.name)">
        <div mdbRipple class="btn rounded bg-gradient bg-primary p-4" style="width: 100%">
          <i class="{{app.icon}}" style="font-size: 24px; color: white;"></i>
        </div>
        <div class="mb-3">
          {{app.name}}
        </div>
      </div>
    </div>
  </div>
  