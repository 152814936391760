<ng-container *ngIf="!loading; else displayLoading">
  <app-notice content="Users listed here can perform potentially destructive actions. Only provide the necessary permissions for specific tasks." type="warning"></app-notice>
  <br class="noselect"><br class="noselect">

  <div class="datatable" >
    <div class="d-flex justify-content-between"  style="margin-bottom: 20px">
      <div>
          <div class="input-group">
              <input *ngIf="members.length>0" type="text" class="form-control form-control-lg" placeholder="Filter admins" (keyup)="search($event)"
              #searchInput size=25 />
          </div>
      </div>
      <div>
        <div class="d-flex justify-content-end align-items-center">
          <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle>
              <span><i class="fas fa-cog me-2"></i>Actions</span>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <!-- <li ><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Invite Member</a></li> -->
              <li (click)="fetchCommunityAccess()"><a class="dropdown-item pointer"><i class="fas fa-sync-alt me-2"></i>Refresh</a></li>
            </ul>
          </div>
        </div>
      </div>
  </div>
  <div *ngIf="members.length===0">
    <app-no-results message="No user access records found"></app-no-results>
  </div>
  <div *ngIf="members.length>0">
    <table
    responsive class="table align-middle mb-0 bg-white"
              class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;"   mdbTable mdbTableSort #table="mdbTable"
      #sort="mdbTableSort" [style]="" [responsive]="true" [sort]="sort" [dataSource]="members" [pagination]="pagination"
      [filterFn]="filterTable" >

      <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
      <thead class="bg-light">
        <tr>
          <th [mdbTableSortHeader]="'displayName'">Contact</th>
          <th [mdbTableSortHeader]="'permissions'">Permission(s)</th>
          <th [mdbTableSortHeader]="'lastSignIn'">Status</th>
          <th *ngIf="hasEditAdminPermissions"></th>
        </tr>
      </thead>
    </ng-container>
    <ng-template #noResultsFound>
      <div class="mb-5"></div>
      <app-no-results [message]="'No users found matching: ' + searchText"></app-no-results>
      <div class="mb-5"></div>
    </ng-template>
      <tbody>


        <tr *ngFor="let item of table.data; let i = index">
          <td>
            <div class="d-flex align-items-center">
              <img referrerPolicy="no-referrer" [src]="item.profilePic" (error)="item.profilePic = './assets/user-profile.png'" alt="" style="width: 45px; height: 45px;" class="rounded-circle" />
              <div class="ms-3">
                <p class="fw-bold mb-1">{{item.displayName}}</p>
                <p class="text-muted mb-0">{{item.email}}</p>
              </div>
            </div>
          </td>
          <td>
            <p class="fw-normal mb-1">
              {{item.permissions.length}} Permissions
              <!-- <span style="text-transform: Capitalize" *ngFor="let permission of item.permissions; index as i">
                {{permission}}<span *ngIf="((item.permissions.length!==1)&&((i+1)!==item.permissions.length))">,</span>
              </span> -->
            </p>
          </td>
          <td>
            {{(item.lastSignIn/1000) | timeAgo}}<br>
            {{(item.lastSignIn/1000) | date:'medium'}}<br>
          </td>
          <td *ngIf="hasEditAdminPermissions" style="width: 50px" class="text-end">
            <span  *ngIf="!(item.uid===currentUser.uid)" style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
              <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;" class="dropdown-toggle hidden-arrow tableDropdown text-center "
                role="button" mdbDropdownToggle aria-expanded="false">
                <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                  class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
              </span>
              <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <a  (click)="editUserPermissions(item)" class="dropdown-item pointer">Edit Access</a>
                </li>
              </ul>
            </span>
          </td>
        </tr>


      </tbody>
    </table>
    <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
  </div>
</div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
