import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Chapter } from 'src/app/interfaces/chapter';
import { Video } from 'src/app/interfaces/video';
import { MdbTablePaginationComponent, MdbTableDirective } from 'mdb-angular-ui-kit/table';

@Component({
  selector: 'app-chapters-list',
  templateUrl: './chapters-list.component.html',
  styleUrls: ['./chapters-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChaptersListComponent implements OnInit {
  @Input() videoDetails: Video
  @Output('seekTo') seekTo: EventEmitter<any> = new EventEmitter();
  @Input() chapters: Chapter[] = []
  @Input() currentChapter: Chapter
  @Input() nextChapter: Chapter
  currentChapterName: string = ''
  nextChapterName: string = ''
  searchText: string
  // Datetable
  @ViewChild('table') resourceTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;


  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.currentChapter) {
      this.currentChapterName = this.currentChapter.name
    } else {
      this.currentChapterName = ''
    }
    if (this.nextChapter) {
      this.nextChapterName = this.nextChapter.name
    } else {
      this.nextChapterName = ''
    }
    if (this.chapters && this.resourceTable) { this.resourceTable.dataSource = this.chapters }
  }

  triggerSeekTo(seconds: number) {
    this.seekTo.emit(seconds)
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.resourceTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: Chapter, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      if (key === 'groups') {
        let groupNames = ''
        data[key].forEach((group) => {
          groupNames += group.name + ' '
        })
        return groupNames.toLowerCase().includes(searchTerm.toLowerCase());
      } else if (key === 'name') {
        return data[key].toString().toLowerCase().includes(searchTerm.toLowerCase());

      } else if (key === 'description') {
        return data[key].toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

}
