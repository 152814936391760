<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Ensemble</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<ng-container *ngIf="!error; else displayError">
  <div class="modal-body">
    <form [formGroup]="ensembleForm">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
              class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50" />
            <label mdbLabel class="form-label" for="name">Name</label>
            <div class="form-helper">
              <div class="form-counter">{{ ensembleForm.controls.name.value.length }} / 50</div>
              <div *ngIf="ensembleForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
              </div>
              <div *ngIf="ensembleForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
              </div>
            </div>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><app-validation-icon [inputValid]="ensembleForm.controls.name.invalid"></app-validation-icon>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect">
      <br *ngIf="ensembleForm.controls.name.value.length < 3 || ensembleForm.controls.name.value.length === 50"
        class="noselect">
      <div class="input-group mt-2">
        <div class="input-container">
          <mdb-form-control>
            <textarea mdbInput [mdbValidate] type="text" formControlName="description" id="description"
              class="form-control form-control-lg" [validateSuccess]="false" placeholder="Description" maxLength="250"></textarea>
            <label mdbLabel class="form-label" for="description">Description</label>
            <div class="form-helper">
              <div class="form-counter">{{ ensembleForm.controls.description.value.length }} / 250</div>
              <div *ngIf="ensembleForm.controls.description.value.length === 250" class="form-counter">Maximum 250 characters allowed
              </div>
            </div>
          </mdb-form-control>
        </div>
        <span class="input-group-text border-0"><span class="me-2">&nbsp;</span>
          <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
      </div>
      <br class="noselect">
      <br *ngIf="ensembleForm.controls.description.value.length === 250"
        class="noselect">
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="submit()" [disabled]="ensembleForm.invalid||isSaving" type="button" class="btn btn-primary btn-rounded">
      <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Updating</span>
      <span *ngIf="!isSaving">Update</span>
    </button>
  </div>
</ng-container>
<ng-template #displayError>
  <div class="modal-body">
    <app-https-error [error]="error"></app-https-error>
  </div>
</ng-template>
