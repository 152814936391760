import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DateTime, Settings } from 'luxon';
import { Community } from 'src/app/interfaces/community';
import { communityOverview } from 'src/app/interfaces/communityOverview';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material'

@Component({
  selector: 'app-community-manage-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class ManageCommunityOverviewComponent implements OnInit {
  chartData = []
  @Input() communityDetails: Community
  loading: boolean = true
  communityOverview: communityOverview
  error

  // Datepicker
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  startYear = DateTime.local().toObject().month > 8 ? DateTime.local().toObject().year : DateTime.local().toObject().year - 1;
  endYear = DateTime.local().toObject().month > 8 ? DateTime.local().toObject().year + 1 : DateTime.local().toObject().year;
  ranges: any = {
    'Current month': [DateTime.local().startOf('month'), DateTime.local().endOf('month')],
    'Last month': [DateTime.local().minus({ 'months': 1 }).startOf('month'), DateTime.local().minus({ 'months': 1 }).endOf('month')],
    'Current year': [DateTime.local().startOf('year'), DateTime.local().endOf('year')],
    'Last year': [DateTime.local().minus({ 'year': 1 }).startOf('year'), DateTime.local().minus({ 'year': 1 }).endOf('year')],
  }
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 1 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");
  maxDate = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");

  constructor(
    private functions: AngularFireFunctions
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.fetchCommunityOverviewStats()
  }



  fetchCommunityOverviewStats() {
    // this.chartData = []
    this.loading = true
    const callable = this.functions.httpsCallable('getCommunityOverviewStats');
    const obs = callable({
      communityId: this.communityDetails.id,
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000)
    });
    obs.subscribe({
      next: (res) => {
        this.communityOverview = res
        this.chartData = res.chartData
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  updateDates(range) {
    if (range.startDate && range.endDate) {
      let newEndDate = range.endDate['$d'].toLocaleString('en-US', { timeZone: 'America/New_York' });
      range.endDate['$d'] = new Date(newEndDate);
      this.startDate = DateTime.fromJSDate(range.startDate['$d']).plus({ 'days': 1 }).toFormat("MM/dd/yyyy");
      this.endDate = DateTime.fromJSDate(range.endDate['$d']).toFormat("MM/dd/yyyy");
      this.fetchCommunityOverviewStats()
    }
  }



}
