<div class="container-fluid full-height p-0">
  <div class="row full-height m-0">
    <div class="col-8 p-0">
      <div>Buttons of Products</div>
      <!-- Other content goes here -->
    </div>
    <div class="col-4 p-0 d-flex flex-column">
      <div class="transaction-details">
        Transaction Details<br>Space fills up to whatever space available (adds scrollbar if needed)<br>
        Transaction Details<br>Space fills up to whatever space available (adds scrollbar if needed)<br>
        Transaction Details<br>Space fills up to whatever space available (adds scrollbar if needed)<br>
      </div>
      <div class="control-buttons">
        <app-pos-controls></app-pos-controls>
      </div>
    </div>
  </div>
</div>
