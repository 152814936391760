
import { Component, Input, OnInit, Inject, NgZone, PLATFORM_ID, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-seatmap-view',
  templateUrl: './seatmap-view.component.html',
  styleUrls: ['./seatmap-view.component.scss']
})
export class SeatmapViewComponent implements OnInit {
  showChart: boolean = true;
  isLoadingChart: boolean = false;
  @Output('selectSeat') output: EventEmitter<any> = new EventEmitter()
  @Input() isLoadingSeatData
  @Input() seatData = []
  private root: am5.Root;
  constructor(
    @Inject(PLATFORM_ID) private platformId, private zone: NgZone,
    private cdRef: ChangeDetectorRef
  ) { }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit(): void {
    console.clear = () => {
      this.root = am5.Root.new("seatMapChart");
      this.root._logo.dispose();
      this.root.setThemes([
        am5themes_Animated.new(this.root)
      ]);
    };
  }

  async ngOnChanges() {
    if (!this.isLoadingSeatData && this.seatData.length) {
      this.drawSeatLayout();
    } else {
      this.showChart = false;
    }
  }

  ngAfterViewInit() {
    this.ngOnChanges()
  }

  private async drawSeatLayout() {
    this.browserOnly(async () => {
      if (this.root) { await this.root.dispose(); }

      this.showChart = true;
      this.cdRef.detectChanges();
      this.root = am5.Root.new("seatMapChart");
      this.root._logo.dispose();
      this.root.setThemes([
        am5themes_Animated.new(this.root)
      ]);

      // Create a MapChart
      let chart = this.root.container.children.push(
        am5map.MapChart.new(this.root, {
          projection: am5map.geoEquirectangular(),
          zoomLevel: 0.8,
          maxZoomLevel: 10,
          minZoomLevel: 0.5
        })
      );

      // Create a blank GeoJSON map
      //@ts-expect-error
      chart.geodata = {
        type: "FeatureCollection",
        features: [],
      };

      // Create a MapPolygonSeries
      const polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(this.root, {}));
      polygonSeries.mapPolygons.template.setAll({
        tooltipHTML: "{tooltip}",
        cursorOverStyle: "pointer"
      });


      // Define fill colors based on seat state
      const availableColor = am5.color("#00cc00");
      const unavailableColor = am5.color("#ececec");
      const selectedColor = am5.color("#ffcc00");

      // Set seat fill colors
      polygonSeries.mapPolygons.template.adapters.add("fill", (fill, target) => {
        const dataItem = target.dataItem;
        if (dataItem) {
          const seat: any = dataItem.dataContext;
          switch(seat.state) {
            case 'available':
              target.set("cursorOverStyle", "pointer");
              return availableColor;
            case 'unavailable':
              target.set("cursorOverStyle", "not-allowed");
              return unavailableColor;
            case 'selected':
              target.set("cursorOverStyle", "pointer");
              return selectedColor;
          }
        }
        return fill;
      });

      // Handle seat click events
      polygonSeries.mapPolygons.template.events.on("click", (event) => {
        const seat: any = event.target.dataItem.dataContext;
        switch(seat.state) {
          case 'unavailable':
            break;
          case 'available':
            seat.state = "selected";
            this.output.emit({type: 'selected', id: seat.id, priceArray: seat.priceArray, row: seat.row, seat: seat.seat, section: seat.section})
            break;
          case 'selected':
            seat.state = "available";
            this.output.emit({type: 'unselected', id: seat.id, priceArray: seat.priceArray, section: seat.section})
            break;
        }

        event.target.set("fill", event.target.get("fill"));
      });

      // Create seat data
      const seatData = this.seatData.map((seat) => {
        let tooltip = `Seat: ${seat.row}${seat.seat}`;
        if (seat.state === 'available') {
          let lowestPrice = seat.priceArray[0].price;
          let highestPrice = seat.priceArray[0].price;
          for (let i = 1; i < seat.priceArray.length; i++) {
            if (seat.priceArray[i].price < lowestPrice) {
              lowestPrice = seat.priceArray[i].price;
            }
            if (seat.priceArray[i].price > highestPrice) {
              highestPrice = seat.priceArray[i].price;
            }
          }
          let formattedLowestPrice = (lowestPrice / 100).toFixed(2);
          let formattedHighestPrice = (highestPrice / 100).toFixed(2);
          if (seat.priceArray.length > 1) {
            tooltip += `<br>Price: $${formattedLowestPrice} - $${formattedHighestPrice}`;
          } else {
            tooltip += `<br>Price: $${formattedLowestPrice}`;
          }
        }
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [seat.x, seat.y],
                [seat.x, seat.y + 0.9],
                [seat.x + 0.9, seat.y + 0.9],
                [seat.x + 0.9, seat.y],
                [seat.x, seat.y],
              ],
            ],
          },
          row: seat.row,
          seat: seat.seat,
          state: seat.state,
          section: seat.section,
          id: seat.id,
          priceArray: seat.priceArray,
          tooltip: tooltip
        };
      });

      polygonSeries.data.setAll(seatData);

      // Create a zoom control
      let zoomControl = chart.set("zoomControl", am5map.ZoomControl.new(this.root, {}));
      chart.on("translateX", () => {
        // console.log(chart)


        let centerLongitude = chart._settings.translateX;
        let centerLatitude = chart._settings.translateY;
        let zoomLevel = chart.get("zoomLevel");
        this.drawMiniMap(centerLongitude, centerLatitude, zoomLevel)
      })
    });
  }

  drawMiniMap(centerLongitude, centerLatitude, zoomLevel) {
    console.log(centerLongitude, centerLatitude, zoomLevel)
  }





}
