<div class="d-flex justify-content-center align-items-center h-100">
  <div class="row">
    <div #iconWrapper>
      <i
        class="fas fas fa-exclamation-circle fa-2x bg-danger bg-gradient text-light"
      ></i>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center align-items-center h-100">
  <div class="row">
    <h5 class="display-5 mb-3">Ticket Error</h5>
  </div>
</div>
