<div class="datatable">
  <div class="d-flex justify-content-between" style="margin-bottom: 20px">
    <div>
      <div class="input-group">
        <input *ngIf="videoItems.length>0" type="text" class="form-control form-control-lg" placeholder="Filter videos"
          (keyup)="search($event)" #searchInput size=25 />
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-end align-items-center">
        <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
          <button [disabled]="loading" class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button"
            id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
            <span><i class="fas fa-cog me-2"></i>Actions</span>
          </button>
          <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
            <li (click)="addVideo()"><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Add Video</a></li>
            <li (click)="addStream()"><a class="dropdown-item pointer"><i class="fas fa-broadcast-tower me-2"></i>Add Livestream</a></li>
            <li (click)="fetchCommunityVideos()"><a class="dropdown-item pointer"><i
                  class="fas fa-sync-alt me-2"></i>Refresh</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!loading; else displaySkeleton">
    <div class="datatable">
      <div *ngIf="videoItems.length===0">
        <app-no-results message="No videos found"></app-no-results>
      </div>
      <div *ngIf="videoItems.length>0">
        <table responsive class="table align-middle mb-0 bg-white"
          class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
          mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true" [dataSource]="videoItems"
          [pagination]="pagination" [sort]="sort" [filterFn]="filterTable">
          <ng-container *ngIf="communityDetails.communityType.includes('Videographer'); else linkedCommunityView">
            <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
              <thead class="bg-light">
                <tr>
                  <th [mdbTableSortHeader]="'name'">Video</th>
                  <th [mdbTableSortHeader]="'created'">Created</th>
                  <th [mdbTableSortHeader]="'status'">Status</th>
                  <th></th>
                </tr>
              </thead>
            </ng-container>
            <ng-template #noResultsFound>
              <div class="mb-5"></div>
              <app-no-results [message]="'No videos found matching: ' + searchText"></app-no-results>
              <div class="mb-5"></div>
            </ng-template>
            <tbody>
              <tr *ngFor="let item of table.data; let i = index">
                <td class="">
                  <div class="d-flex flex-column flex-md-row align-items-start">
                    <div class="d-flex justify-start-start me-3">
                      <ng-container *ngIf="!item.gif.includes('processing'); else processingImg">
                        <img [routerLink]="'/videos/watch'" [queryParams]="{v: item.id}" [src]="item.thumbnail"
                          class="vidImg static pointer" />
                      </ng-container>
                      <ng-template #processingImg>
                        <div class="bg-image hover-overlay" mdbRipple rippleColor="light">
                          <img [src]="'./assets/tides/1.svg'" class="vidImg processingImg" />
                          <a>
                            <div class="mask" style="background-color: rgba(0, 0, 0, 0.25);"></div>
                          </a>
                        </div>
                      </ng-template>
                    </div>
                    <div class="justify-start flex-grow-1 text-wrap">
                      <h6>{{item.name}}</h6>
                      <small>{{item | json}}</small>
                      <!-- <small>{{item.views}}</small> -->
                    </div>
                  </div>
                </td>
                <td style="width: 150px">
                  {{item.created | date}}
                </td>
                <td style="width: 50px">
                  {{item.status}}
                </td>
                <td style="width: 50px" class="text-end">
                  <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                    <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
                      class="dropdown-toggle hidden-arrow tableDropdown text-center text-center" role="button"
                      mdbDropdownToggle aria-expanded="false">
                      <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                        class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                    </span>
                    <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                      aria-labelledby="navbarDropdownMenuLink">
                      <li>
                        <a (click)="edit(item, 'details')" class="dropdown-item pointer">Edit</a>
                      </li>
                    </ul>
                  </span>
                </td>
              </tr>
            </tbody>
          </ng-container>
          <ng-template #linkedCommunityView>
            <div class="row">
              <div class="col-md-4 col-lg-3 col-xl-3" *ngFor="let item of table.data; let i = index">
                <app-community-list-item [itemDetails]="item"
                [communityDetails]="communityDetails"></app-community-list-item>
              </div>
            </div>
            <div class="row" *ngIf="(pagination._total===0&&searchText)">
              <app-no-results [message]="'No videos found matching: ' + searchText"></app-no-results>
            </div>
          </ng-template>
        </table>
        <mdb-table-pagination #pagination [entries]="12" rowsPerPageText="Results"></mdb-table-pagination>
      </div>
    </div>

  </ng-container>
  <ng-template #displaySkeleton>
    <ng-container *ngIf="!error; else displayError">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </ng-template>
</div>
