import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { LoginComponent } from '../login/login.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MdbNotificationService, MdbNotificationRef, MdbNotificationConfig } from 'mdb-angular-ui-kit/notification';
import { ToastComponent } from '../toast/toast.component';
import { environment } from '../../../../environments/environment';
import { UserSettingsComponent } from '../../modals/user-settings/user-settings.component';
import { NotificationsComponent } from '../../modals/notifications/notifications.component';
import { EnvironmentService } from 'src/app/services/environment.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  notificationsModalRef: MdbModalRef<NotificationsComponent> | null = null;
  modalRef: MdbModalRef<LoginComponent> | null = null;
  userSettingsModalRef: MdbModalRef<UserSettingsComponent> | null = null;
  @Input() sidenav
  @Input() loggedIn
  @Input() environmentName: string
  @Input() userProfile
  notificationsCount: number = 0
  notificationsFetched: boolean = false
  launchedUserSettings: boolean = false
  notificationRef: MdbNotificationRef<ToastComponent>;
  toastOptions = environment.toastOptions.success as MdbNotificationConfig<{}>;
  constructor(
    private modalService: MdbModalService,
    private afAuth: AngularFireAuth,
    private toastService: MdbNotificationService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private themeToggleService: ThemeToggleService
    ) {}


  ngOnInit(): void {

  }

  ngOnChanges() {
    if(this.loggedIn) {
      this.checkEditNotifications()
      if(!this.notificationsFetched) {
        this.getNotificationsCount()
      }
    }
  }

  login() {
    this.modalRef = this.modalService.open(LoginComponent, {
      ignoreBackdropClick: true,
    })
  }

  userSettings(section = 'profile') {
    this.launchedUserSettings = true
    this.userSettingsModalRef = this.modalService.open(UserSettingsComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        userProfile: this.userProfile,
        section: section
      }
    })
    this.userSettingsModalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.launchedUserSettings = false
      }
    })
  }

  checkEditNotifications() {
    this.route.queryParams
    .subscribe(params => {
      if (params.editNotifications) {
        if(!this.launchedUserSettings) {
          this.userSettings('notifications')
        }
      }
    })
  }

  getNotificationsCount() {
    if(!this.notificationsFetched&&this.userProfile) {
      this.notificationsService.fetchNotifications(this.userProfile.id)
      this.notificationsService.notificationsCountChanged.subscribe((notificationsCount: number) => {
        this.notificationsCount = notificationsCount
      });
      this.notificationsFetched = true
    }
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this.toastOptions.data['content'] = 'You\'ve been logged out.';
      this.toastOptions.data['title'] = 'Logout Successful';
      this.toastService.open(ToastComponent, this.toastOptions)
    })
  }

  openNotifications() {
    this.notificationsModalRef = this.modalService.open(NotificationsComponent, {
      containerClass: 'right',
      modalClass: 'modal-dialog-scrollable modal-side modal-top-right',
      ignoreBackdropClick: false,
    })
  }
  
  toggle() {
    console.log('toggle sidenav')
    this.sidenav.toggle()
  }

  colorModeToggle() {
    this.themeToggleService.toggleTheme()
  }

}
