<section class="">
  <ng-container *ngIf="!loading; else displaySkeleton">
    <ng-container *ngIf="stripeAccountExists; else stripeNotSetup">
      <app-stripe-requirements [communityDetails]="communityDetails" [stripeAccountDetails]="stripeAccountDetails"></app-stripe-requirements>
      <app-community-manage-overview [communityDetails]="communityDetails"></app-community-manage-overview>
    </ng-container>
    <ng-template #stripeNotSetup>
      <h3>Congratulations on creating your community! 🎉</h3>
      <p>We're thrilled to have you aboard and excited to assist you in onboarding your members. To get started, we need to gather some essential information.</p>

      <p>Once you've completed the onboarding process, you'll be ready to unlock the full potential of Event Odyssey! One critical step in this journey is setting up your payment system through Stripe to effortlessly manage transactions and offer a seamless experience to your members.</p>

      <div>
        <button [ngClass]="(loadingStripeLink) ? 'loaderCursor' : 'pointer'" [disabled]="loadingStripeLink" (click)="setupStripe()" class="btn btn-primary btn-rounded text-start">Setup Stripe<span *ngIf="loadingStripeLink">&nbsp;&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span></button>
      </div>
      <br>
      <p>If you have any questions or need assistance along the way, our support team is always here to help. Feel free to reach out to <a class="pointer" routerLink="/support">here</a>.</p>
    </ng-template>
  </ng-container>
  <ng-template #displaySkeleton>
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <ng-container *ngIf="!error; else displayError">
            <app-loading></app-loading>
          </ng-container>
          <ng-template #displayError>
            <app-https-error [error]="error"></app-https-error>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</section>
